import "common/LanguageSelector.scss";
import PropTypes from "prop-types";
import exact from "prop-types-exact";

const LanguageSelector = ({ t, languages, languageCode, setLanguageCode }) => {
  const currentLanguage = languages.find(language => language.code === languageCode);
  if (!currentLanguage) return null;

  const languageChanged = e => setLanguageCode(e.target.value);

  return (
    <div className="LanguageSelector">
      <h2>{t("select_language")}</h2>
      <select value={currentLanguage.code} onChange={languageChanged}>
        {languages.map(language => (
          <option key={language.code} value={language.code} lang={language.code}>
            {language.title} ({language.code.toUpperCase()})
          </option>
        ))}
      </select>
    </div>
  );
};

LanguageSelector.propTypes = exact({
  t: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  languageCode: PropTypes.string.isRequired,
  setLanguageCode: PropTypes.func.isRequired
});

export default LanguageSelector;
