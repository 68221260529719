import "modals/Badges.scss";
import Modal from "react-modal";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { allBadges } from "elements/badges/badges.js";
import { elevateDown } from "common/sounds.js";

const Badges = ({ t, badges, availableBadges, isOpen, closeModal }) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel={t("your_badges")} className="Badges">
    <h2>{t("your_badges")}</h2>
    <div className="items">
      {availableBadges.map(badge => {
        const title = t("badge_" + badge.replace(/-/g, "_") + "_title");
        const description = t("badge_" + badge.replace(/-/g, "_") + "_description");
        return (
          <div key={badge} className={`badge ${badge}${badges.includes(badge) ? " owns" : ""}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        );
      })}
    </div>
    <button
      onClick={() => {
        closeModal();
        elevateDown();
      }}
      className="close"
    >
      {t("close")}
    </button>
  </Modal>
);

Badges.propTypes = exact({
  t: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  availableBadges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
});

export default Badges;
