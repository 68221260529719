import "scenes/Intro.scss";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { ReactComponent as IntroSvg } from "scenes/intro.svg";

import LanguageSelector from "common/LanguageSelector.js";

const Intro = ({ t, languages, languageCode, setLanguageCode, goToNextScene }) => (
  <div className="Intro scene">
    <header>
      <h1>{t("its_up_to_you")}</h1>
      <LanguageSelector t={t} languages={languages} languageCode={languageCode} setLanguageCode={setLanguageCode} />
    </header>
    <IntroSvg alt={t("title")} />
    <button onClick={goToNextScene}>{t("start")}</button>
  </div>
);

Intro.propTypes = exact({
  t: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  languageCode: PropTypes.string.isRequired,
  setLanguageCode: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired
});

export default Intro;
