const badgesToCharacterTypes = [
  { badge: "water-conservation-master", characterTypes: ["adult", "child"] },
  { badge: "clean-street-wizard", characterTypes: ["adult", "child"] },
  { badge: "eco-ninja", characterTypes: ["adult", "child"] },
  { badge: "energy-saving-expert", characterTypes: ["child"] },
  { badge: "low-waste-champion", characterTypes: ["child"] },
  { badge: "responsible-shopper", characterTypes: ["adult"] },
  { badge: "recycling-captain", characterTypes: ["adult", "child"] }
];

export const allBadges = badgesToCharacterTypes.map(badgeToCharacterTypes => badgeToCharacterTypes.badge);

export const getAvailableBadgesFor = characterType =>
  badgesToCharacterTypes
    .filter(badgeToCharacterTypes => badgeToCharacterTypes.characterTypes.includes(characterType))
    .map(badgeToCharacterTypes => badgeToCharacterTypes.badge);
