import "modals/BadgeAwarded.scss";
import Modal from "react-modal";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { allBadges } from "elements/badges/badges.js";
import { elevateDown } from "common/sounds.js";

const BadgeAwarded = ({ t, badge, isOpen, closeModal }) => {
  const title = t("badge_" + badge.replace(/-/g, "_") + "_title");
  const description = t("badge_" + badge.replace(/-/g, "_") + "_description");
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel={t("badge_action_1")} className="BadgeAwarded">
      <div className={`badge ${badge}`}>
        <div className="badge-text">
          <h3>{t("badge_action_1")}</h3>
          <p>{t("badge_action_2", { badge: title })}</p>
          <p>{description}</p>
        </div>
      </div>
      <button
        onClick={() => {
          closeModal();
          elevateDown();
        }}
        className="close"
      >
        {t("close")}
      </button>
    </Modal>
  );
};

BadgeAwarded.propTypes = exact({
  t: PropTypes.func.isRequired,
  badge: PropTypes.oneOf(allBadges).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
});

export default BadgeAwarded;
