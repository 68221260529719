const i18n = {
  title: "Juego Sociedad Saludable 2050",
  start: "Iniciar",
  select_language: "Seleccionar idioma",
  adult: "Adulto",
  child: "Niño o niña",
  fullscreen: "Pantalla completa",
  restart_scene: "Reiniciar escena",
  restart_game: "Reiniciar juego",
  previous: "Anterior",
  next: "Siguiente",
  close: "Cerrar",
  toggle_sound: "Sonido activado/desactivado",
  character_selection_title: "Selecciona tu personaje",
  character_selection_body: "Las decisiones que tomamos cada día determinan nuestro futuro. En este juego, simulamos situaciones cotidianas. Toma tus propias decisiones y descubre qué consecuencia tienen. ¡Asegúrate de encontrar los secretos ocultos para conseguir tus insignias! Empecemos creando tu personaje.",
  character_set_up_title: "Crea tu personaje",
  select_me: "Seleccióname",
  breakfast_title: "¿Qué tomas para desayunar?",
  your_badges: "Insignias",
  badge_water_conservation_master_title: "Máster",
  badge_water_conservation_master_description: "¡Enhorabuena! ¡Has conseguido la categoría de máster en el ahorro de un recurso escaso! El agua es fundamental para todos los tipos de vida.",
  badge_clean_street_wizard_title: "Mago/a",
  badge_clean_street_wizard_description: "¡Enhorabuena! Has conseguido la categoría de Mago/a en ayudar a que nuestro medio ambiente esté más limpio recogiendo la basura y depositándola en el contenedor.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "¡Enhorabuena! Has conseguido la categoría de Ninja en el ahorro de energía. Ahorrar energía con nuestros aparatos electrónicos es una manera excelente de contribuir a reducir el consumo de energía.",
  badge_energy_saving_expert_title: "Experto/a",
  badge_energy_saving_expert_description: "¡Enhorabuena! Has conseguido la categoría de Experto/a en el ahorro de energía. Sigue descubriendo formas de reducir el consumo de energía.",
  badge_low_waste_champion_title: "Campeón/campeona",
  badge_low_waste_champion_description: "¡Enhorabuena! Has conseguido la categoría de campeón/campeona en la reducción de residuos y en ayudar al medio ambiente reduciendo el consumo de plástico.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "¡Enhorabuena! Has conseguido la categoría de Pro en tomar decisiones saludables para el medio ambiente. Sigue utilizando la menor cantidad posible de plástico llevando contigo tu propia bolsa reutilizable.",
  badge_recycling_captain_title: "Estrella",
  badge_recycling_captain_description: "¡Enhorabuena! Eres una estrella del reciclaje. Es importante reciclar y reutilizar los materiales para tener un medio ambiente que goce de buena salud.",
  faucet: "Grifo",
  transportation_title_adult: "¿Cómo vas a trabajar?",
  transportation_title_child: "¿Cómo vas al colegio?",
  trash: "Basura",
  no_drink: "No bevo nada",
  no_drink_description: "",
  tap_water: "Agua del grifo",
  tap_water_description: "Ten en cuenta que es importante hidratarse. Intenta beber al menos entre 1,5 y 2 litros de agua al día.",
  milk: "Leche",
  milk_description: "Elige leche natural sin azúcar, preferiblemente con bajo contenido en grasa.",
  freshly_made_juice: "Zumo recién exprimido",
  freshly_made_juice_description: "Si te gusta el zumo, elígelo recién exprimido; ah, no es necesario añadir azúcar. Intenta comer fruta fresca.",
  coffee_or_tea: "Café/té",
  coffee_or_tea_description: "Disfruta de tu té y café preferiblemente sin azúcar.",
  plant_based_drink: "Bebidas vegetales",
  plant_based_drink_description: "Prueba las bebidas vegetales sin azúcares añadidos y, si es posible, elige aquellas que contienen vitaminas y minerales.",
  whole_grain_cereals: "Cereales integrales",
  whole_grain_cereals_description: "Opta por cereales integrales sin azúcares añadidos.",
  choco_balls: "Cereales de chocolate",
  choco_balls_description: "Los cereales para desayuno pueden suponer una parte considerable de nuestra ingesta diaria de azúcares. ¿Y si optases por aquellos con menos azúcares añadidos? Y ya que estás, elige aquellos con mayor cantidad de fibra.",
  white_bread_or_toast: "Pan blanco/tostada",
  white_bread_or_toast_description: "Prueba el pan integral.",
  wholemeal_bread_or_toast: "Pan integral/tostada",
  wholemeal_bread_or_toast_description: "Prueba el pan integral.",
  biscuits: "Galletas",
  biscuits_description: "Las galletas tienen un alto contenido calórico y pueden suponer una parte considerable de nuestro consumo diario de azúcares y grasas. Es preferible reducir su consumo. Opta por aquellas con menos grasas y menos azúcares. Y ya que estás, elige las que tengan mayor cantidad de fibra.",
  no_topping: "Sin nada",
  no_topping_description: "",
  yogurt: "Yogur",
  yogurt_description: "Prueba el yogur natural bajo en grasas y sin azúcares añadidos.",
  honey: "Miel",
  honey_description: "Elige el yogur natural sin añadir azúcar ni mermelada. Opta por añadir fruta o cereales enteros sin azúcar en su lugar.",
  jam: "Mermelada",
  jam_description: "Elige el yogur natural sin añadir azúcar ni mermelada. Opta por añadir fruta o cereales enteros sin azúcar en su lugar.",
  fruit: "Fruta",
  fruit_description: "Es una gran opción empezar el día con fruta, intenta elegir fruta de temporada. Si añades frutos secos, opta por aquellos que no lleven sal o azúcares añadidos.",
  butter: "Mantequilla",
  butter_description: "La mantequilla contiene grandes cantidades de grasa y puede ser salada. Intenta no comerla con demasiada frecuencia y elige aquella sin sal.",
  chocolate_cream: "Crema de cacao",
  chocolate_cream_description: "La crema de cacao contiene gran cantidad de azúcares y grasas saturadas. Intenta no comerla con demasiada frecuencia.",
  ham_or_bacon: "Jamón/bacon",
  ham_or_bacon_description: "La carne procesada contiene grandes cantidades de grasa y sal. Si comes carne procesada, limita su consumo.",
  cheese: "Queso",
  cheese_description: "El queso contiene grandes cantidades de sal y grasas saturadas. Intenta no comerlo con demasiada frecuencia y siempre en pequeñas porciones.",
  avocado_and_tomato: "Aguacate y tomate",
  avocado_and_tomato_description: "Es una gran opción empezar el día con algunas verduras. Intenta comer fruta y verdura en abundancia durante el día y opta por aquellas que sean de producción local y estacionales.",
  battery: "Batería",
  battery_description: "Presta atención a la batería, ¡te indica cómo afectan tus decisiones!",
  lunch_title: "¿Qué te apetece para comer?",
  breaktime_title: "Por la tarde: ¡tiempo libre!",
  snacking_title: "¿Te apetece picotear algo?",
  supermarket_title: "¿Qué vas a comprar?",
  dinner_selection_title: "¿Qué vas a cenar?",
  dinner_cooking_title: "¿Qué hay para cenar?",
  dinner_delivery_title: "¿Qué hay para cenar?",
  your_available_items: "Tus opciones",
  your_selections: "Tus selecciones",
  walk: "A pie",
  walk_description: "Practica actividad física en tu vida diaria. Si es posible, ve a la escuela o al trabajo caminando o en bicicleta. Te mantendrás en forma y es bueno para el medio ambiente.",
  bicycle: "En bicicleta",
  bicycle_description: "Practica actividad física en tu vida diaria. Si es posible, ve a la escuela o al trabajo caminando o en bicicleta. Te mantendrás en forma y es bueno para el medio ambiente.",
  scooter_or_motorbike: "En patinete/moto",
  scooter_or_motorbike_description: "Intenta usar el coche o la moto con menos frecuencia para ayudar al medio ambiente. Si no es posible, intenta compartir coche.",
  car: "En coche",
  car_description: "Intenta usar el coche o la moto con menos frecuencia para ayudar al medio ambiente. Si no es posible, intenta compartir coche.",
  public_transport: "En transporte público",
  public_transport_description: "Utiliza el transporte público y combínalo con los desplazamientos a pie y en bicicleta en lugar de ir en coche. Deja a un lado el coche y ayuda al medio ambiente.",
  salad_with_egg: "Ensalada con huevo",
  salad_with_egg_description: "Añade verduras frescas a tus comidas, siempre que sea posible.",
  beef_steak: "Filete de ternera",
  beef_steak_description: "Si comes carne, considera la posibilidad de reducir su consumo, especialmente de carne roja o procesada. Es bueno para tu salud y para el medio ambiente.",
  chicken: "Pollo",
  chicken_description: "Si comes carne, considera la posibilidad de reducir su consumo, especialmente de carne roja o procesada. Es bueno para tu salud y para el medio ambiente.",
  lentil_soup: "Lentejas",
  lentil_soup_description: "Se recomienda consumir legumbres con regularidad para tener una dieta variada y, mayoritariamente, de origen vegetal.",
  vegetable_burger: "Hamburguesa vegetal",
  vegetable_burger_description: "Las hamburguesas vegetales pueden ser una buena alternativa a la carne. Fíjate en que no contengan demasiada sal.",
  white_pasta: "Pasta blanca",
  white_pasta_description: "Opta por los productos integrales a la hora de elegir la pasta, el arroz y el pan.",
  wholegrain_pasta: "Pasta integral",
  wholegrain_pasta_description: "Opta por los productos integrales a la hora de elegir la pasta, el arroz y el pan.",
  white_rice: "Arroz blanco",
  white_rice_description: "Opta por los productos integrales a la hora de elegir la pasta, el arroz y el pan.",
  wholegrain_rice: "Arroz integral",
  wholegrain_rice_description: "Opta por los productos integrales a la hora de elegir la pasta, el arroz y el pan.",
  white_bread_bun: "Pan blanco",
  white_bread_bun_description: "Opta por los productos integrales a la hora de elegir la pasta, el arroz y el pan.",
  no_salad: "Sin acompañamiento",
  no_salad_description: "",
  fresh_green_salad: "Ensalada fresca",
  fresh_green_salad_description: "Pon color en tus comidas y añade verduras de temporada, cuanta más variedad mejor.",
  french_fries: "Patatas fritas",
  french_fries_description: "Se recomienda optar por las patatas cocidas en lugar de productos fritos a base de patata.",
  boiled_broccoli: "Brócoli hervido",
  boiled_broccoli_description: "Intenta comer mucha verdura a lo largo del día. Opta por verduras locales y de temporada.",
  bottled_water: "Agua embotellada",
  bottled_water_description: "Bebe agua del grifo para reducir el uso de plástico.",
  soft_drink: "Refrescos",
  soft_drink_description: "Las bebidas azucaradas representan una parte considerable de nuestra ingesta diaria de azúcar. ¿Y si optas por beber agua?",
  beer_or_wine: "Cerveza/vino",
  beer_or_wine_description: "Limita el consumo de alcohol de cualquier tipo. Evitar el consumo de alcohol es bueno para tu salud.",
  watching_tv: "Ver la televisión o jugar a videojuegos",
  watching_tv_description: "Haz actividad física e intenta reducir el tiempo que pasas sentado. Durante el tiempo libre, intenta practicar algún deporte, jugar con tus amigos o ayudar a tu comunidad. Es bueno para tu salud y para la sociedad. Evita pasar demasiado tiempo frente a una pantalla.",
  doing_sports: "Hacer deporte",
  doing_sports_description: "Haz actividad física e intenta reducir el tiempo que pasas sentado. Durante el tiempo libre, intenta practicar algún deporte, jugar con tus amigos o ayudar a tu comunidad. Es bueno para tu salud y para la sociedad. Evita pasar demasiado tiempo frente a una pantalla.",
  playing_outside: "Jugar fuera de casa",
  playing_outside_description: "Haz actividad física e intenta reducir el tiempo que pasas sentado. Durante el tiempo libre, intenta practicar algún deporte, jugar con tus amigos o ayudar a tu comunidad. Es bueno para tu salud y para la sociedad. Evita pasar demasiado tiempo frente a una pantalla.",
  charity_work: "Voluntariado con adultos",
  charity_work_description: "Haz actividad física e intenta reducir el tiempo que pasas sentado. Durante el tiempo libre, intenta practicar algún deporte, jugar con tus amigos o ayudar a tu comunidad. Es bueno para tu salud y para la sociedad. Evita pasar demasiado tiempo frente a una pantalla.",
  carrots_tomatoes: "Zanahorias/tomates cherry",
  carrots_tomatoes_description: "Prioriza las frutas, las verduras o los frutos secos. Reduce el consumo de aperitivos salados y bebidas con alto contenido en azúcares, grasas y sal.",
  fresh_fruit: "Fruta fresca",
  fresh_fruit_description: "Prioriza las frutas, las verduras o los frutos secos. Reduce el consumo de aperitivos salados y bebidas con alto contenido en azúcares, grasas y sal.",
  chocolate_bar: "Tableta de chocolate",
  chocolate_bar_description: "Prioriza las frutas, las verduras o los frutos secos. Reduce el consumo de aperitivos salados y bebidas con alto contenido en azúcares, grasas y sal.",
  chips: "Patatas fritas de bolsa",
  chips_description: "Prioriza las frutas, las verduras o los frutos secos. Reduce el consumo de aperitivos salados y bebidas con alto contenido en azúcares, grasas y sal.",
  juice_drink: "Bebida de frutas",
  juice_drink_description: "En lugar de bebidas de frutas, opta por fruta fresca o zumos recién exprimidos sin azúcar añadido.",
  supermarket_prompt: "Escoge hasta 9 productos",
  raw_fish: "Pescado fresco",
  raw_fish_description: "Comer pescado de forma regular te ayuda a obtener nutrientes importantes. Prueba diferentes tipos de pescado.",
  raw_beef: "Carne fresca de tenera",
  raw_beef_description: "Si comes carne, considera la posibilidad de reducir su consumo, especialmente de carne roja o procesada. Es bueno para tu salud y para el medio ambiente.",
  raw_chicken: "Carne fresca de pollo",
  raw_chicken_description: "Si comes carne, considera la posibilidad de reducir su consumo, especialmente de carne roja o procesada. Es bueno para tu salud y para el medio ambiente.",
  whole_salad: "Plato completo de ensalada por ejemplo, con lechuga",
  whole_salad_description: "Prioriza las frutas y verduras frescas, especialmente las de temporada y de producción local.",
  packaged_salad: "Ensalada envasada",
  packaged_salad_description: "Prioriza las frutas y verduras frescas, especialmente las de temporada y de producción local.",
  seasonal_fruit: "Fruta de temporada y producción local",
  seasonal_fruit_description: "Prioriza las frutas y verduras frescas, especialmente las de temporada y de producción local.",
  exotic_fruit: "Frutas exóticas",
  exotic_fruit_description: "Prioriza las frutas y verduras frescas, especialmente las de temporada y de producción local.",
  ham: "Jamón",
  ham_description: "La carne procesada contiene gran cantidad de grasa y sal. Si comes carne procesada, intenta limitar su consumo.",
  tofu: "Tofu",
  tofu_description: "Explora nuevos productos vegetales y nuevas recetas.",
  hummus: "Hummus",
  hummus_description: "El hummus es una buena alternativa que puede ayudarte a aumentar el consumo de legumbres.",
  precooked_pizza: "Pizza precocinada",
  precooked_pizza_description: "Las comidas precocinadas pueden contener grandes cantidades de sal o grasa. Opta por aquellos productos que no estén precocinados. Esto te permitirá controlar la cantidad de sal, azúcares y grasas en las comidas.",
  precooked_fries: "Patatas fritas precocinadas",
  precooked_fries_description: "Las comidas precocinadas pueden contener grandes cantidades de sal o grasa. Opta por aquellos productos que no estén precocinados. Esto te permitirá controlar la cantidad de sal, azúcares y grasas en las comidas.",
  food_delivery: "Comida a domicilio",
  food_delivery_description: "",
  cooking_at_home: "Cocinar en casa",
  cooking_at_home_description: "",
  cheeseburger: "Hamburguesa con queso, patatas fritas y refresco",
  cheeseburger_description: "Evita consumir este tipo de comidas. Son ricas en grasa y sal. Para beber, agua.",
  pizza_and_soft_drink: "Pizza y refresco",
  pizza_and_soft_drink_description: "Estas comidas tienen un alto contenido en grasas, sal y azúcares. Elige pizzas «sencillas» con menos ingredientes o más verduras y opta por aquellas con masa integral, si es posible. Para beber, agua.",
  noodles_with_vegetables: "Tallarines con verduras",
  noodles_with_vegetables_description: "Se trata de una buena opción para aumentar el consumo de verduras. Añade algunas legumbres para completar la comida.",
  mushroom_risoto: "Risotto de setas",
  mushroom_risoto_description: "Las setas son una buena opción vegetariana. Intenta no añadir demasiado queso. Puedes completar esta comida con una ensalada.",
  precooked_meal: "Plato de comida precocinado - nuggets de pollo",
  precooked_meal_description: "Las comidas precocinadas pueden contener grandes cantidades de sal, grasa e incluso azúcares, Opta por aquellos productos que no estén precocinados. Puedes controlar de forma fácil la cantidad de estos nutrientes. Para dar sabor a la comida, utiliza hierbas aromáticas y especias en lugar de sal.",
  frozen_pizza: "Pizza congelada",
  frozen_pizza_description: "Las comidas precocinadas pueden contener grandes cantidades de sal, grasa e incluso azúcares, Opta por aquellos productos que no estén precocinados. Puedes controlar de forma fácil la cantidad de estos nutrientes. Para dar sabor a la comida, utiliza hierbas aromáticas y especias en lugar de sal.",
  rice_and_salad: "Arroz cocinado en casa y ensalada verde",
  rice_and_salad_description: "Las comidas precocinadas pueden contener grandes cantidades de sal, grasa e incluso azúcares, Opta por aquellos productos que no estén precocinados. Puedes controlar de forma fácil la cantidad de estos nutrientes. Para dar sabor a la comida, utiliza hierbas aromáticas y especias en lugar de sal.",
  ham_sandwich_and_soft_drink: "Sándwich de jamón y refresco",
  ham_sandwich_and_soft_drink_description: "La carne procesada contiene gran cantidad de grasa y sal. Si comes carne procesada, intenta limitar su consumo.",
  wholegrain_pasta_with_sauce: "Pasta integral con salsa vegetal",
  wholegrain_pasta_with_sauce_description: "Prueba la pasta integral.",
  hnw_title_adult: "Un mundo nuevo y saludable",
  hnw_description_1_adult: "Las decisiones que tomamos definen nuestro futuro. Si todo el mundo eligiese comer y vivir como lo has hecho tú en este juego, nuestro futuro sería más saludable, con personas que se cuidan las unas a las otras y al medio ambiente.",
  hnw_description_2_adult: "La ciencia coincide en que, para preservar nuestra salud y la de nuestro planeta, debemos seguir una dieta variada: rica en cereales, legumbres, verduras y frutas frescas. Evita el consumo de alimentos procesados, carne y aperitivos salados con alto contenido en sal, azúcares y grasas. Además, estaremos más saludables y cuidaremos del medio ambiente si practicamos actividad física, caminamos, montamos en bicicleta o utilizamos el transporte público y reducimos el uso del coche.",
  hnw_description_3_adult: "Hay pequeñas decisiones que pueden mejorar nuestra salud y nuestro medio ambiente: aire y agua limpios, alimentos seguros, suelo fértil y espacios verdes, respetar la naturaleza y a todas las personas. Aplícalo también fuera de este juego. Haz que tus amigos y tu familia se sumen a este viaje.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "¿Quieres volver a jugar?",
  hnw_title_child: "Las decisiones que tomamos configuran nuestro futuro",
  hnw_description_1_child: "Si todo el mundo eligiese comer y vivir como lo has hecho tú en este juego, tendríamos un futuro más saludable.",
  hnw_description_2_child: "Esto es lo que puedes hacer:\n- Asegúrate de seguir una dieta variada para proteger tu salud y la de nuestro planeta.\n- Opta por los cereales, las legumbres, las verduras y las frutas frescas.  \n- Limita el consumo de alimentos procesados, la carne y los aperitivos salados con alto contenido en sal, azúcares y grasas.\n- Haz actividad física, pasea, monta en bicicleta o utiliza el transporte público.\n- Reduce el uso del coche.",
  hnw_description_3_child: "Estas decisiones tan sencillas pueden mejorar tu salud, a la vez que ayudas a cuidar del medio ambiente y de todas las personas.",
  hnw_description_4_child: "Piensa en ello también fuera de este juego.",
  hnw_description_5_child: "Haz que tus amigos y tu familia se sumen a este viaje.",
  hnw_description_6_child: "¿Quieres volver a jugar?",
  etl_title_adult: "Comer para vivir",
  etl_description_1_adult: "Las decisiones que tomamos definen nuestro futuro. Puede que no lo sepas, pero tu estilo de vida puede tener un impacto en la salud de nuestro planeta.",
  etl_description_2_adult: "La ciencia coincide en que, para preservar nuestra salud y la de nuestro planeta, debemos seguir una dieta variada: rica en cereales, legumbres, hortalizas frescas y frutas, y limitando el consumo de los alimentos procesados, la carne y los aperitivos con alto contenido en sal, azúcares y grasas. Además, todo el mundo sale ganando si practicamos actividad física, caminamos, montamos en bicicleta o utilizamos el transporte público y reducimos el uso del coche.",
  etl_description_3_adult: "Hay pequeñas decisiones que pueden mejorar nuestra salud y nuestro medio ambiente: aire y agua limpios, alimentos seguros, suelo fértil y espacios verdes, respetando la naturaleza y a todas las personas.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "¿Quieres volver a jugar?",
  etl_title_child: "Las decisiones que tomamos configuran nuestro futuro",
  etl_description_1_child: "Si todos mejorásemos nuestra manera de comer y de vivir, podríamos tener un futuro más saludable.",
  etl_description_2_child: "Esto es lo que puedes hacer:\n- Asegúrate de seguir una dieta variada para proteger tu salud y la de nuestro planeta.\n- Opta por los cereales, las legumbres, las verduras y las frutas frescas.  \n- Limita el consumo de alimentos procesados, la carne y los aperitivos salados con alto contenido en sal, azúcares y grasas.\n- Haz actividad física, pasea, monta en bicicleta o utiliza el transporte público.\n- Reduce el uso del coche.",
  etl_description_3_child: "Estas decisiones tan sencillas pueden mejorar tu salud, a la vez que ayudas a cuidar del medio ambiente y de todas las personas.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "¿Quieres volver a jugar?",
  share: "Compartir",
  replay: "Repetir",
  its_up_to_you: "Depende de ti",
  badge_action_1: "¡Desvelado el secreto!",
  badge_action_2: "Ganaste la insignia %{badge}",
  fridgeDoor: "Puerta de frigorífico",
  light: "Luz",
  bottle: "Botella",
  reusable_bag: "Bolsa reutilizable",
  plastic_bag: "Bolsa de plástico",
  recycle_bin: "Papelera de reciclaje",
  share_text_subject: "Depende de ti",
  share_text_body: "Jugué a 2050 – ¡Depende de ti! ¿Tienes curiosidad por saber qué pinta tiene tu mundo? #ExperienceEUScience\nJuega ahora %{shareUrl}\nEste juego es una creación del Centro Común de Investigación, el servicio de ciencia y conocimiento de la Comisión Europea.\nEntérate de más cosas https://joint-research-centre.ec.europa.eu/index_es?etrans=es"
};
export default i18n;
