import "scenes/DinnerSelection.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import { characterPropTypes } from "elements/character/Character.js";
import { loFiClick } from "common/sounds.js";
import { allBadges, getAvailableBadgesFor } from "elements/badges/badges.js";
import Badges from "modals/Badges.js";

const availableItems = ["food-delivery", "cooking-at-home"];

const DinnerSelection = ({ t, character, addSelection, badges, restartScene, restartGame, goToNextScene, battery }) => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModal, setOpenModal] = useState();
  const [sceneCompleted, setSceneCompleted] = useState(false);

  const itemClicked = item => {
    if (sceneCompleted) return;
    setSelectedItem(item);
    addSelection(item);
    loFiClick();
    setSceneCompleted(true);
  };

  return (
    <div className="DinnerSelection scene">
      <div className="map">
        <div>
          {availableItems.map(item => (
            <button
              key={item}
              onClick={() => itemClicked(item)}
              className={`item ${item}${selectedItem === item ? " selected" : ""}`}
              title={t(item.replace(/-/g, "_"))}
              disabled={sceneCompleted}
            >
              <label>{t(item.replace(/-/g, "_"))}</label>
            </button>
          ))}
        </div>
      </div>

      <Footer
        t={t}
        badgesCount={badges.length}
        openBadgesModal={() => setOpenModal("badges")}
        restartScene={sceneCompleted ? restartScene : undefined}
        restartGame={restartGame}
        goToNextScene={sceneCompleted ? goToNextScene : undefined}
      />
      {battery}
      <Badges
        t={t}
        badges={badges}
        availableBadges={getAvailableBadgesFor(character.type)}
        isOpen={openModal === "badges"}
        closeModal={() => setOpenModal(undefined)}
      />
    </div>
  );
};

DinnerSelection.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  addSelection: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired,
  battery: PropTypes.element.isRequired
});

export default DinnerSelection;
