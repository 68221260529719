const i18n = {
  title: "2050 Gioco della società sana",
  start: "Inizio",
  select_language: "Seleziona la lingua",
  adult: "Adulto",
  child: "Bambino",
  fullscreen: "Schermo intero",
  restart_scene: "Riavvia la scena",
  restart_game: "Riavvia il gioco",
  previous: "Precedente",
  next: "Seguente",
  close: "Chiudi",
  toggle_sound: "Suono attivato/disattivato",
  character_selection_title: "Seleziona il tuo personaggio",
  character_selection_body: "Le nostre scelte influiscono sul nostro futuro. In questo gioco si simulano situazioni quotidiane. Fai le tue scelte e scopri il loro effetto. Guardati bene intorno per scoprire dei segreti nascosti e guadagnare i tuoi distintivi! Iniziamo con la creazione del tuo personaggio.",
  character_set_up_title: "Costruisci il tuo personaggio",
  select_me: "Selezionami",
  breakfast_title: "Cosa mangi a colazione?",
  your_badges: "I tuoi distintivi",
  badge_water_conservation_master_title: "Specialista",
  badge_water_conservation_master_description: "Complimenti! Sei uno specialista del risparmio di una risorsa scarsa! L'acqua è fondamentale per tutti i tipi di vita.",
  badge_clean_street_wizard_title: "Mago",
  badge_clean_street_wizard_description: "Complimenti! Sei un mago nel rendere il nostro ambiente più pulito raccogliendo la spazzatura e gettandola nel cestino.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Complimenti! Sei un ninja nel risparmio energetico. Risparmiare l'energia dei nostri elettrodomestici è un ottimo modo per ridurre il consumo di energia.",
  badge_energy_saving_expert_title: "Esperto",
  badge_energy_saving_expert_description: "Complimenti! Sei un esperto del risparmio energetico. Continua a trovare modi per ridurre il tuo consumo di energia.",
  badge_low_waste_champion_title: "Campione",
  badge_low_waste_champion_description: "Complimenti! Sei un campione nel ridurre i tuoi rifiuti e nell'aiutare l'ambiente limitando l'uso della plastica.",
  badge_responsible_shopper_title: "Professionista",
  badge_responsible_shopper_description: "Complimenti! Sei un professionista nel fare scelte sane per l'ambiente. Continua a utilizzare la minore quantità di plastica possibile portando la tua borsa riutilizzabile.",
  badge_recycling_captain_title: "Star",
  badge_recycling_captain_description: "Complimenti! Sei una star del riciclaggio. È importante riciclare e riutilizzare i materiali per un ambiente sano.",
  faucet: "Rubinetto",
  transportation_title_adult: "Come vai al lavoro?",
  transportation_title_child: "Come vai a scuola?",
  trash: "Rifiuti",
  no_drink: "Nessuna bevanda",
  no_drink_description: "",
  tap_water: "Acqua del rubinetto",
  tap_water_description: "Idratati bene. Cerca di bere almeno 1,5-2 litri di acqua al giorno.",
  milk: "Latte",
  milk_description: "Scegli il latte naturale non zuccherato, preferibilmente a basso contenuto di grassi.",
  freshly_made_juice: "Succo di frutta appena spremuto",
  freshly_made_juice_description: "Se ti piace il succo di frutta, scegli un succo appena spremuto: non c'è bisogno di aggiungere zucchero. Prova a mangiare frutta fresca.",
  coffee_or_tea: "Caffè/tè",
  coffee_or_tea_description: "Goditi tè e caffè preferibilmente senza aggiungere zucchero.",
  plant_based_drink: "Bevanda a base vegetale",
  plant_based_drink_description: "Prova le bevande a base vegetale, senza zuccheri aggiunti, e scegli possibilmente quelle che contengono vitamine e minerali.",
  whole_grain_cereals: "Cereali integrali",
  whole_grain_cereals_description: "Scegli cereali integrali senza zuccheri aggiunti.",
  choco_balls: "Palline di cereali al cioccolato",
  choco_balls_description: "I cereali da colazione possono rappresentare una parte considerevole dell'assunzione giornaliera di zuccheri: puoi scegliere quelli con meno zuccheri aggiunti? E già che ci sei, scegli quelli con più fibre.",
  white_bread_or_toast: "Pane/pane in cassetta bianco",
  white_bread_or_toast_description: "Prova il pane integrale.",
  wholemeal_bread_or_toast: "Pane/pane in cassetta integrale",
  wholemeal_bread_or_toast_description: "Prova il pane integrale.",
  biscuits: "Biscotti",
  biscuits_description: "I biscotti sono ricchi di calorie e possono rappresentare una parte considerevole dell'assunzione giornaliera di zuccheri e grassi. È preferibile ridurne il consumo. Prova quelli con meno grassi e meno zuccheri. E già che ci sei, opta anche per quelli con più fibre.",
  no_topping: "Nessun condimento",
  no_topping_description: "",
  yogurt: "Yogurt",
  yogurt_description: "Prova lo yogurt magro senza zuccheri aggiunti.",
  honey: "Miele",
  honey_description: "Scegli lo yogurt bianco senza aggiunta di zuccheri o marmellata. Aggiungi piuttosto frutta o cereali integrali non zuccherati.",
  jam: "Marmellata",
  jam_description: "Scegli lo yogurt bianco senza aggiunta di zuccheri o marmellata. Aggiungi piuttosto frutta o cereali integrali non zuccherati.",
  fruit: "Frutta",
  fruit_description: "Iniziare la giornata con la frutta è un'ottima scelta. Prova a scegliere la frutta di stagione. Se aggiungi frutta a guscio, preferisci quella senza sale o zuccheri aggiunti.",
  butter: "Burro",
  butter_description: "Il burro contiene elevate quantità di grassi e può essere salato. Cerca di non mangiarlo troppo spesso e prendi il tipo senza sale.",
  chocolate_cream: "Crema al cioccolato",
  chocolate_cream_description: "La crema al cioccolato contiene molto zucchero e molti grassi saturi. Cerca di non mangiarla troppo spesso.",
  ham_or_bacon: "Prosciutto/bacon",
  ham_or_bacon_description: "La carne trasformata contiene quantità elevate di grassi e sale. Se mangi carne trasformata, limitane il consumo.",
  cheese: "Formaggio",
  cheese_description: "Il formaggio contiene elevate quantità di sale e grassi saturi. Cerca di non mangiarlo troppo spesso e consumane piccole dosi.",
  avocado_and_tomato: "Avocado e pomodoro",
  avocado_and_tomato_description: "Iniziare la giornata con gli ortaggi è un'ottima scelta. Cerca di mangiare molta frutta e verdura durante la giornata e prediligi quella locale e di stagione.",
  battery: "Batteria",
  battery_description: "Tieni d'occhio la batteria, indica l'impatto delle tue scelte!",
  lunch_title: "Che cosa desideri per pranzo?",
  breaktime_title: "Pomeriggio libero - Tempo libero!",
  snacking_title: "Vuoi fare uno spuntino?",
  supermarket_title: "Cosa acquisti?",
  dinner_selection_title: "Che piani hai per la cena?",
  dinner_cooking_title: "Cosa mangi per cena?",
  dinner_delivery_title: "Cosa mangi per cena?",
  your_available_items: "Le tue scelte",
  your_selections: "Le tue selezioni",
  walk: "A piedi",
  walk_description: "Sii fisicamente attivo nella tua vita quotidiana. Se possibile, vai a scuola o al lavoro a piedi o in bicicletta. Ti mantiene in forma ed è meglio per l'ambiente.",
  bicycle: "Bicicletta",
  bicycle_description: "Sii fisicamente attivo nella tua vita quotidiana. Se possibile, vai a scuola o al lavoro a piedi o in bicicletta. Ti mantiene in forma ed è meglio per l'ambiente.",
  scooter_or_motorbike: "Scooter/moto",
  scooter_or_motorbike_description: "Cerca di usare meno l'auto o la moto per un ambiente più pulito. Se non è possibile, cerca di usare il car pooling.",
  car: "Auto",
  car_description: "Cerca di usare meno l'auto o la moto per un ambiente più pulito. Se non è possibile, cerca di usare il car pooling.",
  public_transport: "Trasporti pubblici",
  public_transport_description: "Usa i trasporti pubblici e abbinali a tratti a piedi e in bicicletta invece di andare in auto. Concediti una pausa dalla guida e aiuta l'ambiente.",
  salad_with_egg: "Insalata con uova",
  salad_with_egg_description: "Aggiungi verdure fresche ai tuoi pasti ogni volta che puoi.",
  beef_steak: "Bistecca di manzo",
  beef_steak_description: "Se mangi carne, considera di ridurne l'assunzione, in particolare di carni rosse o trasformate. È meglio per la tua salute e l'ambiente.",
  chicken: "Pollo",
  chicken_description: "Se mangi carne, considera di ridurne l'assunzione, in particolare di carni rosse o trasformate. È meglio per la tua salute e l'ambiente.",
  lentil_soup: "Zuppa di lenticchie",
  lentil_soup_description: "Il consumo regolare di legumi è incoraggiato nell'ambito di una dieta varia e prevalentemente a base vegetale.",
  vegetable_burger: "Hamburger vegetale",
  vegetable_burger_description: "Un hamburger vegetale può essere una buona alternativa alla carne. Fai attenzione che non contenga troppo sale.",
  white_pasta: "Pasta",
  white_pasta_description: "Dai la preferenza ai prodotti integrali quando scegli la pasta, il riso e il pane.",
  wholegrain_pasta: "Pasta integrale",
  wholegrain_pasta_description: "Dai la preferenza ai prodotti integrali quando scegli la pasta, il riso e il pane.",
  white_rice: "Riso",
  white_rice_description: "Dai la preferenza ai prodotti integrali quando scegli la pasta, il riso e il pane.",
  wholegrain_rice: "Riso integrale",
  wholegrain_rice_description: "Dai la preferenza ai prodotti integrali quando scegli la pasta, il riso e il pane.",
  white_bread_bun: "Pane",
  white_bread_bun_description: "Dai la preferenza ai prodotti integrali quando scegli la pasta, il riso e il pane.",
  no_salad: "Nessun contorno",
  no_salad_description: "",
  fresh_green_salad: "Insalata fresca",
  fresh_green_salad_description: "Aggiungi delle belle verdure di stagione ai tuoi pasti: più sono varie, meglio è.",
  french_fries: "Patatine fritte",
  french_fries_description: "Le patate bollite sono da preferire rispetto ai prodotti a base di patatine fritte.",
  boiled_broccoli: "Broccoli bolliti",
  boiled_broccoli_description: "Cerca di a mangiare molta verdura durante la giornata. Scegli verdure locali e di stagione.",
  bottled_water: "Acqua in bottiglia",
  bottled_water_description: "Bevi acqua del rubinetto per ridurre l'uso della plastica.",
  soft_drink: "Bibite analcoliche",
  soft_drink_description: "Le bevande zuccherate rappresentano una parte considerevole dell'assunzione giornaliera di zucchero: perché non scegliere l'acqua invece?",
  beer_or_wine: "Birra/vino",
  beer_or_wine_description: "Se bevi alcolici di qualsiasi tipo, limitane l'assunzione. Evitare gli alcolici è meglio per la tua salute.",
  watching_tv: "Guardare la TV o giocare ai videogiochi",
  watching_tv_description: "Sii fisicamente attivo ogni giorno e cerca di ridurre il tempo che passi seduto. Nel tempo libero prova a fare sport, gioca con i tuoi amici o aiuta la comunità. Fa bene alla salute e alla società. Evita di passare troppo tempo davanti a uno schermo.",
  doing_sports: "Praticare sport",
  doing_sports_description: "Sii fisicamente attivo ogni giorno e cerca di ridurre il tempo che passi seduto. Nel tempo libero prova a fare sport, gioca con i tuoi amici o aiuta la comunità. Fa bene alla salute e alla società. Evita di passare troppo tempo davanti a uno schermo.",
  playing_outside: "Giocare all'aperto",
  playing_outside_description: "Sii fisicamente attivo ogni giorno e cerca di ridurre il tempo che passi seduto. Nel tempo libero prova a fare sport, gioca con i tuoi amici o aiuta la comunità. Fa bene alla salute e alla società. Evita di passare troppo tempo davanti a uno schermo.",
  charity_work: "Fare volontariato con adulti",
  charity_work_description: "Sii fisicamente attivo ogni giorno e cerca di ridurre il tempo che passi seduto. Nel tempo libero prova a fare sport, gioca con i tuoi amici o aiuta la comunità. Fa bene alla salute e alla società. Evita di passare troppo tempo davanti a uno schermo.",
  carrots_tomatoes: "Carote/pomodorini",
  carrots_tomatoes_description: "Scegli frutta, verdura o frutta a guscio. Limita gli spuntini e le bevande ad alto contenuto di zuccheri, grassi e sale.",
  fresh_fruit: "Frutta fresca",
  fresh_fruit_description: "Scegli frutta, verdura o frutta a guscio. Limita gli spuntini e le bevande ad alto contenuto di zuccheri, grassi e sale.",
  chocolate_bar: "Barretta di cioccolato",
  chocolate_bar_description: "Scegli frutta, verdura o frutta a guscio. Limita gli spuntini e le bevande ad alto contenuto di zuccheri, grassi e sale.",
  chips: "Patatine",
  chips_description: "Scegli frutta, verdura o frutta a guscio. Limita gli spuntini e le bevande ad alto contenuto di zuccheri, grassi e sale.",
  juice_drink: "Bevanda a base di succo di frutta",
  juice_drink_description: "Scegli frutta fresca o succhi appena spremuti senza zuccheri aggiunti invece di bevande a base di frutta.",
  supermarket_prompt: "Scegli fino a 9 prodotti",
  raw_fish: "Pesce crudo",
  raw_fish_description: "Il consumo regolare di pesce fornisce importanti sostanze nutritive. Provane diversi tipi.",
  raw_beef: "Manzo crudo",
  raw_beef_description: "Se mangi carne, considera di ridurne l'assunzione, in particolare di carni rosse o trasformate. È meglio per la tua salute e l'ambiente.",
  raw_chicken: "Pollo crudo",
  raw_chicken_description: "",
  whole_salad: "Insalata intera ad esempio lattuga",
  whole_salad_description: "Prediligi frutta e verdura fresca, soprattutto di stagione e locale.",
  packaged_salad: "Insalata in busta",
  packaged_salad_description: "Prediligi frutta e verdura fresca, soprattutto di stagione e locale.",
  seasonal_fruit: "Frutta locale di stagione",
  seasonal_fruit_description: "Prediligi frutta e verdura fresca, soprattutto di stagione e locale.",
  exotic_fruit: "Frutta esotica",
  exotic_fruit_description: "Prediligi frutta e verdura fresca, soprattutto di stagione e locale.",
  ham: "Prosciutto",
  ham_description: "La carne trasformata contiene quantità elevate di grassi e sale. Se mangi carne trasformata, prova a limitarne il consumo.",
  tofu: "Tofu",
  tofu_description: "Prova nuovi prodotti a base vegetale e nuove ricette.",
  hummus: "Hummus",
  hummus_description: "L'hummus è una buona alternativa che ti aiuta ad aumentare l'apporto di legumi.",
  precooked_pizza: "Pizza precotta",
  precooked_pizza_description: "I pasti precotti contengono un'elevata quantità di sale e grassi. Prediligi prodotti che non sono precotti. Ti aiuta a tenere sotto controllo la quantità di sale, zucchero e grassi nei tuoi pasti.",
  precooked_fries: "Patatine fritte precotte",
  precooked_fries_description: "I pasti precotti contengono un'elevata quantità di sale e grassi. Prediligi prodotti che non sono precotti. Ti aiuta a tenere sotto controllo la quantità di sale, zucchero e grassi nei tuoi pasti.",
  food_delivery: "Consegna di cibo a domicilio",
  food_delivery_description: "",
  cooking_at_home: "Cibo cucinato a casa",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger",
  cheeseburger_description: "Evita di consumare questo tipo di cibi. Sono ricchi di grassi e sale. Bevi abbastanza acqua.",
  pizza_and_soft_drink: "Pizza e bibita analcolica",
  pizza_and_soft_drink_description: 'Questi cibi sono ricchi di grassi, sale e zuccheri. Scegli pizze "semplici" con meno ingredienti o più verdure e scegli la pizza con farina integrale, se possibile. Bevi abbastanza acqua.',
  noodles_with_vegetables: "Spaghetti con verdure",
  noodles_with_vegetables_description: "Questa è una buona opzione per aumentare il consumo di verdure. Aggiungi legumi per fare un pasto completo.",
  mushroom_risoto: "Risotto ai funghi",
  mushroom_risoto_description: "I funghi sono una buona opzione vegetariana. Fai attenzione a non aggiungere troppo formaggio. Puoi completare questo pasto con un'insalata.",
  precooked_meal: "Piatto pronto precotto - bocconcini di pollo",
  precooked_meal_description: "I piatti pronti precotti possono contenere elevate quantità di sale, grassi o persino zuccheri. Prediligi prodotti che non sono precotti. Puoi controllare meglio la quantità di questi nutrienti. Usa erbe e spezie al posto del sale per insaporire.",
  frozen_pizza: "Pizza surgelata",
  frozen_pizza_description: "I piatti pronti precotti possono contenere elevate quantità di sale, grassi o persino zuccheri. Prediligi prodotti che non sono precotti. Puoi controllare meglio la quantità di questi nutrienti. Usa erbe e spezie al posto del sale per insaporire.",
  rice_and_salad: "Riso istantaneo e insalata verde",
  rice_and_salad_description: "I piatti pronti precotti possono contenere elevate quantità di sale, grassi o persino zuccheri. Prediligi prodotti che non sono precotti. Puoi controllare meglio la quantità di questi nutrienti. Usa erbe e spezie al posto del sale per insaporire.",
  ham_sandwich_and_soft_drink: "Panino al prosciutto e bibita analcolica",
  ham_sandwich_and_soft_drink_description: "La carne trasformata contiene quantità elevate di grassi e sale. Se mangi carne trasformata, prova a limitarne il consumo. Inoltre prova a evitare le bevande zuccherate e le bibite analcoliche. Scegli piuttosto l'acqua del rubinetto.",
  wholegrain_pasta_with_sauce: "Pasta integrale con sugo di verdure",
  wholegrain_pasta_with_sauce_description: "Prova la pasta integrale.",
  hnw_title_adult: "Un nuovo mondo salutare",
  hnw_description_1_adult: "Le nostre scelte influiscono sul nostro futuro. Se tutti mangiamo e viviamo come hai fatto tu nel gioco, il nostro futuro può essere più sano con persone che si prendono cura l'una dell'altra e dell'ambiente.",
  hnw_description_2_adult: "Tutti gli scienziati pensano che per preservare la nostra salute e quella del nostro pianeta dobbiamo seguire una dieta varia: ricca di cereali, legumi, frutta e verdura fresche, con pochi alimenti trasformati, carne e spuntini ricchi di sale, zuccheri e grassi. Ci sono più vantaggi per tutti se siamo fisicamente attivi, camminiamo, andiamo in bicicletta o prendiamo i trasporti pubblici e riduciamo l'uso dell'automobile.",
  hnw_description_3_adult: "Le cose semplici possono migliorare la tua salute e il nostro ambiente: aria e acqua pulite, alimenti sicuri, terreni fertili e spazi verdi, nel rispetto della natura e di tutte le persone. Pensaci anche al di fuori di questo gioco. Porta con te, lungo il viaggio, anche i tuoi amici e la tua famiglia.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Vuoi giocare di nuovo?",
  hnw_title_child: "Le nostre scelte influiscono sul nostro futuro",
  hnw_description_1_child: "Se mangiamo e viviamo tutti come hai fatto tu nel gioco, possiamo avere un futuro più sano.",
  hnw_description_2_child: "Questo è ciò che puoi fare: \n- Assicurati di seguire una dieta varia per preservare la tua salute e la salute del nostro pianeta. \n- Scegli cereali, legumi, ortaggi freschi e frutta.  \n- Limita gli alimenti trasformati, la carne e gli snack ad alto tenore di sale, zuccheri e grassi. \n- Sii fisicamente attivo, vai a piedi, in bicicletta, usa i trasporti pubblici .\n- Riduci l'uso dell'automobile.",
  hnw_description_3_child: "Queste semplici scelte possono migliorare la nostra salute e far sì che ci prendiamo cura dell'ambiente e di tutte le persone.",
  hnw_description_4_child: "Pensaci anche al di fuori di questo gioco.",
  hnw_description_5_child: "Porta con te, lungo il viaggio, anche i tuoi amici e la tua famiglia.",
  hnw_description_6_child: "Vuoi giocare di nuovo?",
  etl_title_adult: "Mangiare per vivere",
  etl_description_1_adult: "Le nostre scelte influiscono sul nostro futuro. Potresti non sapere che il tuo stile di vita può avere un impatto sulla salute del nostro pianeta.",
  etl_description_2_adult: "Tutti gli scienziati pensano che per preservare la nostra salute e quella del nostro pianeta dobbiamo seguire una dieta varia: ricca di cereali, legumi, frutta e verdura fresche, con pochi alimenti trasformati, carne e spuntini ricchi di sale, zuccheri e grassi. Ci sono più vantaggi per tutti se siamo fisicamente attivi, camminiamo, andiamo in bicicletta o prendiamo i trasporti pubblici e riduciamo l'uso dell'automobile.",
  etl_description_3_adult: "Le cose semplici possono migliorare la tua salute e il nostro ambiente: aria e acqua pulite, alimenti sicuri, terreni fertili e spazi verdi, nel rispetto della natura e di tutte le persone.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Vuoi giocare di nuovo?",
  etl_title_child: "Le nostre scelte influiscono sul nostro futuro",
  etl_description_1_child: "Se tutti miglioriamo il modo in cui mangiamo e viviamo, possiamo avere un futuro più sano.",
  etl_description_2_child: "Questo è ciò che puoi fare: \n- Assicurati di seguire una dieta varia per preservare la tua salute e la salute del nostro pianeta. \n- Scegli cereali, legumi, ortaggi freschi e frutta. \n- Limita gli alimenti trasformati, la carne e gli snack ad alto tenore di sale, zuccheri e grassi. \n- Sii fisicamente attivo, vai a piedi, in bicicletta, usa i trasporti pubblici . \n- Riduci l'uso dell'automobile.",
  etl_description_3_child: "Queste semplici scelte possono migliorare la nostra salute e far sì che ci prendiamo cura dell'ambiente e di tutte le persone.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Vuoi giocare di nuovo?",
  share: "Condividi",
  replay: "Gioca di nuovo",
  its_up_to_you: "Dipende da te",
  badge_action_1: "Hai rivelato un segreto nascosto!",
  badge_action_2: "Hai vinto il distintivo di %{badge}",
  fridgeDoor: "Porta del frigorifero",
  light: "Luce",
  bottle: "Borraccia",
  reusable_bag: "Sacchetto riutilizzabile",
  plastic_bag: "Sacchetto di plastica",
  recycle_bin: "Cestino",
  share_text_subject: "Dipende da te",
  share_text_body: "Ho giocato a 2050 - Dipende da te! Curioso di vedere com'è il tuo mondo? #ExperienceEUScience\nGioca subito %{shareUrl}\nQuesto gioco è stato elaborato dal Centro comune di ricerca, il servizio della Commissione europea per la scienza e la conoscenza.\nScopri di più https://joint-research-centre.ec.europa.eu/index_it?etrans=it"
};
export default i18n;
