const i18n = {
  title: "Gesunde Gesellschaft 2050",
  start: "Start",
  select_language: "Sprache auswählen",
  adult: "Erwachsener",
  child: "Kind",
  fullscreen: "Vollbildmodus",
  restart_scene: "Szene neu starten",
  restart_game: "Spiel neu starten",
  previous: "Zurück",
  next: "Weiter",
  close: "Schließen",
  toggle_sound: "Ton ein/aus",
  character_selection_title: "Wähle deinen Charakter aus",
  character_selection_body: "Unsere Entscheidungen beeinflussen unsere Zukunft. In diesem Spiel simulieren wir Alltagssituationen. Triff Entscheidungen und erfahre mehr über ihre Auswirkungen. Achte auf versteckte Geheimnisse und verdiene deine Badges! Fangen wir mit der Erstellung deines Charakters an.",
  character_set_up_title: "Erstelle deinen Charakter",
  select_me: "Bestätigen",
  breakfast_title: "Was gibt es bei dir zum Frühstück?",
  your_badges: "Abzeichen",
  badge_water_conservation_master_title: "Meister/in",
  badge_water_conservation_master_description: "Gratuliere! Du bist ein/e Meister/in beim Sparen einer knappen Ressource! Wasser ist für alle Lebewesen unentbehrlich.",
  badge_clean_street_wizard_title: "Fee",
  badge_clean_street_wizard_description: "Gratuliere! Du bist ein/e Magier/in für eine saubere Umwelt, wenn es darum geht, Müll von der Straße aufzusammeln und im Mülleimer verschwinden zu lassen.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Gratuliere! Du bist ein Ninja in Sachen Energiesparen. Stromsparen bei Elektrogeräten ist ein guter Weg, um den Energieverbrauch zu reduzieren.",
  badge_energy_saving_expert_title: "Experte/-in",
  badge_energy_saving_expert_description: "Gratuliere! Du bist ein/e Experte/-in in Sachen Energiesparen. Such weiter nach Möglichkeiten, um deinen Energieverbrauch zu senken.",
  badge_low_waste_champion_title: "Champion",
  badge_low_waste_champion_description: "Gratuliere! Du bist ein Champion in Sachen Abfall- und Kunststoffvermeidung und leistest damit einen wichtigen Beitrag zum Umweltschutz.",
  badge_responsible_shopper_title: "Profi",
  badge_responsible_shopper_description: "Gratuliere, du bist ein Profi, wenn es darum geht, gesunde Entscheidungen für die Umwelt zu treffen. Verwende weiterhin so wenig Plastik wie möglich mit deiner eigenen wiederverwendbaren Tragetasche.",
  badge_recycling_captain_title: "Star",
  badge_recycling_captain_description: "Gratuliere! Du bist ein Star beim Recyceln. Für eine gesunde Umwelt ist es wichtig, Materialien zu recyceln und wiederzuverwenden.",
  faucet: "Wasserhahn",
  transportation_title_adult: "Wie kommst du zur Arbeit?",
  transportation_title_child: "Wie kommst du zur Schule?",
  trash: "Müll",
  no_drink: "Kein Getränk",
  no_drink_description: "",
  tap_water: "Leitungswasser",
  tap_water_description: "Achte stets auf ausreichende Flüssigkeitszufuhr. Du solltest jeden Tag mindestens 1,5 bis 2 Liter Wasser trinken.",
  milk: "Milch",
  milk_description: "Pure Milch, ungesüßt und vorzugsweise fettarm, ist die beste Wahl.",
  freshly_made_juice: "Frisch gepresster Saft",
  freshly_made_juice_description: "Frisch gepresste Säfte sind von Natur aus süß. Da kannst du auf Zucker verzichten. Frisches Obst sollte bei keinem Frühstück fehlen.",
  coffee_or_tea: "Kaffee/Tee",
  coffee_or_tea_description: "Trink deinen Tee und Kaffee am besten ohne Zucker.",
  plant_based_drink: "Pflanzliche Milchalternative",
  plant_based_drink_description: "Wie wäre es mal mit einer pflanzlichen Milchalternative, am besten ungezuckert und mit zugesetzten Vitaminen und Mineralstoffen?",
  whole_grain_cereals: "Vollkorn-Cerealien",
  whole_grain_cereals_description: "Esse zum Frühstück lieber Vollkorn-Cerealien ohne Zuckerzusatz.",
  choco_balls: "Schokobälle",
  choco_balls_description: "Frühstücksflocken können einen erheblichen Teil unserer täglichen Zuckeraufnahme ausmachen. Hast du schon mal solche mit weniger Zuckerzusatz probiert? Und wenn du schon dabei bist, nimm am besten die mit mehr Ballaststoffen.",
  white_bread_or_toast: "Weißbrot oder weißes Toastbrot",
  white_bread_or_toast_description: "Probiere mal Vollkornbrot.",
  wholemeal_bread_or_toast: "Vollkornbrot/Toastbrot",
  wholemeal_bread_or_toast_description: "Probiere mal Vollkornbrot.",
  biscuits: "Kekse",
  biscuits_description: "Kekse sind kalorienreich und können einen erheblichen Teil unserer täglichen Zucker- und Fettaufnahme ausmachen. Du solltest lieber weniger davon essen. Iss lieber Kekse mit weniger Fett und Zucker. Und wenn du schon dabei bist, nimm am besten die mit mehr Ballaststoffen.",
  no_topping: "Kein Topping",
  no_topping_description: "",
  yogurt: "Joghurt",
  yogurt_description: "Nimm fettarmen Naturjoghurt ohne Zuckerzusatz.",
  honey: "Honig",
  honey_description: "Iss dein Joghurt lieber natur, ohne Zucker oder Marmelade. Gib lieber Obst oder ungesüßte Vollkorn-Cerealien dazu.",
  jam: "Marmelade",
  jam_description: "Iss dein Joghurt lieber natur, ohne Zucker oder Marmelade. Gib lieber Obst oder ungesüßte Vollkorn-Cerealien dazu.",
  fruit: "Obst",
  fruit_description: "Mit Obst, am besten saisonalem, in den Tag zu starten ist eine gute Wahl. Wenn du Nüsse dazu isst, dann lieber solche ohne Salz- oder Zuckerzusatz.",
  butter: "Butter",
  butter_description: "Butter hat einen hohen Fettgehalt und kann salzig sein. Versuche, nicht zu oft Butter zu essen, und nimm lieber ungesalzene.",
  chocolate_cream: "Schokoaufstrich",
  chocolate_cream_description: "Schokoaufstrich enthält viel Zucker und gesättigte Fette. Er sollte eher die Ausnahme sein.",
  ham_or_bacon: "Schinken/Speck",
  ham_or_bacon_description: "Verarbeitetes Fleisch enthält große Mengen an Fett und Salz. Wenn verarbeitetes Fleisch bei dir auf dem Speiseplan steht, dann gilt weniger ist mehr.",
  cheese: "Käse",
  cheese_description: "Käse enthält hohe Mengen an Salz und gesättigten Fettsäuren. Iss ihn lieber nicht zu oft und nur in kleinen Mengen.",
  avocado_and_tomato: "Avocado und Tomate",
  avocado_and_tomato_description: "Mit Gemüse in den Tag zu starten ist eine gute Wahl. Iss tagsüber viel - am besten regionales und saisonales - Obst und Gemüse.",
  battery: "Batterie",
  battery_description: "Achte auf deine Batterie, sie zeigt dir die Auswirkungen deiner Entscheidungen an!",
  lunch_title: "Was möchtest du zum Mittagessen?",
  breaktime_title: "Schulfreier Nachmittag - Freizeit!",
  snacking_title: "Lust auf einen Snack?",
  supermarket_title: "Was kaufst du ein?",
  dinner_selection_title: "Was gibt es zum Abendessen?",
  dinner_cooking_title: "Was gibt es zum Abendessen?",
  dinner_delivery_title: "Was gibt es zum Abendessen?",
  your_available_items: "Deine Optionen",
  your_selections: "Deine Auswahl",
  walk: "Zu Fuß",
  walk_description: "Sei körperlich aktiv im Alltag. Wenn möglich, geh zu Fuß oder fahr mit dem Rad zur Schule/Arbeit. So bleibst du fit und trägst zum Schutz der Umwelt bei.",
  bicycle: "Fahrrad",
  bicycle_description: "Sei körperlich aktiv im Alltag. Wenn möglich, geh zu Fuß oder fahr mit dem Rad zur Schule/Arbeit. So bleibst du fit und trägst zum Schutz der Umwelt bei.",
  scooter_or_motorbike: "Motorroller/Motorrad",
  scooter_or_motorbike_description: "Lass der Umwelt zuliebe das Auto oder das Motorrad auch mal stehen. Sollte das nicht möglich sein, dann kannst du Mitfahrgelegenheiten ausprobieren.",
  car: "Auto",
  car_description: "Lass der Umwelt zuliebe das Auto oder das Motorrad auch mal stehen. Sollte das nicht möglich sein, dann kannst du Mitfahrgelegenheiten ausprobieren.",
  public_transport: "Öffentliche Verkehrsmittel",
  public_transport_description: "Kombiniere öffentliche Verkehrsmittel mit Laufen und Radfahren, statt mit dem Auto zu fahren. Lass dein Auto mal stehen und schone so die Umwelt.",
  salad_with_egg: "Salat mit Ei",
  salad_with_egg_description: "Frisches Gemüse sollte, wann immer möglich, bei keiner Mahlzeit fehlen.",
  beef_steak: "Rindersteak",
  beef_steak_description: "Wenn du Fleisch isst, versuch den Verzehr zu reduzieren, vor allem von rotem oder verarbeitetem Fleisch. Das ist besser für deine Gesundheit und die Umwelt.",
  chicken: "Hähnchen",
  chicken_description: "Wenn du Fleisch isst, versuch den Verzehr zu reduzieren, vor allem von rotem oder verarbeitetem Fleisch. Das ist besser für deine Gesundheit und die Umwelt.",
  lentil_soup: "Linsensuppe",
  lentil_soup_description: "Hülsenfrüchte sollten im Rahmen einer abwechslungsreichen, überwiegend pflanzlichen Ernährung regelmäßig auf dem Speiseplan stehen.",
  vegetable_burger: "Veggie-Burger",
  vegetable_burger_description: "Ein Veggie-Burger ist eine gute Fleischalternative. Achte darauf, dass er nicht zu viel Salz enthält.",
  white_pasta: "Weiße Nudeln",
  white_pasta_description: "Greif bei Nudeln, Reis und Brot lieber zu Vollkornprodukten.",
  wholegrain_pasta: "Vollkornnudeln",
  wholegrain_pasta_description: "Greif bei Nudeln, Reis und Brot lieber zu Vollkornprodukten.",
  white_rice: "Weißer Reis",
  white_rice_description: "Greif bei Nudeln, Reis und Brot lieber zu Vollkornprodukten.",
  wholegrain_rice: "Vollkornreis",
  wholegrain_rice_description: "Greif bei Nudeln, Reis und Brot lieber zu Vollkornprodukten.",
  white_bread_bun: "Weißbrötchen",
  white_bread_bun_description: "Greif bei Nudeln, Reis und Brot lieber zu Vollkornprodukten.",
  no_salad: "Nichts",
  no_salad_description: "",
  fresh_green_salad: "Frischer grüner Salat",
  fresh_green_salad_description: "Buntes Saisongemüse sollte ständig auf dem Speiseplan stehen: je mehr Abwechslung, desto besser.",
  french_fries: "Pommes",
  french_fries_description: "Iss lieber Pellkartoffeln anstelle von frittierten Kartoffelgerichten.",
  boiled_broccoli: "Gekochter Brokkoli",
  boiled_broccoli_description: "Achte darauf, tagsüber viel Gemüse zu essen. Am besten lokal angebautes und saisonales Gemüse.",
  bottled_water: "Tafelwasser",
  bottled_water_description: "Trink Leitungswasser, um den Plastikverbrauch zu reduzieren.",
  soft_drink: "Softdrink",
  soft_drink_description: "Zuckerhaltige Getränke können einen erheblichen Teil unserer täglichen Zuckeraufnahme ausmachen – kannst du stattdessen Wasser trinken?",
  beer_or_wine: "Bier/Wein",
  beer_or_wine_description: "Wenn du Alkohol in der einen oder anderen Form zu dir nimmst, schränke deinen Konsum ein. Der Verzicht auf Alkohol ist besser für deine Gesundheit.",
  watching_tv: "Fernsehen oder Videospiele",
  watching_tv_description: "Bewege dich jeden Tag - je weniger Zeit du im Sitzen verbringst, desto besser. In deiner Freizeit kannst du Sport treiben, mit deinen Freundinnen und Freunden spielen oder in deiner Nachbarschaft aushelfen. Das ist gut für deine Gesundheit und für die Gesellschaft. Achte darauf, nicht zu viel Zeit vor dem Bildschirm zu verbringen.",
  doing_sports: "Sport treiben",
  doing_sports_description: "Bewege dich jeden Tag - je weniger Zeit du im Sitzen verbringst, desto besser. In deiner Freizeit kannst du Sport treiben, mit deinen Freundinnen und Freunden spielen oder in deiner Nachbarschaft aushelfen. Das ist gut für deine Gesundheit und für die Gesellschaft. Achte darauf, nicht zu viel Zeit vor dem Bildschirm zu verbringen.",
  playing_outside: "Spielen im Freien",
  playing_outside_description: "Bewege dich jeden Tag - je weniger Zeit du im Sitzen verbringst, desto besser. In deiner Freizeit kannst du Sport treiben, mit deinen Freundinnen und Freunden spielen oder in deiner Nachbarschaft aushelfen. Das ist gut für deine Gesundheit und für die Gesellschaft. Achte darauf, nicht zu viel Zeit vor dem Bildschirm zu verbringen.",
  charity_work: "Freiwilligenarbeit mit Erwachsenen",
  charity_work_description: "Bewege dich jeden Tag - je weniger Zeit du im Sitzen verbringst, desto besser. In deiner Freizeit kannst du Sport treiben, mit deinen Freundinnen und Freunden spielen oder in deiner Nachbarschaft aushelfen. Das ist gut für deine Gesundheit und für die Gesellschaft. Achte darauf, nicht zu viel Zeit vor dem Bildschirm zu verbringen.",
  carrots_tomatoes: "Karotten/Kirschtomaten",
  carrots_tomatoes_description: "Greif lieber zu Obst, Gemüse oder Nüssen. Snacks und Getränke, die viel Zucker, Fett oder Salz enthalten, solltest du möglichst meiden.",
  fresh_fruit: "Frisches Obst",
  fresh_fruit_description: "Greif lieber zu Obst, Gemüse oder Nüssen. Snacks und Getränke, die viel Zucker, Fett oder Salz enthalten, solltest du möglichst meiden.",
  chocolate_bar: "Tafel Schokolade",
  chocolate_bar_description: "Greif lieber zu Obst, Gemüse oder Nüssen. Snacks und Getränke, die viel Zucker, Fett oder Salz enthalten, solltest du möglichst meiden.",
  chips: "Kartoffelchips",
  chips_description: "Greif lieber zu Obst, Gemüse oder Nüssen. Snacks und Getränke, die viel Zucker, Fett oder Salz enthalten, solltest du möglichst meiden.",
  juice_drink: "Saftgetränk",
  juice_drink_description: "Frisches Obst oder frisch gepresste Säfte ohne Zuckerzusatz sind Saftgetränken vorzuziehen.",
  supermarket_prompt: "Du kannst bis zu 9 Produkte auswählen.",
  raw_fish: "Roher Fisch",
  raw_fish_description: "Durch den regelmäßigen Verzehr von Fisch erhältst du wichtige Nährstoffe. Probier verschiedene Sorten.",
  raw_beef: "Rohes Rindfleisch",
  raw_beef_description: "Wenn du Fleisch isst, versuch den Verzehr zu reduzieren, vor allem von rotem oder verarbeitetem Fleisch. Das ist besser für deine Gesundheit und die Umwelt.",
  raw_chicken: "Rohes Hähnchen",
  raw_chicken_description: "Wenn du Fleisch isst, versuch den Verzehr zu reduzieren, vor allem von rotem oder verarbeitetem Fleisch. Das ist besser für deine Gesundheit und die Umwelt.",
  whole_salad: "Ganzer Salat z. B. Kopfsalat",
  whole_salad_description: "Frisches Obst und Gemüse, vor allem saisonales und regionales, sind die bessere Wahl.",
  packaged_salad: "Abgepackter Salat",
  packaged_salad_description: "Frisches Obst und Gemüse, vor allem saisonales und regionales, sind die bessere Wahl.",
  seasonal_fruit: "Saisonales Obst aus der Region",
  seasonal_fruit_description: "Frisches Obst und Gemüse, vor allem saisonales und regionales, sind die bessere Wahl.",
  exotic_fruit: "Exotische Früchte",
  exotic_fruit_description: "Frisches Obst und Gemüse, vor allem saisonales und regionales, sind die bessere Wahl.",
  ham: "Schinken",
  ham_description: "Verarbeitetes Fleisch enthält große Mengen an Fett und Salz. Wenn verarbeitetes Fleisch bei dir auf dem Speiseplan steht, dann gilt weniger ist mehr.",
  tofu: "Tofu",
  tofu_description: "Probiere neue pflanzliche Produkte und neue Rezepte aus.",
  hummus: "Hummus",
  hummus_description: "Hummus ist eine leckere Alternative, um mehr Hülsenfrüchte auf deinen Speiseplan zu bringen.",
  precooked_pizza: "Fertigpizza",
  precooked_pizza_description: "Fertiggerichte können große Mengen an Salz oder Fett enthalten. Frisch Gekochtes ist die bessere Wahl. So behältst du die Kontrolle über die Menge an Salz, Zucker und Fett in deinen Mahlzeiten.",
  precooked_fries: "Fertigpommes",
  precooked_fries_description: "Fertiggerichte können große Mengen an Salz oder Fett enthalten. Frisch Gekochtes ist die bessere Wahl. So behältst du die Kontrolle über die Menge an Salz, Zucker und Fett in deinen Mahlzeiten.",
  food_delivery: "Essenslieferung",
  food_delivery_description: "",
  cooking_at_home: "Zu Hause kochen",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, Pommes und Softdrink",
  cheeseburger_description: "Diese Art von Speisen solltest du meiden. Sie sind reich an Fett und Salz. Vergiss nicht genug Wasser zu trinken.",
  pizza_and_soft_drink: "Pizza und Softdrink",
  pizza_and_soft_drink_description: "Solche Mahlzeiten sind reich an Fett, Salz und Zucker.  „Einfache“ Pizzen mit weniger Zutaten oder mehr Gemüse sind die bessere Wahl, nach Möglichkeit mit Vollkorn-Pizzateig. Vergiss nicht genung Wasser zu trinken.",
  noodles_with_vegetables: "Nudeln mit Gemüse",
  noodles_with_vegetables_description: "Dies ist eine gute Möglichkeit, um mehr Gemüse zu essen. Iss auch Hülsenfrüchte dazu.",
  mushroom_risoto: "Pilzrisotto",
  mushroom_risoto_description: "Pilze sind eine gute vegetarische Alternative. Aber übertreib es nicht mit dem Käse. Dazu passt ein frischer Salat.",
  precooked_meal: "Chicken-Nuggets Fertiggericht",
  precooked_meal_description: "Fertiggerichte können große Mengen an Salz, Fett oder sogar Zucker enthalten. Frisch Gekochtes ist die bessere Wahl. So kannst du die Aufnahme dieser Nährstoffe besser kontrollieren. Nimm Kräuter und Gewürze anstelle von Salz zum Abschmecken.",
  frozen_pizza: "Tiefkühlpizza",
  frozen_pizza_description: "Fertiggerichte können große Mengen an Salz, Fett oder sogar Zucker enthalten. Frisch Gekochtes ist die bessere Wahl. So kannst du die Aufnahme dieser Nährstoffe besser kontrollieren. Nimm Kräuter und Gewürze anstelle von Salz zum Abschmecken.",
  rice_and_salad: "Selbstgekochter Reis und grüner Salat",
  rice_and_salad_description: "Fertiggerichte können große Mengen an Salz, Fett oder sogar Zucker enthalten. Frisch Gekochtes ist die bessere Wahl. So kannst du die Aufnahme dieser Nährstoffe besser kontrollieren. Nimm Kräuter und Gewürze anstelle von Salz zum Abschmecken.",
  ham_sandwich_and_soft_drink: "Schinkensandwich und Softdrink",
  ham_sandwich_and_soft_drink_description: "Verarbeitetes Fleisch enthält große Mengen an Fett und Salz. Falls du verarbeitetes Fleisch isst, versuche dessen Verzehr einzuschränken.",
  wholegrain_pasta_with_sauce: "Vollkornnudeln mit Gemüsesauce",
  wholegrain_pasta_with_sauce_description: "Probiere mal Vollkornnudeln.",
  hnw_title_adult: "Gesunde neue Welt",
  hnw_description_1_adult: "Unsere Entscheidungen prägen unsere Zukunft. Wenn wir alle so essen und leben wie du in diesem Spiel, dann kann unsere Zukunft gesünder sein und Menschen werden aufeinander und auf die Umwelt achten.",
  hnw_description_2_adult: "Die Wissenschaft ist sich einig, dass eine abwechslungsreiche Ernährung, die reich an Getreide, Hülsenfrüchten, frischem Gemüse und Obst ist, gut für unsere Gesundheit und die unseres Planeten ist. Meide mehr oder weniger verarbeitete Lebensmittel, Fleisch und salz-, zucker- und fettreiche Snacks. Wenn wir körperlich aktiv sind, zu Fuß gehen, mit dem Rad fahren oder die öffentlichen Verkehrsmittel benutzen und weniger mit dem Auto unterwegs sind, gewinnen wir alle.",
  hnw_description_3_adult: "Einfache Dinge können deiner Gesundheit und unserer Umwelt guttun: saubere Luft und sauberes Wasser, sichere Lebensmittel, fruchtbare Böden und Grünflächen, Respekt gegenüber der Natur und den Mitmenschen. Behalte das im Kopf, auch außerhalb dieses Spiels. Überzeuge auch deine Freundinnen und Freunde und deine Familie von diesem Weg.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Möchtest du nochmal spielen?",
  hnw_title_child: "Unsere Entscheidungen prägen unsere Zukunft",
  hnw_description_1_child: "Wenn wir alle so essen und leben wie du in diesem Spiel, dann können wir eine gesündere Zukunft schaffen.",
  hnw_description_2_child: "Das kannst du dazu beitragen:\n- Achte auf eine abwechslungsreiche Ernährung, um deine Gesundheit und die Gesundheit unseres Planeten zu erhalten.\n- Setze Getreide, Hülsenfrüchte, frisches Gemüse und Obst auf deinen Speiseplan.  \n- Iss weniger verarbeitete Lebensmittel, Fleisch und salz-, zucker- und fettreiche Snacks.\n- Bewege dich, geh zu Fuß, fahr mit dem Rad oder nimm die öffentlichen Verkehrsmittel.\n- Sei weniger oft mit dem Auto unterwegs.",
  hnw_description_3_child: "Diese einfachen Entscheidungen können deine Gesundheit stärken und tragen gleichzeitig zum Schutz der Umwelt und der Menschheit bei.",
  hnw_description_4_child: "Behalte das im Kopf, auch außerhalb dieses Spiels.",
  hnw_description_5_child: "Überzeuge auch deine Freundinnen und Freunde und deine Familie von diesem Weg.",
  hnw_description_6_child: "Möchtest du nochmal spielen?",
  etl_title_adult: "Essen um zu leben",
  etl_description_1_adult: "Unsere Entscheidungen prägen unsere Zukunft. Vielleicht bist du dir dessen nicht bewusst, aber dein Lebensstil kann Auswirkungen auf die Gesundheit unseres Planeten haben.",
  etl_description_2_adult: "Die Wissenschaft ist sich einig, dass eine abwechslungsreiche Ernährung, die reich an Getreide, Hülsenfrüchten, frischem Gemüse und Obst ist, gut für unsere Gesundheit und die unseres Planeten ist. Meide mehr oder weniger verarbeitete Lebensmittel, Fleisch und salz-, zucker- und fettreiche Snacks. Wenn wir körperlich aktiv sind, zu Fuß gehen, mit dem Rad fahren oder die öffentlichen Verkehrsmittel benutzen und weniger mit dem Auto unterwegs sind, gewinnen wir alle.",
  etl_description_3_adult: "Einfache Dinge können deiner Gesundheit und unserer Umwelt guttun: saubere Luft und sauberes Wasser, sichere Lebensmittel, fruchtbare Böden und Grünflächen, Respekt gegenüber der Natur und den Mitmenschen.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Möchtest du nochmal spielen?",
  etl_title_child: "Unsere Entscheidungen prägen unsere Zukunft",
  etl_description_1_child: "Wenn wir alle unsere Ernährungs- und Lebensweise überdenken, können wir für eine gesündere Zukunft sorgen.",
  etl_description_2_child: "Das kannst du dazu beitragen:\n- Achte auf eine abwechslungsreiche Ernährung, um deine Gesundheit und die Gesundheit unseres Planeten zu erhalten.\n- Setze Getreide, Hülsenfrüchte, frisches Gemüse und Obst auf deinen Speiseplan.  \n- Iss weniger verarbeitete Lebensmittel, Fleisch und salz-, zucker- und fettreiche Snacks.\n- Bewege dich, geh zu Fuß, fahr mit dem Rad oder nimm die öffentlichen Verkehrsmittel.\n- Sei weniger oft mit dem Auto unterwegs.",
  etl_description_3_child: "Diese einfachen Entscheidungen können deine Gesundheit stärken und tragen gleichzeitig zum Schutz der Umwelt und der Menschheit bei.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Möchtest du nochmal spielen?",
  share: "Teilen",
  replay: "Nochmal spielen",
  its_up_to_you: "Es liegt bei dir",
  badge_action_1: "Verstecktes Geheimnis entdeckt!",
  badge_action_2: "Du hast das %{badge}-Abzeichen gewonnen!",
  fridgeDoor: "Kühlschranktür",
  light: "Licht",
  bottle: "Flasche",
  reusable_bag: "Wiederverwendbare Tüte",
  plastic_bag: "Plastiktüte",
  recycle_bin: "Wertstofftonne",
  share_text_subject: "Es liegt bei dir",
  share_text_body: "Ich habe „2050 – Es liegt an dir“ gespielt! Was ist deine Welt? #ExperienceEUScience\nSpiele das Spiel %{shareUrl}\nDieses Spiel wurde von der Gemeinsamen Forschungsstelle, dem wissenschaftlichen Dienst der Europäischen Kommission, entwickelt.\nEntdecke mehr https://joint-research-centre.ec.europa.eu/index_de?etrans=de"
};
export default i18n;
