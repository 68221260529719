import "scenes/Supermarket.scss";
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, { characterPropTypes } from "elements/character/Character.js";
import { success, loFiClick } from "common/sounds.js";
import { ReactComponent as ReusableBagSvg } from "elements/items/reusable-bag-icon.svg";
import { ReactComponent as PlasticBagSvg } from "elements/items/plastic-bag-icon.svg";
import { allBadges, getAvailableBadgesFor } from "elements/badges/badges.js";
import Badges from "modals/Badges.js";
import BadgeAwarded from "modals/BadgeAwarded.js";
import AvailableItemsAndSelections from "modals/AvailableItemsAndSelections.js";
import CharacterResponsiveCustomModal from "elements/character/CharacterResponsiveCustomModal.js";
import ScrollableArrowsHintClasses from "common/ScrollableArrowsHintClasses.js";

const hiddenItemBadgeAwarded = "responsible-shopper";

const availableItems = [
  "raw-fish",
  "raw-beef",
  "raw-chicken",
  "whole-salad",
  "packaged-salad",
  "seasonal-fruit",
  "exotic-fruit",
  "ham",
  "tofu",
  "hummus",
  "precooked-pizza",
  "precooked-fries"
];

const maxItemsSelected = 9;

const Supermarket = ({
  t,
  character,
  addSelection,
  addBadge,
  badges,
  restartScene,
  restartGame,
  goToNextScene,
  battery
}) => {
  const shelvesDomRef = useRef();
  const [selectedBag, setSelectedBag] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openModal, setOpenModal] = useState();
  const [sceneCompleted, setSceneCompleted] = useState(false);
  const enteringInventoryAnimation = useRef(false);

  const goodBagSelected = () => {
    if (selectedBag) return;
    setOpenModal("badgeAwarded");
    setSelectedBag("good");
    addBadge(hiddenItemBadgeAwarded);
    success();
  };

  const badBagSelected = () => {
    if (selectedBag) return;
    setSelectedBag("bad");
    loFiClick();
  };

  const itemClicked = item => {
    if (enteringInventoryAnimation.current) return; // User managed to select an available item before the previous item selection animation has ended.
    enteringInventoryAnimation.current = true;
    loFiClick();
    // Make item leave shelves.
    const itemDom = shelvesDomRef.current.querySelector("." + item);
    itemDom.classList.add("leaveShelves");
    // When it has fully left shelves, change available items.
    itemDom.addEventListener(
      "animationend",
      () => {
        enteringInventoryAnimation.current = false;
        setSelectedItems(selectedItems.concat(item));
        addSelection(item);
        setSceneCompleted(true); // Even selecting one item completes the scene.
      },
      { once: true }
    );
  };

  return (
    <div className="Supermarket scene">
      <div className="primary-items">
        <div className="sign">
          <p>{t("supermarket_prompt")}</p>
        </div>
        <CharacterResponsiveCustomModal
          t={t}
          characterElement={
            <div className="character-bag">
              <Character {...character} />
              <div className="bag">
                {selectedBag === "good" && <div className="item reusable-bag">{t("reusable_bag")}</div>}
                {selectedBag === "bad" && <div className="item plastic-bag">{t("plastic_bag")}</div>}
              </div>
            </div>
          }
        />

        <ScrollableArrowsHintClasses>
          <div className="shelves" ref={shelvesDomRef}>
            {availableItems
              .filter(item => !selectedItems.includes(item))
              .map(item => (
                <button
                  key={item}
                  onClick={() => itemClicked(item)}
                  className={`item ${item}${selectedItems.includes(item) ? " selected" : ""}`}
                  title={t(item.replace(/-/g, "_"))}
                  disabled={selectedItems.length >= maxItemsSelected || selectedItems.includes(item)}
                >
                  <label>{t(item.replace(/-/g, "_"))}</label>
                </button>
              ))}
          </div>
        </ScrollableArrowsHintClasses>
        <div className="inventory">
          <div className="bag-selection">
            <button
              onClick={goodBagSelected}
              className={`reusable-bag-icon${selectedBag === "good" ? " selected" : ""}`}
              disabled={selectedBag !== undefined}
              title={t("reusable_bag")}
            >
              <ReusableBagSvg />
            </button>
            <button
              onClick={badBagSelected}
              className={`plastic-bag-icon${selectedBag === "bad" ? " selected" : ""}`}
              disabled={selectedBag !== undefined}
              title={t("plastic_bag")}
            >
              <PlasticBagSvg />
            </button>
          </div>
          {selectedItems.length > 0 && (
            <ScrollableArrowsHintClasses>
              <div className="items">
                {selectedItems.map(item => (
                  <button key={item} className={`item ${item}`} disabled={true} title={t(item.replace(/-/g, "_"))}>
                    <label>{t(item.replace(/-/g, "_"))}</label>
                  </button>
                ))}
              </div>
            </ScrollableArrowsHintClasses>
          )}
        </div>
      </div>

      <Footer
        t={t}
        openAvailableItemsAndSelectionsModal={() => setOpenModal("availableItemsAndSelections")}
        badgesCount={badges.length}
        openBadgesModal={() => setOpenModal("badges")}
        restartScene={sceneCompleted ? restartScene : undefined}
        restartGame={restartGame}
        goToNextScene={sceneCompleted ? goToNextScene : undefined}
      />
      {battery}
      <BadgeAwarded
        t={t}
        badge={hiddenItemBadgeAwarded}
        isOpen={openModal === "badgeAwarded"}
        closeModal={() => setOpenModal(undefined)}
      />
      <Badges
        t={t}
        badges={badges}
        availableBadges={getAvailableBadgesFor(character.type)}
        isOpen={openModal === "badges"}
        closeModal={() => setOpenModal(undefined)}
      />
      <AvailableItemsAndSelections
        t={t}
        availableItems={availableItems}
        selections={selectedItems.length >= maxItemsSelected ? selectedItems : availableItems} // Only show selections when the user has reached max selectable items.
        isOpen={openModal === "availableItemsAndSelections"}
        closeModal={() => setOpenModal(undefined)}
      />
    </div>
  );
};

Supermarket.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  addSelection: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  addBadge: PropTypes.func.isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired,
  battery: PropTypes.element.isRequired
});

export default Supermarket;
