import "scenes/World.scss";
import { useEffect } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import { characterPropTypes } from "elements/character/Character.js";
import { allBadges } from "elements/badges/badges.js";
import { renderFragmentWithNewlines } from "utils.js";
import { ReactComponent as HealthyNewWorldSvg } from "scenes/healthy-new-world.svg";
import { ReactComponent as EatToLiveSvg } from "scenes/eat-to-live.svg";
import { jik, morningSpring, morningSpringStop, industrial, industrialStop } from "common/sounds.js";

const getShareData = function (t, languageCode) {
  const urlParams = new URLSearchParams(window.location.search);
  const shareUrl = new URL(urlParams.get("shareUrl") || window.location.href);
  shareUrl.searchParams.set("lang", languageCode);

  return {
    url: shareUrl.toString(),
    title: t("share_text_subject"),
    text: t("share_text_body", { shareUrl: shareUrl.toString() })
  };
};

/**
 * Share the specified shareData parameters using the Web Share API.
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#parameters
 */
const share = async function (shareData) {
  try {
    await window.navigator.share(shareData);
  } catch (err) {
    console.log("Could not share or share cancelled. Reason: " + err.message);
  }
};

/**
 * Detects whether this is a mobile device. Assumes that all mobile devices
 * have a window.orientation and that all desktop devices do not.
 */
const isMobile = () => window.orientation !== undefined;

const shareResult = async function (t, languageCode) {
  const shareData = getShareData(t, languageCode);
  if (isMobile() && window.navigator.canShare && window.navigator.canShare(shareData)) {
    share({ url: shareData.url });
  } else {
    window.open(
      `mailto:?subject=${encodeURIComponent(shareData.title)}&body=${encodeURIComponent(shareData.text)}`,
      "_blank"
    );
  }
};

const World = ({ t, character, badges, restartGame, score, languageCode }) => {
  const world = score > 0 ? "hnw" : "etl"; // "... in case of draw, we send the user to the ETL world"

  const renderEndWorldParagraph = text =>
    text.match(/[?;]$/) ? ( // Detect this by the existence of a questionmark at the end of the text.
      <p>
        <button
          className="restart"
          onClick={() => {
            restartGame();
            jik();
          }}
          title={t("restart_game")}
        >
          {renderFragmentWithNewlines(text)}
        </button>
      </p>
    ) : (
      <p>{renderFragmentWithNewlines(text)}</p>
    );

  // Play sound here, and not in App.js because the sound depends on the state of the world.
  useEffect(() => {
    world === "hnw" ? morningSpring() : industrial();
    return () => {
      world === "hnw" ? morningSpringStop() : industrialStop();
    };
  }, [world]);

  const shareDisabled = new URLSearchParams(window.location.search).has("shareDisabled");

  return (
    <div className={`World scene ${world}`}>
      <main>
        <header>
          <h1>
            {t("its_up_to_you")}
            <span>2050</span>
          </h1>
        </header>
        <h2>{t(world + "_title_" + character.type)}</h2>
        <div className="world-illustration">
          {world === "hnw" && <HealthyNewWorldSvg />}
          {world === "etl" && <EatToLiveSvg />}
        </div>
        <div className="world-description">
          {renderEndWorldParagraph(t(world + "_description_1_" + character.type))}
          {renderEndWorldParagraph(t(world + "_description_2_" + character.type))}
          {renderEndWorldParagraph(t(world + "_description_3_" + character.type))}
          {renderEndWorldParagraph(t(world + "_description_4_" + character.type))}
          {renderEndWorldParagraph(t(world + "_description_5_" + character.type))}
          {renderEndWorldParagraph(t(world + "_description_6_" + character.type))}
        </div>
        {badges.length > 0 && (
          <ul className="badges">
            {badges.map(badge => (
              <li key={badge} className={`badge ${badge}`}>
                <h5>{t("badge_" + badge.replace(/-/g, "_") + "_title")}</h5>
              </li>
            ))}
          </ul>
        )}
      </main>
      <Footer
        t={t}
        restartGame={restartGame}
        shareResult={shareDisabled ? undefined : () => shareResult(t, languageCode)}
      />
    </div>
  );
};

World.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  restartGame: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  languageCode: PropTypes.string.isRequired
});

export default World;
