import React from "react";
import { createRoot } from "react-dom/client";
import App from "App.js";
import Modal from "react-modal";

const root = document.getElementById("root");

createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// https://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement(root);

const registerServiceWorker = () => {
  if (!window.navigator.serviceWorker) {
    console.warn("Service workers are not supported by this browser");
    return;
  }
  console.log("Registering service worker");
  window.navigator.serviceWorker
    .register("/service-worker.js", {
      scope: "/"
    })
    .then(
      () => {
        console.log("Service worker has been registered");
      },
      e => {
        console.error("Service worker registration failed", e);
      }
    );
};

registerServiceWorker();
