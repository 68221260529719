const i18n = {
  title: "Sundt samfund 2050-spillet",
  start: "Start",
  select_language: "Vælg sprog",
  adult: "Voksen",
  child: "Barn",
  fullscreen: "Fuld skærm",
  restart_scene: "Genstart scene",
  restart_game: "Genstart spil",
  previous: "Forrige",
  next: "Næste",
  close: "Luk",
  toggle_sound: "Lyd tændt/slukket",
  character_selection_title: "Vælg din figur",
  character_selection_body: "Vores valg påvirker vores fremtid. I dette spil simulerer vi daglige situationer. Træf dine valg og lær om deres effekt. Husk at se dig omkring efter skjulte hemmeligheder, så du kan få badges! Lad os begynde med at lave din figur.",
  character_set_up_title: "Vælg, hvordan din figur skal se ud",
  select_me: "Vælg mig",
  breakfast_title: "Hvad skal du have til morgenmad?",
  your_badges: "Badges",
  badge_water_conservation_master_title: "Mester",
  badge_water_conservation_master_description: "Tillykke! Du er en mester i at spare på en knap ressource! Vand er af afgørende vigtighed for alle former for liv.",
  badge_clean_street_wizard_title: "Tryllefe",
  badge_clean_street_wizard_description: "Tillykke! Du er fantastisk til at bidrage til et renere miljø ved at samle affald op og smide det i skraldespanden.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Tillykke! Du er en ninja til at spare på energien. At spare på energien fra vores apparater er en god måde at reducere energiforbruget på.",
  badge_energy_saving_expert_title: "Ekspert",
  badge_energy_saving_expert_description: "Tillykke! Du er ekspert i at spare på energien. Bliv ved med at finde måder at reducere dit energiforbrug på.",
  badge_low_waste_champion_title: "Champion",
  badge_low_waste_champion_description: "Tillykke! Du er en champion i at reducere mængden af dit affald og hjælpe miljøet ved at skære ned på brugen af plastik.",
  badge_responsible_shopper_title: "Professionel",
  badge_responsible_shopper_description: "Tillykke, du er professionel, når det gælder om at træffe sunde valg for miljøet. Fortsæt med at bruge så lidt plastik som muligt ved at have din egen genanvendelige pose med.",
  badge_recycling_captain_title: "Stjerne",
  badge_recycling_captain_description: "Tillykke! Du er en stjerne, når det gælder genanvendelse. Det er vigtigt at genanvende og genbruge materialer for at sikre et sundt miljø.",
  faucet: "Hane",
  transportation_title_adult: "Hvordan kommer du på arbejde?",
  transportation_title_child: "Hvordan kommer du i skole?",
  trash: "Affald",
  no_drink: "Intet at drikke",
  no_drink_description: "",
  tap_water: "Vand fra vandhanen",
  tap_water_description: "Få nok væske. Prøv at drikke mindst 1,5-2 liter vand om dagen.",
  milk: "Mælk",
  milk_description: "Vælg almindelig usødet mælk, helst med lavt fedtindhold.",
  freshly_made_juice: "Friskpresset juice",
  freshly_made_juice_description: "Hvis du godt kan lide juice, så vælg en friskpresset juice - du behøver ikke at tilsætte sukker. Prøv at spise frisk frugt.",
  coffee_or_tea: "Kaffe/te",
  coffee_or_tea_description: "Drik helst din te og kaffe uden sukker.",
  plant_based_drink: "Plantebaseret drik",
  plant_based_drink_description: "Prøv plantebaserede drikke uden tilsat sukker, og vælg om muligt drikke, der indeholder vitaminer og mineraler.",
  whole_grain_cereals: "Fuldkornsprodukter",
  whole_grain_cereals_description: "Spis helst fuldkornsprodukter uden tilsat sukker.",
  choco_balls: "Choco balls",
  choco_balls_description: "Morgenmadsprodukter kan tegne sig for en betydelig del af vores daglige sukkerindtag - kan du vælge produkter med lavere indhold af tilsatte sukkerstoffer? Og når du nu alligevel er i gang, så vælg produkter med flere fibre.",
  white_bread_or_toast: "Hvidt brød/toast",
  white_bread_or_toast_description: "Prøv fuldkornsbrød.",
  wholemeal_bread_or_toast: "Fuldkornsbrød/toast",
  wholemeal_bread_or_toast_description: "Prøv fuldkornsbrød.",
  biscuits: "Kiks",
  biscuits_description: "Kiks har et højt kalorieindhold og kan tegne sig for en betydelig del af vores daglige sukker- og fedtindtag. Det er bedst at reducere sit indtag af disse produkter. Spis helst kiks med lavere fedt- og sukkerindhold. Og når du nu alligevel er i gang, så vælg også kiks med flere fibre.",
  no_topping: "Intet ovenpå",
  no_topping_description: "",
  yogurt: "Yoghurt",
  yogurt_description: "Prøv almindelig yoghurt med lavt fedtindhold uden tilsat sukker.",
  honey: "Honning",
  honey_description: "Vælg almindelig yoghurt uden tilsætning af sukker eller syltetøj. Tilsæt helst frugt eller usødede fuldkornsprodukter.",
  jam: "Syltetøj",
  jam_description: "Vælg almindelig yoghurt uden tilsætning af sukker eller syltetøj. Tilsæt helst frugt eller usødede fuldkornsprodukter.",
  fruit: "Frugt",
  fruit_description: "Det er et godt valg at starte dagen med frugt, og prøv at vælge årstidens frugt. Hvis du tilsætter nødder, så vælg helst nødder uden tilsat salt eller sukker.",
  butter: "Smør",
  butter_description: "Smør indeholder store mængder fedt og kan være saltet. Prøv ikke at spise det for ofte, og vælg typerne uden salt.",
  chocolate_cream: "Chokoladesmørepålæg",
  chocolate_cream_description: "Chokoladesmørepålæg indeholder en masse sukker og mættet fedt. Prøv ikke at spise det for ofte.",
  ham_or_bacon: "Skinke/bacon",
  ham_or_bacon_description: "Forarbejdet kød indeholder store mængder fedt og salt. Hvis du spiser forarbejdet kød, bør du begrænse dit forbrug af det.",
  cheese: "Ost",
  cheese_description: "Ost indeholder store mængder salt og mættet fedt. Prøv ikke at spise det for ofte, og spis det i små mængder.",
  avocado_and_tomato: "Avocado og tomat",
  avocado_and_tomato_description: "Det er et godt valg at starte dagen med grøntsager. Prøv at spise masser af frugt og grøntsager i løbet af dagen, og vælg helst lokale og sæsonbestemte grøntsager og frugter.",
  battery: "Batteri",
  battery_description: "Hold godt øje med dit batteri, da det viser effekten af dine valg!",
  lunch_title: "Hvad vil du gerne have til frokost?",
  breaktime_title: "Fri om eftermiddagen - fritid!",
  snacking_title: "Har du lyst til en snack?",
  supermarket_title: "Hvad køber du?",
  dinner_selection_title: "Hvad er dine middagsplaner?",
  dinner_cooking_title: "Hvad skal du have til aftensmad?",
  dinner_delivery_title: "Hvad skal du have til aftensmad?",
  your_available_items: "Dine muligheder",
  your_selections: "Dine valg",
  walk: "Går",
  walk_description: "Vær fysisk aktiv i hverdagen. Hvis det er muligt, bør du gå eller cykle til skole/på arbejde. Det holder dig i form, og det er bedre for miljøet.",
  bicycle: "Cykel",
  bicycle_description: "Vær fysisk aktiv i hverdagen. Hvis det er muligt, bør du gå eller cykle til skole/på arbejde. Det holder dig i form, og det er bedre for miljøet.",
  scooter_or_motorbike: "Scooter/motorcykel",
  scooter_or_motorbike_description: "Prøv at bruge bilen eller motorcyklen sjældnere for at sikre et renere miljø. Hvis det ikke er muligt, kan du prøve samkørsel.",
  car: "Bil",
  car_description: "Prøv at bruge bilen eller motorcyklen sjældnere for at sikre et renere miljø. Hvis det ikke er muligt, kan du prøve samkørsel.",
  public_transport: "Offentlig transport",
  public_transport_description: "Brug offentlig transport, og kombiner det med gå- og cykelture i stedet for at tage bilen. Giv dig selv en pause fra at køre bil og hjælp miljøet.",
  salad_with_egg: "Salat med æg",
  salad_with_egg_description: "Tilføj friske grøntsager til dine måltider, når det er muligt.",
  beef_steak: "Bøf af oksekød",
  beef_steak_description: "Hvis du spiser kød, bør du overveje at reducere dit indtag, især af rødt eller forarbejdet kød. Det er bedre for dit helbred og for miljøet.",
  chicken: "Kylling",
  chicken_description: "Hvis du spiser kød, bør du overveje at reducere dit indtag, især af rødt eller forarbejdet kød. Det er bedre for dit helbred og for miljøet.",
  lentil_soup: "Linsesuppe",
  lentil_soup_description: "Det anbefales at spise bælgfrugter regelmæssigt som en del af en varieret og overvejende plantebaseret kost.",
  vegetable_burger: "Grøntsagsburger",
  vegetable_burger_description: "En grøntsagsburger kan være et godt alternativ til kød. Vær forsigtig med, at den ikke indeholder for meget salt.",
  white_pasta: "Hvid pasta",
  white_pasta_description: "Vælg helst fuldkornsprodukter, når du vælger pasta, ris og brød.",
  wholegrain_pasta: "Fuldkornspasta",
  wholegrain_pasta_description: "Vælg helst fuldkornsprodukter, når du vælger pasta, ris og brød.",
  white_rice: "Hvide ris",
  white_rice_description: "Vælg helst fuldkornsprodukter, når du vælger pasta, ris og brød.",
  wholegrain_rice: "Fuldkornsris",
  wholegrain_rice_description: "Vælg helst fuldkornsprodukter, når du vælger pasta, ris og brød.",
  white_bread_bun: "Bolle af hvidt brød",
  white_bread_bun_description: "Vælg helst fuldkornsprodukter, når du vælger pasta, ris og brød.",
  no_salad: "Intet",
  no_salad_description: "",
  fresh_green_salad: "Frisk grøn salat",
  fresh_green_salad_description: "Tilføj nogle farverige sæsonbestemte grøntsager til dine måltider, og jo mere du varierer, jo bedre.",
  french_fries: "Pomfritter",
  french_fries_description: "Kogte kartofler bør foretrækkes frem for friturestegte kartoffelprodukter.",
  boiled_broccoli: "Kogt broccoli",
  boiled_broccoli_description: "Prøv at spise masser af grøntsager i løbet af dagen. Vælg lokale og sæsonbestemte grøntsager.",
  bottled_water: "Vand på flaske",
  bottled_water_description: "Drik vand fra vandhanen for at reducere brugen af plastik.",
  soft_drink: "Læskedrik",
  soft_drink_description: "Sukkerholdige drikkevarer tegner sig for en betydelig del af vores daglige sukkerindtag — kan du vælge vand i stedet?",
  beer_or_wine: "Øl/vin",
  beer_or_wine_description: "Begræns dit forbrug af alkohol i enhver form. Det er bedre for dit helbred at undgå alkohol.",
  watching_tv: "Se tv eller spille computerspil",
  watching_tv_description: "Vær fysisk aktiv hver dag, og prøv at reducere den tid, du bruger på at sidde ned. I din fritid kan du prøve at dyrke sport, lege med dine venner eller hjælpe dit lokalsamfund. Det er godt for dit helbred og for lokalsamfundet. Undgå at tilbringe for meget tid foran skærmen.",
  doing_sports: "Dyrke sport",
  doing_sports_description: "Vær fysisk aktiv hver dag, og prøv at reducere den tid, du bruger på at sidde ned. I din fritid kan du prøve at dyrke sport, lege med dine venner eller hjælpe dit lokalsamfund. Det er godt for dit helbred og for lokalsamfundet. Undgå at tilbringe for meget tid foran skærmen.",
  playing_outside: "Lege udenfor",
  playing_outside_description: "Vær fysisk aktiv hver dag, og prøv at reducere den tid, du bruger på at sidde ned. I din fritid kan du prøve at dyrke sport, lege med dine venner eller hjælpe dit lokalsamfund. Det er godt for dit helbred og for lokalsamfundet. Undgå at tilbringe for meget tid foran skærmen.",
  charity_work: "Velgørenhedsarbejde sammen med voksne",
  charity_work_description: "Vær fysisk aktiv hver dag, og prøv at reducere den tid, du bruger på at sidde ned. I din fritid kan du prøve at dyrke sport, lege med dine venner eller hjælpe dit lokalsamfund. Det er godt for dit helbred og for lokalsamfundet. Undgå at tilbringe for meget tid foran skærmen.",
  carrots_tomatoes: "Gulerødder/cherrytomater",
  carrots_tomatoes_description: "Vælg helst frugt, grøntsager eller nødder. Begræns snacks og drikkevarer, der indeholder meget sukker, fedt og salt.",
  fresh_fruit: "Frisk frugt",
  fresh_fruit_description: "Vælg helst frugt, grøntsager eller nødder. Begræns snacks og drikkevarer, der indeholder meget sukker, fedt og salt.",
  chocolate_bar: "Chokoladebar",
  chocolate_bar_description: "Vælg helst frugt, grøntsager eller nødder. Begræns snacks og drikkevarer, der indeholder meget sukker, fedt og salt.",
  chips: "Chips",
  chips_description: "Vælg helst frugt, grøntsager eller nødder. Begræns snacks og drikkevarer, der indeholder meget sukker, fedt og salt.",
  juice_drink: "Juicedrik",
  juice_drink_description: "Vælg helst frisk frugt eller friskpresset juice uden tilsat sukker i stedet for juicedrikke.",
  supermarket_prompt: "Vælg op til 9 produkter",
  raw_fish: "Rå fisk",
  raw_fish_description: "Ved at spise fisk jævnligt får du vigtige næringsstoffer. Prøv forskellige slags",
  raw_beef: "Råt oksekød",
  raw_beef_description: "Hvis du spiser kød, bør du overveje at reducere dit indtag, især af rødt eller forarbejdet kød. Det er bedre for dit helbred og for miljøet.",
  raw_chicken: "Rå kylling",
  raw_chicken_description: "Hvis du spiser kød, bør du overveje at reducere dit indtag, især af rødt eller forarbejdet kød. Det er bedre for dit helbred og for miljøet.",
  whole_salad: "Hele salatgrøntsager - f.eks. Salat",
  whole_salad_description: "Vælg helst frisk frugt og friske grøntsager, især sæsonbestemte og lokale grøntsager.",
  packaged_salad: "Forpakket salat",
  packaged_salad_description: "Vælg helst frisk frugt og friske grøntsager, især sæsonbestemte og lokale grøntsager.",
  seasonal_fruit: "Lokal sæsonbestemt frugt",
  seasonal_fruit_description: "Vælg helst frisk frugt og friske grøntsager, især sæsonbestemte og lokale grøntsager.",
  exotic_fruit: "Eksotiske frugter",
  exotic_fruit_description: "Vælg helst frisk frugt og friske grøntsager, især sæsonbestemte og lokale grøntsager.",
  ham: "Skinke",
  ham_description: "Forarbejdet kød indeholder store mængder fedt og salt. Hvis du spiser forarbejdet kød, så prøv at begrænse dit forbrug af det.",
  tofu: "Tofu",
  tofu_description: "Prøv nye plantebaserede produkter og nye opskrifter.",
  hummus: "Hummus",
  hummus_description: "Hummus er et godt alternativ, der kan hjælpe dig med at øge dit indtag af bælgfrugter.",
  precooked_pizza: "Færdigtilberedt pizza",
  precooked_pizza_description: "Færdigtilberedte måltider kan indeholde store mængder salt eller fedt. Vælg helst produkter, der ikke er færdigtilberedte. På den måde kan du kontrollere mængden af salt, sukker og fedtstoffer i dine måltider.",
  precooked_fries: "Forkogte pomfritter",
  precooked_fries_description: "Færdigtilberedte måltider kan indeholde store mængder salt eller fedt. Vælg helst produkter, der ikke er færdigtilberedte. På den måde kan du kontrollere mængden af salt, sukker og fedtstoffer i dine måltider.",
  food_delivery: "Udbragt mad",
  food_delivery_description: "",
  cooking_at_home: "Lave mad derhjemme",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, pomfritter og læskedrik",
  cheeseburger_description: "Undgå at indtage denne type måltider. De har et højt indhold af fedt og salt. Drik vand.",
  pizza_and_soft_drink: "Pizza og læskedrik",
  pizza_and_soft_drink_description: 'Denne type måltider har et højt indhold af fedt, salt og sukker. Vælg "enkle" pizzaer med færre ingredienser eller flere grøntsager, og vælg om muligt fuldkornspizzabrød. Drik vand.',
  noodles_with_vegetables: "Nudler med grøntsager",
  noodles_with_vegetables_description: "Dette er en god mulighed for at øge dit indtag af grøntsager. Tilføj nogle bælgfrugter for at gøre dit måltid komplet.",
  mushroom_risoto: "Risotto med svampe",
  mushroom_risoto_description: "Svampe er et godt vegetarisk alternativ. Pas på, at du ikke tilsætter for meget ost. Du kan supplere dette måltid med en salat.",
  precooked_meal: "Forkogt færdigret - kyllingenuggets",
  precooked_meal_description: "Forkogte retter kan indeholde store mængder salt, fedt eller endda sukker. Vælg helst produkter, der ikke er forkogte. Du kan bedre holde styr på mængden af disse næringsstoffer. Brug krydderier frem for salt til at sætte smag på.",
  frozen_pizza: "Frysepizza",
  frozen_pizza_description: "Forkogte retter kan indeholde store mængder salt, fedt eller endda sukker. Vælg helst produkter, der ikke er forkogte. Du kan bedre holde styr på mængden af disse næringsstoffer. Brug krydderier frem for salt til at sætte smag på.",
  rice_and_salad: "Selvtilberedt måltid med ris og grøn salat",
  rice_and_salad_description: "Forkogte retter kan indeholde store mængder salt, fedt eller endda sukker. Vælg helst produkter, der ikke er forkogte. Du kan bedre holde styr på mængden af disse næringsstoffer. Brug krydderier frem for salt til at sætte smag på.",
  ham_sandwich_and_soft_drink: "Skinkesandwich og læskedrik",
  ham_sandwich_and_soft_drink_description: "Forarbejdet kød indeholder store mængder fedt og salt. Hvis du spiser forarbejdet kød, så prøv at begrænse dit forbrug af det.",
  wholegrain_pasta_with_sauce: "Fuldkornspasta med grøntsagssauce",
  wholegrain_pasta_with_sauce_description: "Prøv fuldkornspasta.",
  hnw_title_adult: "En sund ny verden",
  hnw_description_1_adult: "Vores valg former vores fremtid. Hvis vi alle spiser og lever, som du gjorde i spillet, kan vores fremtid blive sundere med mennesker, der passer på hinanden og på miljøet.",
  hnw_description_2_adult: "Forskerne har konsekvent fastslået, at vi for at bevare vores og planetens sundhed bør spise en varieret kost for at bevare vores sundhed: Med et højt indhold af kornprodukter, bælgfrugter, friske grøntsager og frugt. Undgå forarbejdede fødevarer, kød og snacks med et højt indhold af salt, sukker og fedt. Der er flere gevinster at hente, hvis vi er fysisk aktive, går, cykler eller tager offentlig transport og reducerer brugen af biler.",
  hnw_description_3_adult: "Enkle tiltag kan styrke dit helbred og vores miljø: Ren luft og rent vand, sikre fødevarer, frugtbar jord og grønne områder, respekt for naturen og for alle mennesker. Tænk også på det, når du er færdig med dette spil. Tag dine venner og din familie med på rejsen.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Vil du gerne spille igen?",
  hnw_title_child: "Vores valg former vores fremtid",
  hnw_description_1_child: "Hvis vi alle spiser og lever, som du gjorde i spillet, kan vi få en sundere fremtid.",
  hnw_description_2_child: "Du kan gøre følgende:\n- Sørg for at spise en varieret kost for at bevare vores og planetens sundhed.\n- Vælg kornprodukter, bælgfrugter, friske grøntsager og frugt.  \n- Begræns dit forbrug af forarbejdede fødevarer, kød og snacks med et højt indhold af salt, sukker og fedt.\n- Vær fysisk aktiv, gå, tag cyklen eller brug offentlig transport.\n- Reducer brugen af biler.",
  hnw_description_3_child: "Disse enkle valg kan styrke dit helbred og sikre, at vi passer på miljøet og alle mennesker.",
  hnw_description_4_child: "Tænk også på det, når du er færdig med dette spil.",
  hnw_description_5_child: "Tag dine venner og din familie med på rejsen.",
  hnw_description_6_child: "Vil du gerne spille igen?",
  etl_title_adult: "Spis for at leve",
  etl_description_1_adult: "Vores valg former vores fremtid. Du er måske ikke klar over, at din livsstil kan have en indvirkning på vores planets sundhed.",
  etl_description_2_adult: "Forskerne har konsekvent fastslået, at vi for at bevare vores og planetens sundhed bør spise en varieret kost for at bevare vores sundhed: Med et højt indhold af kornprodukter, bælgfrugter, friske grøntsager og frugt. Undgå forarbejdede fødevarer, kød og snacks med et højt indhold af salt, sukker og fedt. Der er flere gevinster at hente, hvis vi er fysisk aktive, går, cykler eller tager offentlig transport og reducerer brugen af biler.",
  etl_description_3_adult: "Enkle tiltag kan styrke dit helbred og vores miljø: ren luft og rent vand, sikre fødevarer, frugtbar jord og grønne områder, respekt for naturen og for alle mennesker.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Vil du gerne spille igen?",
  etl_title_child: "Vores valg former vores fremtid",
  etl_description_1_child: "Hvis vi alle forbedrer den måde, vi spiser og lever på, kan vi få en sundere fremtid.",
  etl_description_2_child: "Du kan gøre følgende:\n- Sørg for at spise en varieret kost for at bevare vores og planetens sundhed.\n- Vælg kornprodukter, bælgfrugter, friske grøntsager og frugt.  \n- Begræns dit forbrug af forarbejdede fødevarer, kød og snacks med et højt indhold af salt, sukker og fedt.\n- Vær fysisk aktiv, gå, tag cyklen eller brug offentlig transport.\n- Reducer brugen af biler.",
  etl_description_3_child: "Disse enkle valg kan styrke dit helbred og sikre, at vi passer på miljøet og alle mennesker.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Vil du gerne spille igen?",
  share: "Del",
  replay: "Spil igen",
  its_up_to_you: "Det er op til dig",
  badge_action_1: "Skjult hemmelighed afsløret!",
  badge_action_2: "Du har vundet %{badge}-badget",
  fridgeDoor: "Køleskabsdør",
  light: "Lys",
  bottle: "Flaske",
  reusable_bag: "Genanvendelig pose",
  plastic_bag: "Plastikpose",
  recycle_bin: "Affaldssortering",
  share_text_subject: "Det er op til dig",
  share_text_body: "Jeg har spillet 2050 – Det er op til dig! Hvordan mon din verden ser ud? #ExperienceEUScience\nSpil nu %{shareUrl}\nDette spil er udviklet af Det Fælles Forskningscenter, som er Europa-Kommissionens videnskabs- og videnstjeneste.\nSe mere https://joint-research-centre.ec.europa.eu/index_da?etrans=da"
};
export default i18n;
