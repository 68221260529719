const i18n = {
  title: "2050 - spēle veselīgai sabiedrībai",
  start: "Sākt",
  select_language: "Atlasīt valodu",
  adult: "Pieaudzis cilvēks",
  child: "Bērns",
  fullscreen: "Pilnekrāns",
  restart_scene: "Atsākt ainu",
  restart_game: "Atsākt spēli",
  previous: "Iepriekšējais",
  next: "Nākamais",
  close: "Aizvērt",
  toggle_sound: "Skaņa ieslēgta/izslēgta",
  character_selection_title: "Izvēlies sev varoni!",
  character_selection_body: "Mūsu izvēle ietekmē mūsu nākotni. Šajā spēlē mēs imitējam ikdienas situācijas. Izdariet izvēli un uzziniet, ko tā ietekmē! Pameklēt sev apkārt noslēptas lietas, lai nopelnītu nozīmītes! Vispirms izveido savu varoni!",
  character_set_up_title: "Izveido savu varoni!",
  select_me: "Atlasi mani!",
  breakfast_title: "Ko tu ēd brokastīs?",
  your_badges: "Žetoni",
  badge_water_conservation_master_title: "Meistars",
  badge_water_conservation_master_description: "Apsveicam! Tu esi ierobežota resursa taupīšanas meistars! Ūdens ir svarīgs visam dzīvajam.",
  badge_clean_street_wizard_title: "Burvis",
  badge_clean_street_wizard_description: "Apsveicam! Tu esi burvis, kas palīdz mūsu videi kļūt tīrākai, savācot atkritumus un izmetot tos atkritumu tvertnē.",
  badge_eco_ninja_title: "Nindzja",
  badge_eco_ninja_description: "Apsveicam! Tu esi enerģijas taupīšanas nindzja. Ierīču patērētās enerģijas taupīšana ir lielisks veids, kā palīdzēt samazināt kopējo enerģijas patēriņu.",
  badge_energy_saving_expert_title: "Eksperts",
  badge_energy_saving_expert_description: "Apsveicam! Tu esi enerģijas taupīšanas eksperts. Turpini meklēt veidus, kā samazināt enerģijas patēriņu.",
  badge_low_waste_champion_title: "Čempions",
  badge_low_waste_champion_description: "Apsveicam! Tu esi mazatkritumu čempions, kas palīdz videi, samazinot plastmasas izmantošanu.",
  badge_responsible_shopper_title: "Speciālists",
  badge_responsible_shopper_description: "Apsveicam, tu esi speciālists veselīgā izvēlē par labu videi. Turpini patērēt pēc iespējas mazāk plastmasas, ņemot līdzi vairākkārt izmantojamu maisiņu.",
  badge_recycling_captain_title: "Zvaigzne",
  badge_recycling_captain_description: "Apsveicam! Tu esi pārstrādes zvaigzne. Ir svarīgi pārstrādāt un atkārtoti izmantot materiālus, lai vide būtu veselīga.",
  faucet: "Krāns",
  transportation_title_adult: "Kā tu nokļūsti darbā?",
  transportation_title_child: "Kā tu nokļūsti skolā?",
  trash: "Atkritumu tvertne",
  no_drink: "Nekāds dzēriens",
  no_drink_description: "",
  tap_water: "Krāna ūdens",
  tap_water_description: "Dzer ūdeni. Centies izdzert vismaz 1,5-2 litrus ūdens dienā!",
  milk: "Piens",
  milk_description: "Izvēlies parastu nesaldinātu pienu, vēlams - ar zemu tauku saturu!",
  freshly_made_juice: "Svaigi spiesta sula",
  freshly_made_juice_description: "Ja tev garšo sula, izvēlies svaigi spiestu sulu - nevajag pievienot cukuru. Centies ēst svaigus augļus!",
  coffee_or_tea: "Kafija/tēja",
  coffee_or_tea_description: "Izbaudi tēju un kafiju, vēlams - bez cukura!",
  plant_based_drink: "Augu izcelsmes dzēriens",
  plant_based_drink_description: "Nogaršo augu izcelsmes dzērienus bez pievienota cukura un, ja iespējams, izvēlies tos, kuros ir vitamīni un minerālvielas!",
  whole_grain_cereals: "Pilngraudu pārslas",
  whole_grain_cereals_description: "Dod priekšroku pilngraudu pārslām bez pievienota cukura!",
  choco_balls: "Šokolādes bumbiņu pārslas",
  choco_balls_description: "Ar brokastu pārslām var uzņemt lielu daļu dienas cukura normas. Vai vari izvēlēties tādas pārslas, kurās ir mazāk pievienota cukura? Un raugies arī, lai tajās būtu pēc iespējas vairāk šķiedrvielu!",
  white_bread_or_toast: "Baltmaize/ baltmaizes grauzdiņš",
  white_bread_or_toast_description: "Nogaršo pilngraudu maizi!",
  wholemeal_bread_or_toast: "Pilngraudu maize/grauzdiņš",
  wholemeal_bread_or_toast_description: "Nogaršo pilngraudu maizi!",
  biscuits: "Cepumi",
  biscuits_description: "Cepumos ir daudz kaloriju, un ar tiem var uzņemt lielu daļu dienas cukura un tauku normas. To patēriņu labāk samazināt. Dod priekšroku cepumiem ar zemāku tauku un cukura saturu! Un raugies arī, lai tajos būtu pēc iespējas vairāk šķiedrvielu!",
  no_topping: "Bez pildījuma",
  no_topping_description: "",
  yogurt: "Jogurts",
  yogurt_description: "Nogaršo jogurtu ar zemu tauku saturu un bez piedevām un pievienota cukura!",
  honey: "Medus",
  honey_description: "Izvēlies jogurtu bez piedevām, un nepievieno tam ne cukuru, ne ievārījumu! Labāk pievienot augļus vai nesaldinātas pilngraudu pārslas.",
  jam: "Ievārījums",
  jam_description: "Izvēlies jogurtu bez piedevām, un nepievieno tam ne cukuru, ne ievārījumu! Labāk pievienot augļus vai nesaldinātas pilngraudu pārslas.",
  fruit: "Augļi",
  fruit_description: "Sākt dienu ar augļiem ir lieliska izvēle. Centies ēst sezonas augļus! Ja vēlies papildināt maltīti ar riekstiem, izvēlies riekstus bez pievienotas sāls vai cukura!",
  butter: "Sviests",
  butter_description: "Sviestā ir daudz tauku, un tas var būt sasālīts. Centies to neēst pārāk bieži un izvēlies ēdienus bez sāls!",
  chocolate_cream: "Šokolādes krēms",
  chocolate_cream_description: "Šokolādes krēmā ir daudz cukura un piesātināto tauku. Centies to neēst pārāk bieži!",
  ham_or_bacon: "Šķiņķis/speķis",
  ham_or_bacon_description: "Pārstrādātā gaļā ir daudz tauku un sāls. Ja tu ēd pārstrādātu gaļu, ierobežo tās patēriņu!",
  cheese: "Siers",
  cheese_description: "Sierā ir milzīgi daudz sāls un piesātināto tauku. Centies to neēst pārāk bieži un ēd mazās devās!",
  avocado_and_tomato: "Avokado un tomāts",
  avocado_and_tomato_description: "Sākt dienu, apēdot mazliet dārzeņu, ir lieliska izvēle. Dienas laikā centies ēst daudz augļu un dārzeņu un priekšroku dod vietējiem sezonas augļiem un dārzeņiem!",
  battery: "Akumulators",
  battery_description: "Uzmani savu akumulatoru, tas rāda tavas izvēles ietekmi!",
  lunch_title: "Ko tu vēlētos pusdienās?",
  breaktime_title: "Pēcpusdienā nekas nav ieplānots - brīvais laiks! Ko tu dari?",
  snacking_title: "Vai vēlies kādu uzkodu?",
  supermarket_title: "Ko tu pērc?",
  dinner_selection_title: "Kas tev būs vakariņās?",
  dinner_cooking_title: "Kas paredzēts vakariņās?",
  dinner_delivery_title: "Kas paredzēts vakariņās?",
  your_available_items: "Tavas izvēles iespējas",
  your_selections: "Tava izvēle",
  walk: "Eju kājām",
  walk_description: "Esi fiziski aktīvs savā ikdienas dzīvē! Ja iespējams, uz skolu/darbu ej kājām vai brauc ar divriteni! Tādā veidā tu uzturēsi sevi labā formā, un tas ir labāk apkārtējai videi.",
  bicycle: "Braucu ar divriteni",
  bicycle_description: "Esi fiziski aktīvs savā ikdienas dzīvē! Ja iespējams, uz skolu/darbu ej kājām vai brauc ar divriteni! Tādā veidā tu uzturēsi sevi labā formā, un tas ir labāk apkārtējai videi.",
  scooter_or_motorbike: "Braucu ar motorolleru/motociklu",
  scooter_or_motorbike_description: "Centies retāk braukt ar auto vai motociklu, lai apkārtējā vide būtu tīrāka! Ja tas nav iespējams, centies izmantot automobiļu koplietošanu!",
  car: "Braucu ar auto",
  car_description: "Centies retāk braukt ar auto vai motociklu, lai apkārtējā vide būtu tīrāka! Ja tas nav iespējams, centies izmantot automobiļu koplietošanu!",
  public_transport: "Braucu ar sabiedrisko transportu",
  public_transport_description: "Izmanto sabiedrisko transportu un apvieno to ar iešanu un riteņbraukšanu, nevis brauc ar auto! Atpūties no braukšanas ar auto un palīdzi videi!",
  salad_with_egg: "Salātus ar olu",
  salad_with_egg_description: "Pievieno maltītei svaigus dārzeņus, kad vien iespējams!",
  beef_steak: "Liellopu gaļas steiku",
  beef_steak_description: "Ja tu ēd gaļu, apsver iespēju patērēt to mazāk, īpaši sarkano vai pārstrādāto gaļu! Tā būs labāk tavai veselībai un videi.",
  chicken: "Vistu",
  chicken_description: "Ja tu ēd gaļu, apsver iespēju patērēt to mazāk, īpaši sarkano vai pārstrādāto gaļu! Tā būs labāk tavai veselībai un videi.",
  lentil_soup: "Lēcu zupu",
  lentil_soup_description: "Daudzveidīgā un galvenokārt augu izcelsmes uzturā ir ieteicams regulāri ēst pākšaugus.",
  vegetable_burger: "Dārzeņu burgeru",
  vegetable_burger_description: "Dārzeņu burgers var būt laba alternatīva gaļai. Uzmanies, lai tajā nav pārāk daudz sāls!",
  white_pasta: "Balto miltu makaronu ēdienu",
  white_pasta_description: "Dod priekšroku pilngraudu produktiem, kad izvēlies makaronu ēdienus, rīsus un maizi!",
  wholegrain_pasta: "Pilngraudu makaronu ēdienu",
  wholegrain_pasta_description: "Dod priekšroku pilngraudu produktiem, kad izvēlies makaronu ēdienus, rīsus un maizi!",
  white_rice: "Baltos rīsus",
  white_rice_description: "Dod priekšroku pilngraudu produktiem, kad izvēlies makaronu ēdienus, rīsus un maizi!",
  wholegrain_rice: "Pilngraudu rīsus",
  wholegrain_rice_description: "Dod priekšroku pilngraudu produktiem, kad izvēlies makaronu ēdienus, rīsus un maizi!",
  white_bread_bun: "Smalkmaizīti",
  white_bread_bun_description: "Dod priekšroku pilngraudu produktiem, kad izvēlies makaronu ēdienus, rīsus un maizi!",
  no_salad: "Neko",
  no_salad_description: "",
  fresh_green_salad: "Svaigus zaļos salātus",
  fresh_green_salad_description: "Maltītei pievieno nedaudz krāsainu sezonas dārzeņu – jo lielāka daudzveidība, jo labāk!",
  french_fries: "Fritētus kartupeļus",
  french_fries_description: "Labāk izvēlēties vārītus kartupeļus, nevis eļļā vārītus kartupeļu produktus.",
  boiled_broccoli: "Vārītus brokoļus",
  boiled_broccoli_description: "Dienas laikā centies ēst daudz dārzeņu! Izvēlies vietējos un sezonas dārzeņus!",
  bottled_water: "Pudelēs pildītu ūdeni",
  bottled_water_description: "Dzer krāna ūdeni, lai samazinātu plastmasas izmantošanu!",
  soft_drink: "Atspirdzinošo dzērienu",
  soft_drink_description: "Ar saldinātiem dzērieniem var uzņemt lielu daļu dienas cukura normas. Vai vari tā vietā izvēlēties ūdeni?",
  beer_or_wine: "Alu/vīnu",
  beer_or_wine_description: "Ja tu dzer kādus alkoholiskus dzērienus, ierobežo to patēriņu! Veselības labad labāk atturēties no alkohola.",
  watching_tv: "Skatos TV vai spēlēju videospēles",
  watching_tv_description: "Katru dienu centies būt fiziski aktīvs un centies samazināt laiku, ko pavadi sēžot! Brīvajā laikā pasporto, parotaļājies ar draugiem vai palīdzi apkārtējiem. Tā būs labāk tavai veselībai un sabiedrībai. Centies nepavadīt pārāk daudz laika pie ekrāna!",
  doing_sports: "Nodarbojos ar sportu",
  doing_sports_description: "Katru dienu centies būt fiziski aktīvs un centies samazināt laiku, ko pavadi sēžot! Brīvajā laikā pasporto, parotaļājies ar draugiem vai palīdzi apkārtējiem. Tā būs labāk tavai veselībai un sabiedrībai. Centies nepavadīt pārāk daudz laika pie ekrāna!",
  playing_outside: "Rotaļājos ārā",
  playing_outside_description: "Katru dienu centies būt fiziski aktīvs un centies samazināt laiku, ko pavadi sēžot! Brīvajā laikā pasporto, parotaļājies ar draugiem vai palīdzi apkārtējiem. Tā būs labāk tavai veselībai un sabiedrībai. Centies nepavadīt pārāk daudz laika pie ekrāna!",
  charity_work: "Nodarbojos ar labdarību kopā ar pieaugušajiem",
  charity_work_description: "Katru dienu centies būt fiziski aktīvs un centies samazināt laiku, ko pavadi sēžot! Brīvajā laikā pasporto, parotaļājies ar draugiem vai palīdzi apkārtējiem. Tā būs labāk tavai veselībai un sabiedrībai. Centies nepavadīt pārāk daudz laika pie ekrāna!",
  carrots_tomatoes: "Burkānus / ķiršu tomātus",
  carrots_tomatoes_description: "Dod priekšroku augļiem, dārzeņiem vai riekstiem! Mazāk patērē uzkodas un dzērienus, kuros ir liels cukura, tauku un sāls daudzums!",
  fresh_fruit: "Svaigus augļus",
  fresh_fruit_description: "Dod priekšroku augļiem, dārzeņiem vai riekstiem! Mazāk patērē uzkodas un dzērienus, kuros ir liels cukura, tauku un sāls daudzums!",
  chocolate_bar: "Šokolādes tāfelīti",
  chocolate_bar_description: "Dod priekšroku augļiem, dārzeņiem vai riekstiem! Mazāk patērē uzkodas un dzērienus, kuros ir liels cukura, tauku un sāls daudzums!",
  chips: "Čipsus",
  chips_description: "Dod priekšroku augļiem, dārzeņiem vai riekstiem! Mazāk patērē uzkodas un dzērienus, kuros ir liels cukura, tauku un sāls daudzums!",
  juice_drink: "Sulas dzērienu",
  juice_drink_description: "Dod priekšroku svaigiem augļiem vai svaigi spiestai sulai bez pievienota cukura, nevis sulas dzērieniem!",
  supermarket_prompt: "Izvēlies ne vairāk kā 9 produktus!",
  raw_fish: "Jēlas zivis",
  raw_fish_description: "Regulāri ēdot zivis, uzņemam svarīgas barības vielas. Nogaršo dažādas zivis!",
  raw_beef: "Jēla liellopu gaļa",
  raw_beef_description: "Ja tu ēd gaļu, pamēģini to ēst mazāk, sevišķi sarkanu un pārstrādātu gaļu! Būs labāk gan tavai veselībai, gan videi.",
  raw_chicken: "Jēla vista",
  raw_chicken_description: "Ja tu ēd gaļu, apsver iespēju patērēt to mazāk, īpaši sarkano vai pārstrādāto gaļu! Tā būs labāk tavai veselībai un videi.",
  whole_salad: "Vesels salātu dārzenis - piemēram, dārza salātu galviņa",
  whole_salad_description: "Dod priekšroku svaigiem augļiem un dārzeņiem, īpaši sezonas un vietējiem!",
  packaged_salad: "Iepakoti salāti",
  packaged_salad_description: "Dod priekšroku svaigiem augļiem un dārzeņiem, īpaši sezonas un vietējiem!",
  seasonal_fruit: "Vietējie sezonas augļi",
  seasonal_fruit_description: "Dod priekšroku svaigiem augļiem un dārzeņiem, īpaši sezonas un vietējiem!",
  exotic_fruit: "Eksotiski augļi",
  exotic_fruit_description: "Dod priekšroku svaigiem augļiem un dārzeņiem, īpaši sezonas un vietējiem!",
  ham: "Šķiņķis",
  ham_description: "Pārstrādātā gaļā ir daudz tauku un sāls. Ja tu ēd pārstrādātu gaļu, centies ierobežot tās patēriņu!",
  tofu: "Tofu",
  tofu_description: "Izzini jaunus augu izcelsmes produktus un jaunas receptes!",
  hummus: "Humoss",
  hummus_description: "Humoss ir laba alternatīva, kas palīdzēs palielināt pākšaugu uzņemšanu.",
  precooked_pizza: "Picas pusfabrikāts",
  precooked_pizza_description: "Ēdienu pusfabrikātos var būt daudz sāls vai tauku. Dod priekšroku produktiem, kas nav pusfabrikāti! Tas ļaus kontrolēt maltītē ietilpstošo sāls, cukura un tauku daudzumu.",
  precooked_fries: "Fritētu kartupeļu pusfabrikāti",
  precooked_fries_description: "Ēdienu pusfabrikātos var būt daudz sāls vai tauku. Dod priekšroku produktiem, kas nav pusfabrikāti! Tas ļaus kontrolēt maltītē ietilpstošo sāls, cukura un tauku daudzumu.",
  food_delivery: "Ēdiena piegāde",
  food_delivery_description: "",
  cooking_at_home: "Ēdiena gatavošana mājās",
  cooking_at_home_description: "",
  cheeseburger: "Siera burgers, eļļā vārīti kartupeļi un atspirdzinošs dzēriens",
  cheeseburger_description: "Centies neēst šādas maltītes! Tajās ir daudz tauku un sāls. Dzeršanai izvēlies ūdeni!",
  pizza_and_soft_drink: "Pica un atspirdzinošs dzēriens",
  pizza_and_soft_drink_description: "Šādā maltītē ir daudz tauku, sāls un cukura. Izvēlies “vienkāršas” picas ar mazāk sastāvdaļām vai vairāk dārzeņiem un, ja iespējams, izvēlies pilngraudu picas pamatni! Dzeršanai izvēlies ūdeni!",
  noodles_with_vegetables: "Nūdeles ar dārzeņiem",
  noodles_with_vegetables_description: "Tas ir labs risinājums, kā palielināt dārzeņu uzņemšanu. Pievieno nedaudz pākšaugu, lai maltīte būtu pilnīgāka.",
  mushroom_risoto: "Sēņu risoto",
  mushroom_risoto_description: "Sēnes ir labs veģetārisks ēdiens. Uzmanies, lai nepievienotu pārāk daudz siera! Šādu maltīti vari papildināt ar salātiem.",
  precooked_meal: "Ēdiena pusfabrikāts - vistas nageti",
  precooked_meal_description: "Ēdienu pusfabrikātos var būt daudz sāls, tauku un pat cukura. Dod priekšroku produktiem, kas nav pusfabrikāti! Tad varēsi labāk kontrolēt šo uzturvielu daudzumu. Garšas bagātināšanai sāls vietā izmanto garšaugus un garšvielas!",
  frozen_pizza: "Saldēta pica",
  frozen_pizza_description: "Ēdienu pusfabrikātos var būt daudz sāls, tauku un pat cukura. Dod priekšroku produktiem, kas nav pusfabrikāti! Tad varēsi labāk kontrolēt šo uzturvielu daudzumu. Garšas bagātināšanai sāls vietā izmanto garšaugus un garšvielas!",
  rice_and_salad: "Paštaisīta maltīte - rīsi un zaļie salāti",
  rice_and_salad_description: "Ēdienu pusfabrikātos var būt daudz sāls, tauku un pat cukura. Dod priekšroku produktiem, kas nav pusfabrikāti! Tad varēsi labāk kontrolēt šo uzturvielu daudzumu. Garšas bagātināšanai sāls vietā izmanto garšaugus un garšvielas!",
  ham_sandwich_and_soft_drink: "Šķiņķa sviestmaize un atspirdzinošs dzēriens",
  ham_sandwich_and_soft_drink_description: "Pārstrādātā gaļā ir daudz tauku un sāls. Ja tu ēd pārstrādātu gaļu, centies ierobežot tās patēriņu! Centies arī izvairīties no cukurotiem dzērieniem un atspirdzinošajiem dzērieniem! To vietā dzer krāna ūdeni!",
  wholegrain_pasta_with_sauce: "Pilngraudu makaronu ēdiens ar dārzeņu mērci",
  wholegrain_pasta_with_sauce_description: "Nogaršo pilngraudu makaronu ēdienus!",
  hnw_title_adult: "Veselīga jaunā pasaule",
  hnw_description_1_adult: "Mūsu izvēle veido mūsu nākotni. Ja visi ēdīsim un dzīvosim tā, kā tu to darīji spēlē, mūsu nākotne var kļūt veselīgāka kopā ar cilvēkiem, kuri rūpējas cits par citu un apkārtējo vidi.",
  hnw_description_2_adult: "Zinātnieki konsekventi uzskata, ka, lai saglabātu mūsu un mūsu planētas veselību, mums vajadzētu uzņemt daudzveidīgu uzturu, kas ir bagāts ar graudaugiem, pākšaugiem, svaigiem dārzeņiem un augļiem. Izvairies no pārstrādātiem pārtikas produktiem, gaļas un uzkodām ar augstu sāls, cukura un tauku saturu! Ja būsim fiziski aktīvi, iesim kājām, brauksim ar divriteni vai izmantosim sabiedrisko transportu un mazāk brauksim ar auto, ieguvēji būsim gan mēs, gan vide.",
  hnw_description_3_adult: "Tavu veselību un mūsu vidi var uzlabot vienkāršas lietas: tīrs gaiss un ūdens, nekaitīga pārtika, bagātīga augsne un zaļās zonas, cieņa pret dabu un visiem cilvēkiem. Padomā par to arī ārpus šīs spēles! Uzaicini savus draugus un ģimeni piedalīties šajā ceļojumā.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Vēlies spēlēt vēlreiz?",
  hnw_title_child: "Mūsu izvēles veido mūsu nākotni",
  hnw_description_1_child: "Ja visi ēdīsim un dzīvosim tā, kā tu to darīji spēlē, mūsu nākotne var būt veselīgāka.",
  hnw_description_2_child: "Ko vari darīt tu?\n- Uzņemt daudzveidīgu uzturu, lai saglabātu mūsu un mūsu planētas veselību.\n- Izvēlēties graudaugus, pākšaugus, svaigus dārzeņus un augļus. \n- Mazāk ēst pārstrādātus pārtikas produktus, gaļu un uzkodas ar augstu sāls, cukura un tauku saturu.\n- Būt fiziski aktīvs, staigāt, braukt ar divriteni un sabiedrisko transportu.\n- Mazāk braukt ar auto.",
  hnw_description_3_child: "Šī vienkāršā izvēle var uzlabot tavu veselību, un tā mēs varam arī parūpēties par vidi un visiem cilvēkiem.",
  hnw_description_4_child: "Padomā par to arī ārpus šīs spēles!",
  hnw_description_5_child: "Uzaicini draugus un ģimeni piedalīties šajā ceļojumā!",
  hnw_description_6_child: "Vēlies spēlēt vēlreiz?",
  etl_title_adult: "Ēst, lai dzīvotu",
  etl_description_1_adult: "Mūsu izvēle veido mūsu nākotni. Varbūt to nezini, bet tavs dzīvesveids var ietekmēt mūsu planētas veselību.",
  etl_description_2_adult: "Zinātnieki konsekventi uzskata, ka, lai saglabātu mūsu un mūsu planētas veselību, mums vajadzētu uzņemt daudzveidīgu uzturu, kas ir bagāts ar graudaugiem, pākšaugiem, svaigiem dārzeņiem un augļiem. Izvairies no pārstrādātiem pārtikas produktiem, gaļas un uzkodām ar augstu sāls, cukura un tauku saturu! Ja būsim fiziski aktīvi, iesim kājām, brauksim ar divriteni vai izmantosim sabiedrisko transportu un mazāk brauksim ar auto, ieguvēji būsim gan mēs, gan vide.",
  etl_description_3_adult: "Tavu veselību un mūsu vidi var uzlabot vienkāršas lietas: tīrs gaiss un ūdens, nekaitīga pārtika , bagātīga augsne un zaļās zonas, cieņa pret dabu un visiem cilvēkiem.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Vēlies spēlēt vēlreiz?",
  etl_title_child: "Mūsu izvēle veido mūsu nākotni",
  etl_description_1_child: "Ja visi uzlabosim savus ēšanas un dzīves paradumus, mums var būt veselīgāka nākotne.",
  etl_description_2_child: "Ko vari darīt tu?\n- Uzņemt daudzveidīgu uzturu, lai saglabātu mūsu un mūsu planētas veselību.\n- Izvēlēties graudaugus, pākšaugus, svaigus dārzeņus un augļus. \n- Mazāk ēst pārstrādātus pārtikas produktus, gaļu un uzkodas ar augstu sāls, cukura un tauku saturu.\n- Būt fiziski aktīvs, staigāt, braukt ar divriteni un sabiedrisko transportu.\n- Mazāk braukt ar auto.",
  etl_description_3_child: "Šī vienkāršā izvēle var uzlabot tavu veselību, un tā mēs varam arī parūpēties par vidi un visiem cilvēkiem.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Vēlies spēlēt vēlreiz?",
  share: "Kopīgot",
  replay: "Spēlēt atkal",
  its_up_to_you: "Tas ir tavās rokās!",
  badge_action_1: "Noslēpums atklāts!",
  badge_action_2: "Tu ieguvi %{badge} nozīmīti.",
  fridgeDoor: "Ledusskapja durvis",
  light: "Gaisma",
  bottle: "Pudele",
  reusable_bag: "Atkallietojama kulīte",
  plastic_bag: "Plastmasas kulīte",
  recycle_bin: "Atkritumu tvertne",
  share_text_subject: "Tas ir tavās rokās!",
  share_text_body: "Es spēlēju 2050 – tagad tu! Gribi zināt, kā izskatās tava pasaule? #ExperienceEUScience\nSpēlē %{shareUrl}\nŠo spēli izstrādājis  Kopīgo pētījumu centrs – Eiropas Komisijas zinātnes un zinību dienests.\nSīkāk par to https://joint-research-centre.ec.europa.eu/index_lv?etrans=lv"
};
export default i18n;
