import PropTypes from "prop-types";
import exact from "prop-types-exact";
import "elements/character/CharacterResponsiveCustomModal.scss";

// This one was developed in a rush. We should rename, use event handlers, and remove those ids.
const CharacterResponsiveCustomModal = ({ t, characterElement }) => (
  <div className="character-rounded" id="open-character-modal">
    <a
      href="#open-character-modal"
      className="open-character-modal-button"
      id="open-character-modal-button"
      type="button"
    >
      &#x1f5c1;
    </a>
    <a
      href="#close-character-modal"
      className="close-character-modal-button"
      id="close-character-modal-button"
      type="button"
    >
      &#128193;
    </a>
    {characterElement}
    <a
      href="#character-overlay-modal"
      className="character-overlay-modal-close"
      id="character-overlay-modal-close"
      type="button"
    >
      {t("close")}
    </a>
  </div>
);

CharacterResponsiveCustomModal.propTypes = exact({
  t: PropTypes.func.isRequired,
  characterElement: PropTypes.element.isRequired
});

export default CharacterResponsiveCustomModal;
