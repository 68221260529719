import { selectableToPoints } from "game-score.js";
import {
  psychedelic,
  psychedelicStop,
  cityTraffic,
  cityTrafficStop,
  suburbanTrafficWithBirds,
  suburbanTrafficWithBirdsStop,
  roomAmbienceWithBirds,
  roomAmbienceWithBirdsStop,
  playgroundNoises,
  playgroundNoisesStop,
  supermarket,
  supermarketStop,
  carPass,
  carPassStop
} from "common/sounds.js";

export const scenes = [
  "intro",
  "character-selection",
  "character-set-up",
  "breakfast",
  "transportation",
  "lunch",
  "breaktime",
  "snacking",
  "supermarket",
  "dinner-selection",
  "dinner-delivery",
  "dinner-cooking",
  "world"
];

export const getSceneStartAndStopSoundFunctions = scene => {
  if (
    ["character-selection", "character-set-up", "breakfast", "lunch", "dinner-delivery", "dinner-cooking"].includes(
      scene
    )
  )
    return [psychedelic, psychedelicStop];
  if (["transportation"].includes(scene)) return [cityTraffic, cityTrafficStop];
  if (["dinner-selection"].includes(scene)) return [suburbanTrafficWithBirds, suburbanTrafficWithBirdsStop];
  if (["breaktime"].includes(scene)) return [roomAmbienceWithBirds, roomAmbienceWithBirdsStop];
  if (["snacking"].includes(scene)) return [playgroundNoises, playgroundNoisesStop];
  if (["supermarket"].includes(scene)) return [supermarket, supermarketStop];
  if (scene && scene.startsWith("map-transition-")) return [carPass, carPassStop]; // There are various MapTransition scenes, each with a different suffix.
  return [undefined, undefined];
};

/**
 * These are the timeline steps. Multiple scenes can be rendered in specific
 * timeline indexes because they fork depending on a previous user choice.
 */
export const timelineScenes = [
  ["character-set-up"],
  ["breakfast"],
  ["transportation"],
  ["lunch"],
  ["breaktime"],
  ["snacking"],
  ["supermarket"],
  ["dinner-selection"],
  ["dinner-delivery", "dinner-cooking"]
];

export const selectables = selectableToPoints.map(selectablePoints => selectablePoints.name);

export const getNewGameState = () => ({
  scene: scenes[0],
  character: undefined,
  badges: [],
  selections: []
});
