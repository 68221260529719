const i18n = {
  title: "Mäng Terve ühiskond 2050",
  start: "Alusta",
  select_language: "Vali keel",
  adult: "Täiskasvanu",
  child: "Laps",
  fullscreen: "Täisekraan",
  restart_scene: "Alusta episoodi uuesti",
  restart_game: "Mängi veel kord",
  previous: "Eelmine",
  next: "Järgmine",
  close: "Sulge",
  toggle_sound: "Heli sisse/välja",
  character_selection_title: "Vali oma tegelaskuju",
  character_selection_body: "Oma valikutega mõjutame oma tulevikku. Selles mängus simuleeritakse igapäevaseid olukordi. Tee oma valik ja vaata, milline on selle mõju. Otsi peidetud vihjeid, et teenida aumärke! Alusta oma tegelaskuju loomisest.",
  character_set_up_title: "Loo oma tegelaskuju",
  select_me: "Vali mind!",
  breakfast_title: "Mida sa hommikusöögiks sööd?",
  your_badges: "Aumärgid",
  badge_water_conservation_master_title: "Meister",
  badge_water_conservation_master_description: "Palju õnne! Oled nappide loodusvarade säästmise meister! Vesi on oluline kõikide eluvormide jaoks.",
  badge_clean_street_wizard_title: "Võlur",
  badge_clean_street_wizard_description: "Palju õnne! Oled võlur, kes aitab meie keskkonda puhtamaks muuta, korjates maast üles prügi ja pannes selle prügikasti.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Palju õnne! Oled energiasäästu-ninja! Seadmete poolt tarbitava energia kokkuhoid on suurepärane viis energiatarbimise vähendamiseks.",
  badge_energy_saving_expert_title: "Ekspert",
  badge_energy_saving_expert_description: "Palju õnne! Oled energiasäästuekspert! Otsi edaspidigi võimalusi energiatarbimist vähendada.",
  badge_low_waste_champion_title: "Tšempion",
  badge_low_waste_champion_description: "Palju õnne! Oled jäätmete vähendamise ja keskkonna säästmise tšempion, kasutades vähem plasti!",
  badge_responsible_shopper_title: "Proff",
  badge_responsible_shopper_description: "Palju õnne! Oled keskkonnahoidlike valikute tegemise proff! Kasuta edaspidigi võimalikult vähe plasti, kandes kaasas korduskasutatavat kotti.",
  badge_recycling_captain_title: "Staar",
  badge_recycling_captain_description: "Palju õnne! Oled ringlussevõtustaar! Tervema keskkonna tagamiseks on oluline materjalide ringlussevõtt ja taaskasutus.",
  faucet: "Kraan",
  transportation_title_adult: "Kuidas sa tööle lähed?",
  transportation_title_child: "Kuidas sa kooli lähed?",
  trash: "Prügi",
  no_drink: "Ei joo midagi",
  no_drink_description: "",
  tap_water: "Kraanivesi",
  tap_water_description: "Joo piisavalt vett. Püüa juua vähemalt 1,5–2 liitrit vett päevas.",
  milk: "Piim",
  milk_description: "Eelista tavalist magustamata piima, soovitatavalt väherasvast.",
  freshly_made_juice: "Värskelt pressitud mahl",
  freshly_made_juice_description: "Kui sulle meeldib juua mahla, eelista värskelt pressitud mahla – suhkrut ei ole vaja lisada. Söö värskeid puuvilju ja marju.",
  coffee_or_tea: "Kohv/tee",
  coffee_or_tea_description: "Teed ja kohvi on soovitatav juua ilma suhkruta.",
  plant_based_drink: "Taimne jook",
  plant_based_drink_description: "Joo taimseid jooke, millele ei ole lisatud suhkruid, ja võimaluse korral eelista neid, mis sisaldavad vitamiine ja mineraalaineid.",
  whole_grain_cereals: "Täisterahelbed",
  whole_grain_cereals_description: "Eelista täisterahelbeid, millele ei ole lisatud suhkruid.",
  choco_balls: "Kakaopallid",
  choco_balls_description: "Hommikuhelbed võivad moodustada suure osa meie päevasest suhkrutarbimisest – võiksid süüa helbeid, millele on lisatud vähem suhkruid. Lisaks võiksid valida sellised, milles on rohkem kiudaineid.",
  white_bread_or_toast: "Sai/röstsai",
  white_bread_or_toast_description: "Söö täisteraleiba.",
  wholemeal_bread_or_toast: "Täisteraleib/täisteraröstleib",
  wholemeal_bread_or_toast_description: "Söö täisteraleiba.",
  biscuits: "Küpsised",
  biscuits_description: "Küpsised sisaldavad rohkesti kaloreid ja võivad moodustada suure osa meie päevasest suhkru- ja rasvatarbimisest. Nende söömist tasub vähendada. Eelista väiksema rasva- ja suhkrusisaldusega küpsiseid. Lisaks võiksid valida sellised, milles on ka rohkem kiudaineid.",
  no_topping: "Ilma lisandita",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Eelista maitsestamata väherasvast jogurtit, millele ei ole lisatud suhkruid.",
  honey: "Mesi",
  honey_description: "Eelista maitsestamata jogurtit, millele ei ole lisatud suhkruid või moosi. Lisada võib puuvilju või marju või magustamata täisterahelbeid.",
  jam: "Moos",
  jam_description: "Eelista maitsestamata jogurtit, millele ei ole lisatud suhkruid või moosi. Lisada võib puuvilju või marju või magustamata täisterahelbeid.",
  fruit: "Puuvili",
  fruit_description: "Puuviljad ja marjad on päeva alustamiseks suurepärane valik, eelista hooajalisi puuvilju ja marju. Kui lisad pähkleid, eelista neid, millele ei ole lisatud soola ega suhkrut.",
  butter: "Või",
  butter_description: "Või sisaldab suures koguses rasva ja võib olla soolane. Püüa seda tarbida mitte liiga sageli ja eelista magedat võid.",
  chocolate_cream: "Šokolaadikreem",
  chocolate_cream_description: "Šokolaadikreem sisaldab palju suhkrut ja küllastunud rasva. Ära söö seda liiga tihti.",
  ham_or_bacon: "Sink/peekon",
  ham_or_bacon_description: "Töödeldud liha sisaldab palju rasva ja soola. Püüa süüa võimalikult vähe töödeldud liha.",
  cheese: "Juust",
  cheese_description: "Juust sisaldab suures koguses soola ja küllastunud rasvhappeid. Püüa seda tarbida mitte liiga sageli ja väikeste portsjonitena.",
  avocado_and_tomato: "Avokaado ja tomat",
  avocado_and_tomato_description: "Köögiviljad on päeva alustamiseks suurepärane valik. Püüa päeva jooksul süüa piisavalt puuvilju, marju ja köögivilju ning eelista kohalikke ja hooajalisi.",
  battery: "Aku",
  battery_description: "Jälgi tähelepanelikult mõõdikut, mis näitab valikute mõju!",
  lunch_title: "Mida sa lõunaks süüa sooviksid?",
  breaktime_title: "Pärastlõunane vaba aeg",
  snacking_title: "Tahaksid midagi näksida?",
  supermarket_title: "Mida ostad?",
  dinner_selection_title: "Millised on õhtusöögiplaanid?",
  dinner_cooking_title: "Mis on õhtusöögiks?",
  dinner_delivery_title: "Mis on õhtusöögiks?",
  your_available_items: "Valikud",
  your_selections: "Valikud",
  walk: "Jalgsi",
  walk_description: "Hoia end füüsiliselt aktiivsena. Võimaluse korral mine kooli või tööle jalgsi või rattaga. See hoiab sind vormis ja on parem keskkonnale.",
  bicycle: "Jalgrattaga",
  bicycle_description: "Hoia end füüsiliselt aktiivsena. Võimaluse korral mine kooli või tööle jalgsi või rattaga. See hoiab sind vormis ja on parem keskkonnale.",
  scooter_or_motorbike: "Rolleri/mootorrattaga",
  scooter_or_motorbike_description: "Et keskkonda vähem saastada, püüa autot või mootorratast harvem kasutada. Kui see ei ole võimalik, eelista autode ühiskasutust.",
  car: "Autoga",
  car_description: "Et keskkonda vähem saastada, püüa autot või mootorratast harvem kasutada. Kui see ei ole võimalik, eelista autode ühiskasutust.",
  public_transport: "Ühistranspordiga",
  public_transport_description: "Kasuta ühistransporti ja kombineeri seda kõndimise ja rattasõiduga, selle asemel, et autoga minna. Puhka autosõidust ja säästa keskkonda.",
  salad_with_egg: "Salat munaga",
  salad_with_egg_description: "Lisa toidule võimaluse korral värskeid köögivilju.",
  beef_steak: "Biifsteek",
  beef_steak_description: "Kui sööd liha, vähenda selle tarbimist, eriti punase või töödeldud liha söömist. See teeb head nii sinu tervisele kui ka keskkonnale.",
  chicken: "Kana",
  chicken_description: "Kui sööd liha, vähenda selle tarbimist, eriti punase või töödeldud liha söömist. See teeb head nii sinu tervisele kui ka keskkonnale.",
  lentil_soup: "Läätsesupp",
  lentil_soup_description: "Söö regulaarselt kaunvilju, see on osa mitmekesisest ja taimepõhisest toitumisest.",
  vegetable_burger: "Taimne burger",
  vegetable_burger_description: "Taimne burger võib olla hea alternatiiv lihale. Vaata, et see ei sisaldaks liiga palju soola.",
  white_pasta: "Valged makarontooted",
  white_pasta_description: "Makarontooteid, riisi ja leiba valides eelista täisteratooteid.",
  wholegrain_pasta: "Täisteramakarontooted",
  wholegrain_pasta_description: "Makarontooteid, riisi ja leiba valides eelista täisteratooteid.",
  white_rice: "Valge riis",
  white_rice_description: "Makarontooteid, riisi ja leiba valides eelista täisteratooteid.",
  wholegrain_rice: "Täisterariis",
  wholegrain_rice_description: "Makarontooteid, riisi ja leiba valides eelista täisteratooteid.",
  white_bread_bun: "Kukkel",
  white_bread_bun_description: "Makarontooteid, riisi ja leiba valides eelista täisteratooteid.",
  no_salad: "Ilma salatita",
  no_salad_description: "",
  fresh_green_salad: "Värske roheline salat",
  fresh_green_salad_description: "Lisa oma toitudele värvilisi hooajalisi köögivilju ja mida erinevamaid, seda parem.",
  french_fries: "Friikartulid",
  french_fries_description: "Frititud kartulitoodetele tuleks eelistada keedetud kartulit.",
  boiled_broccoli: "Keedetud brokoli",
  boiled_broccoli_description: "Püüa päeva jooksul süüa piisavalt köögivilju. Eelista kohalikke ja hooajalisi köögivilju.",
  bottled_water: "Pudelivesi",
  bottled_water_description: "Joo kraanivett, et vähendada plasti kasutamist.",
  soft_drink: "Karastusjook",
  soft_drink_description: "Magustatud joogid moodustavad suure osa meie igapäevasest suhkrutarbimisest – eelista neile vett.",
  beer_or_wine: "Õlu/vein",
  beer_or_wine_description: "Kui tarvitad alkoholi, piira selle tarbimist. Alkoholi vältimine teeb tervisele head.",
  watching_tv: "Teleri vaatamine või videomängude mängimine",
  watching_tv_description: "Hoia end füüsiliselt aktiivsena ja püüa vähendada istudes veedetud aega. Vabal ajal tee sporti, mängi sõpradega või aita oma kogukonda. See teeb head sinu tervisele ja ühiskonnale. Ära veeda liiga palju aega ekraani ees.",
  doing_sports: "Sportimine",
  doing_sports_description: "Hoia end füüsiliselt aktiivsena ja püüa vähendada istudes veedetud aega. Vabal ajal tee sporti, mängi sõpradega või aita oma kogukonda. See teeb head sinu tervisele ja ühiskonnale. Ära veeda liiga palju aega ekraani ees.",
  playing_outside: "Õues mängimine",
  playing_outside_description: "Hoia end füüsiliselt aktiivsena ja püüa vähendada istudes veedetud aega. Vabal ajal tee sporti, mängi sõpradega või aita oma kogukonda. See teeb head sinu tervisele ja ühiskonnale. Ära veeda liiga palju aega ekraani ees.",
  charity_work: "Heategevus koos täiskasvanutega",
  charity_work_description: "Hoia end füüsiliselt aktiivsena ja püüa vähendada istudes veedetud aega. Vabal ajal tee sporti, mängi sõpradega või aita oma kogukonda. See teeb head sinu tervisele ja ühiskonnale. Ära veeda liiga palju aega ekraani ees.",
  carrots_tomatoes: "Porgandid/kirsstomatid",
  carrots_tomatoes_description: "Eelista puuvilju, marju, köögivilju või pähkleid. Piira suure suhkru-, rasva- ja soolasisaldusega näkside ja jookide tarbimist.",
  fresh_fruit: "Värsked puuviljad",
  fresh_fruit_description: "Eelista puuvilju, marju, köögivilju või pähkleid. Piira suure suhkru-, rasva- ja soolasisaldusega näkside ja jookide tarbimist.",
  chocolate_bar: "Šokolaad",
  chocolate_bar_description: "Eelista puuvilju, marju, köögivilju või pähkleid. Piira suure suhkru-, rasva- ja soolasisaldusega näkside ja jookide tarbimist.",
  chips: "Kartulikrõpsud",
  chips_description: "Eelista puuvilju, marju, köögivilju või pähkleid. Piira suure suhkru-, rasva- ja soolasisaldusega näkside ja jookide tarbimist.",
  juice_drink: "Mahlajook",
  juice_drink_description: "Eelista mahlajookidele värskeid puuvilju ja marju või värskelt pressitud mahla, millele ei ole lisatud suhkrut.",
  supermarket_prompt: "Vali kuni 9 toodet",
  raw_fish: "Toores kala",
  raw_fish_description: "Kala söömine tagab organismile vajalikud toitained. Proovi erinevaid kalu.",
  raw_beef: "Toores veiseliha",
  raw_beef_description: "Kui sööd liha, vähenda selle tarbimist, eriti punase või töödeldud liha söömist. See teeb head nii sinu tervisele kui ka keskkonnale.",
  raw_chicken: "Toores kana",
  raw_chicken_description: "",
  whole_salad: "Terve salatiköögivili - nt lehtsalat",
  whole_salad_description: "Eelista värskeid puuvilju, marju ja köögivilju, eriti hooajalisi ja kohalikke.",
  packaged_salad: "Pakendatud salat",
  packaged_salad_description: "Eelista värskeid puuvilju, marju ja köögivilju, eriti hooajalisi ja kohalikke.",
  seasonal_fruit: "Hooajalised kohalikud puuviljad ja marjad",
  seasonal_fruit_description: "Eelista värskeid puuvilju, marju ja köögivilju, eriti hooajalisi ja kohalikke.",
  exotic_fruit: "Eksootilised puuviljad ja marjad",
  exotic_fruit_description: "Eelista värskeid puuvilju, marju ja köögivilju, eriti hooajalisi ja kohalikke.",
  ham: "Sink",
  ham_description: "Töödeldud liha sisaldab palju rasva ja soola. Püüa töödeldud liha tarbimist piirata.",
  tofu: "Tofu",
  tofu_description: "Proovi uusi taimseid tooteid ja uusi retsepte.",
  hummus: "Hummus",
  hummus_description: "Hummus on hea alternatiiv, mis aitab suurendada kaunviljade tarbimist.",
  precooked_pizza: "Eeltöödeldud pitsa",
  precooked_pizza_description: "Eeltöödeldud toidud võivad sisaldada palju soola või rasva. Eelista tooteid, mis ei ole eeltöödeldud, see võimaldab kontrollida soola, suhkrute ja rasvade kogust toidus.",
  precooked_fries: "Eeltöödeldud friikartulid",
  precooked_fries_description: "Eeltöödeldud toidud võivad sisaldada palju soola või rasva. Eelista tooteid, mis ei ole eeltöödeldud, see võimaldab kontrollida soola, suhkrute ja rasvade kogust toidus.",
  food_delivery: "Toidu kättetoimetamine",
  food_delivery_description: "",
  cooking_at_home: "Kodus toiduvalmistamine",
  cooking_at_home_description: "",
  cheeseburger: "Juustuburger",
  cheeseburger_description: "Väldi selliste toitude tarbimist, need on suure rasva- ja soolasisaldusega. Joo piisavalt vett.",
  pizza_and_soft_drink: "Pitsa ja karastusjook",
  pizza_and_soft_drink_description: "Selline toit sisaldab palju rasva, soola ja suhkrut. Eelista lihtsaid pitsasid, millel on vähem koostisosi või millele on lisatud rohkem köögivilju, ning võimaluse korral vali täisterajahust pitsapõhi. Joo piisavalt vett.",
  noodles_with_vegetables: "Nuudlid köögiviljadega",
  noodles_with_vegetables_description: "See on hea võimalus suurendada oma köögiviljade tarbimist. Täienda oma toitu kaunviljadega.",
  mushroom_risoto: "Seenerisoto",
  mushroom_risoto_description: "Seened on hea valik taimetoitlastele. Ära lisa liiga palju juustu. Sellele toidule saad juurde süüa salatit.",
  precooked_meal: "Eeltöödeldud valmistoit - kanapalad",
  precooked_meal_description: "Eeltöödeldud toidud võivad sisaldada palju soola, rasva või isegi suhkruid. Eelista tooteid, mis ei ole eeltöödeldud, nende toitainete kogust on lihtsam kontrollida. Toidu maitsestamiseks kasuta soola asemel ürte ja vürtse.",
  frozen_pizza: "Külmutatud pitsa",
  frozen_pizza_description: "Eeltöödeldud toidud võivad sisaldada palju soola, rasva või isegi suhkruid. Eelista tooteid, mis ei ole eeltöödeldud, nende toitainete kogust on lihtsam kontrollida. Toidu maitsestamiseks kasuta soola asemel ürte ja vürtse.",
  rice_and_salad: "Omatehtud toit - riis ja roheline salat",
  rice_and_salad_description: "Eeltöödeldud toidud võivad sisaldada palju soola, rasva või isegi suhkruid. Eelista tooteid, mis ei ole eeltöödeldud, nende toitainete kogust on lihtsam kontrollida. Toidu maitsestamiseks kasuta soola asemel ürte ja vürtse.",
  ham_sandwich_and_soft_drink: "Singivõileib ja karastusjook",
  ham_sandwich_and_soft_drink_description: "Töödeldud liha sisaldab palju rasva ja soola. Püüa töödeldud liha tarbimist piirata. Püüa vältida ka magustatud ja karastusjooke. Nende asemel joo kraanivett.",
  wholegrain_pasta_with_sauce: "Täisteramakarontooted köögiviljakastmega",
  wholegrain_pasta_with_sauce_description: "Eelista täisteramakarontooteid.",
  hnw_title_adult: "Uus terve maailm",
  hnw_description_1_adult: "Oma valikutega kujundame oma tuleviku. Kui kõik teeksid sinuga sarnaseid valikuid, elaksid inimesed tervislikumalt ning hoolitseksid üksteise ja keskkonna eest.",
  hnw_description_2_adult: "Teadlased kinnitavad järjekindlalt, et enda ja oma planeedi tervise hoidmiseks peaksime toituma mitmekesiselt: söö tera- ja kaunvilju, värsked köögi- ja puuvilju ning marju. Piira töödeldud toidu, liha ning palju soola, suhkruid ja rasva sisaldavate näkside söömist. Kui hoiame end füüsiliselt aktiivsena, käime jala, sõidame ratta või ühistranspordiga ja kasutame vähem autot, võidavad sellest kõik.",
  hnw_description_3_adult: "Sinu tervist ja meie keskkonna seisundit võivad parandada lihtsad asjad: puhas õhk ja vesi, ohutu toit, viljakas muld ja haljasalad, austus looduse ja kõigi inimeste vastu. Mõtle sellele ka väljaspool seda mängu. Kutsu sellele teekonnale kaasa ka oma sõbrad ja perekond.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Kas tahaksid uuesti mängida?",
  hnw_title_child: "Oma valikutega kujundame oma tuleviku",
  hnw_description_1_child: "Kui kõik teeksid sinuga sarnaseid valikuid, elaksid inimesed tervislikumalt.",
  hnw_description_2_child: "Tee nii:\n- toitu mitmekülgselt, nii hoiad enda ja planeedi tervist \n- eelista tera- ja kaunvilju ning värskeid köögi- ja puuvilju  \n- piira suure soola-, suhkru- ja rasvasisaldusega töödeldud toidu, liha ja näkside söömist \n- ole füüsiliselt aktiivne, jäi jala, sõida rattaga, kasuta ühistransporti \n- vähenda auto kasutamist",
  hnw_description_3_child: "Need lihtsad valikud võivad parandada su tervist ning tagada, et hoiame keskkonda ja kõiki inimesi.",
  hnw_description_4_child: "Mõtle sellele ka väljaspool seda mängu.",
  hnw_description_5_child: "Kutsu sellele teekonnale kaasa ka oma sõbrad ja perekond.",
  hnw_description_6_child: "Kas tahaksid uuesti mängida?",
  etl_title_adult: "Söö selleks, et elada",
  etl_description_1_adult: "Oma valikutega kujundame oma tuleviku. Sa ei pruugi olla teadlik, et su elustiil võib mõjutada meie planeedi tervist.",
  etl_description_2_adult: "Teadlased kinnitavad järjekindlalt, et enda ja oma planeedi tervise hoidmiseks peaksime toituma mitmekesiselt: söö tera- ja kaunvilju, värsked köögi- ja puuvilju ning marju. Piira töödeldud toidu, liha ning palju soola, suhkruid ja rasva sisaldavate näkside söömist. Kui hoiame end füüsiliselt aktiivsena, käime jala, sõidame ratta või ühistranspordiga ja kasutame vähem autot, võidavad sellest kõik.",
  etl_description_3_adult: "Sinu tervist ja meie keskkonna seisundit võivad parandada lihtsad asjad: puhas õhk ja vesi, ohutu toit, viljakas muld ja haljasalad, austus looduse ja kõigi inimeste vastu.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Kas tahaksid uuesti mängida?",
  etl_title_child: "Oma valikutega kujundame oma tuleviku",
  etl_description_1_child: "Kui me kõik parandame oma toitumis- ja eluviisi, võime tagada tervema tuleviku.",
  etl_description_2_child: "Tee nii:\n- toitu mitmekülgselt, nii hoiad enda ja planeedi tervist \n- eelista tera- ja kaunvilju ning värskeid köögi- ja puuvilju  \n- piira suure soola-, suhkru- ja rasvasisaldusega töödeldud toidu, liha ja näkside söömist \n- ole füüsiliselt aktiivne, jäi jala, sõida rattaga, kasuta ühistransporti \n- vähenda auto kasutamist",
  etl_description_3_child: "Need lihtsad valikud võivad parandada su tervist ning tagada, et hoiame keskkonda ja kõiki inimesi.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Kas tahaksid uuesti mängida?",
  share: "Jaga",
  replay: "Mängi uuesti",
  its_up_to_you: "Kõik oleneb sinust!",
  badge_action_1: "Leidsid peidetud vihje!",
  badge_action_2: "Võitsid aumärgi %{badge}",
  fridgeDoor: "Külmkapi uks",
  light: "Valgus",
  bottle: "Pudel",
  reusable_bag: "Korduvkasutatav kott",
  plastic_bag: "Kilekott",
  recycle_bin: "Ringlussevõtu konteiner",
  share_text_subject: "Kõik oleneb sinust!",
  share_text_body: "Osalesin mängus 2050 – Kõik oleneb sinust! Tahad teada, milline sinu maailm välja näeb? #ExperienceEUScience\nMängi %{shareUrl}\nSelle mängu on loonud Teadusuuringute Ühiskeskus, mis on Euroopa Komisjoni teadus- ja teadmustalitus.\nRohkem teavet https://joint-research-centre.ec.europa.eu/index_et?etrans=et"
};
export default i18n;
