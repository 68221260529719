import "common/Timeline.scss";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { timelineScenes } from "game-state.js";
import { types as characterTypes } from "elements/character/Character.js";

const getSceneIndex = scene => timelineScenes.findIndex(timelineScene => timelineScene.includes(scene));

const Timeline = ({ t, scene, characterType }) => {
  const sceneIndex = getSceneIndex(scene);

  if (sceneIndex === -1) return null;

  const sceneLabel = t(`${scene.replace(/-/g, "_")}_title_${characterType}`) || t(`${scene.replace(/-/g, "_")}_title`);

  return (
    <header className={"Timeline " + scene}>
      <div>
        <ol>
          {timelineScenes.map((timelineScene, index) =>
            timelineScene.includes(scene) ? (
              <li key={index}>
                <h2>{sceneLabel}</h2>
              </li>
            ) : (
              <li key={index} className={"dot" + (index < sceneIndex ? " past" : "")}>
                -
              </li>
            )
          )}
        </ol>
      </div>
    </header>
  );
};

Timeline.propTypes = exact({
  t: PropTypes.func.isRequired,
  scene: PropTypes.string.isRequired,
  characterType: PropTypes.oneOf(characterTypes)
});

export default Timeline;
