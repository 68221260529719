const i18n = {
  title: "Cluiche Sochaí Sláintiúla 2050",
  start: "Tosaigh",
  select_language: "Roghnaigh teanga",
  adult: "Duine fásta",
  child: "Leanbh",
  fullscreen: "Scáileán iomlán",
  restart_scene: "Cuir tús leis an radharc arís",
  restart_game: "Cuir tús leis an gcluiche arís",
  previous: "Roimhe",
  next: "Ar aghaidh",
  close: "Dún",
  toggle_sound: "Fuaim ar siúl/múchta",
  character_selection_title: "Roghnaigh do charachtar",
  character_selection_body: "Bíonn tionchar ag ár roghanna ar ár dtodhchaí. Déanaimid cásanna laethúla a ionsamhlú sa chluiche seo. Déan do roghanna agus foghlaim faoin tionchar a bhíonn acu. Déan cinnte go mbreathnaíonn tú thart go bhfeicfidh tú rúin atá ceilte chun do shuaitheantais a fháil! Tosaímis trí do charachtar féin a chruthú.",
  character_set_up_title: "Tóg do charachtar féin",
  select_me: "Roghnaigh mise",
  breakfast_title: "Cad a itheann tú don bhricfeasta?",
  your_badges: "Do shuaitheantais",
  badge_water_conservation_master_title: "Máistir",
  badge_water_conservation_master_description: "Comhghairdeas leat! Is máistir thú ar acmhainn thearc a chaomhnú! Tá an t‑uisce ríthábhachtach le haghaidh gach cineáil beatha.",
  badge_clean_street_wizard_title: "Draoi",
  badge_clean_street_wizard_description: "Comhghairdeas leat! Is draoi thú maidir lenár gcomhshaol a dhéanamh níos glaine trí bhruscar a bhailiú agus é a chur sa bhosca bruscair.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Comhghairdeas leat! Is ninja thú maidir le fuinneamh a chaomhnú. Is bealach iontach é fuinneamh a chaomhnú ónar bhfearais chun cuidiú le hídiú fuinnimh a laghdú.",
  badge_energy_saving_expert_title: "Saineolaí",
  badge_energy_saving_expert_description: "Comhghairdeas leat! Is saineolaí thú ar fhuinneamh a chaomhnú. Lean ar aghaidh ag aimsiú bealaí chun d’ídiú fuinnimh a laghdú.",
  badge_low_waste_champion_title: "Seaimpín",
  badge_low_waste_champion_description: "Comhghairdeas leat! Is seaimpín thú ar do dhramhaíl a laghdú agus cuidiú leis an gcomhshaol tríd an leas a bhaineann tú as plaisteach a laghdú.",
  badge_responsible_shopper_title: "Gairmí",
  badge_responsible_shopper_description: "Comhghairdeas leat! Is gairmí thú maidir le roghanna sláinte don chomhshaol a dhéanamh. Lean ar aghaidh ag úsáid a laghad plaistigh agus is féidir trí do mhála in‑athúsáidte féin a bhreith leat.",
  badge_recycling_captain_title: "Laoch",
  badge_recycling_captain_description: "Comhghairdeas leat! Is laoch thú i gcúrsaí athchúrsála. Tá sé tábhachtach ábhair a athchúrsáil agus a athúsáid ar mhaithe le comhshaol sláintiúil.",
  faucet: "Sconna",
  transportation_title_adult: "Cén chaoi a dtéann tú chuig an obair?",
  transportation_title_child: "Cén chaoi a dtéann tú ar scoil?",
  trash: "Bruscar",
  no_drink: "Ní ólaim aon rud",
  no_drink_description: "",
  tap_water: "Uisce as an sconna",
  tap_water_description: "Ól go leor deochanna. Déan iarracht 1.5–2 lítear uisce ar a laghad a ól in aghaidh an lae.",
  milk: "Bainne",
  milk_description: "Roghnaigh gnáthbhainne gan siúcra, agus bainne ar bheagán saille más féidir.",
  freshly_made_juice: "Sú úrbhrúite",
  freshly_made_juice_description: "Más maith leat sú, roghnaigh sú úrbhrúite – ní gá siúcra a chur leis. Bain triail as torthaí úra a ithe.",
  coffee_or_tea: "Caife/tae",
  coffee_or_tea_description: "Bain taitneamh as do thae agus do chaife, gan siúcra a chur ann más féidir.",
  plant_based_drink: "Deoch phlandabhunaithe",
  plant_based_drink_description: "Bain triail as deochanna plandabhunaithe, gan aon siúcra sa bhreis iontu agus más féidir roghnaigh deochanna a bhfuil vitimíní agus mianraí iontu.",
  whole_grain_cereals: "Gránaigh shlánghráinneacha",
  whole_grain_cereals_description: "Moltar gránaigh shlánghráinneacha gan aon siúra sa bhreis iontu.",
  choco_balls: "Gránaigh sheacláide",
  choco_balls_description: "D’fhéadfadh cuid mhór de na siúcraí a ithimid gach lá teacht ó ghránaigh bhricfeasta – an féidir leat gránaigh a gcuirtear níos lú siúcraí iontu a roghnú? Agus tú ag smaoineamh faoin méid sin, roghnaigh gránach a bhfuil níos mó snáithín ann.",
  white_bread_or_toast: "Arán/tósta bán",
  white_bread_or_toast_description: "Bain triail as arán slánghráinneach.",
  wholemeal_bread_or_toast: "Arán/tósta slánghráinneach",
  wholemeal_bread_or_toast_description: "Bain triail as arán slánghráinneach.",
  biscuits: "Brioscaí",
  biscuits_description: "Bíonn go leor calraí i mbrioscaí agus d’fhéadfadh cuid mhór de na siúcraí agus den tsaill a ithimid gach lá teacht uathu. Moltar níos lú brioscaí a ithe. Moltar brioscaí a bhfuil níos lú saille agus níos lú siúcra iontu. Agus tú ag smaoineamh faoin méid sin, roghnaigh briosca a bhfuil níos mó snáithín ann.",
  no_topping: "Gan aon rud air",
  no_topping_description: "",
  yogurt: "Iógart",
  yogurt_description: "Bain triail as iógart ar bheagán saille gan aon siúcra sa bhreis ann.",
  honey: "Mil",
  honey_description: "Roghnaigh iógart pléineáilte gan aon siúcra ná subh a chur leis. Moltar torthaí nó gránaigh shlánghráinneacha gan siúcra a chur leis an iógart.",
  jam: "Subh",
  jam_description: "Roghnaigh iógart pléineáilte gan aon siúcra ná subh a chur leis. Moltar torthaí nó gránaigh shlánghráinneacha gan siúcra a chur leis an iógart.",
  fruit: "Torthaí",
  fruit_description: "Is rogha iontach é tús a chur leis an lá le torthaí, déan iarracht torthaí séasúracha a roghnú. Má itheann tú cnónna freisin, moltar cnónna gan aon salann ná siúcra breise.",
  butter: "Im",
  butter_description: "Bíonn go leor saille in im agus bíonn sé goirt uaireanta. Déan iarracht gan im a ithe rómhinic agus roghnaigh im nach bhfuil aon salann ann.",
  chocolate_cream: "Cróichín seacláide",
  chocolate_cream_description: "Bíonn go leor siúcra agus saill sháithithe i gcróichín seacláide. Déan iarracht gan é a ithe rómhinic.",
  ham_or_bacon: "Liamhás/bagún",
  ham_or_bacon_description: "Bíonn go leor saille agus salainn i bhfeoil phróiseáilte. Má itheann tú feoil phróiseáilte, ná hith an iomarca di.",
  cheese: "Cáis",
  cheese_description: "Bíonn go leor salainn agus saill sháithithe i gcáis. Déan iarracht gan cáis a ithe rómhinic, agus ith píosa beag cáise nuair a itheann tú í.",
  avocado_and_tomato: "Abhacád agus tráta",
  avocado_and_tomato_description: "Is rogha iontach é tús a chur leis an lá le roinnt glasraí. Déan iarracht neart torthaí agus glasraí a ithe i gcaitheamh an lae agus moltar torthaí agus glasraí atá áitiúil agus séasúrach.",
  battery: "Ceallra",
  battery_description: "Coinnigh súil ghéar ar do cheallra, léirítear an tionchar a bhaineann le do roghanna leis!",
  lunch_title: "Cad ba mhaith leat a ithe don lón?",
  breaktime_title: "Tráthnóna saor!",
  snacking_title: "Ar mhaith leat sneaic?",
  supermarket_title: "Cad a cheannaíonn tú?",
  dinner_selection_title: "Cad atá beartaithe agat don dinnéar?",
  dinner_cooking_title: "Cad a íosfaidh tú don dinnéar?",
  dinner_delivery_title: "Cad a íosfaidh tú don dinnéar?",
  your_available_items: "An méid atá ar fáil duit",
  your_selections: "An méid a roghnaigh tú",
  walk: "Siúl",
  walk_description: "Déan gníomhaíocht choirp gach aon lá. Más féidir, téigh ar scoil/chuig an obair de shiúl na gcos nó ar rothar. Ar an mbealach sin fanfaidh tú aclaí agus tá sé níos fearr don chomhshaol.",
  bicycle: "Rothar",
  bicycle_description: "Déan gníomhaíocht choirp gach aon lá. Más féidir, téigh ar scoil/chuig an obair de shiúl na gcos nó ar rothar. Ar an mbealach sin fanfaidh tú aclaí agus tá sé níos fearr don chomhshaol.",
  scooter_or_motorbike: "Scútar/gluaisrothar",
  scooter_or_motorbike_description: "Déan iarracht roghanna eile a úsáid seachas an gluaisteán nó an gluaisrothar ar mhaithe le comhshaol níos glaine. Mura bhfuil sé sin indéanta, déan iarracht carr‑roinnt a dhéanamh.",
  car: "Gluaisteán",
  car_description: "Déan iarracht roghanna eile a úsáid seachas an gluaisteán nó an gluaisrothar ar mhaithe le comhshaol níos glaine. Mura bhfuil sé sin indéanta, déan iarracht carr‑roinnt a dhéanamh.",
  public_transport: "Iompar poiblí",
  public_transport_description: "Úsáid iompar poiblí in éineacht le siúl agus rothaíocht seachas dul sa ghluaisteán. Tabhair sos duit féin ón tiomáint agus cuidigh leis an gcomhshaol.",
  salad_with_egg: "Sailéad agus ubh",
  salad_with_egg_description: "Cuir glasraí úra le do bhéilí, aon uair is féidir.",
  beef_steak: "Stéig mhairteola",
  beef_steak_description: "Má itheann tú feoil, smaoinigh faoin méid a itheann tú a laghdú, go háirithe feoil dhearg nó feoil phróiseáilte. Tá sé níos sláintiúla duit féin agus níos fearr don chomhshaol.",
  chicken: "Sicín",
  chicken_description: "Má itheann tú feoil, smaoinigh faoin méid a itheann tú a laghdú, go háirithe feoil dhearg nó feoil phróiseáilte. Tá sé níos sláintiúla duit féin agus níos fearr don chomhshaol.",
  lentil_soup: "Anraith lintilí",
  lentil_soup_description: "Moltar léagúim a ithe go minic mar chuid d’aiste bia atá éagsúil agus bunaithe ar phlandaí den chuid is mó.",
  vegetable_burger: "Burgar glasraí",
  vegetable_burger_description: "D’fhéadfadh burgar glasraí a bheith ina rogha mhaith ina áit feola. Bí cúramach nach bhfuil an iomarca salainn ann.",
  white_pasta: "Pasta bán",
  white_pasta_description: "Moltar táirgí slánghráinneacha nuair atá pasta, rís agus arán á roghnú agat.",
  wholegrain_pasta: "Pasta slánghráinneach",
  wholegrain_pasta_description: "Moltar táirgí slánghráinneacha nuair atá pasta, rís agus arán á roghnú agat.",
  white_rice: "Rís bhán",
  white_rice_description: "Moltar táirgí slánghráinneacha nuair atá pasta, rís agus arán á roghnú agat.",
  wholegrain_rice: "Rís shlánghráinneach",
  wholegrain_rice_description: "Moltar táirgí slánghráinneacha nuair atá pasta, rís agus arán á roghnú agat.",
  white_bread_bun: "Borróg aráin bán",
  white_bread_bun_description: "Moltar táirgí slánghráinneacha nuair atá pasta, rís agus arán á roghnú agat.",
  no_salad: "Dada",
  no_salad_description: "",
  fresh_green_salad: "Sailéad glas úr",
  fresh_green_salad_description: "Cuir roinnt glasraí séasúracha ildaite le do bhéilí, dá mhéad glasraí éagsúla a itheann tú is é is fearr é.",
  french_fries: "Sceallóga",
  french_fries_description: "Moltar prátaí bruite a roghnú in áit táirgí práta atá domhainfhriochta.",
  boiled_broccoli: "Brocailí bruite",
  boiled_broccoli_description: "Déan iarracht neart torthaí agus glasraí a ithe i gcaitheamh an lae. Roghnaigh glasraí áitiúla agus séasúracha.",
  bottled_water: "Uisce i mbuidéal",
  bottled_water_description: "Ól uisce as an sconna chun úsáid plaistigh a laghdú.",
  soft_drink: "Deoch bhog",
  soft_drink_description: "D’fhéadfadh cuid mhór den siúcra a ithimid gach lá teacht ó dheochanna a bhfuil siúcra breise iontu – an féidir leat uisce a roghnú ina n‑áit siúd?",
  beer_or_wine: "Beoir/fíon",
  beer_or_wine_description: "Má ólann tú alcól d’aon chineál, ná hól an iomarca de. Tá sé níos fearr don tsláinte an t‑alcól a sheachaint.",
  watching_tv: "Breathnú ar an teilifís nó físchluichí a imirt",
  watching_tv_description: "Déan gníomhaíocht choirp gach lá agus déan iarracht an méid ama a chaitheann tú i do shuí a laghdú. Nuair atá am saor agat, imir roinnt spóirt, téigh ag spraoi le do chairde nó cuidigh le do phobal. Tá sé go maith do do shláinte agus don tsochaí. Seachain an iomarca ama a chaitheamh os comhair scáileáin.",
  doing_sports: "Spórt a imirt",
  doing_sports_description: "Déan gníomhaíocht choirp gach lá agus déan iarracht an méid ama a chaitheann tú i do shuí a laghdú. Nuair atá am saor agat, imir roinnt spóirt, téigh ag spraoi le do chairde nó cuidigh le do phobal. Tá sé go maith do do shláinte agus don tsochaí. Seachain an iomarca ama a chaitheamh os comhair scáileáin.",
  playing_outside: "Dul ag spraoi taobh amuigh",
  playing_outside_description: "Déan gníomhaíocht choirp gach lá agus déan iarracht an méid ama a chaitheann tú i do shuí a laghdú. Nuair atá am saor agat, imir roinnt spóirt, téigh ag spraoi le do chairde nó cuidigh le do phobal. Tá sé go maith do do shláinte agus don tsochaí. Seachain an iomarca ama a chaitheamh os comhair scáileáin.",
  charity_work: "Obair dheonach le daoine fásta",
  charity_work_description: "Déan gníomhaíocht choirp gach lá agus déan iarracht an méid ama a chaitheann tú i do shuí a laghdú. Nuair atá am saor agat, imir roinnt spóirt, téigh ag spraoi le do chairde nó cuidigh le do phobal. Tá sé go maith do do shláinte agus don tsochaí. Seachain an iomarca ama a chaitheamh os comhair scáileáin.",
  carrots_tomatoes: "Cairéid/trátaí silín",
  carrots_tomatoes_description: "Moltar torthaí, glasraí nó cnónna. Ná hith an iomarca sneaiceanna a bhfuil go leor siúcra, saille agus salainn iontu.",
  fresh_fruit: "Torthaí úra",
  fresh_fruit_description: "Moltar torthaí, glasraí nó cnónna. Ná hith an iomarca sneaiceanna a bhfuil go leor siúcra, saille agus salainn iontu.",
  chocolate_bar: "Barra seacláide",
  chocolate_bar_description: "Moltar torthaí, glasraí nó cnónna. Ná hith an iomarca sneaiceanna a bhfuil go leor siúcra, saille agus salainn iontu.",
  chips: "Criospaí",
  chips_description: "Moltar torthaí, glasraí nó cnónna. Ná hith an iomarca sneaiceanna a bhfuil go leor siúcra, saille agus salainn iontu.",
  juice_drink: "Deoch de bhunús sú",
  juice_drink_description: "Moltar torthaí úra nó súnna úrbhrúite gan aon siúcra breise iontu seachas deochanna de bhunús sú.",
  supermarket_prompt: "Roghnaigh suas le 9 dtáirge",
  raw_fish: "Iasc amh",
  raw_fish_description: "Má itheann tú iasc go rialta, cuidíonn sé leat cothaithigh thábhachtacha a fháil. Bain triail as meascáin díobh.",
  raw_beef: "Mairteoil amh",
  raw_beef_description: "Má itheann tú feoil, smaoinigh faoin méid a itheann tú a laghdú, go háirithe feoil dhearg nó feoil phróiseáilte. Tá sé níos sláintiúla duit féin agus níos fearr don chomhshaol.",
  raw_chicken: "Sicín amh",
  raw_chicken_description: "",
  whole_salad: "Glasra iomlán le haghaidh sailéid - e.g. leitís",
  whole_salad_description: "Moltar torthaí agus glasraí úra, go háirithe cinn shéasúracha agus áitiúla.",
  packaged_salad: "Sailéad pacáistithe",
  packaged_salad_description: "Moltar torthaí agus glasraí úra, go háirithe cinn shéasúracha agus áitiúla.",
  seasonal_fruit: "Torthaí séasúracha áitiúla",
  seasonal_fruit_description: "Moltar torthaí agus glasraí úra, go háirithe cinn shéasúracha agus áitiúla.",
  exotic_fruit: "Torthaí coimhthíocha",
  exotic_fruit_description: "Moltar torthaí agus glasraí úra, go háirithe cinn shéasúracha agus áitiúla.",
  ham: "Liamhás",
  ham_description: "Bíonn go leor saille agus salainn i bhfeoil phróiseáilte. Má itheann tú feoil phróiseáilte, déan iarracht gan an iomarca di a ithe.",
  tofu: "Tófú",
  tofu_description: "Fiosraigh táirgí nua plandabhunaithe agus oidis nua.",
  hummus: "Humas",
  hummus_description: "Is rogha mhalartach dheas é humas a chuideoidh leat an méid léagúm a itheann tú a mhéadú.",
  precooked_pizza: "Píotsa réamhchócaráilte",
  precooked_pizza_description: "D’fhéadfadh go leor salainn nó saille a bheith i mbéilí réamhchócaráilte. Moltar táirgí nach bhfuil réamhchócaráilte. Ar an mbealach sin beidh smacht agat ar an méid salainn, siúcra agus saille i do bhéilí.",
  precooked_fries: "Sceallóga réamhchócaráilte",
  precooked_fries_description: "D’fhéadfadh go leor salainn nó saille a bheith i mbéilí réamhchócaráilte. Moltar táirgí nach bhfuil réamhchócaráilte. Ar an mbealach sin beidh smacht agat ar an méid salainn, siúcra agus saille i do bhéilí.",
  food_delivery: "Seachadadh bia",
  food_delivery_description: "",
  cooking_at_home: "Cócaráil sa bhaile",
  cooking_at_home_description: "",
  cheeseburger: "Burgar cáise",
  cheeseburger_description: "Seachain an cineál sin béilí a ithe. Bíonn siad lán le saill agus salann. Ól dóthain uisce.",
  pizza_and_soft_drink: "Píotsa agus deoch bhog",
  pizza_and_soft_drink_description: "Bíonn go leor saille, salainn agus siúcra i mbéilí den sórt sin. Roghnaigh píotsaí ‘simplí’ a bhfuil níos lú comhábhar nó níos mó glasraí orthu, agus roghnaigh taos píotsa slánghráinneach, más féidir. Ól dóthain uisce.",
  noodles_with_vegetables: "Núdail agus glasraí",
  noodles_with_vegetables_description: "Is rogha mhalartach mhaith é sin chun an méid glasraí a itheann tú a mhéadú. Cuirfidh roinnt léagúm dlaoi mhullaigh ar an mbéile.",
  mushroom_risoto: "Risotto muisiriúin",
  mushroom_risoto_description: "Is rogha mhaith iad muisiriúin le haghaidh feoilséantóirí. Bí cúramach nach gcuireann tú an iomarca cáise leis. Cuirfidh sailéad dlaoi mhullaigh ar an mbéile sin.",
  precooked_meal: "Béile réamhullmhaithe réamhchócaráilte - cnaipíní sicín",
  precooked_meal_description: "D’fhéadfadh go leor salainn, saille, nó siúcra fiú a bheith i mbéilí réamhchócaráilte. Moltar táirgí nach bhfuil réamhchócaráilte. Beidh smacht níos fearr agat ar mhéid na gcothaitheach sin. Úsáid luibheanna agus spíosraí in áit salainn chun blas a chur le rudaí.",
  frozen_pizza: "Píotsa reoite",
  frozen_pizza_description: "D’fhéadfadh go leor salainn, saille, nó siúcra fiú a bheith i mbéilí réamhchócaráilte. Moltar táirgí nach bhfuil réamhchócaráilte. Beidh smacht níos fearr agat ar mhéid na gcothaitheach sin. Úsáid luibheanna agus spíosraí in áit salainn chun blas a chur le rudaí.",
  rice_and_salad: "Béile a chócaráil tú féin de rís agus sailéad glas",
  rice_and_salad_description: "D’fhéadfadh go leor salainn, saille, nó siúcra fiú a bheith i mbéilí réamhchócaráilte. Moltar táirgí nach bhfuil réamhchócaráilte. Beidh smacht níos fearr agat ar mhéid na gcothaitheach sin. Úsáid luibheanna agus spíosraí in áit salainn chun blas a chur le rudaí.",
  ham_sandwich_and_soft_drink: "Ceapaire liamháis agus deoch bhog",
  ham_sandwich_and_soft_drink_description: "Bíonn go leor saille agus salainn i bhfeoil phróiseáilte. Má itheann tú feoil phróiseáilte, déan iarracht gan an iomarca di a ithe. Déan iarracht deochanna a bhfuil siúcra iontu agus deochanna boga a sheachaint. Ól uisce as an sconna ina n‑áit.",
  wholegrain_pasta_with_sauce: "Pasta slánghráinneach le hanlann glasraí",
  wholegrain_pasta_with_sauce_description: "Bain triail as pasta slánghráinneach.",
  hnw_title_adult: "Domhan Nua Sláintiúil",
  hnw_description_1_adult: "Múnlaíonn ár roghanna an todhchaí. Má ithimid agus má chaithimid ár saol mar a rinne tú sa chluiche, d’fhéadfadh ár dtodhchaí a bheith níos sláintiúla agus beidh daoine ag tabhairt aire dá chéile agus don chomhshaol.",
  hnw_description_2_adult: "Léiríonn eolaithe arís agus arís eile ina gcuid taighde gur cheart dúinn aiste bia éagsúil a ithe chun ár sláinte féin agus chun sláinte ár bpláinéid a chaomhnú: aiste bia atá lán le gránaigh, léagúim, glasraí agus torthaí úra. Seachain bia próiseáilte agus feoil agus sneaiceanna a bhfuil cion ard salainn, siúcra agus saille iontu. Rachaidh sé chun tairbhe cách má dhéanaimid gníomhaíocht choirp, má bhímid ag siúl, ag rothaíocht nó ag taisteal ar iompar poiblí agus ag laghdú úsáid gluaisteán dá bharr.",
  hnw_description_3_adult: "D’fhéadfadh rudaí simplí cur go mór le do shláinte agus lenár gcomhshaol: aer agus uisce glan, bia sábháilte, ithreacha saibhre agus spásanna glasa, meas ar an dúlra agus ar gach duine. Smaoinigh faoi nuair nach bhfuil an cluiche seo á imirt agat freisin. Beir leat do chairde agus do theaghlach ar an turas.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Ar mhaith leat imirt arís?",
  hnw_title_child: "Múnlaíonn ár roghanna an todhchaí",
  hnw_description_1_child: "Má ithimid agus má chaithimid ár saol mar a rinne tú sa chluiche, d’fhéadfadh todhchaí níos sláintiúla a bheith againn.",
  hnw_description_2_child: "Seo a leanas an méid is féidir leat a dhéanamh:\n Cinntigh go n‑itheann tú aiste bia éagsúil chun do shláinte féin agus sláinte ár bpláinéid a chaomhnú. \n- Roghnaigh gránaigh, léagúim, glasraí agus torthaí úra.  \n- Seachain bia próiseáilte agus feoil agus sneaiceanna a bhfuil cion ard salainn, siúcra agus saille iontu.\n- Déan gníomhaíocht choip, téigh ag siúl, ag rothaíocht, ag taisteal ar iompar poiblí.\n- Laghdaigh an úsáid a bhaineann tú as gluaisteáin.",
  hnw_description_3_child: "Féadfaidh na roghanna simplí sin cur go mór le do shláinte agus cinntítear leo go dtugaimid aire don chomhshaol agus do gach duine.",
  hnw_description_4_child: "Smaoinigh faoi nuair nach bhfuil an cluiche seo á imirt agat freisin.",
  hnw_description_5_child: "Beir leat do chairde agus do theaghlach ar an turas.",
  hnw_description_6_child: "Ar mhaith leat imirt arís?",
  etl_title_adult: "Ith chun Maireachtáil",
  etl_description_1_adult: "Múnlaíonn ár roghanna an todhchaí. Seans nach bhfuil a fhios agat é, ach féadfaidh tionchar a bheith ag do shlí mhaireachtála ar shláinte ár bpláinéid.",
  etl_description_2_adult: "Léiríonn eolaithe arís agus arís eile ina gcuid taighde gur cheart dúinn aiste bia éagsúil a ithe chun ár sláinte féin agus chun sláinte ár bpláinéid a chaomhnú: aiste bia atá lán le gránaigh, léagúim, glasraí agus torthaí úra. Seachain bia próiseáilte agus feoil agus sneaiceanna a bhfuil cion ard salainn, siúcra agus saille iontu. Rachaidh sé chun tairbhe cách má dhéanaimid gníomhaíocht choirp, má bhímid ag siúl, ag rothaíocht nó ag taisteal ar iompar poiblí agus ag laghdú úsáid gluaisteán dá bharr.",
  etl_description_3_adult: "D’fhéadfadh rudaí simplí cur go mór le do shláinte agus lenár gcomhshaol: aer agus uisce glan, bia sábháilte, ithreacha saibhre agus spásanna glasa, meas ar an dúlra agus ar gach duine.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Ar mhaith leat imirt arís?",
  etl_title_child: "Múnlaíonn ár roghanna an todhchaí",
  etl_description_1_child: "Dá bhfeabhsóimis uile an bealach a n‑ithimid agus a gcaithimid ár saol, d’fhéadfadh todhchaí níos sláintiúla a bheith againn.",
  etl_description_2_child: "Seo a leanas an méid is féidir leat a dhéanamh:\n Cinntigh go n‑itheann tú aiste bia éagsúil chun do shláinte féin agus sláinte ár bpláinéid a chaomhnú. \n- Roghnaigh gránaigh, léagúim, glasraí agus torthaí úra.  \n- Seachain bia próiseáilte agus feoil agus sneaiceanna a bhfuil cion ard salainn, siúcra agus saille iontu.\n- Déan gníomhaíocht choip, téigh ag siúl, ag rothaíocht, ag taisteal ar iompar poiblí.\n- Laghdaigh an úsáid a bhaineann tú as gluaisteáin.",
  etl_description_3_child: "Féadfaidh na roghanna simplí sin cur go mór le do shláinte agus cinntítear leo go dtugaimid aire don chomhshaol agus do gach duine.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Ar mhaith leat imirt arís?",
  share: "Roinn",
  replay: "Imir arís",
  its_up_to_you: "Is fút féin atá sé",
  badge_action_1: "Rún folaithe nochta!",
  badge_action_2: "Ghnóthaigh tú an suaitheantas ‘%{badge}’",
  fridgeDoor: "Doras an chuisneora",
  light: "Solas",
  bottle: "Buidéal",
  reusable_bag: "Mála in‑athúsáidte",
  plastic_bag: "Mála plaisteach",
  recycle_bin: "Bosca bruscair athchúrsála",
  share_text_subject: "Is fút féin atá sé",
  share_text_body: "D’imir mé 2050 – Is fút féin atá sé! An bhfuil tú fiosrach faoin gcuma atá ar do dhomhan féin? #ExperienceEUScience\nImir anois %{shareUrl}\nD’fhorbair an tAirmheán Comhpháirteach Taighde, is é sin seirbhís eolaíochta agus eolais an Choimisiúin Eorpaigh, an cluiche seo.\nFoghlaim níos mó https://joint-research-centre.ec.europa.eu/index_ga?etrans=ga"
};
export default i18n;
