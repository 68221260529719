const i18n = {
  title: "2050 Une société en bonne santé",
  start: "Commencer",
  select_language: "Choisir la langue",
  adult: "Adulte",
  child: "Enfant",
  fullscreen: "Plein écran",
  restart_scene: "Recommencer la scène",
  restart_game: "Recommencer le jeu",
  previous: "Précédent",
  next: "Suivant",
  close: "Fermer",
  toggle_sound: "Activer/Couper le son",
  character_selection_title: "Choisis ton personnage",
  character_selection_body: "Notre avenir dépend de nos choix. Dans ce jeu, nous simulons des situations quotidiennes. Fais tes choix et découvres-en les conséquences. N’oublie pas de chercher les secrets cachés pour gagner tes badges! Commençons par créer ton personnage.",
  character_set_up_title: "Crée ton personnage",
  select_me: "Choisis-moi",
  breakfast_title: "Que prends-tu au petit-déjeuner?",
  your_badges: "Badges",
  badge_water_conservation_master_title: "Maître",
  badge_water_conservation_master_description: "Félicitations! Tu es un maître en matière d’économie d’une ressource rare! L’eau est fondamentale pour toutes les formes de vie.",
  badge_clean_street_wizard_title: "Magicien",
  badge_clean_street_wizard_description: "Félicitations! Tu es un magicien qui contribue à la propreté de notre environnement en ramassant les déchets et en les jetant à la poubelle.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Félicitations! Tu es un ninja en économie d’énergie. Économiser l’énergie de nos appareils est un excellent moyen de réduire la consommation d’énergie.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Félicitations! Tu es un expert en économie d’énergie. Continuez à chercher des moyens de réduire votre consommation d’énergie.",
  badge_low_waste_champion_title: "Champion",
  badge_low_waste_champion_description: "Félicitations! Tu es un champion qui réduit ses déchets et protège l’environnement en diminuant l’utilisation de plastique.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "Félicitations! Tu es un pro des choix sains pour l’environnement. Continuez à utiliser le moins de plastique possible, en transportant votre propre sac réutilisable.",
  badge_recycling_captain_title: "Star",
  badge_recycling_captain_description: "Félicitations! Tu es une star du recyclage. Il est important de recycler et de réutiliser les matériaux pour un environnement sain.",
  faucet: "Robinet",
  transportation_title_adult: "Comment vas-tu au travail?",
  transportation_title_child: "Comment vas-tu à l’école?",
  trash: "Poubelle",
  no_drink: "Pas de boisson",
  no_drink_description: "",
  tap_water: "Eau du robinet",
  tap_water_description: "Hydrate-toi. Pense à boire au moins 1,5 à 2 litres d’eau par jour.",
  milk: "Lait",
  milk_description: "Choisis du lait nature non sucré, de préférence à faible teneur en matière grasse.",
  freshly_made_juice: "Jus fraîchement pressé",
  freshly_made_juice_description: "Si tu aimes le jus, choisis-le fraîchement pressé – il n’est pas nécessaire d’ajouter du sucre. Essaie de manger des fruits frais.",
  coffee_or_tea: "Café/thé",
  coffee_or_tea_description: "Déguste ton thé et ton café de préférence sans ajouter de sucre.",
  plant_based_drink: "Boisson végétale",
  plant_based_drink_description: "Essaie les boissons végétales, sans sucres ajoutés et, si possible, choisis celles contenant des vitamines et des minéraux.",
  whole_grain_cereals: "Céréales complètes",
  whole_grain_cereals_description: "Privilégie les céréales complètes sans sucres ajoutés.",
  choco_balls: "Céréales «choco balls»",
  choco_balls_description: "Les céréales pour le petit-déjeuner peuvent représenter une part considérable de notre dose journalière de sucres – peux-tu choisir celles qui contiennent moins de sucres ajoutés? Par la même occasion, choisis celles qui contiennent plus de fibres.",
  white_bread_or_toast: "Pain/toast blanc",
  white_bread_or_toast_description: "Essaie le pain complet.",
  wholemeal_bread_or_toast: "Pain/toast complet",
  wholemeal_bread_or_toast_description: "Essaie le pain complet.",
  biscuits: "Biscuits",
  biscuits_description: "Les biscuits sont riches en calories et peuvent représenter une part considérable de nos doses journalières de sucres et de graisses. Il vaut mieux réduire leur consommation. Privilégie ceux qui présentent une faible teneur en matières grasses et moins de sucres. Par la même occasion, choisis ceux qui contiennent également plus de fibres.",
  no_topping: "Rien dessus",
  no_topping_description: "",
  yogurt: "Yaourts",
  yogurt_description: "Essaie le yaourt nature à faible teneur en matières grasses et sans sucres ajoutés.",
  honey: "Miel",
  honey_description: "Choisis du yaourt nature, sans ajouter de sucres ni de confiture. Privilégie l’ajout de fruits ou de céréales complètes non sucrées.",
  jam: "Confiture",
  jam_description: "Choisis du yaourt nature, sans ajouter de sucres ni de confiture. Privilégie l’ajout de fruits ou de céréales complètes non sucrées.",
  fruit: "Fruits",
  fruit_description: "Excellent choix de commencer la journée avec des fruits, pense à choisir des fruits de saison. Si tu ajoutes des fruits à coque, privilégie ceux sans sel ou sucres ajoutés.",
  butter: "Beurre",
  butter_description: "Le beurre contient de grandes quantités de matières grasses et peut être salé. Essaye de ne pas en manger trop souvent et opte pour le beurre doux.",
  chocolate_cream: "Pâte à tartiner au chocolat",
  chocolate_cream_description: "La pâte à tartiner au chocolat contient beaucoup de sucre et de graisses saturées. Essaie de ne pas en manger trop souvent.",
  ham_or_bacon: "Jambon/bacon",
  ham_or_bacon_description: "La viande transformée contient de grandes quantités de graisses et de sel. Si tu manges de la viande transformée, limite ta consommation.",
  cheese: "Fromage",
  cheese_description: "Le fromage contient de grandes quantités de sel et de graisses saturées. Essaye de ne pas en manger trop souvent et de le consommer en petites quantités.",
  avocado_and_tomato: "Avocat et tomate",
  avocado_and_tomato_description: "Excellent choix de commencer la journée avec des légumes. Pense à manger beaucoup de fruits et de légumes au cours de la journée et privilégie ceux qui sont locaux et de saison.",
  battery: "Batterie",
  battery_description: "Surveille de près ta batterie, elle indique les conséquences de tes choix!",
  lunch_title: "Que veux-tu pour le déjeuner?",
  breaktime_title: "Après-midi de congé – temps libre!",
  snacking_title: "Envie d’un goûter?",
  supermarket_title: "Qu’achètes-tu ?",
  dinner_selection_title: "Qu’as-tu prévu pour le dîner ?",
  dinner_cooking_title: "Que manges-tu au dîner ?",
  dinner_delivery_title: "Que manges-tu au dîner ?",
  your_available_items: "Tes options",
  your_selections: "Tes sélections",
  walk: "À pied",
  walk_description: "Pratique une activité physique chaque jour. Si possible, vas à l’école/au travail à pied ou à vélo. Cela te maintient en forme et c’est mieux pour l’environnement.",
  bicycle: "À vélo",
  bicycle_description: "Pratique une activité physique chaque jour. Si possible, vas à l’école/au travail à pied ou à vélo. Cela te maintient en forme et c’est mieux pour l’environnement.",
  scooter_or_motorbike: "En scooter/moto",
  scooter_or_motorbike_description: "Pense à utiliser moins souvent la voiture ou la moto, pour un environnement plus propre. Si cela n’est pas possible, pense au covoiturage.",
  car: "En voiture",
  car_description: "Pense à utiliser moins souvent la voiture ou la moto, pour un environnement plus propre. Si cela n’est pas possible, pense au covoiturage.",
  public_transport: "En transports publics",
  public_transport_description: "Utilise les transports publics et associe-les à la marche et au vélo, plutôt que de te déplacer en voiture. Épargne-toi la conduite et protège l’environnement.",
  salad_with_egg: "Salade avec des œufs",
  salad_with_egg_description: "Ajoute des légumes frais à vos repas, chaque fois que c'est possible.",
  beef_steak: "Steak de bœuf",
  beef_steak_description: "Si tu manges de la viande, envisage de réduire ta consommation, en particulier les viandes rouges ou transformées. C’est mieux pour ta santé et pour l’environnement.",
  chicken: "Poulet",
  chicken_description: "Si tu manges de la viande, envisage de réduire ta consommation, en particulier les viandes rouges ou transformées. C’est mieux pour ta santé et pour l’environnement.",
  lentil_soup: "Soupe de lentilles",
  lentil_soup_description: "La consommation régulière de légumineuses est encouragée dans le cadre d’une alimentation variée et essentiellement végétale.",
  vegetable_burger: "Burger végétal",
  vegetable_burger_description: "Un burger végétal peut tout à fait remplacer la viande. Veille à ce qu’il ne contienne pas trop de sel.",
  white_pasta: "Pâtes blanches",
  white_pasta_description: "Privilégie les aliments complets lorsque tu choisis des pâtes, du riz et du pain.",
  wholegrain_pasta: "Pâtes complètes",
  wholegrain_pasta_description: "Privilégie les aliments complets lorsque tu choisis des pâtes, du riz et du pain.",
  white_rice: "Riz blanc",
  white_rice_description: "Privilégie les aliments complets lorsque tu choisis des pâtes, du riz et du pain.",
  wholegrain_rice: "Riz complet",
  wholegrain_rice_description: "Privilégie les aliments complets lorsque tu choisis des pâtes, du riz et du pain.",
  white_bread_bun: "Petit pain blanc",
  white_bread_bun_description: "Privilégie les aliments complets lorsque tu choisis des pâtes, du riz et du pain.",
  no_salad: "Rien",
  no_salad_description: "",
  fresh_green_salad: "Salade verte fraîche",
  fresh_green_salad_description: "Ajoute des légumes de saison colorés à tes repas, plus tu varies, mieux c’est.",
  french_fries: "Frites",
  french_fries_description: "Les pommes de terre cuites à l'eau sont préférables aux pommes de terre frites.",
  boiled_broccoli: "Brocolis cuits",
  boiled_broccoli_description: "Pense à manger beaucoup de légumes au cours de la journée. Choisis des légumes locaux et de saison.",
  bottled_water: "Eau embouteillée",
  bottled_water_description: "Bois de l’eau du robinet pour réduire la consommation de plastique.",
  soft_drink: "Boisson rafraîchissante",
  soft_drink_description: "Les boissons sucrées représentent une part considérable de notre dose journalière de sucres –peux-tu les remplacer par de l’eau?",
  beer_or_wine: "Bière/vin",
  beer_or_wine_description: "Limite ta consommation de tout type d’alcool. Éviter l’alcool est meilleur pour la santé.",
  watching_tv: "Regarder la télévision ou jouer à des jeux vidéo",
  watching_tv_description: "Pratique une activité physique chaque jour et passe moins de temps assis. Pendant ton temps libre, pense à faire du sport, à jouer avec tes amis ou à aider ta communauté. C’est bon pour ta santé et pour la société. Évite de passer trop de temps devant un écran.",
  doing_sports: "Faire du sport",
  doing_sports_description: "Pratique une activité physique chaque jour et passe moins de temps assis. Pendant ton temps libre, pense à faire du sport, à jouer avec tes amis ou à aider ta communauté. C’est bon pour ta santé et pour la société. Évite de passer trop de temps devant un écran.",
  playing_outside: "Jouer à l’extérieur",
  playing_outside_description: "Pratique une activité physique chaque jour et passe moins de temps assis. Pendant ton temps libre, pense à faire du sport, à jouer avec tes amis ou à aider ta communauté. C’est bon pour ta santé et pour la société. Évite de passer trop de temps devant un écran.",
  charity_work: "Activités caritatives avec des adultes",
  charity_work_description: "Pratique une activité physique chaque jour et passe moins de temps assis. Pendant ton temps libre, pense à faire du sport, à jouer avec tes amis ou à aider ta communauté. C’est bon pour ta santé et pour la société. Évite de passer trop de temps devant un écran.",
  carrots_tomatoes: "Carottes/tomates cerises",
  carrots_tomatoes_description: "Privilégie les fruits, les légumes ou les fruits à coque. Limite les en-cas et boissons riches en sucres, en graisses et en sel.",
  fresh_fruit: "Fruits frais",
  fresh_fruit_description: "Privilégie les fruits, les légumes ou les fruits à coque. Limite les en-cas et boissons riches en sucres, en graisses et en sel.",
  chocolate_bar: "Barres de chocolat",
  chocolate_bar_description: "Privilégie les fruits, les légumes ou les fruits à coque. Limite les en-cas et boissons riches en sucres, en graisses et en sel.",
  chips: "Chips",
  chips_description: "Privilégie les fruits, les légumes ou les fruits à coque. Limite les en-cas et boissons riches en sucres, en graisses et en sel.",
  juice_drink: "Boisson à base de jus",
  juice_drink_description: "Privilégie les fruits frais ou les jus fraîchement pressés sans sucres ajoutés, plutôt que les boissons à base de jus.",
  supermarket_prompt: "Choisis jusqu’à 9 produits",
  raw_fish: "Poisson cru",
  raw_fish_description: "Manger régulièrement du poisson t'aide à obtenir des nutriments importants. Essayes-en plusieurs.",
  raw_beef: "Viande de bœuf crue",
  raw_beef_description: "Si tu manges de la viande, essaye de réduire ta consommation, en particulier pour les viandes rouges ou transformées. C’est meilleur pour ta santé et pour l’environnement.",
  raw_chicken: "Poulet cru",
  raw_chicken_description: "Si tu manges de la viande, envisage de réduire ta consommation, en particulier les viandes rouges ou transformées. C’est mieux pour ta santé et pour l’environnement.",
  whole_salad: "Salade entière par exemple, laitue",
  whole_salad_description: "Privilégie les fruits et légumes frais, en particulier les fruits et légumes de saison et locaux.",
  packaged_salad: "Salade en sachet",
  packaged_salad_description: "Privilégie les fruits et légumes frais, en particulier les fruits et légumes de saison et locaux.",
  seasonal_fruit: "Fruits locaux de saison",
  seasonal_fruit_description: "Privilégie les fruits et légumes frais, en particulier les fruits et légumes de saison et locaux.",
  exotic_fruit: "Fruits exotiques",
  exotic_fruit_description: "Privilégie les fruits et légumes frais, en particulier les fruits et légumes de saison et locaux.",
  ham: "Jambon",
  ham_description: "La viande transformée contient de grandes quantités de graisses et de sel. Si tu manges de la viande transformée, pense à limiter votre consommation.",
  tofu: "Tofu",
  tofu_description: "Découvre de nouveaux produits végétaux et de nouvelles recettes.",
  hummus: "Houmous",
  hummus_description: "Le houmous est une bonne solution de substitution, qui t’aidera à augmenter votre consommation de légumineuses.",
  precooked_pizza: "Pizza précuite",
  precooked_pizza_description: "Les plats précuits peuvent contenir de grandes quantités de sel ou de graisse. Privilégie les produits qui ne sont pas précuits. Cela te permettra de contrôler la quantité de sel, de sucres et de graisses contenue dans vos repas.",
  precooked_fries: "Frites précuites",
  precooked_fries_description: "Les plats précuits peuvent contenir de grandes quantités de sel ou de graisse. Privilégie les produits qui ne sont pas précuits. Cela te permettra de contrôler la quantité de sel, de sucres et de graisses contenue dans vos repas.",
  food_delivery: "Livraison de repas",
  food_delivery_description: "",
  cooking_at_home: "Cuisiner à la maison",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, frites et boisson rafraîchissante",
  cheeseburger_description: "Évite de consommer ce type de repas. Ils sont riches en graisses et en sel. Comme boisson, bois de l’eau.",
  pizza_and_soft_drink: "Pizza et boisson rafraîchissante",
  pizza_and_soft_drink_description: "Ces repas sont riches en graisses, en sel et en sucres. Choisis des pizzas «simples» comprenant moins d’ingrédients ou davantage de légumes et choisissez, si possible, de la pâte à pizza à la farine complète. Comme boisson, bois de l’eau.",
  noodles_with_vegetables: "Nouilles aux légumes",
  noodles_with_vegetables_description: "C’est une bonne option pour consommer plus de légumes. Ajoute quelques légumineuses pour compléter ton repas.",
  mushroom_risoto: "Risotto aux champignons",
  mushroom_risoto_description: "Les champignons sont une bonne option végétarienne. Attention à ne pas ajouter trop de fromage. Tu peux compléter ce repas par une salade.",
  precooked_meal: "Plat préparé précuit -nuggets de poulet",
  precooked_meal_description: "Les plats précuits peuvent contenir de grandes quantités de sel, de graisse, voire de sucres. Privilégie les produits qui ne sont pas précuits. Tu peux mieux contrôler la quantité de ces nutriments. Remplace le sel par des herbes et des épices pour donner du goût.",
  frozen_pizza: "Pizza surgelée",
  frozen_pizza_description: "Les plats précuits peuvent contenir de grandes quantités de sel, de graisse, voire de sucres. Privilégie les produits qui ne sont pas précuits. Tu peux mieux contrôler la quantité de ces nutriments. Remplace le sel par des herbes et des épices pour donner du goût.",
  rice_and_salad: "Plat de riz préparé soi-même et salade verte",
  rice_and_salad_description: "Les plats précuits peuvent contenir de grandes quantités de sel, de graisse, voire de sucres. Privilégie les produits qui ne sont pas précuits. Tu peux mieux contrôler la quantité de ces nutriments. Remplace le sel par des herbes et des épices pour donner du goût.",
  ham_sandwich_and_soft_drink: "Sandwich au jambon et boisson rafraîchissante",
  ham_sandwich_and_soft_drink_description: "La viande transformée contient de grandes quantités de graisses et de sel. Si tu manges de la viande transformée, pense à limiter votre consommation. Pense également à éviter les boissons sucrées et les boissons rafraîchissantes. Bois plutôt de l’eau du robinet.",
  wholegrain_pasta_with_sauce: "Pâtes complètes avec une sauce aux légumes",
  wholegrain_pasta_with_sauce_description: "Essaie les pâtes complètes.",
  hnw_title_adult: "Un nouveau monde sain",
  hnw_description_1_adult: "Notre avenir dépend de nos choix. Si nous mangeons et vivons tous comme tu l'as fait dans le jeu, nous pourrons avoir un avenir plus sain, avec des personnes qui prendront soin les unes des autres et de l’environnement.",
  hnw_description_2_adult: "Les scientifiques estiment invariablement que pour préserver notre santé et celle de notre planète, nous devons avoir une alimentation variée: riche en céréales, en légumineuses et en légumes et fruits frais. Évite les aliments transformés, la viande et les en-cas, qui sont riches en sel, en sucres et en graisses. La pratique d’une activité physique, la marche, le vélo, l’utilisation des transports publics ainsi que la réduction de l’usage de la voiture présentent plus d’avantages.",
  hnw_description_3_adult: "Des choses simples peuvent améliorer ta santé et notre environnement: la propreté de l’air et de l’eau, la sécurité alimentaire, la richesse des sols et des espaces verts, dans le respect de la nature et de l’ensemble de la population. Penses-y également en dehors de ce jeu. Embarquez vos amis et votre famille dans cette aventure.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Veux-tu rejouer?",
  hnw_title_child: "Notre avenir dépend de nos choix",
  hnw_description_1_child: "Si nous mangeons et vivons tous comme tu l’as fait dans le jeu, nous pourrons avoir un avenir plus sain.",
  hnw_description_2_child: "Voici ce que tu peux faire au quotidien:\n- pense à avoir une alimentation variée pour préserver ta santé et celle de notre planète;\n- choisis des céréales, des légumineuses, des légumes et des fruits frais;  \n- limite les aliments transformés, la viande et les en-cas, qui sont riches en sel, en sucres et en graisses;\n- pratique une activité physique, marche, roule à vélo, prends les transports publics;\n- utilise moins la voiture.",
  hnw_description_3_child: "Ces choix simples peuvent améliorer ta santé et assurer la préservation de l’environnement et de l’ensemble de la population.",
  hnw_description_4_child: "Penses-y également en dehors de ce jeu.",
  hnw_description_5_child: "Embarque tes amis et ta famille dans cette aventure.",
  hnw_description_6_child: "Veux-tu rejouer?",
  etl_title_adult: "Manger pour vivre",
  etl_description_1_adult: "Notre avenir dépend de nos choix. Tu ne le sais peut-être pas, mais ton mode de vie peut avoir une incidence sur la santé de notre planète.",
  etl_description_2_adult: "Les scientifiques estiment invariablement que pour préserver notre santé et celle de notre planète, nous devons avoir une alimentation variée: riche en céréales, en légumineuses et en légumes et fruits frais. Évite les aliments transformés, la viande et les en-cas, qui sont riches en sel, en sucres et en graisses. La pratique d’une activité physique, la marche, le vélo, l’utilisation des transports publics ainsi que la réduction de l’usage de la voiture présentent plus d’avantages.",
  etl_description_3_adult: "Des choses simples peuvent améliorer ta santé et notre environnement: la propreté de l’air et de l’eau, la sécurité alimentaire, la richesse des sols et des espaces verts, dans le respect de la nature et de l’ensemble de la population.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Veux-tu rejouer?",
  etl_title_child: "Notre avenir dépend de nos choix",
  etl_description_1_child: "Si nous améliorons tous notre façon de manger et de vivre, nous pouvons avoir un avenir plus sain.",
  etl_description_2_child: "Voici ce que tu peux faire au quotidien:\n- pense à avoir une alimentation variée pour préserver ta santé et celle de notre planète;\n- choisis des céréales, des légumineuses, des légumes et des fruits frais;  \n- limite les aliments transformés, la viande et les en-cas, qui sont riches en sel, en sucres et en graisses;\n- pratique une activité physique, marche, roule à vélo, prends les transports publics;\n- utilise moins la voiture.",
  etl_description_3_child: "Ces choix simples peuvent améliorer ta santé et assurer la préservation de l’environnement et de l’ensemble de la population.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Veux-tu rejouer?",
  share: "Partager",
  replay: "Rejouer",
  its_up_to_you: "À toi de décider",
  badge_action_1: "Le secret caché est révélé!",
  badge_action_2: "Tu as gagné le %{badge} badge",
  fridgeDoor: "Porte du frigo",
  light: "Lumière",
  bottle: "Bouteille",
  reusable_bag: "Sac réutilisable",
  plastic_bag: "Sac plastique",
  recycle_bin: "Corbeille de recyclage",
  share_text_subject: "À toi de décider",
  share_text_body: "J'ai joué à 2050 - à toi de décider! Curieux de savoir à quoi ressemble le tien? #ExperienceEUScience\nJouer maintenant %{shareUrl}\nCe jeu a été développé par le Centre commun de recherche, le service de la Commission européenne pour la science et la connaissance.  \nPlus d’informations https://joint-research-centre.ec.europa.eu/index_fr?etrans=fr"
};
export default i18n;
