import "scenes/CharacterSelection.scss";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, { getRandomCharacterState } from "elements/character/Character.js";

const CharacterSelection = ({
  t,
  setCharacterType,
  setCharacterSkinTone,
  setCharacterOutfit,
  setCharacterHairStyle,
  setCharacterAccessory,
  restartScene,
  restartGame,
  goToNextScene
}) => {
  const randomizeDelay = useRef(70);
  const time = useRef(Date.now());
  const [adultCharacter, setAdultCharacter] = useState(getRandomCharacterState("adult"));
  const [childCharacter, setChildCharacter] = useState(getRandomCharacterState("child"));

  useEffect(() => {
    if (Date.now() - time.current > 4000) return; // Only randomize for 4 seconds.
    randomizeDelay.current *= 1.04; // Slightly increase the delay of appearance of the next randomized characters.
    const id = setTimeout(() => {
      setAdultCharacter(getRandomCharacterState("adult"));
      setChildCharacter(getRandomCharacterState("child"));
    }, randomizeDelay.current);
    return () => clearInterval(id);
  });

  return (
    <div className="CharacterSelection scene">
      <aside>
        <div className="scene-text">
          <h2>{t("character_selection_title")}</h2>
          <p>{t("character_selection_body")}</p>
        </div>
      </aside>
      <main>
        <div className="characters">
          <div>
            <Character {...adultCharacter} />
            <button
              onClick={() => {
                setCharacterType(adultCharacter.type);
                setCharacterSkinTone(adultCharacter.skinTone);
                setCharacterOutfit(adultCharacter.outfit);
                setCharacterHairStyle(adultCharacter.hairStyle);
                setCharacterAccessory(adultCharacter.accessory);
                goToNextScene();
              }}
            >
              {t("adult")}
            </button>
          </div>
          <div>
            <Character {...childCharacter} />
            <button
              onClick={() => {
                setCharacterType(childCharacter.type);
                setCharacterSkinTone(childCharacter.skinTone);
                setCharacterOutfit(childCharacter.outfit);
                setCharacterHairStyle(childCharacter.hairStyle);
                setCharacterAccessory(childCharacter.accessory);
                goToNextScene();
              }}
            >
              {t("child")}
            </button>
          </div>
        </div>
      </main>
      <Footer t={t} restartScene={restartScene} restartGame={restartGame} />
    </div>
  );
};

CharacterSelection.propTypes = exact({
  t: PropTypes.func.isRequired,
  setCharacterType: PropTypes.func.isRequired,
  setCharacterSkinTone: PropTypes.func.isRequired,
  setCharacterOutfit: PropTypes.func.isRequired,
  setCharacterHairStyle: PropTypes.func.isRequired,
  setCharacterAccessory: PropTypes.func.isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired
});

export default CharacterSelection;
