import "scenes/Breakfast.scss";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, { characterPropTypes } from "elements/character/Character.js";
import { droplet, success, loFiClick } from "common/sounds.js";
import { ReactComponent as FaucetSvg } from "elements/items/faucet.svg";
import { useForceUpdate } from "utils.js";
import AvailableItemsAndSelections from "modals/AvailableItemsAndSelections.js";
import { allBadges, getAvailableBadgesFor } from "elements/badges/badges.js";
import Badges from "modals/Badges.js";
import BadgeAwarded from "modals/BadgeAwarded.js";
import CharacterResponsiveCustomModal from "elements/character/CharacterResponsiveCustomModal.js";
import ScrollableArrowsHintClasses from "common/ScrollableArrowsHintClasses.js";

const hiddenItemBadgeAwarded = "water-conservation-master";

const getAvailableItemsFor = selectedItems => {
  if (selectedItems.length === 0) {
    return ["no-drink", "tap-water", "milk", "freshly-made-juice", "coffee-or-tea", "plant-based-drink"];
  }
  if (selectedItems.length === 1) {
    if (["milk", "plant-based-drink"].includes(selectedItems[0])) {
      return ["whole-grain-cereals", "choco-balls", "white-bread-or-toast", "wholemeal-bread-or-toast", "biscuits"];
    } else {
      return [
        "yogurt",
        "whole-grain-cereals",
        "choco-balls",
        "white-bread-or-toast",
        "wholemeal-bread-or-toast",
        "biscuits"
      ];
    }
  }
  if (selectedItems.length === 2) {
    if (["yogurt"].includes(selectedItems[1])) {
      return ["no-topping", "honey", "jam", "whole-grain-cereals", "choco-balls", "fruit"];
    }
    if (["white-bread-or-toast", "wholemeal-bread-or-toast"].includes(selectedItems[1])) {
      return ["butter", "chocolate-cream", "ham-or-bacon", "cheese", "avocado-and-tomato"];
    }
  }
  return [];
};

const Breakfast = ({
  t,
  character,
  addSelection,
  addBadge,
  badges,
  restartScene,
  restartGame,
  goToNextScene,
  battery
}) => {
  const tableDomRef = useRef();
  const forceUpdate = useForceUpdate();
  const [hiddenItemFound, setHiddenItemFound] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [availableItems, setAvailableItems] = useState(getAvailableItemsFor(selectedItems));
  const [openModal, setOpenModal] = useState();
  const [sceneCompleted, setSceneCompleted] = useState(false);
  const enteringInventoryAnimation = useRef(false);

  const hiddenItemClicked = () => {
    if (hiddenItemFound) return;
    setOpenModal("badgeAwarded");
    setHiddenItemFound(true);
    addBadge(hiddenItemBadgeAwarded);
    success();
  };

  const itemClicked = item => {
    if (enteringInventoryAnimation.current) return; // User managed to select an available item before the previous item selection animation has ended.
    enteringInventoryAnimation.current = true;
    // Add item to selected items.
    const newSelectedItems = selectedItems.concat(item);
    setSelectedItems(newSelectedItems);
    addSelection(item);
    loFiClick();
    // Make item leave table.
    const itemDom = tableDomRef.current.querySelector("." + item);
    itemDom.classList.add("leaveTable");
    // When it has fully left table, change available items.
    itemDom.addEventListener(
      "animationend",
      () => {
        setAvailableItems(getAvailableItemsFor(newSelectedItems));
        enteringInventoryAnimation.current = false;
      },
      { once: true }
    );
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (!hiddenItemFound && sceneCompleted) droplet();
      forceUpdate();
    }, 1000);
    return () => clearTimeout(id);
  });

  useEffect(() => {
    if (availableItems.length === 0) {
      setSceneCompleted(true);
    }
  }, [availableItems]);

  return (
    <div className="Breakfast scene">
      <div className="background-elements">
        <div className="item frame"></div>
        <div className="item plant"></div>
      </div>

      <div className="item sink">
        <div>
          <button onClick={hiddenItemClicked} className="item faucet" disabled={hiddenItemFound} title={t("faucet")}>
            <FaucetSvg />
          </button>
        </div>
      </div>
      <div className="primary-elements">
        <CharacterResponsiveCustomModal t={t} characterElement={<Character {...character} />} />

        <div className="item table" ref={tableDomRef}>
          <ScrollableArrowsHintClasses>
            <div>
              {availableItems.map(item => (
                <button
                  key={item}
                  onClick={() => itemClicked(item)}
                  className={`item ${item}`}
                  title={t(item.replace(/-/g, "_"))}
                >
                  <label>{t(item.replace(/-/g, "_"))}</label>
                </button>
              ))}
            </div>
          </ScrollableArrowsHintClasses>
        </div>
      </div>
      <div className="inventory">
        {selectedItems.length > 0 && (
          <div className="items">
            {selectedItems.map(item => (
              <button key={item} className={`item ${item}`} disabled={true} title={t(item.replace(/-/g, "_"))}>
                <label>{t(item.replace(/-/g, "_"))}</label>
              </button>
            ))}
          </div>
        )}
      </div>

      <Footer
        t={t}
        availableItemsAndSelectionsNotificationHash={availableItems.concat(selectedItems).join()}
        openAvailableItemsAndSelectionsModal={() => setOpenModal("availableItemsAndSelections")}
        badgesCount={badges.length}
        openBadgesModal={() => setOpenModal("badges")}
        restartScene={sceneCompleted ? restartScene : undefined}
        restartGame={restartGame}
        goToNextScene={sceneCompleted ? goToNextScene : undefined}
      />
      {battery}
      <BadgeAwarded
        t={t}
        badge={hiddenItemBadgeAwarded}
        isOpen={openModal === "badgeAwarded"}
        closeModal={() => setOpenModal(undefined)}
      />
      <Badges
        t={t}
        badges={badges}
        availableBadges={getAvailableBadgesFor(character.type)}
        isOpen={openModal === "badges"}
        closeModal={() => setOpenModal(undefined)}
      />
      <AvailableItemsAndSelections
        t={t}
        availableItems={availableItems}
        selections={availableItems.length === 0 ? selectedItems : undefined} // Only show selections when there are no more available items left (at the end of the scene).
        isOpen={openModal === "availableItemsAndSelections"}
        closeModal={() => setOpenModal(undefined)}
      />
    </div>
  );
};

Breakfast.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  addSelection: PropTypes.func.isRequired,
  addBadge: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired,
  battery: PropTypes.element.isRequired
});

export default Breakfast;
