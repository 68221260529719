const i18n = {
  title: "Игра Здраво общество през 2050 г.",
  start: "Начало",
  select_language: "Избери език",
  adult: "Възрастен",
  child: "Дете",
  fullscreen: "Цял екран",
  restart_scene: "Почни отново етапа",
  restart_game: "Почни отново играта",
  previous: "Назад",
  next: "Напред",
  close: "Затвори",
  toggle_sound: "Звук вкл./изкл.",
  character_selection_title: "Избери персонаж",
  character_selection_body: "Нашият избор днес се отразява на бъдещето ни. В тази игра симулираме ежедневни ситуации. Избирай и проследи последствията от своя избор. Oглеждай се за скрити тайни, за да получиш медали! Да започнем с това как ще изглежда твоят персонаж.",
  character_set_up_title: "Избери и облечи своя персонаж",
  select_me: "Избери мен",
  breakfast_title: "Какво закусваш?",
  your_badges: "Медали",
  badge_water_conservation_master_title: "Гуру",
  badge_water_conservation_master_description: "Браво! Ти си гуру в опазването на един оскъден ресурс! Без вода няма живот.",
  badge_clean_street_wizard_title: "Магьосник",
  badge_clean_street_wizard_description: "Браво! Ти си магьосник и помагаш на околната среда да стане по-чиста, като събираш боклуци и ги изхвърляш в кошчето.",
  badge_eco_ninja_title: "Нинджа",
  badge_eco_ninja_description: "Браво! Ти си нинджа в спестяването на енергия. Спестяването на електричество от нашите уреди е чудесен начин да спомогнем за намаляването на консумацията на енергия.",
  badge_energy_saving_expert_title: "Експерт",
  badge_energy_saving_expert_description: "Браво! Ти си експерт по спестяване на електричество. Продължавай да търсиш начини да намалиш консумацията на енергия.",
  badge_low_waste_champion_title: "Шампион",
  badge_low_waste_champion_description: "Браво! Ти си шампион по намаляване на боклука и помагаш на околната среда, като ползваш по-малко пластмаса.",
  badge_responsible_shopper_title: "Майстор",
  badge_responsible_shopper_description: "Браво! Ти си майстор и умееш да избираш в полза на околната среда. Продължавай да използваш по-малко пластмаса, като си носиш платнена чанта за пазаруване.",
  badge_recycling_captain_title: "Звезда",
  badge_recycling_captain_description: "Браво! Ти си звезда на рециклирането. Важно е да рециклираме и да изизползваме повторно материалите за една по-здрава околна среда.",
  faucet: "Чешма",
  transportation_title_adult: "Как ходиш на работа?",
  transportation_title_child: "Как ходиш на училище?",
  trash: "Боклук",
  no_drink: "Без напитка",
  no_drink_description: "",
  tap_water: "Чешмяна вода",
  tap_water_description: "Хидратирай се. Пробвай да пиеш поне 1,5 - 2 литра вода на ден",
  milk: "Мляко",
  milk_description: "Избери мляко без овкусители и без подсладители, за предпочитане нискомаслено.",
  freshly_made_juice: "Фреш - прясно изцеден сок",
  freshly_made_juice_description: "Ако обичаш сок, избери прясно изцеден - не добавяй захар. Пробвай да ядеш пресни плодове.",
  coffee_or_tea: "Кафе/чай",
  coffee_or_tea_description: "Пий любимия си чай и кафе по възможност без захар.",
  plant_based_drink: "Напитка на растителна основа",
  plant_based_drink_description: "Пробвай с напитки на растителна основа без добавена захар и ако може, избери такива, които съдържат витамини и минерали.",
  whole_grain_cereals: "Пълнозърнесто мюсли",
  whole_grain_cereals_description: "Избирай пълнозърнесто мюсли без добавени захари.",
  choco_balls: "Шоколадови топчета",
  choco_balls_description: "Зърнената закуска може да представлява значителна част от дневния ни прием на захар - можеш ли да избереш закуска с по-малко добавена захар? Cега избери закуска с повече фибри.",
  white_bread_or_toast: "Бял хляб/препечена филийка",
  white_bread_or_toast_description: "Опитай пълнозърнест хляб.",
  wholemeal_bread_or_toast: "Черен хляб/препечена филийка",
  wholemeal_bread_or_toast_description: "Опитай пълнозърнест хляб.",
  biscuits: "Бисквити",
  biscuits_description: "Бисквитите съдържат много калории и могат да представляват значителна част от дневния ни прием на захар и мазнини. По-добре е да намалим консумацията им. Избирай тези с по-ниско съдържание на мазнини и захар. А сега избери от тези с повече фибри.",
  no_topping: "Без добавка",
  no_topping_description: "",
  yogurt: "Кисело мляко",
  yogurt_description: "Пробвай с неовкусено нискомаслено кисело мляко без добавени захари.",
  honey: "Мед",
  honey_description: "Избери неовкусено кисело мляко, без да добавяш захар или конфитюр. По-добре добави плод или неподсладено пълнозърнесто мюсли.",
  jam: "Конфитюр",
  jam_description: "Избери неовкусено кисело мляко, без да добавяш захар или конфитюр. По-добре добави плод или неподсладено пълнозърнесто мюсли.",
  fruit: "Плодове",
  fruit_description: "Чудесен избор да започнеш деня с плодове, старай се да избираш сезонни плодове. Ако добавяш ядки, избери от тези без добавени сол и захар.",
  butter: "Масло",
  butter_description: "Маслото съдържа много мазнини и може да е солено. Опитай се да ядеш от него по-рядко и предпочитай безсолното масло.",
  chocolate_cream: "Горещ шоколад",
  chocolate_cream_description: "Горещият шоколад съдържа много захар и наситени мазнини. Опитай се да не консумираш от него твърде често.",
  ham_or_bacon: "Шунка/бекон",
  ham_or_bacon_description: "Преработеното месо съдържа високи количества мазнини и сол. Ако ядеш преработено месо, ограничи количествата.",
  cheese: "Сирене",
  cheese_description: "Сирената имат високо съдържание на сол и наситени мазнини. Консумирай сирене по-рядко и не прекалявай с количеството.",
  avocado_and_tomato: "Авокадо и домат",
  avocado_and_tomato_description: "Чудесен избор - да започнеш деня си със зеленчуци. Опитай се да ядеш много плодове и зеленчуци през деня и избирай местни и сезонни плодове.",
  battery: "Батерия",
  battery_description: "Следи батерията! Тя показва последствията от предпочитанията ти!",
  lunch_title: "Какво предпочиташ за обяд?",
  breaktime_title: "Следобедна почивка - свободно време!",
  snacking_title: "Искаш ли нещо за хапване?",
  supermarket_title: "Какво ще купиш?",
  dinner_selection_title: "Какво планираш за вечеря?",
  dinner_cooking_title: "Какво има за вечеря?",
  dinner_delivery_title: "Какво има за вечеря?",
  your_available_items: "Възможен избор",
  your_selections: "Твоят избор",
  walk: "Пеша",
  walk_description: "Бъди физически активен всеки ден. Ако е възможно, ходи пеша или карай колело до училище/работата. Така ще си във форма, а това е по-добре и за околната среда.",
  bicycle: "С колело",
  bicycle_description: "Бъди физически активен всеки ден. Ако е възможно, ходи пеша или карай колело до училище/работата. Така ще си във форма, а това е по-добре и за околната среда.",
  scooter_or_motorbike: "Със скутер/мотор",
  scooter_or_motorbike_description: "Опитай се да използваш колата или мотора по-рядко за една по-чиста околна среда. Ако не е възможно, пробвай т.нар. споделено пътуване.",
  car: "Кола",
  car_description: "Опитай се да използваш колата или мотора по-рядко за една по-чиста околна среда. Ако не е възможно, пробвай т.нар. споделено пътуване.",
  public_transport: "Обществен транспорт",
  public_transport_description: "Използвай обществен транспорт и го комбинирай с ходене или колоездене, вместо да пътуваш с кола. Дай си почивка от шофирането и помогни на околната среда.",
  salad_with_egg: "Салата с яйца",
  salad_with_egg_description: "Добавяй свежи зеленчуци към ястията си винаги, когато можеш.",
  beef_steak: "Телешка пържола",
  beef_steak_description: "Ако ядеш месо, помисли дали да не намалиш количествата, особено на червени и преработени меса. По-добре е за здравето ти и за околната среда.",
  chicken: "Пиле",
  chicken_description: "Ако ядеш месо, помисли дали да не намалиш количествата, особено на червени и преработени меса. По-добре е за здравето ти и за околната среда.",
  lentil_soup: "Супа от леща",
  lentil_soup_description: "Редовната консумация на бобови растения се препоръчва като част от разнообразното хранене на растителна основа.",
  vegetable_burger: "Зеленчуков бургер",
  vegetable_burger_description: "Зеленчуковият бургер може да бъде добра алтернатива на месото. Погледни дали не съдържа прекалено много сол.",
  white_pasta: "Бяла паста - макаронени изделия",
  white_pasta_description: "Подбирай пълнозърнести продукти, когато избираш паста, ориз и хляб.",
  wholegrain_pasta: "Пълнозърнеста паста",
  wholegrain_pasta_description: "Подбирай пълнозърнести продукти, когато избираш паста, ориз и хляб.",
  white_rice: "Бял ориз",
  white_rice_description: "Подбирай пълнозърнести продукти, когато избираш паста, ориз и хляб.",
  wholegrain_rice: "Пълнозърнест ориз",
  wholegrain_rice_description: "Подбирай пълнозърнести продукти, когато избираш паста, ориз и хляб.",
  white_bread_bun: "Бяла питка",
  white_bread_bun_description: "Подбирай пълнозърнести продукти, когато избираш паста, ориз и хляб.",
  no_salad: "Без салата",
  no_salad_description: "",
  fresh_green_salad: "Свежа зелена салата",
  fresh_green_salad_description: "Добави цветни сезонни зеленчуци към храната си - колкото по-разнообразна е тя, толкова по-добре.",
  french_fries: "Пържени картофки",
  french_fries_description: "По-добре е картофите да са варени, а не пържени.",
  boiled_broccoli: "Варени броколи",
  boiled_broccoli_description: "Опитай се да ядеш много зеленчуци през деня. Избирай местни и сезонни зеленчуци.",
  bottled_water: "Бутилирана вода",
  bottled_water_description: "Пий чешмяна вода, за да намалиш употребата на пластмаса.",
  soft_drink: "Безалкохолни напитки",
  soft_drink_description: "На подсладените напитки се пада значителна част от нашия дневен прием на захар - можеш ли да избереш вода вместо тях?",
  beer_or_wine: "Бира/вино",
  beer_or_wine_description: "Ограничи приема на алкохол. По-здравословно e да се пие по-малко алкохол.",
  watching_tv: "Телевизионни предавания или видеоигри",
  watching_tv_description: "Бъди физически активен всеки ден и се опитвай да прекарваш седнал по-малко време. В свободното си време се опитай да спортуваш, да играеш с приятелите си или да свършиш нещо полезно за всички. Това е добре за здравето ти и за обществото. Избягвай да прекарваш прекалено дълго време пред екрана.",
  doing_sports: "Спорт",
  doing_sports_description: "Бъди физически активен всеки ден и се опитвай да прекарваш седнал по-малко време. В свободното си време се опитай да спортуваш, да играеш с приятелите си или да свършиш нещо полезно за всички. Това е добре за здравето ти и за обществото. Избягвай да прекарваш прекалено дълго време пред екрана.",
  playing_outside: "Игра навън",
  playing_outside_description: "Бъди физически активен всеки ден и се опитвай да прекарваш седнал по-малко време. В свободното си време се опитай да спортуваш, да играеш с приятелите си или да свършиш нещо полезно за всички. Това е добре за здравето ти и за обществото. Избягвай да прекарваш прекалено дълго време пред екрана.",
  charity_work: "Благотворителна дейност с възрастните",
  charity_work_description: "Бъди физически активен всеки ден и се опитвай да прекарваш седнал по-малко време. В свободното си време се опитай да спортуваш, да играеш с приятелите си или да свършиш нещо полезно за всички. Това е добре за здравето ти и за обществото. Избягвай да прекарваш прекалено дълго време пред екрана.",
  carrots_tomatoes: "Моркови/чери домати",
  carrots_tomatoes_description: "Избирай плодове, зеленчуци или ядки. Ограничи закуските и напитките с много захар, мазнини и сол.",
  fresh_fruit: "Пресен плод",
  fresh_fruit_description: "Избирай плодове, зеленчуци или ядки. Ограничи закуските и напитките с много захар, мазнини и сол.",
  chocolate_bar: "Шоколадово десертче",
  chocolate_bar_description: "Избирай плодове, зеленчуци или ядки. Ограничи закуските и напитките с много захар, мазнини и сол.",
  chips: "Чипс",
  chips_description: "Избирай плодове, зеленчуци или ядки. Ограничи закуските и напитките с много захар, мазнини и сол.",
  juice_drink: "Сок",
  juice_drink_description: "Избирай пресни плодове или фрешове без добавена захар вместо сокчета.",
  supermarket_prompt: "Избери до 9 продукта",
  raw_fish: "Сурова риба",
  raw_fish_description: "Редовната консумация на риба ти осигурява важни хранителни вещества. Опитвай различни видове риба.",
  raw_beef: "Сурово говеждо",
  raw_beef_description: "Ако ядеш месо, пробвай да намалиш консумацията му, особено на червено и на преработено месо. Така е по-добре за здравето ти и за околната среда.",
  raw_chicken: "Сурово пиле",
  raw_chicken_description: "Ако ядеш месо, помисли дали да не намалиш количествата, особено на червени и преработени меса. По-добре е за здравето ти и за околната среда.",
  whole_salad: "Цели зеленчуци за салата - напр. Маруля",
  whole_salad_description: "Избирай свежи плодове или зеленчуци, най-вече сезонни и местни.",
  packaged_salad: "Пакетирана салата",
  packaged_salad_description: "Избирай свежи плодове или зеленчуци, най-вече сезонни и местни.",
  seasonal_fruit: "Сезонни местни плодове",
  seasonal_fruit_description: "Избирай свежи плодове или зеленчуци, най-вече сезонни и местни.",
  exotic_fruit: "Екзотични плодове",
  exotic_fruit_description: "Избирай свежи плодове или зеленчуци, най-вече сезонни и местни.",
  ham: "Шунка",
  ham_description: "Преработеното месо съдържа много мазнини и сол. Ако ядеш преработено месо, опитай се да ограничиш консумацията му.",
  tofu: "Тофу",
  tofu_description: "Изпробвай нови продукти на растителна основа и нови рецепти.",
  hummus: "Хумус",
  hummus_description: "Хумусът е добра алтернатива, която ще ти помогне да увеличиш приема на бобови растения",
  precooked_pizza: "Полуготова пица",
  precooked_pizza_description: "Полуфабрикатите могат да съдържат много сол и мазнини. Избирай продукти, които не са полуготови. Това ще ти позволи да контролираш количеството сол, захар и мазнини.",
  precooked_fries: "Полуготови пържени картофки",
  precooked_fries_description: "Полуфабрикатите могат да съдържат много сол и мазнини. Избирай продукти, които не са полуготови. Това ще ти позволи да контролираш количеството сол, захар и мазнини.",
  food_delivery: "Доставка на храна",
  food_delivery_description: "",
  cooking_at_home: "Готвене - у дома",
  cooking_at_home_description: "",
  cheeseburger: "Чийзбургер, пържени картофки и безалкохолна напитка",
  cheeseburger_description: "Избягвай консумацията на храни от този тип. Те са богати на мазнини и сол. За пиене си вземи вода.",
  pizza_and_soft_drink: "Пица и безалкохолна напитка",
  pizza_and_soft_drink_description: "Подобни храни съдържат много мазнини, сол и захар. Избирай семпли пици с по-малко съставки или повече зеленчуци и избирай пълнозърнесто тесто, ако е възможно. За пиене си вземи вода.",
  noodles_with_vegetables: "Нудли със зеленчуци",
  noodles_with_vegetables_description: "Това е добър вариант да увеличиш консумацията на зеленчуци. Добави малко бобови растения, за да бъде храната ти истински здравословна.",
  mushroom_risoto: "Гъбено ризото",
  mushroom_risoto_description: "Гъбите са добър вегетариански вариант. Не прекалявай с кашкавала, който добавяш към ястието си. Можеш да добавиш салата за по-здравословен вариант.",
  precooked_meal: "Полуготово меню - пилешки хапки",
  precooked_meal_description: "Полуфабрикатите могат да съдържат много сол, мазнини и дори захари. Избирай продукти, които не са полуготови, така ще можеш да контролираш по-добре приема на тези вещества. За овкусяване използвай билки и подправки вместо сол.",
  frozen_pizza: "Замразена пица",
  frozen_pizza_description: "Полуфабрикатите могат да съдържат много сол, мазнини и дори захари. Избирай продукти, които не са полуготови, така ще можеш да контролираш по-добре приема на тези вещества. За овкусяване използвай билки и подправки вместо сол.",
  rice_and_salad: "Ястие, приготвено от теб, с ориз и зелена салата",
  rice_and_salad_description: "Полуфабрикатите могат да съдържат много сол, мазнини и дори захари. Избирай продукти, които не са полуготови, така ще можеш да контролираш по-добре приема на тези вещества. За овкусяване използвай билки и подправки вместо сол.",
  ham_sandwich_and_soft_drink: "Сандвич с шунка и безалкохолна напитка",
  ham_sandwich_and_soft_drink_description: "Преработеното месо съдържа много мазнини и сол. Ако ядеш преработено месо, опитвай се да ограничиш количествата. Освен това се опитай да избягваш подсладени и безалкохолни напитки. Вместо тях пий чешмяна вода.",
  wholegrain_pasta_with_sauce: "Пълнозърнеста паста със зеленчуков сос.",
  wholegrain_pasta_with_sauce_description: "Опитай пълнозърнеста паста",
  hnw_title_adult: "Здравословен нов свят",
  hnw_description_1_adult: "Нашите избори предопределят бъдещето. Ако всички се храним и живеем така, както направи ти в играта, ще живеем по-здравословно в бъдеще заедно с хора, които не са безразлични към другите и към природата.",
  hnw_description_2_adult: "Учените неколкократно са констатирали, че за да запазим здравето си и това на планетата ни, трябва да се храним здравословно: с повече зърнени и бобови култури и свежи зеленчуци и плодове. Да избягваме преработени храни, месо и закуски, съдържащи твърде много сол, захари и мазнини. Всички можем само да спечелим, ако сме физически активни, ходим пеша, караме колело или използваме обществения транспорт и караме автомобилите си по-рядко.",
  hnw_description_3_adult: "Нещата, които могат да укрепят здравето ни и са полезни и за околната среда, са прости: чист въздух и вода, безопасна храна, плодородни почви и зелени пространства, уважение към природата и всички хора. Замисли се за това и извън тази игра. Покани приятелите и семейството си на това пътешествие.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Искаш ли да играеш отново?",
  hnw_title_child: "Нашите избори предопределят бъдещето ни",
  hnw_description_1_child: "Ако всички се храним и живеем така, както направи ти в играта, можем да разчитаме на здравословно бъдеще.",
  hnw_description_2_child: "Ето какво можеш да направиш::\n- Старай се да се храниш здравословно, за да опазиш своето здраве и това на нашата планета.\n- Избирай зърнени и бобови култури и свежи зеленчуци и плодове.  \n- Ограничи преработените храни, месо и закуски с много сол, захари и мазнини.\n- Бъди физически активен, ходи пеша, карай колело и ползвай обществения транспорт.\n- Карай кола по-рядко.",
  hnw_description_3_child: "Тези семпли варианти могат да бъдат по-здравословни за теб, за околната среда и за всички хора.",
  hnw_description_4_child: "Помисли над това и след края на играта.",
  hnw_description_5_child: "Покани приятелите и семейството си на това пътешествие.",
  hnw_description_6_child: "Искаш ли да играеш отново?",
  etl_title_adult: "Храни се, за да живееш",
  etl_description_1_adult: "Нашите избори предопределят бъдещето ни. Може да не си даваш сметка за това, но начинът ти на живот може да има последствия за здравето на нашата планета.",
  etl_description_2_adult: "Учените неколкократно са констатирали, че за да запазим здравето си и това на планетата ни, трябва да се храним здравословно: с повече зърнени и бобови култури и свежи зеленчуци и плодове. Да избягваме преработени храни, месо и закуски, съдържащи твърде много сол, захари и мазнини. Всички можем само да спечелим, ако сме физически активни, ходим пеша, караме колело или използваме обществения транспорт и караме автомобилите си по-рядко.",
  etl_description_3_adult: "Нещата, които могат да укрепят здравето ни и са полезни и за околната среда, са прости: чист въздух и вода, безопасна храна, плодородни почви и зелени пространства, уважение към природата и всички хора.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Искаш ли да играеш отново?",
  etl_title_child: "Нашите избори предопределят бъдещето ни",
  etl_description_1_child: "Ако се храним и живеем по-здравословно, можем да се надяваме на по-добро здраве в бъдеще.",
  etl_description_2_child: "Ето какво можеш да направиш::\n- Старай се да се храниш здравословно, за да опазиш своето здраве и това на нашата планета.\n- Избирай зърнени и бобови култури и свежи зеленчуци и плодове.  \n- Ограничи преработените храни, месо и закуски с много сол, захари и мазнини.\n- Бъди физически активен, ходи пеша, карай колело и ползвай обществения транспорт.\n- Карай кола по-рядко.",
  etl_description_3_child: "Тези семпли варианти могат да бъдат по-здравословни за теб, за околната среда и за всички хора.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Искаш ли да играеш отново?",
  share: "Сподели!",
  replay: "Играй отново!",
  its_up_to_you: "От теб зависи!",
  badge_action_1: "Нищо не ти убягва!",
  badge_action_2: "Ти спечели медал за %{badge}",
  fridgeDoor: "Врата на хладилника",
  light: "Светлина",
  bottle: "Бутилка",
  reusable_bag: "Торба за многократна употреба",
  plastic_bag: "Пластмасова торбичка",
  recycle_bin: "Контейнер за рециклиране",
  share_text_subject: "От теб зависи!",
  share_text_body: "Аз играх 2050 – от теб зависи! Любопитен ли си как ще изглежда твоят?#ExperienceEUScience\nИграй сега %{shareUrl}\nТази игра е разработена от Съвместния изследователски център - службата на Комисията за наука и знания.\nОткрий още https://joint-research-centre.ec.europa.eu/index_bg?etrans=bg"
};
export default i18n;
