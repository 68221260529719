const i18n = {
  title: "Hra Zdravá spoločnosť 2050",
  start: "Štart",
  select_language: "Výber jazyka",
  adult: "Dospelý",
  child: "Dieťa",
  fullscreen: "Celá obrazovka",
  restart_scene: "Reset scény",
  restart_game: "Späť na začiatok hry",
  previous: "Späť",
  next: "Ďalej",
  close: "Zavrieť",
  toggle_sound: "Zapnúť/vypnúť zvuk",
  character_selection_title: "Vyber si postavu",
  character_selection_body: "Budúcnosť si vytvárame vlastnými rozhodnutiami. V tejto hre simulujeme každodenné situácie. Vyber si z ponuky a zisti, aký vplyv má tvoj výber.  Určite sa poobzeraj aj okolo seba, či niekde nie sú schované prekvapenia, ktoré ti umožnia získať odznaky. Začni tým, že si vytvoríš postavu.",
  character_set_up_title: "Vytvor si postavu",
  select_me: "Vybrať túto postavu",
  breakfast_title: "Čo raňajkuješ?",
  your_badges: "Odznaky",
  badge_water_conservation_master_title: "Majster",
  badge_water_conservation_master_description: "Gratulujeme! Si majster v šetrení vzácnym zdrojom! Voda je nevyhnutná pre všetky druhy života.",
  badge_clean_street_wizard_title: "Génius",
  badge_clean_street_wizard_description: "Gratulujeme! Si génius, pokiaľ ide o prispievanie k čistejšiemu životnému prostrediu, pretože zbieraš odpadky a odhadzuješ ich do odpadkových košov.",
  badge_eco_ninja_title: "Nindža",
  badge_eco_ninja_description: "Gratulujeme! Si nindža v šetrení energie. Šetrenie energie, ktorú využívajú naše spotrebiče, je skvelý spôsob, ako prispieť k zníženiu spotreby energie.",
  badge_energy_saving_expert_title: "Odborník",
  badge_energy_saving_expert_description: "Gratulujeme! Si odborník na šetrenie energie. Pokračuj v hľadaní spôsobov, ako znížiť svoju spotrebu energie.",
  badge_low_waste_champion_title: "Šampión",
  badge_low_waste_champion_description: "Gratulujeme! Si šampión v znižovaní množstva odpadu, ktoré vyprodukuješ, a pomáhaní životnému prostrediu tým, že používaš menej plastov.",
  badge_responsible_shopper_title: "Profík",
  badge_responsible_shopper_description: "Gratulujeme, si profík v prijímaní zdravých rozhodnutí v záujme životného prostredia. Aj naďalej využívaj čo najmenej plastov tým, že si budeš nosiť vlastnú tašku, ktorú môžeš používať opakovane.",
  badge_recycling_captain_title: "Hviezda",
  badge_recycling_captain_description: "Gratulujeme! Si hviezda v recyklácii. V záujme zdravého životného prostredia je dôležité materiály recyklovať a opakovane používať.",
  faucet: "Vodovodný kohútik",
  transportation_title_adult: "Ako chodíš do práce?",
  transportation_title_child: "Ako chodíš do školy?",
  trash: "Odpadky",
  no_drink: "Žiadny nápoj",
  no_drink_description: "",
  tap_water: "Voda z vodovodu",
  tap_water_description: "Dodaj telu dostatok tekutín. Snaž sa vypiť aspoň 1,5 litra až 2 litre vody denne.",
  milk: "Mlieko",
  milk_description: "Vyber si obyčajné nesladené mlieko, ideálne nízkotučné.",
  freshly_made_juice: "Čerstvo vylisovaná šťava",
  freshly_made_juice_description: "Ak ti chutia džúsy, vyber si čerstvo vylisovanú šťavu – netreba pridávať cukor. Snaž sa jesť čerstvé ovocie.",
  coffee_or_tea: "Káva/čaj",
  coffee_or_tea_description: "Vychutnaj si čaj a kávu, ideálne bez pridania cukru.",
  plant_based_drink: "Rastlinný nápoj",
  plant_based_drink_description: "Snaž sa piť nápoje z rastlín bez pridaných cukrov a podľa možnosti si vyberaj nápoje, ktoré obsahujú vitamíny a minerály.",
  whole_grain_cereals: "Celozrnné cereálie",
  whole_grain_cereals_description: "Uprednostňuj celozrnné cereálie bez pridaných cukrov.",
  choco_balls: "Čokoládové guľôčky",
  choco_balls_description: "Raňajkové cereálie môžu tvoriť značnú časť nášho denného príjmu cukrov – vieš si vybrať také, ktoré obsahujú menej pridaných cukrov? Okrem toho si vyberaj aj také, ktoré majú viac vlákniny.",
  white_bread_or_toast: "Biely chlieb/hrianky",
  white_bread_or_toast_description: "Snaž sa jesť celozrnný chlieb.",
  wholemeal_bread_or_toast: "Tmavý chlieb/hrianky",
  wholemeal_bread_or_toast_description: "Snaž sa jesť celozrnný chlieb.",
  biscuits: "Sušienky",
  biscuits_description: "Sušienky obsahujú veľa kalórií a môžu tvoriť značnú časť nášho denného príjmu cukrov a tuku. Je lepšie obmedziť ich konzumáciu. Uprednostňuj sušienky s nižším obsahom tuku a cukrov. Okrem toho si vyberaj aj také, ktoré majú viac vlákniny.",
  no_topping: "Bez polevy",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Snaž sa jesť biely nízkotučný jogurt bez pridaných cukrov.",
  honey: "Med",
  honey_description: "Vyber si biely jogurt bez pridania cukrov či džemu. Radšej si pridaj ovocie alebo nesladené celozrnné cereálie.",
  jam: "Džem",
  jam_description: "Vyber si biely jogurt bez pridania cukrov či džemu. Radšej si pridaj ovocie alebo nesladené celozrnné cereálie.",
  fruit: "Ovocie",
  fruit_description: "Začať deň ovocím je skvelou voľbou. Snaž sa vybrať si sezónne ovocie. Ak pridáš orechy, uprednostni nesolené alebo nesladené.",
  butter: "Maslo",
  butter_description: "Maslo obsahuje veľké množstvo tuku a môže byť slané. Snaž sa ho nejesť príliš často a vyberať si maslo bez soli.",
  chocolate_cream: "Čokoládová nátierka",
  chocolate_cream_description: "Čokoládová nátierka obsahuje veľa cukru a nasýtených tukov. Snaž sa nejesť ju príliš často.",
  ham_or_bacon: "Šunka/slanina",
  ham_or_bacon_description: "Spracované mäso obsahuje veľké množstvo tuku a soli. Ak ješ spracované mäso, obmedz jeho konzumáciu.",
  cheese: "Syr",
  cheese_description: "Syr obsahuje veľké množstvo soli a nasýtených tukov. Snaž sa ho nejesť príliš často a konzumovať ho len v malom množstve.",
  avocado_and_tomato: "Avokádo a rajčina",
  avocado_and_tomato_description: "Začať deň nejakou zeleninou je skvelou voľbou. Počas dňa sa snaž jesť veľa ovocia a zeleniny a uprednostni produkty, ktoré sú lokálne a sezónne.",
  battery: "Batéria",
  battery_description: "Pozorne sleduj svoju batériu, ukazuje, aký vplyv majú tvoje rozhodnutia!",
  lunch_title: "Čo si dáš na obed?",
  breaktime_title: "Prišlo poobedie a máme voľno!",
  snacking_title: "Máš chuť na malé občerstvenie?",
  supermarket_title: "Čo si kúpiš?",
  dinner_selection_title: "Čo budeš jesť na večeru?",
  dinner_cooking_title: "Čo je na večeru?",
  dinner_delivery_title: "Čo je na večeru?",
  your_available_items: "Tvoje možnosti",
  your_selections: "Tvoj výber",
  walk: "Pešo",
  walk_description: "Každý deň sa venuj fyzickej aktivite. Podľa možnosti choď do školy/práce pešo alebo na bicykli. Udrží ťa to v dobrej kondícii a je to lepšie pre životné prostredie.",
  bicycle: "Na bicykli",
  bicycle_description: "Každý deň sa venuj fyzickej aktivite. Podľa možnosti choď do školy/práce pešo alebo na bicykli. Udrží ťa to v dobrej kondícii a je to lepšie pre životné prostredie.",
  scooter_or_motorbike: "Skútrom/motocyklom",
  scooter_or_motorbike_description: "V záujme čistejšieho životného prostredia sa snaž používať auto či motocykel menej často. Ak to nie je možné, skús car-pooling (spoločnú jazdu jedným autom).",
  car: "Autom",
  car_description: "V záujme čistejšieho životného prostredia sa snaž používať auto či motocykel menej často. Ak to nie je možné, skús car-pooling (spoločnú jazdu jedným autom).",
  public_transport: "Verejnou dopravou",
  public_transport_description: "Používaj verejnú dopravu a kombinuj ju s chôdzou a bicyklovaním namiesto jazdy autom. Daj si prestávku od šoférovania a pomôž životnému prostrediu.",
  salad_with_egg: "Šalát s vajíčkom",
  salad_with_egg_description: "Pridaj čerstvú zeleninu k svojim jedlám vždy, keď je to možné.",
  beef_steak: "Hovädzí rezeň",
  beef_steak_description: "Ak ješ mäso, pouvažuj nad obmedzením jeho príjmu, najmä pokiaľ ide o červené či spracované mäso. Je to prospešné pre tvoje zdravie, ako aj životné prostredie.",
  chicken: "Kura",
  chicken_description: "Ak ješ mäso, pouvažuj nad obmedzením jeho príjmu, najmä pokiaľ ide o červené či spracované mäso. Je to prospešné pre tvoje zdravie, ako aj životné prostredie.",
  lentil_soup: "Šošovicová polievka",
  lentil_soup_description: "Ako súčasť pestrej a prevažne rastlinnej stravy sa odporúča pravidelná konzumácia strukovín.",
  vegetable_burger: "Burger na báze zeleniny",
  vegetable_burger_description: "Burger na báze zeleniny môže byť vhodnou alternatívou mäsa. Daj si pozor, aby neobsahoval priveľa soli.",
  white_pasta: "Biele cestoviny",
  white_pasta_description: "Pri výbere cestovín, ryže a chleba uprednostni celozrnné produkty.",
  wholegrain_pasta: "Celozrnné cestoviny",
  wholegrain_pasta_description: "Pri výbere cestovín, ryže a chleba uprednostni celozrnné produkty.",
  white_rice: "Biela ryža",
  white_rice_description: "Pri výbere cestovín, ryže a chleba uprednostni celozrnné produkty.",
  wholegrain_rice: "Celozrnná ryža",
  wholegrain_rice_description: "Pri výbere cestovín, ryže a chleba uprednostni celozrnné produkty.",
  white_bread_bun: "Biela žemľa",
  white_bread_bun_description: "Pri výbere cestovín, ryže a chleba uprednostni celozrnné produkty.",
  no_salad: "Žiadny",
  no_salad_description: "",
  fresh_green_salad: "Čerstvý šalát z listovej zeleniny",
  fresh_green_salad_description: "Do svojich jedál pridaj farebnú sezónnu zeleninu. Čím väčšia pestrosť, tým lepšie.",
  french_fries: "Hranolčeky",
  french_fries_description: "Pred vyprážanými produktmi zo zemiakov sa uprednostňujú varené zemiaky.",
  boiled_broccoli: "Varená brokolica",
  boiled_broccoli_description: "Snaž sa jesť veľa zeleniny počas dňa. Vyberaj si lokálnu a sezónnu zeleninu.",
  bottled_water: "Fľašková voda",
  bottled_water_description: "Pi vodu z vodovodu a znížiš tak používanie plastov.",
  soft_drink: "Nealkoholický nápoj",
  soft_drink_description: "Sladené nápoje tvoria značnú časť nášho denného príjmu cukru – dala by sa namiesto nich vybrať voda?",
  beer_or_wine: "Pivo/víno",
  beer_or_wine_description: "Ak piješ akýkoľvek druh alkoholu, obmedz jeho množstvo. V záujme tvojho zdravia je lepšie alkoholu sa vyhýbať.",
  watching_tv: "Sledovanie televízie alebo hranie videohier",
  watching_tv_description: "Každý deň sa venuj fyzickej aktivite a snaž sa obmedziť čas trávený sedením. Snaž sa vo voľnom čase športovať, hrať sa s kamarátmi alebo pomáhať svojej komunite. Je to prospešné pre tvoje zdravie a spoločnosť. Netráv príliš veľa času pred obrazovkou.",
  doing_sports: "Športovanie",
  doing_sports_description: "Každý deň sa venuj fyzickej aktivite a snaž sa obmedziť čas trávený sedením. Snaž sa vo voľnom čase športovať, hrať sa s kamarátmi alebo pomáhať svojej komunite. Je to prospešné pre tvoje zdravie a spoločnosť. Netráv príliš veľa času pred obrazovkou.",
  playing_outside: "Hranie sa vonku",
  playing_outside_description: "Každý deň sa venuj fyzickej aktivite a snaž sa obmedziť čas trávený sedením. Snaž sa vo voľnom čase športovať, hrať sa s kamarátmi alebo pomáhať svojej komunite. Je to prospešné pre tvoje zdravie a spoločnosť. Netráv príliš veľa času pred obrazovkou.",
  charity_work: "Charitatívna práca s dospelými",
  charity_work_description: "Každý deň sa venuj fyzickej aktivite a snaž sa obmedziť čas trávený sedením. Snaž sa vo voľnom čase športovať, hrať sa s kamarátmi alebo pomáhať svojej komunite. Je to prospešné pre tvoje zdravie a spoločnosť. Netráv príliš veľa času pred obrazovkou.",
  carrots_tomatoes: "Mrkva/cherry rajčiny",
  carrots_tomatoes_description: "Uprednostňuj ovocie, zeleninu alebo oriešky. Obmedz občerstvenie a nápoje s vysokým obsahom cukrov, tuku a soli.",
  fresh_fruit: "Čerstvé ovocie",
  fresh_fruit_description: "Uprednostňuj ovocie, zeleninu alebo oriešky. Obmedz občerstvenie a nápoje s vysokým obsahom cukrov, tuku a soli.",
  chocolate_bar: "Čokoládová tyčinka",
  chocolate_bar_description: "Uprednostňuj ovocie, zeleninu alebo oriešky. Obmedz občerstvenie a nápoje s vysokým obsahom cukrov, tuku a soli.",
  chips: "Zemiakové lupienky",
  chips_description: "Uprednostňuj ovocie, zeleninu alebo oriešky. Obmedz občerstvenie a nápoje s vysokým obsahom cukrov, tuku a soli.",
  juice_drink: "Džús",
  juice_drink_description: "Pred džúsmi uprednostni čerstvé ovocie alebo čerstvo vylisované šťavy bez pridaného cukru.",
  supermarket_prompt: "Zvoľ si najviac 9 produktov.",
  raw_fish: "Surová ryba",
  raw_fish_description: "Pravidelná konzumácia rýb ti pomáha získať dôležité živiny. Vyskúšaj rôzne druhy.",
  raw_beef: "Surové hovädzie mäso",
  raw_beef_description: "Ak ješ mäso, pouvažuj nad znížením  jeho príjmu,  najmä červeného alebo spracovaného mäsa. Je to lepšie pre tvoje zdravie a životné prostredie.",
  raw_chicken: "Surové kuracie mäso",
  raw_chicken_description: "Ak ješ mäso, pouvažuj nad obmedzením jeho príjmu, najmä pokiaľ ide o červené či spracované mäso. Je to prospešné pre tvoje zdravie, ako aj životné prostredie.",
  whole_salad: "Celá zelenina na šalát - napr. hlávkový šalát",
  whole_salad_description: "Uprednostni čerstvé ovocie a zeleninu, najmä sezónne a lokálne produkty.",
  packaged_salad: "Balený šalát",
  packaged_salad_description: "Uprednostni čerstvé ovocie a zeleninu, najmä sezónne a lokálne produkty.",
  seasonal_fruit: "Sezónne lokálne ovocie",
  seasonal_fruit_description: "Uprednostni čerstvé ovocie a zeleninu, najmä sezónne a lokálne produkty.",
  exotic_fruit: "Exotické ovocie",
  exotic_fruit_description: "Uprednostni čerstvé ovocie a zeleninu, najmä sezónne a lokálne produkty.",
  ham: "Šunka",
  ham_description: "Spracované mäso obsahuje veľké množstvo tuku a soli. Ak ješ spracované mäso, snaž sa obmedziť jeho konzumáciu.",
  tofu: "Tofu",
  tofu_description: "Vyskúšaj nové rastlinné produkty a nové recepty.",
  hummus: "Hummus",
  hummus_description: "Hummus je vhodnou alternatívou, ktorá ti pomôže zvýšiť príjem strukovín.",
  precooked_pizza: "Predpečená pizza",
  precooked_pizza_description: "Polotovary môžu obsahovať veľké množstvá soli alebo tuku. Uprednostňuj produkty, ktoré nie sú polotovarmi. Vďaka tomu budeš mať kontrolu nad množstvom soli, cukrov a tukov vo svojich jedlách.",
  precooked_fries: "Predsmažené hranolčeky",
  precooked_fries_description: "Polotovary môžu obsahovať veľké množstvá soli alebo tuku. Uprednostňuj produkty, ktoré nie sú polotovarmi. Vďaka tomu budeš mať kontrolu nad množstvom soli, cukrov a tukov vo svojich jedlách.",
  food_delivery: "Donáška jedla",
  food_delivery_description: "",
  cooking_at_home: "Varenie doma",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, hranolčeky a nealkoholický nápoj",
  cheeseburger_description: "Tento druh jedál nekonzumuj. Obsahujú veľmi veľa tuku a soli. Ako nápoj si daj vodu.",
  pizza_and_soft_drink: "Pizza a nealkoholický nápoj",
  pizza_and_soft_drink_description: "Takéto jedlá majú vysoký obsah tuku, soli a cukrov. Vyberaj si „jednoduché“ pizze s menším množstvom ingrediencií alebo väčším množstvom zeleniny a podľa možnosti celozrnné cesto na pizzu. Ako nápoj si daj vodu.",
  noodles_with_vegetables: "Rezance so zeleninou",
  noodles_with_vegetables_description: "Sú dobrou voľbou na zvýšenie konzumácie zeleniny. Aby bolo tvoje jedlo kompletné, pridaj nejaké strukoviny.",
  mushroom_risoto: "Hubové rizoto",
  mushroom_risoto_description: "Huby sú dobrou vegetariánskou voľbou. Daj si pozor na pridanie nadmerného množstva syra. Aby bolo toto jedlo kompletné, môžeš si k nemu dať šalát.",
  precooked_meal: "Polotovar - kuracie nugety",
  precooked_meal_description: "Polotovary môžu obsahovať veľké množstvá soli, tuku i cukrov. Uprednostňuj produkty, ktoré nie sú polotovarmi. Vďaka tomu budeš mať lepšiu kontrolu nad množstvom týchto živín. Na dochutenie použi namiesto soli bylinky a koreniny.",
  frozen_pizza: "Mrazená pizza",
  frozen_pizza_description: "Polotovary môžu obsahovať veľké množstvá soli, tuku i cukrov. Uprednostňuj produkty, ktoré nie sú polotovarmi. Vďaka tomu budeš mať lepšiu kontrolu nad množstvom týchto živín. Na dochutenie použi namiesto soli bylinky a koreniny.",
  rice_and_salad: "Vlastnoručne pripravené jedlo, ryža a zeleninový šalát",
  rice_and_salad_description: "Polotovary môžu obsahovať veľké množstvá soli, tuku i cukrov. Uprednostňuj produkty, ktoré nie sú polotovarmi. Vďaka tomu budeš mať lepšiu kontrolu nad množstvom týchto živín. Na dochutenie použi namiesto soli bylinky a koreniny.",
  ham_sandwich_and_soft_drink: "Šunkový sendvič a nealkoholický nápoj",
  ham_sandwich_and_soft_drink_description: "Spracované mäso obsahuje veľké množstvo tuku a soli. Ak ješ spracované mäso, snaž sa obmedziť jeho konzumáciu. Vyhýbaj sa aj sladeným nápojom a nealkoholickým nápojom. Daj si namiesto toho vodu z vodovodu.",
  wholegrain_pasta_with_sauce: "Celozrnné cestoviny so zeleninovou omáčkou",
  wholegrain_pasta_with_sauce_description: "Snaž sa jesť celozrnné cestoviny.",
  hnw_title_adult: "Zdravý nový svet",
  hnw_description_1_adult: "Naše voľby formujú našu budúcnosť. Ak budeme všetci jesť a žiť ako ty v tejto hre, naša budúcnosť môže byť zdravšia a ľudia sa budú starať o seba navzájom i o životné prostredie.",
  hnw_description_2_adult: "Vedci sústavne tvrdia, že v záujme udržania nášho zdravia a zdravia našej planéty by sme mali jesť pestrú stravu: bohatú na obilniny, strukoviny, čerstvú zeleninu a čerstvé ovocie. Vyhýbaj sa malým spracovaným potravinám, mäsu a občerstveniu s vysokým obsahom soli, cukrov a tuku. Fyzická aktivita, chôdza, bicyklovanie či cestovanie verejnou dopravou a obmedzenie používania auta má veľké množstvo výhod pre všetkých.",
  hnw_description_3_adult: "K posilneniu zdravia a zlepšeniu životného prostredia môžu prispieť jednoduché veci: čisté ovzdušie a voda, bezpečné potraviny, úrodné pôdy a zelené plochy, rešpektovanie prírody a všetkých ľudí. Mysli na to aj mimo tejto hry. Vydaj sa na túto cestu spolu so svojimi kamarátmi a s rodinou.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Chceš si zahrať znovu?",
  hnw_title_child: "Naše voľby formujú našu budúcnosť",
  hnw_description_1_child: "Ak budeme všetci jesť a žiť ako ty v tejto hre, naša budúcnosť môže byť zdravšia.",
  hnw_description_2_child: "Môžeš urobiť tieto kroky:\n- Uisti sa, že ješ pestrú stravu v záujme svojho zdravia a zdravia našej planéty.\n- Vyberaj si obilniny, strukoviny, čerstvú zeleninu a čerstvé ovocie.\n- Obmedz konzumáciu spracovaných potravín, mäsa a občerstvenia s vysokým obsahom soli, cukrov a tuku.\n- Venuj sa fyzickým aktivitám, choď pešo, jazdi na bicykli a cestuj verejnou dopravou.\n- Obmedz jazdu autom.",
  hnw_description_3_child: "Tieto jednoduché voľby môžu posilniť tvoje zdravie a zaistiť starostlivosť o životné prostredie a všetkých ľudí.",
  hnw_description_4_child: "Mysli na to aj mimo tejto hry.",
  hnw_description_5_child: "Vydaj sa na túto cestu spolu so svojimi kamarátmi a s rodinou.",
  hnw_description_6_child: "Chceš si zahrať znovu?",
  etl_title_adult: "Stravovanie v záujme prežitia",
  etl_description_1_adult: "Naše voľby formujú našu budúcnosť. Možno si to neuvedomuješ, ale tvoj životný štýl môže mať vplyv na zdravie našej planéty.",
  etl_description_2_adult: "Vedci sústavne tvrdia, že v záujme udržania nášho zdravia a zdravia našej planéty by sme mali jesť pestrú stravu: bohatú na obilniny, strukoviny, čerstvú zeleninu a čerstvé ovocie. Vyhýbaj sa malým spracovaným potravinám, mäsu a občerstveniu s vysokým obsahom soli, cukrov a tuku. Fyzická aktivita, chôdza, bicyklovanie či cestovanie verejnou dopravou a obmedzenie používania auta má veľké množstvo výhod pre všetkých.",
  etl_description_3_adult: "K posilneniu zdravia a zlepšeniu životného prostredia môžu prispieť jednoduché veci: čisté ovzdušie a voda, bezpečné potraviny, úrodné pôdy a zelené plochy, rešpektovanie prírody a všetkých ľudí.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Chceš si zahrať znovu?",
  etl_title_child: "Naše voľby formujú našu budúcnosť",
  etl_description_1_child: "Ak všetci zlepšíme spôsob, akým sa stravujeme a žijeme, naša budúcnosť môže byť zdravšia.",
  etl_description_2_child: "Môžeš urobiť tieto kroky:\n- Uisti sa, že ješ pestrú stravu v záujme svojho zdravia a zdravia našej planéty.\n- Vyberaj si obilniny, strukoviny, čerstvú zeleninu a čerstvé ovocie.\n- Obmedz konzumáciu spracovaných potravín, mäsa a občerstvenia s vysokým obsahom soli, cukrov a tuku.\n- Venuj sa fyzickým aktivitám, choď pešo, jazdi na bicykli a cestuj verejnou dopravou.\n- Obmedz jazdu autom.",
  etl_description_3_child: "Tieto jednoduché voľby môžu posilniť tvoje zdravie a zaistiť starostlivosť o životné prostredie a všetkých ľudí.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Chceš si zahrať znovu?",
  share: "Zdieľať",
  replay: "Hrať znovu",
  its_up_to_you: "Je to na tebe",
  badge_action_1: "Skryté prekvapenie je odhalené!",
  badge_action_2: "Vyhrávaš odznak %{badge}.",
  fridgeDoor: "Dvere do chladničky",
  light: "Svetlo",
  bottle: "Fľaša",
  reusable_bag: "Taška na opakované použitie",
  plastic_bag: "Plastová taška",
  recycle_bin: "Kôš na triedenie odpadkov",
  share_text_subject: "Je to na tebe",
  share_text_body: "Hral/a som hru 2050 – Je to na tebe! Chceš vedieť, ako vyzerá ten tvoj? #ExperienceEUScience\nHraj teraz %{shareUrl}\nTúto hru vyvinulo Spoločné výskumné centrum, útvar Európskej komisie zodpovedný za vedu a znalosti.\nZisti viac https://joint-research-centre.ec.europa.eu/index_sk?etrans=sk"
};
export default i18n;
