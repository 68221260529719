import "scenes/Transportation.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, { characterPropTypes } from "elements/character/Character.js";
import { success, loFiClick } from "common/sounds.js";
import { ReactComponent as TrashSvg } from "elements/items/trash.svg";
import { allBadges, getAvailableBadgesFor } from "elements/badges/badges.js";
import Badges from "modals/Badges.js";
import BadgeAwarded from "modals/BadgeAwarded.js";
import AvailableItemsAndSelections from "modals/AvailableItemsAndSelections.js";
import CharacterResponsiveCustomModal from "elements/character/CharacterResponsiveCustomModal.js";

const hiddenItemBadgeAwarded = "clean-street-wizard";

const availableItems = ["walk", "bicycle", "scooter-or-motorbike", "car", "public-transport"];

const Transportation = ({
  t,
  character,
  addSelection,
  addBadge,
  badges,
  restartScene,
  restartGame,
  goToNextScene,
  battery
}) => {
  const [hiddenItemFound, setHiddenItemFound] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModal, setOpenModal] = useState();
  const [sceneCompleted, setSceneCompleted] = useState(false);

  const hiddenItemClicked = () => {
    if (hiddenItemFound) return;
    setOpenModal("badgeAwarded");
    setHiddenItemFound(true);
    addBadge(hiddenItemBadgeAwarded);
    success();
  };

  const itemClicked = item => {
    if (sceneCompleted) return;
    setSelectedItem(item);
    addSelection(item);
    loFiClick();
    setSceneCompleted(true);
  };

  return (
    <div className="Transportation scene">
      <div className="primary-elements">
        <CharacterResponsiveCustomModal t={t} characterElement={<Character {...character} />} />

        <button onClick={hiddenItemClicked} className="trash" disabled={hiddenItemFound} title={t("trash")}>
          <TrashSvg />
        </button>

        <div className={`item thrash-can${hiddenItemFound ? " full" : ""}`}></div>

        <div className="road">
          {availableItems.map(item => (
            <button
              key={item}
              onClick={() => itemClicked(item)}
              className={`item ${item}${selectedItem === item ? " selected" : ""}`}
              title={t(item.replace(/-/g, "_"))}
              disabled={sceneCompleted}
            >
              <label>{t(item.replace(/-/g, "_"))}</label>
            </button>
          ))}
        </div>
      </div>

      <Footer
        t={t}
        openAvailableItemsAndSelectionsModal={() => setOpenModal("availableItemsAndSelections")}
        badgesCount={badges.length}
        openBadgesModal={() => setOpenModal("badges")}
        restartScene={sceneCompleted ? restartScene : undefined}
        restartGame={restartGame}
        goToNextScene={sceneCompleted ? goToNextScene : undefined}
      />
      {battery}
      <BadgeAwarded
        t={t}
        badge={hiddenItemBadgeAwarded}
        isOpen={openModal === "badgeAwarded"}
        closeModal={() => setOpenModal(undefined)}
      />
      <Badges
        t={t}
        badges={badges}
        availableBadges={getAvailableBadgesFor(character.type)}
        isOpen={openModal === "badges"}
        closeModal={() => setOpenModal(undefined)}
      />
      <AvailableItemsAndSelections
        t={t}
        availableItems={availableItems}
        selections={selectedItem === undefined ? undefined : [selectedItem]} // When the user has selected an item, show that one as the selections.
        isOpen={openModal === "availableItemsAndSelections"}
        closeModal={() => setOpenModal(undefined)}
      />
    </div>
  );
};

Transportation.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  addSelection: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  addBadge: PropTypes.func.isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired,
  battery: PropTypes.element.isRequired
});

export default Transportation;
