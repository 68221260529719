const i18n = {
  title: "2050 Jogo da Sociedade Saudável",
  start: "Iniciar",
  select_language: "Selecionar língua",
  adult: "Adulto",
  child: "Criança",
  fullscreen: "Ecrã inteiro",
  restart_scene: "Reiniciar a cena",
  restart_game: "Reiniciar o jogo",
  previous: "Anterior",
  next: "Seguinte",
  close: "Fechar",
  toggle_sound: "Ativar/desativar o som",
  character_selection_title: "Seleciona uma personagem",
  character_selection_body: "As nossas escolhas afetam o nosso futuro. Neste jogo, simulamos situações do dia a dia. Descobre quais são as consequências das tuas escolhas. Não te esqueças de procurar os segredos escondidos para ganhar crachás. Começa por criar a tua personagem.",
  character_set_up_title: "Constrói a tua personagem",
  select_me: "Seleciona-me",
  breakfast_title: "O que come ao pequeno-almoço?",
  your_badges: "Crachás",
  badge_water_conservation_master_title: "Mestre",
  badge_water_conservation_master_description: "Parabéns! És mestre na poupança de um recurso escasso. A água é essencial à vida.",
  badge_clean_street_wizard_title: "Mago(a)",
  badge_clean_street_wizard_description: "Parabéns! És mago/a em ajudar a tornar o nosso ambiente mais limpo ao recolher o lixo e ao colocá-lo no caixote do lixo.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Parabéns! És ninja em poupança de energia. Poupar energia nos nossos eletrodomésticos é uma excelente forma de ajudar a reduzir o consumo de energia.",
  badge_energy_saving_expert_title: "Perito(a)",
  badge_energy_saving_expert_description: "Parabéns! És um(a) perito(a) em poupança de energia. Continua a encontrar formas de reduzir o teu consumo de energia.",
  badge_low_waste_champion_title: "Campeão(ã)",
  badge_low_waste_champion_description: "Parabéns! És campeão/ã na redução dos teus resíduos e em ajudar o ambiente ao reduzires a utilização de plástico.",
  badge_responsible_shopper_title: "Profissional",
  badge_responsible_shopper_description: "Parabéns! És profissional em escolhas saudáveis em prol do ambiente. Continua a utilizar a menor quantidade de plástico possível usando sacos reutilizáveis.",
  badge_recycling_captain_title: "Estrela",
  badge_recycling_captain_description: "Parabéns! És genial na reciclagem. É importante reciclar e reutilizar os materiais, em prol de um ambiente saudável.",
  faucet: "Torneira",
  transportation_title_adult: "Como vais para o trabalho?",
  transportation_title_child: "Como vais para a escola?",
  trash: "Lixo",
  no_drink: "Nenhuma bebida",
  no_drink_description: "",
  tap_water: "Água da torneira",
  tap_water_description: "Procura manter-te hidratado/a bebendo, pelo menos, 1,5 a 2 litros de água por dia.",
  milk: "Leite",
  milk_description: "Opta por beber leite natural não açucarado, de preferência magro.",
  freshly_made_juice: "Sumo de fruta espremido",
  freshly_made_juice_description: "Se gostas de sumos, opta por sumos de fruta acabada de espremer. Não precisas de adicionar açúcar. Experimente comer fruta fresca.",
  coffee_or_tea: "Café/chá",
  coffee_or_tea_description: "Se beberes chá ou café, procura beber sem adicionares açúcar.",
  plant_based_drink: "Bebida vegetal",
  plant_based_drink_description: "Experimente bebidas vegetais, sem açúcares adicionados e, se possível, escolha as que contenham vitaminas e minerais.",
  whole_grain_cereals: "Cereais integrais",
  whole_grain_cereals_description: "Opta por cereais integrais sem açúcares adicionados.",
  choco_balls: "Cereais em bolinhas de chocolate",
  choco_balls_description: "Os cereais de pequeno-almoço podem representar uma parte considerável da nossa ingestão diária de açúcares. Podes optar por cereais com menos açúcares adicionados? Já agora, escolhe  também os que têm um maior teor de fibras.",
  white_bread_or_toast: "Pão branco/tostas de pão branco",
  white_bread_or_toast_description: "Opta por pão integral.",
  wholemeal_bread_or_toast: "Pão integral/tostas de pão integral",
  wholemeal_bread_or_toast_description: "Opta por pão integral.",
  biscuits: "Bolachas",
  biscuits_description: "As bolachas, que são muito calóricas, podem representar uma parte considerável da nossa ingestão diária de açúcares e de gorduras. É melhor reduzir o seu consumo. Opta por bolachas com um baixo teor de gorduras e poucos açúcares. Já agora, opta também pelos que têm um maior teor de fibras.",
  no_topping: "Mais nada",
  no_topping_description: "",
  yogurt: "Iogurte",
  yogurt_description: "Experimenta iogurte natural magro sem açúcares adicionados.",
  honey: "Mel",
  honey_description: "Escolhe iogurte natural sem adicionar açúcar ou compotas. Opta por adicionar fruta ou cereais integrais não açucarados.",
  jam: "Compota",
  jam_description: "Escolhe iogurte natural sem adicionar açúcar ou compotas. Opta por adicionar fruta ou cereais integrais não açucarados.",
  fruit: "Fruta",
  fruit_description: "É uma excelente escolha começar o dia com fruta. Procura escolher fruta da época. Se adicionares frutos secos, opta pelos que não têm sal nem açúcares adicionados.",
  butter: "Manteiga",
  butter_description: "A manteiga contém uma grande quantidade de gordura e pode ter bastante sal. Tenta não comer com demasiada frequência e opta por manteiga sem sal.",
  chocolate_cream: "Creme de chocolate",
  chocolate_cream_description: "O creme de chocolate tem muitos açúcares e gorduras saturadas. Procura não o ingerir com muita frequência.",
  ham_or_bacon: "Presunto / toucinho",
  ham_or_bacon_description: "A carne processada tem teores elevados de gorduras e de sal. Evita comeres grandes quantidades ou com muita frequência.",
  cheese: "Queijo",
  cheese_description: "O queijo contém uma grande quantidade de sal e de gorduras saturadas. Tenta não comer com demasiada frequência e consumir em pequenas quantidades.",
  avocado_and_tomato: "Abacate e tomate",
  avocado_and_tomato_description: "É uma excelente escolha começar o dia com produtos hortícolas. Procura comer bastante fruta e produtos hortícolas durante o dia, optando pelos de produção local e da época.",
  battery: "Pilha",
  battery_description: "Segue com atenção a evolução da pilha, que indica o impacto das tuas escolhas.",
  lunch_title: "O que gostarias de almoçar?",
  breaktime_title: "Tarde livre — tempo livre!",
  snacking_title: "Apetece-te comer algo ligeiro?",
  supermarket_title: "O que comprar?",
  dinner_selection_title: "Quais são os teus planos para o jantar?",
  dinner_cooking_title: "O que é o jantar?",
  dinner_delivery_title: "O que é o jantar?",
  your_available_items: "As tuas opções",
  your_selections: "As tuas seleções",
  walk: "A pé",
  walk_description: "Procura ser fisicamente ativo/a na tua vida quotidiana. Se possível, vai a pé ou de bicicleta para a escola ou trabalho. Isso ajuda a manter-te em forma e é melhor para o ambiente.",
  bicycle: "De bicicleta",
  bicycle_description: "Procura ser fisicamente ativo/a na tua vida quotidiana. Se possível, vai a pé ou de bicicleta para a escola ou trabalho. Isso ajuda a manter-te em forma e é melhor para o ambiente.",
  scooter_or_motorbike: "Mota",
  scooter_or_motorbike_description: "Para um ambiente mais limpo, tenta andar menos de automóvel ou de mota. Se isso não for possível, recorre à boleia organizada.",
  car: "Automóvel",
  car_description: "Para um ambiente mais limpo, tenta andar menos de automóvel ou de mota. Se isso não for possível, recorre à boleia organizada.",
  public_transport: "Transportes públicos",
  public_transport_description: "Utiliza os transportes públicos e combina-os com percursos a pé ou de bicicleta, em vez de usar o automóvel. Faz uma pausa na condução e contribui para protejer o ambiente.",
  salad_with_egg: "Salada com ovo",
  salad_with_egg_description: "Sempre que possível, inclui produtos hortícolas frescos nas suas refeições.",
  beef_steak: "Bife de vaca",
  beef_steak_description: "Se comes carne, pensa em reduzir o seu consumo, especialmente no caso de carnes vermelhas ou processadas. É melhor para a saúde e para o ambiente.",
  chicken: "Frango",
  chicken_description: "Se comes carne, pensa em reduzir o seu consumo, especialmente no caso de carnes vermelhas ou processadas. É melhor para a saúde e para o ambiente.",
  lentil_soup: "Sopa de lentilhas",
  lentil_soup_description: "Recomenda-se a ingestão regular de leguminosas como parte de uma alimentação variada e maioritariamente vegetal.",
  vegetable_burger: "Hambúrguer vegetariano",
  vegetable_burger_description: "Um hambúrguer vegetariano pode ser uma boa alternativa à carne. Certifica-te de que não contém muito sal.",
  white_pasta: "Massa branca",
  white_pasta_description: "Quando escolheres massa, arroz ou pão, opta por produtos integrais.",
  wholegrain_pasta: "Massa integral",
  wholegrain_pasta_description: "Quando escolheres massa, arroz ou pão, opta por produtos integrais.",
  white_rice: "Arroz branco",
  white_rice_description: "Quando escolheres massa, arroz ou pão, opta por produtos integrais.",
  wholegrain_rice: "Arroz integral",
  wholegrain_rice_description: "Quando escolheres massa, arroz ou pão, opta por produtos integrais.",
  white_bread_bun: "Pão branco",
  white_bread_bun_description: "Quando escolheres massa, arroz ou pão, opta por produtos integrais.",
  no_salad: "Nada",
  no_salad_description: "",
  fresh_green_salad: "Salada de vegetais frescos",
  fresh_green_salad_description: "Adiciona alguns produtos hortícolas da época de várias cores às tuas refeições. Quanto maior a variedade, melhor.",
  french_fries: "Batatas fritas",
  french_fries_description: "Prefere as batatas cozidas aos produtos de batata fritos.",
  boiled_broccoli: "Brócolos cozidos",
  boiled_broccoli_description: "Procura comer bastantes produtos hortícolas durante o dia. Opta por produtos hortícolas locais e da época.",
  bottled_water: "Água engarrafada",
  bottled_water_description: "Bebe água da torneira para reduzir a utilização de plástico.",
  soft_drink: "Refrigerante",
  soft_drink_description: "As bebidas açucaradas podem representar parte considerável da nossa ingestão diária de açúcares. Não podes beber antes água?",
  beer_or_wine: "Cerveja/vinho",
  beer_or_wine_description: "Se beberes, procura limitar o consumo de bebidas alcoólicas. É melhor para a saúde evitar este tipo de bebidas.",
  watching_tv: "Ver televisão ou jogar videojogos",
  watching_tv_description: "Procura ser fisicamente ativo/a todos os dias e reduzir o tempo que passas sentado/a. Durante o teu tempo livre, procura praticar desporto, brincar com os amigos ou ajudar a comunidade. É bom para a saúde e para a sociedade. Evita passar muito tempo em frente do ecrã.",
  doing_sports: "Praticar desporto",
  doing_sports_description: "Procura ser fisicamente ativo/a todos os dias e reduzir o tempo que passas sentado/a. Durante o teu tempo livre, procura praticar desporto, brincar com os amigos ou ajudar a comunidade. É bom para a saúde e para a sociedade. Evita passar muito tempo em frente do ecrã.",
  playing_outside: "Brincar ao ar livre",
  playing_outside_description: "Procura ser fisicamente ativo/a todos os dias e reduzir o tempo que passas sentado/a. Durante o teu tempo livre, procura praticar desporto, brincar com os amigos ou ajudar a comunidade. É bom para a saúde e para a sociedade. Evita passar muito tempo em frente do ecrã.",
  charity_work: "Trabalho voluntário com adultos",
  charity_work_description: "Procura ser fisicamente ativo/a todos os dias e reduzir o tempo que passas sentado/a. Durante o teu tempo livre, procura praticar desporto, brincar com os amigos ou ajudar a comunidade. É bom para a saúde e para a sociedade. Evita passar muito tempo em frente do ecrã.",
  carrots_tomatoes: "Cenouras / tomates-cereja",
  carrots_tomatoes_description: "Opta por fruta, produtos hortícolas ou frutos secos. Limita o número de refeições ligeiras e de bebidas com muitos açúcares, gorduras e sal.",
  fresh_fruit: "Fruta fresca",
  fresh_fruit_description: "Opta por fruta, produtos hortícolas ou frutos secos. Limita o número de refeições ligeiras e de bebidas com muitos açúcares, gorduras e sal.",
  chocolate_bar: "Chocolate",
  chocolate_bar_description: "Opta por fruta, produtos hortícolas ou frutos secos. Limita o número de refeições ligeiras e de bebidas com muitos açúcares, gorduras e sal.",
  chips: "Batatas fritas",
  chips_description: "Opta por fruta, produtos hortícolas ou frutos secos. Limita o número de refeições ligeiras e de bebidas com muitos açúcares, gorduras e sal.",
  juice_drink: "Sumo de fruta espremido",
  juice_drink_description: "Opta por fruta fresca ou sumos de fruta acabados de espremer sem açúcares adicionados, em vez de sumos comprados já feitos.",
  supermarket_prompt: "Escolhe até 9 produtos",
  raw_fish: "Peixe",
  raw_fish_description: "O consumo regular de peixe fornece nutrientes importantes. Procura comer vários tipos de peixe.",
  raw_beef: "Carne de vaca",
  raw_beef_description: "Se comes carne, pensa em reduzir o seu consumo, especialmente no caso de carnes vermelhas ou processadas. É melhor para a saúde e para o ambiente.",
  raw_chicken: "Frango cru",
  raw_chicken_description: "Se comes carne, pensa em reduzir o seu consumo, especialmente no caso de carnes vermelhas ou processadas. É melhor para a saúde e para o ambiente.",
  whole_salad: "Salada de um único produto hortícola por exemplo, alface",
  whole_salad_description: "Opta por frutos e produtos hortícolas frescos, especialmente da época e locais.",
  packaged_salad: "Embalagem de salada",
  packaged_salad_description: "Opta por frutos e produtos hortícolas frescos, especialmente da época e locais.",
  seasonal_fruit: "Fruta da época local",
  seasonal_fruit_description: "Opta por frutos e produtos hortícolas frescos, especialmente da época e locais.",
  exotic_fruit: "Frutos exóticos",
  exotic_fruit_description: "Opte por frutos e produtos hortícolas frescos, especialmente da época e locais.",
  ham: "Presunto",
  ham_description: "A carne processada tem teores elevados de gorduras e de sal. Procura não comer grandes quantidades nem com frequência.",
  tofu: "Tofu",
  tofu_description: "Procura descobrir novos produtos vegetais e novas receitas.",
  hummus: "Húmus",
  hummus_description: "O húmus é uma boa alternativa que ajuda a aumentar a quantidade de leguminosas ingeridas.",
  precooked_pizza: "Pizza pré cozinhada",
  precooked_pizza_description: "As refeições pré-cozinhadas podem ter muito sal ou muitas gorduras. Prefere produtos que não sejam pré-cozinhados. Desta forma, podes controlar melhor a quantidade de sal, de açúcar e de gordura presente nas tuas refeições.",
  precooked_fries: "Batatas fritas pré-cozinhadas",
  precooked_fries_description: "As refeições pré-cozinhadas podem ter muito sal ou muita gordura. Opta por produtos que não sejam pré-cozinhados. Desta forma, podes controlar a quantidade de sal, de açúcares e de gordura presente nas tuas refeições.",
  food_delivery: "Comida para levar ou entregue ao domicílio",
  food_delivery_description: "",
  cooking_at_home: "Cozinhar em casa",
  cooking_at_home_description: "",
  cheeseburger: "Hambúrguer com queijo, batatas fritas e refrigerante",
  cheeseburger_description: "Evite consumir este tipo de refeições. São ricas em gordura e sal. E, para acompanhar, bebe água.",
  pizza_and_soft_drink: "Pizza e refrigerante",
  pizza_and_soft_drink_description: "Têm teores elevados de gordura, sal e açúcar. Escolhe pizzas «simples», com menos ingredientes ou com mais produtos hortícolas, e, se possível, opta por massa de pizza integral. E, para acompanhar, bebe água.",
  noodles_with_vegetables: "Massas acompanhadas de produtos hortícolas",
  noodles_with_vegetables_description: "Boa opção para aumentar o teu consumo de produtos hortícolas. Junta algumas leguminosas para completar a refeição.",
  mushroom_risoto: "Risoto de cogumelos",
  mushroom_risoto_description: "Os cogumelos são uma boa opção vegetariana. Não juntes demasiado queijo. Podes completar a refeição com uma salada.",
  precooked_meal: "Refeição pronta pré cozinhada - nuggets de frango",
  precooked_meal_description: "As refeições pré-cozinhadas podem ter teores elevados de sal, gordura ou mesmo de açúcar. Prefere produtos que não sejam pré-cozinhados para poderes controlar melhor a quantidade destes nutrientes. Utiliza ervas aromáticas e especiarias, em vez de sal, para dar mais sabor.",
  frozen_pizza: "Piza congelada",
  frozen_pizza_description: "As refeições pré-cozinhadas podem ter teores elevados de sal, gordura ou mesmo de açúcar. Prefere produtos que não sejam pré-cozinhados para poderes controlar melhor a quantidade destes nutrientes. Utiliza ervas aromáticas e especiarias, em vez de sal, para dar mais sabor.",
  rice_and_salad: "Refeição de arroz cozinhado pela própria pessoa e salada de vegetais",
  rice_and_salad_description: "As refeições pré-cozinhadas podem ter teores elevados de sal, gordura ou mesmo de açúcar. Prefere produtos que não sejam pré-cozinhados para poderes controlar melhor a quantidade destes nutrientes. Utiliza ervas aromáticas e especiarias, em vez de sal, para dar mais sabor.",
  ham_sandwich_and_soft_drink: "Sanduíche de presunto e refrigerante",
  ham_sandwich_and_soft_drink_description: "A carne processada tem muita gordura e muito sal. Procura não comer grandes quantidades nem com frequência. Além disso, evita refrigerantes e outras bebidas açucaradas. Em vez disso, beba água da torneira.",
  wholegrain_pasta_with_sauce: "Massa integral com molho vegetal",
  wholegrain_pasta_with_sauce_description: "Experimenta massa integral.",
  hnw_title_adult: "Saudável mundo novo",
  hnw_description_1_adult: "As nossas escolhas moldam o nosso futuro. Se todos comermos e vivermos como fizeste neste jogo, o nosso futuro pode ser mais saudável, um futuro em que as pessoas se preocupam umas com as outras e com o ambiente.",
  hnw_description_2_adult: "Os cientistas estão de acordo em que, para preservarmos a nossa saúde e a saúde do planeta, devemos ter uma alimentação variada, que seja rica em cereais, leguminosas e produtos hortícolas e fruta frescos, e evitar a ingestão de carne, refeições ligeiras e outros alimentos minimamente processados com teores elevados de sal, açúcar e gordura. Todos temos a ganhar se formos fisicamente ativos, caminharmos, andarmos de bicicleta ou utilizarmos os transportes públicos e reduzirmos a utilização do automóvel.",
  hnw_description_3_adult: "Há coisas simples que podem reforçar a saúde e melhorar o ambiente: ar e água limpos, alimentos seguros, solos férteis e espaços verdes, o respeito pela natureza e por todas as pessoas. Pensa em todos estes aspetos também fora do âmbito deste jogo. Leva os teus amigos e família nesta viagem.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Gostavas de voltar a jogar?",
  hnw_title_child: "As nossas escolhas moldam o nosso futuro",
  hnw_description_1_child: "Se todos comermos e vivermos como o fizeste no jogo, podemos ter um futuro mais saudável.",
  hnw_description_2_child: "Eis como podes contribuir para isso:\n- certifica-te de que tens uma alimentação variada, para preservar a nossa saúde e a saúde do planeta,\n- opta por cereais, leguminosas e produtos hortícolas e fruta frescos, \n- limita o consumo de carne, refeições ligeiras e outros alimentos processados com teores elevados de sal, açúcares e gorduras,\n- sê fisicamente ativo(a), caminha, anda de bicicleta, utiliza os transportes públicos,\n- reduz a utilização do automóvel.",
  hnw_description_3_child: "Estas escolhas simples podem reforçar a tua saúde e garantir que cuidamos do ambiente e de todas as pessoas.",
  hnw_description_4_child: "Pensa em todos estes aspetos também fora do âmbito deste jogo.",
  hnw_description_5_child: "Leva os teus amigos e a tua família nesta viagem.",
  hnw_description_6_child: "Gostavas de voltar a jogar?",
  etl_title_adult: "Comer para viver",
  etl_description_1_adult: "As nossas escolhas moldam o nosso futuro. Podes não o saber, mas o teu estilo de vida pode afetar a saúde do nosso planeta.",
  etl_description_2_adult: "Os cientistas estão de acordo em que, para preservarmos a nossa saúde e a saúde do planeta, devemos ter uma alimentação variada, que seja rica em cereais, leguminosas e produtos hortícolas e fruta frescos, e evitar a ingestão de carne, refeições ligeiras e outros alimentos minimamente processados com teores elevados de sal, açúcar e gordura. Todos temos a ganhar se formos fisicamente ativos, caminharmos, andarmos de bicicleta ou utilizarmos os transportes públicos e reduzirmos a utilização do automóvel.",
  etl_description_3_adult: "Há coisas simples que podem reforçar a tua saúde e melhorar o ambiente: ar e água limpos, alimentos seguros, solos férteis e espaços verdes, o respeito da natureza e de todas as pessoas.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Gostavas de voltar a jogar?",
  etl_title_child: "As nossas escolhas moldam o nosso futuro",
  etl_description_1_child: "Se todos melhorarmos a nossa forma de comer e de viver, podemos ter um futuro mais saudável.",
  etl_description_2_child: "Eis como podes contribuir para isso:\n- certifica-te de que tens uma alimentação variada, para preservar a nossa saúde e a saúde do planeta,\n- opta por cereais, leguminosas e produtos hortícolas e fruta frescos, \n- limita o consumo de carne, refeições ligeiras e outros alimentos processados com teores elevados de sal, açúcares e gorduras,\n- sê fisicamente ativo(a), caminha, anda de bicicleta, utiliza os transportes públicos,\n- reduz a utilização do automóvel.",
  etl_description_3_child: "Estas escolhas simples podem reforçar a tua saúde e garantir que cuidamos do ambiente e de todas as pessoas.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Gostavas de voltar a jogar?",
  share: "Partilhar",
  replay: "Voltar a jogar",
  its_up_to_you: "Depende de ti",
  badge_action_1: "Revelado o segredo!",
  badge_action_2: "Ganhaste o crachá %{badge}",
  fridgeDoor: "Porta do frigorífico",
  light: "Luz",
  bottle: "Garrafa",
  reusable_bag: "Saco reutilizável",
  plastic_bag: "Saco de plástico",
  recycle_bin: "Caixote de lixo para reciclagem",
  share_text_subject: "Depende de ti",
  share_text_body: "Joguei 2050 – Depende de ti! Gostavas de saber como é o teu? #ExperienceEUScience\nÉ a tua vez de jogar %{shareUrl}\nEste jogo foi criado pelo Centro Comum de Investigação, o serviço científico e de conhecimento da Comissão Europeia. Sabe mais https://joint-research-centre.ec.europa.eu/index_pt?etrans=pt"
};
export default i18n;
