import "scenes/MapTransition.scss";
import PropTypes from "prop-types";
import { scenes } from "game-state.js";
import { useState, useEffect } from "react";
import { types as characterTypes } from "elements/character/Character.js";
import { ReactComponent as MapSvg } from "scenes/map.svg";
import exact from "prop-types-exact";

const TRANSITION_TIME = 10000;

const MapTransition = ({ characterType, fromScene, toScene, goToNextScene }) => {
  const [move, setMove] = useState(false);

  useEffect(() => {
    setMove(true);
    const id = setTimeout(goToNextScene, TRANSITION_TIME);
    return () => clearTimeout(id);
  }, [goToNextScene]);

  return (
    <div className="MapTransition scene">
      <div className={`map character-type-${characterType} from-${fromScene}${move ? ` to-${toScene}` : ``}`}>
        <MapSvg />
      </div>
    </div>
  );
};

MapTransition.propTypes = exact({
  characterType: PropTypes.oneOf(characterTypes),
  fromScene: PropTypes.oneOf(scenes).isRequired,
  toScene: PropTypes.oneOf(scenes).isRequired,
  goToNextScene: PropTypes.func.isRequired
});

export default MapTransition;
