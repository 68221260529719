import "elements/character/selector/Selector.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { selectNext, selectPrevious } from "common/sounds.js";

const Selector = ({ t, type, options, onOptionChange, initialCurrentOption }) => {
  const [currentOptionIndex, setCurrentOptionIndex] = useState(
    options.indexOf(initialCurrentOption) >= 0 ? options.indexOf(initialCurrentOption) : 0
  );
  const slideToOptionOffset = offset => {
    if (![1, -1].includes(offset)) return;
    let newOptionIndex = currentOptionIndex + offset;
    if (newOptionIndex < 0) newOptionIndex = options.length - 1;
    if (newOptionIndex === options.length) newOptionIndex = 0;
    setCurrentOptionIndex(newOptionIndex);
    onOptionChange(options[newOptionIndex]);
  };

  return (
    <div className={`Selector ${type}`}>
      <button
        className="previous"
        onClick={() => {
          slideToOptionOffset(-1);
          selectPrevious();
        }}
      >
        {t("previous")}
      </button>
      <span className={`icon ${options[currentOptionIndex]}`}>{options[currentOptionIndex]}</span>
      <button
        className="next"
        onClick={() => {
          slideToOptionOffset(1);
          selectNext();
        }}
      >
        {t("next")}
      </button>
    </div>
  );
};

Selector.propTypes = exact({
  t: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["skinTone", "outfit", "hairStyle", "accessory"]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOptionChange: PropTypes.func.isRequired,
  initialCurrentOption: PropTypes.string
});

export default Selector;
