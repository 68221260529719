import PropTypes from "prop-types";
import "elements/character/Character.scss";

export const types = ["adult", "child"];

export const adultSkinTones = [
  "adult-skinTone-1",
  "adult-skinTone-2",
  "adult-skinTone-3",
  "adult-skinTone-4",
  "adult-skinTone-5"
];
export const adultOutfits = ["adult-outfit-1", "adult-outfit-2", "adult-outfit-3", "adult-outfit-4", "adult-outfit-5"];
export const adultHairStyles = [
  "adult-hairStyle-black",
  "adult-hairStyle-black-curly",
  "adult-hairStyle-blonde",
  "adult-hairStyle-brown",
  "adult-hairStyle-grey"
];
export const adultAccessories = [
  "adult-accessory-glasses",
  "adult-accessory-scarf",
  "adult-accessory-watch",
  "adult-accessory-white-hat",
  "adult-accessory-yellow-hat"
];
export const childSkinTones = [
  "child-skinTone-1",
  "child-skinTone-2",
  "child-skinTone-3",
  "child-skinTone-4",
  "child-skinTone-5"
];
export const childOutfits = ["child-outfit-1", "child-outfit-2", "child-outfit-3", "child-outfit-4", "child-outfit-5"];
export const childHairStyles = [
  "child-hairStyle-black",
  "child-hairStyle-black-curly",
  "child-hairStyle-blonde",
  "child-hairStyle-brown",
  "child-hairStyle-ginger"
];
export const childAccessories = [
  "child-accessory-lei",
  "child-accessory-cap",
  "child-accessory-choker",
  "child-accessory-hat",
  "child-accessory-wristbands"
];

export const pickRandom = elements => elements[Math.floor(Math.random() * elements.length)];

export const characterPropTypes = PropTypes.exact({
  type: PropTypes.oneOf(types).isRequired,
  skinTone: PropTypes.oneOf(adultSkinTones.concat(childSkinTones)).isRequired,
  outfit: PropTypes.oneOf(adultOutfits.concat(childOutfits)).isRequired,
  hairStyle: PropTypes.oneOf(adultHairStyles.concat(childHairStyles)).isRequired,
  accessory: PropTypes.oneOf(adultAccessories.concat(childAccessories)).isRequired
});

export const getNewCharacterState = type => ({
  type: type,
  skinTone: type === "adult" ? adultSkinTones[0] : childSkinTones[0],
  outfit: type === "adult" ? adultOutfits[0] : childOutfits[0],
  hairStyle: type === "adult" ? adultHairStyles[0] : childHairStyles[0],
  accessory: type === "adult" ? adultAccessories[0] : childAccessories[0]
});

export const getRandomCharacterState = (type = pickRandom(types)) => ({
  type: type,
  skinTone: type === "adult" ? pickRandom(adultSkinTones) : pickRandom(childSkinTones),
  outfit: type === "adult" ? pickRandom(adultOutfits) : pickRandom(childOutfits),
  hairStyle: type === "adult" ? pickRandom(adultHairStyles) : pickRandom(childHairStyles),
  accessory: type === "adult" ? pickRandom(adultAccessories) : pickRandom(childAccessories)
});

const Character = ({ type, skinTone, outfit, hairStyle, accessory }) => (
  <div className={`Character ${type}`}>
    <span className={`skinTone ${skinTone}`}>{skinTone}</span>
    <span className={`outfit ${outfit}`}>{outfit}</span>
    <span className={`accessory ${accessory}`}>{accessory}</span>
    <span className={`hairStyle ${hairStyle}`}>{hairStyle}</span>
  </div>
);

export default Character;
