/**
 * 5.Pointing system
 * The pointing system defines the outcome of the game. The users, based on their choices, can either end up to the
 * Healthy New World (HNW) or the Eat to Live (ETL) world. The pointing system will work as a scorecard for the two
 * possible outcomes. Each item gives one point for each world and defines the prevailing world.
 */
export const selectableToPoints = [
  { name: "no-drink", pointsFor: [] },
  { name: "tap-water", pointsFor: ["HNW"] },
  { name: "milk", pointsFor: ["HNW", "ETL"] },
  { name: "freshly-made-juice", pointsFor: ["HNW"] },
  { name: "coffee-or-tea", pointsFor: ["ETL"] },
  { name: "plant-based-drink", pointsFor: ["HNW"] },
  { name: "whole-grain-cereals", pointsFor: ["HNW"] },
  { name: "choco-balls", pointsFor: ["ETL"] },
  { name: "white-bread-or-toast", pointsFor: ["ETL"] },
  { name: "wholemeal-bread-or-toast", pointsFor: ["HNW"] },
  { name: "biscuits", pointsFor: ["ETL"] },
  { name: "yogurt", pointsFor: ["HNW", "ETL"] },
  { name: "no-topping", pointsFor: ["HNW"] },
  { name: "honey", pointsFor: ["ETL"] },
  { name: "jam", pointsFor: ["ETL"] },
  { name: "fruit", pointsFor: ["HNW"] },
  { name: "butter", pointsFor: ["ETL"] },
  { name: "chocolate-cream", pointsFor: ["ETL"] },
  { name: "ham-or-bacon", pointsFor: ["ETL"] },
  { name: "cheese", pointsFor: ["ETL"] },
  { name: "avocado-and-tomato", pointsFor: ["HNW", "ETL"] },
  { name: "walk", pointsFor: ["HNW"] },
  { name: "bicycle", pointsFor: ["HNW"] },
  { name: "scooter-or-motorbike", pointsFor: ["ETL"] },
  { name: "car", pointsFor: ["ETL"] },
  { name: "public-transport", pointsFor: ["HNW"] },
  { name: "salad-with-egg", pointsFor: ["HNW"] },
  { name: "beef-steak", pointsFor: ["ETL"] },
  { name: "chicken", pointsFor: ["ETL"] },
  { name: "lentil-soup", pointsFor: ["HNW"] },
  { name: "vegetable-burger", pointsFor: ["HNW"] },
  { name: "white-pasta", pointsFor: ["ETL"] },
  { name: "wholegrain-pasta", pointsFor: ["HNW"] },
  { name: "white-rice", pointsFor: ["ETL"] },
  { name: "wholegrain-rice", pointsFor: ["HNW"] },
  { name: "white-bread-bun", pointsFor: ["ETL"] },
  { name: "no-salad", pointsFor: [] },
  { name: "fresh-green-salad", pointsFor: ["HNW"] },
  { name: "french-fries", pointsFor: ["ETL"] },
  { name: "boiled-broccoli", pointsFor: ["HNW"] },
  { name: "bottled-water", pointsFor: ["ETL"] },
  { name: "soft-drink", pointsFor: ["ETL"] },
  { name: "beer-or-wine", pointsFor: ["ETL"] },
  { name: "watching-tv", pointsFor: ["ETL"] },
  { name: "doing-sports", pointsFor: ["HNW"] },
  { name: "playing-outside", pointsFor: ["HNW"] },
  { name: "charity-work", pointsFor: ["HNW"] },
  { name: "carrots-tomatoes", pointsFor: ["HNW"] },
  { name: "fresh-fruit", pointsFor: ["HNW"] },
  { name: "chocolate-bar", pointsFor: ["ETL"] },
  { name: "chips", pointsFor: ["ETL"] },
  { name: "juice-drink", pointsFor: ["ETL"] },
  { name: "raw-fish", pointsFor: ["HNW", "ETL"] },
  { name: "raw-beef", pointsFor: ["HNW", "ETL"] },
  { name: "raw-chicken", pointsFor: ["HNW", "ETL"] },
  { name: "whole-salad", pointsFor: ["HNW"] },
  { name: "packaged-salad", pointsFor: ["ETL"] },
  { name: "seasonal-fruit", pointsFor: ["HNW"] },
  { name: "exotic-fruit", pointsFor: ["ETL"] },
  { name: "ham", pointsFor: ["ETL"] },
  { name: "tofu", pointsFor: ["HNW"] },
  { name: "hummus", pointsFor: ["HNW"] },
  { name: "precooked-pizza", pointsFor: ["ETL"] },
  { name: "precooked-fries", pointsFor: ["ETL"] },
  { name: "food-delivery", pointsFor: ["HNW", "ETL"] },
  { name: "cooking-at-home", pointsFor: ["HNW", "ETL"] },
  { name: "precooked-meal", pointsFor: ["ETL"] },
  { name: "frozen-pizza", pointsFor: ["ETL"] },
  { name: "rice-and-salad", pointsFor: ["HNW"] },
  { name: "ham-sandwich-and-soft-drink", pointsFor: ["ETL"] },
  { name: "wholegrain-pasta-with-sauce", pointsFor: ["HNW"] },
  { name: "cheeseburger", pointsFor: ["ETL"] },
  { name: "pizza-and-soft-drink", pointsFor: ["ETL"] },
  { name: "noodles-with-vegetables", pointsFor: ["HNW"] },
  { name: "mushroom-risoto", pointsFor: ["HNW"] }
];

export const getScore = selections => {
  const result = { HNW: 0, ETL: 0 };
  selections.forEach(selection => {
    const selectablePoints = selectableToPoints.find(selectable => selectable.name === selection);
    if (!selectablePoints) return; // Continue.
    selectablePoints.pointsFor.map(pointFor => result[pointFor]++);
  });
  return result.HNW - result.ETL;
};
