const i18n = {
  title: "Παιχνίδι Υγιούς Κοινωνίας 2050",
  start: "Έναρξη",
  select_language: "Επιλογή γλώσσας",
  adult: "Ενήλικος/-η",
  child: "Παιδί",
  fullscreen: "Πλήρης οθόνη",
  restart_scene: "Επανεκκίνηση σκηνής",
  restart_game: "Επανεκκίνηση παιχνιδιού",
  previous: "Προηγούμενο",
  next: "Επόμενο",
  close: "Κλείσιμο",
  toggle_sound: "Ήχος ενεργοποιημένος/απενεργοποιημένος",
  character_selection_title: "Επίλεξε τον χαρακτήρα σου",
  character_selection_body: "Οι επιλογές μας επηρεάζουν το μέλλον μας. Σε αυτό το παιχνίδι προσομοιάζουμε καθημερινές καταστάσεις. Κάνε τις επιλογές σου και ανακάλυψε τον αντίκτυπό τους. Ψάξε κρυμμένα μυστικά για να κερδίσεις κονκάρδες! Ας ξεκινήσουμε με τη δημιουργία του χαρακτήρα σου.",
  character_set_up_title: "Δημιούργησε τον χαρακτήρα σου",
  select_me: "Επίλεξέ με",
  breakfast_title: "«Τι τρως για πρωινό;»",
  your_badges: "Παράσημα",
  badge_water_conservation_master_title: "Γκουρού",
  badge_water_conservation_master_description: "Συγχαρητήρια! Είσαι γκουρού όσον αφορά στην εξοικονόμηση ενός σπάνιου πόρου! Το νερό είναι σημαντικό για όλα τα είδη ζωής.",
  badge_clean_street_wizard_title: "Μάγος",
  badge_clean_street_wizard_description: "Συγχαρητήρια! Είσαι μάγος στο να βοηθάς να γίνει το περιβάλλον μας καθαρότερο, μαζεύοντας σκουπίδια και πετώντας τα στον κάδο σκουπιδιών.",
  badge_eco_ninja_title: "Νίντζα",
  badge_eco_ninja_description: "Συγχαρητήρια! Είσαι νίντζα στην εξοικονόμηση ενέργειας. Η εξοικονόμηση ενέργειας από τις συσκευές μας είναι πολύ καλός τρόπος να συμβάλλουμε στη μείωση της κατανάλωσης ενέργειας.",
  badge_energy_saving_expert_title: "Εξπέρ",
  badge_energy_saving_expert_description: "Συγχαρητήρια! Είσαι εξπέρ στην εξοικονόμηση ενέργειας. Συνέχισε να βρίσκεις τρόπους να μειώνεις την ενεργειακή σου κατανάλωση.",
  badge_low_waste_champion_title: "Πρωταθλητής",
  badge_low_waste_champion_description: "Συγχαρητήρια! Είσαι πρωταθλητής στη μείωση των αποβλήτων σου και στο να βοηθάς το περιβάλλον περιορίζοντας τη χρήση πλαστικών.",
  badge_responsible_shopper_title: "Αυθεντία",
  badge_responsible_shopper_description: "Συγχαρητήρια, είσαι αυθεντία στις υγιείς επιλογές για το περιβάλλον. Συνέχισε να χρησιμοποιείς τη μικρότερη δυνατή ποσότητα πλαστικών, έχοντας μαζί σου τη δική σου επαναχρησιμοποιήσιμη σακούλα.",
  badge_recycling_captain_title: "Αστέρι",
  badge_recycling_captain_description: "Συγχαρητήρια! Είσαι αστέρι στην ανακύκλωση. Είναι σημαντικό να ανακυκλώνεις και να επαναχρησιμοποιείς υλικά για ένα υγιές περιβάλλον.",
  faucet: "Βρύση",
  transportation_title_adult: "Πώς πηγαίνεις στη δουλειά;",
  transportation_title_child: "Πώς πηγαίνεις στο σχολείο;",
  trash: "Σκουπίδια",
  no_drink: "Κανένα ρόφημα",
  no_drink_description: "",
  tap_water: "Νερό της βρύσης",
  tap_water_description: "Μείνε ενυδατωμένος/η. Προσπάθησε να πίνεις τουλάχιστον 1,5-2 λίτρα νερού κάθε μέρα.",
  milk: "Γάλα",
  milk_description: "Επίλεξε απλό γάλα χωρίς ζάχαρη ή άλλες γλυκαντικές ουσίες, κατά προτίμηση με χαμηλά λιπαρά.",
  freshly_made_juice: "Φρεσκοστυμμένο χυμό",
  freshly_made_juice_description: "Αν σου αρέσει ο χυμός, προτίμησε τον φρεσκοστυμμένο —δεν χρειάζεται να προσθέτεις ζάχαρη. Προσπάθησε να τρως φρέσκα φρούτα.",
  coffee_or_tea: "Καφέ/τσάι",
  coffee_or_tea_description: "Απόλαυσε το τσάι και τον καφέ σου, κατά προτίμηση, χωρίς να προσθέτεις ζάχαρη.",
  plant_based_drink: "Ρόφημα φυτικής προέλευσης",
  plant_based_drink_description: "Δοκίμασε ροφήματα φυτικής προέλευσης, χωρίς πρόσθετα σάκχαρα και, εφόσον είναι δυνατόν, επίλεγε ροφήματα που περιέχουν βιταμίνες και ιχνοστοιχεία.",
  whole_grain_cereals: "Δημητριακά ολικής άλεσης",
  whole_grain_cereals_description: "Προτίμησε δημητριακά ολικής άλεσης χωρίς πρόσθετα σάκχαρα.",
  choco_balls: "Δημητριακά με σοκολάτα",
  choco_balls_description: "Τα δημητριακά πρωινού μπορεί να αντιστοιχούν σε σημαντικό μέρος των σακχάρων που προσλαμβάνουμε ημερησίως —μπορείς να επιλέγεις όσα έχουν λιγότερα πρόσθετα σάκχαρα; Και μάλιστα να επιλέγεις όσα έχουν περισσότερες φυτικές ίνες.",
  white_bread_or_toast: "Λευκό ψωμί/ψωμί τοστ",
  white_bread_or_toast_description: "Δοκίμασε ψωμί ολικής άλεσης.",
  wholemeal_bread_or_toast: "Ψωμί/ψωμί τοστ ολικής άλεσης",
  wholemeal_bread_or_toast_description: "Δοκίμασε ψωμί ολικής άλεσης.",
  biscuits: "Μπισκότα",
  biscuits_description: "Τα μπισκότα είναι πλούσια σε θερμίδες και μπορεί να αντιστοιχούν σε σημαντικό μέρος των σακχάρων και των λιπαρών που προσλαμβάνουμε ημερησίως. Είναι καλύτερα να μειώσεις την κατανάλωσή τους. Προτίμησε αυτά που έχουν χαμηλότερα λιπαρά και λιγότερα σάκχαρα. Και μάλιστα επίλεξε όσα παράλληλα έχουν περισσότερες φυτικές ίνες.",
  no_topping: "Καμία γαρνιτούρα",
  no_topping_description: "",
  yogurt: "Γιαούρτι",
  yogurt_description: "Δοκίμασε απλό γιαούρτι με χαμηλά λιπαρά χωρίς πρόσθετα σάκχαρα.",
  honey: "Μέλι",
  honey_description: "Επίλεξε απλό γιαούρτι χωρίς να προσθέσεις σάκχαρα ή μαρμελάδα. Προτίμησε την προσθήκη φρούτων ή δημητριακών ολικής άλεσης χωρίς σάκχαρα ή άλλα γλυκαντικά.",
  jam: "Μαρμελάδα",
  jam_description: "Επίλεξε απλό γιαούρτι χωρίς να προσθέσεις σάκχαρα ή μαρμελάδα. Προτίμησε την προσθήκη φρούτων ή δημητριακών ολικής άλεσης χωρίς σάκχαρα ή άλλα γλυκαντικά.",
  fruit: "Φρούτα",
  fruit_description: "Εξαιρετική επιλογή να ξεκινάς τη μέρα με φρούτα, προσπάθησε να επιλέγεις φρούτα εποχής. Αν προσθέτεις ξηρούς καρπούς, προτίμησε όσους δεν έχουν πρόσθετο αλάτι ή σάκχαρα.",
  butter: "Βούτυρο",
  butter_description: "Το βούτυρο περιέχει πολλές λιπαρές ουσίες και μπορεί να περιέχει αλάτι. Προσπάθησε να μην το τρως πολύ συχνά και προτίμησε βούτυρο χωρίς αλάτι.",
  chocolate_cream: "Κρέμα σοκολάτας",
  chocolate_cream_description: "Η κρέμα σοκολάτας περιέχει πολλά σάκχαρα και κορεσμένα λιπαρά. Προσπάθησε να μην την τρως πολύ συχνά.",
  ham_or_bacon: "Ζαμπόν/μπέικον",
  ham_or_bacon_description: "Το επεξεργασμένο κρέας περιέχει μεγάλες ποσότητες λιπαρών και αλατιού. Αν τρως επεξεργασμένο κρέας, περιόρισε την κατανάλωσή του.",
  cheese: "Τυρί",
  cheese_description: "Το τυρί περιέχει πολύ αλάτι και πολλά κορεσμένα λιπαρά. Προσπάθησε να το τρως σε μικρές δόσεις και όχι πολύ συχνά.",
  avocado_and_tomato: "Αβοκάντο και ντομάτα",
  avocado_and_tomato_description: "Εξαιρετική επιλογή να ξεκινάς τη μέρα με λαχανικά. Προσπάθησε να τρως άφθονα φρούτα και λαχανικά κατά τη διάρκεια της μέρας και προτίμησε όσα είναι τοπικά και εποχιακά.",
  battery: "Μπαταρία",
  battery_description: "Έλεγχε την μπαταρία σου, δείχνει τον αντίκτυπο των επιλογών σου!",
  lunch_title: "Τι θα ήθελες για μεσημεριανό;",
  breaktime_title: "Ελεύθερο απόγευμα - Ελεύθερος χρόνος!",
  snacking_title: "Θέλεις ένα σνακ;",
  supermarket_title: "Τι αγοράζεις;",
  dinner_selection_title: "Τι θα φας για βραδινό;",
  dinner_cooking_title: "Τι έχει για βραδινό",
  dinner_delivery_title: "Τι έχει για βραδινό;",
  your_available_items: "Διαθέσιμες επιλογές",
  your_selections: "Οι επιλογές σου",
  walk: "Περπάτημα",
  walk_description: "Να είσαι φυσικά δραστήριος/α στην καθημερινότητά σου. Αν είναι δυνατόν, πήγαινε στο σχολείο / στη δουλειά περπατώντας ή με το ποδήλατο. Διατηρεί την καλή σου φυσική κατάσταση και είναι καλύτερο για το περιβάλλον.",
  bicycle: "Ποδήλατο",
  bicycle_description: "Να είσαι φυσικά δραστήριος/α στην καθημερινότητά σου. Αν είναι δυνατόν, πήγαινε στο σχολείο / στη δουλειά περπατώντας ή με το ποδήλατο. Διατηρεί την καλή σου φυσική κατάσταση και είναι καλύτερο για το περιβάλλον.",
  scooter_or_motorbike: "Πατίνι/μοτοσικλέτα",
  scooter_or_motorbike_description: "Προσπάθησε να χρησιμοποιείς λιγότερο το αυτοκίνητο ή τη μοτοσικλέτα, για καθαρότερο περιβάλλον. Αν αυτό δεν είναι δυνατόν, προσπάθησε να χρησιμοποιείς αυτοκίνητο από κοινού με άλλα άτομα.",
  car: "Αυτοκίνητο",
  car_description: "Προσπάθησε να χρησιμοποιείς λιγότερο το αυτοκίνητο ή τη μοτοσικλέτα, για καθαρότερο περιβάλλον. Αν αυτό δεν είναι δυνατόν, προσπάθησε να χρησιμοποιείς αυτοκίνητο από κοινού με άλλα άτομα.",
  public_transport: "Δημόσιες συγκοινωνίες",
  public_transport_description: "Χρησιμοποίησε τα μέσα μαζικής μεταφοράς και συνδύασέ τα με περπάτημα και ποδήλατο αντί να πηγαίνεις με αυτοκίνητο. Άσε λίγο την οδήγηση και βοήθησε το περιβάλλον.",
  salad_with_egg: "Σαλάτα με αυγό",
  salad_with_egg_description: "Να προσθέτεις ωμά λαχανικά στα γεύματα σου, όποτε είναι δυνατόν.",
  beef_steak: "Μοσχαρίσια μπριζόλα",
  beef_steak_description: "Αν τρως κρέας, θα μπορούσες να ελαττώσεις την ποσότητα που προσλαμβάνεις, ιδίως του κόκκινου ή του επεξεργασμένου κρέατος. Είναι καλύτερο για την υγεία σου και για το περιβάλλον.",
  chicken: "Κοτόπουλο",
  chicken_description: "Αν τρως κρέας, θα μπορούσες να ελαττώσεις την ποσότητα που προσλαμβάνεις, ιδίως του κόκκινου ή του επεξεργασμένου κρέατος. Είναι καλύτερο για την υγεία σου και για το περιβάλλον.",
  lentil_soup: "Φακές σούπα",
  lentil_soup_description: "Η τακτική κατανάλωση οσπρίων ενθαρρύνεται ως μέρος μιας ποικίλης και κατά κύριο λόγο φυτικής διατροφής.",
  vegetable_burger: "Μπιφτέκι λαχανικών",
  vegetable_burger_description: "Το μπιφτέκι λαχανικών μπορεί να είναι μια καλή εναλλακτική λύση αντί για κρέας. Πρόσεχε να μην περιέχει υπερβολικά πολύ αλάτι.",
  white_pasta: "Λευκά ζυμαρικά",
  white_pasta_description: "Όταν επιλέγεις ζυμαρικά, ρύζι και ψωμί, προτίμησε προϊόντα ολικής άλεσης.",
  wholegrain_pasta: "Ζυμαρικά ολικής άλεσης",
  wholegrain_pasta_description: "Όταν επιλέγεις ζυμαρικά, ρύζι και ψωμί, προτίμησε προϊόντα ολικής άλεσης.",
  white_rice: "Λευκό ρύζι",
  white_rice_description: "Όταν επιλέγεις ζυμαρικά, ρύζι και ψωμί, προτίμησε προϊόντα ολικής άλεσης.",
  wholegrain_rice: "Ρύζι πλήρες",
  wholegrain_rice_description: "Όταν επιλέγεις ζυμαρικά, ρύζι και ψωμί, προτίμησε προϊόντα ολικής άλεσης.",
  white_bread_bun: "Λευκό ψωμάκι",
  white_bread_bun_description: "Όταν επιλέγεις ζυμαρικά, ρύζι και ψωμί, προτίμησε προϊόντα ολικής άλεσης.",
  no_salad: "Καμία",
  no_salad_description: "",
  fresh_green_salad: "Ωμή πράσινη σαλάτα",
  fresh_green_salad_description: "Πρόσθεσε χρώμα στα γεύματά σου με λαχανικά εποχής. Όσο μεγαλύτερη η ποικιλία τόσο το καλύτερο.",
  french_fries: "Τηγανητές πατάτες",
  french_fries_description: "Προτίμησε βραστές πατάτες αντί για προϊόντα πατάτας τηγανισμένα σε φριτέζα.",
  boiled_broccoli: "Βραστό μπρόκολο",
  boiled_broccoli_description: "Προσπάθησε να τρως άφθονα λαχανικά κατά τη διάρκεια της ημέρας. Να προτιμάς τοπικά και εποχιακά λαχανικά.",
  bottled_water: "Εμφιαλωμένο νερό",
  bottled_water_description: "Πίνε νερό της βρύσης για να μειώσεις τη χρήση πλαστικού.",
  soft_drink: "Αναψυκτικό",
  soft_drink_description: "Τα ζαχαρούχα αναψυκτικά μπορεί να αντιστοιχούν σε σημαντικό μέρος των σακχάρων που προσλαμβάνουμε ημερησίως —μπορείς αντί γι’ αυτά να επιλέγεις νερό;",
  beer_or_wine: "Μπίρα/κρασί",
  beer_or_wine_description: "Αν πίνεις οποιουδήποτε είδους αλκοολούχο ποτό, ελάττωσε την ποσότητα που προσλαμβάνεις. Είναι καλύτερο για την υγεία σου να αποφεύγεις το αλκοόλ.",
  watching_tv: "Παρακολούθηση τηλεόρασης ή βιντεοπαιχνίδια",
  watching_tv_description: "Να είσαι φυσικά δραστήριος/α κάθε μέρα και προσπάθησε να μειώσεις τον χρόνο που κάθεσαι. Στον ελεύθερο χρόνο σου προσπάθησε να αθληθείς, να παίξεις με την παρέα σου ή να βοηθήσεις την κοινότητά σου. Είναι καλό για την υγεία σου και για την κοινωνία. Απόφυγε να περνάς πολύ χρόνο μπροστά σε μια οθόνη.",
  doing_sports: "Αθλήματα",
  doing_sports_description: "Να είσαι φυσικά δραστήριος/α κάθε μέρα και προσπάθησε να μειώσεις τον χρόνο που κάθεσαι. Στον ελεύθερο χρόνο σου προσπάθησε να αθληθείς, να παίξεις με την παρέα σου ή να βοηθήσεις την κοινότητά σου. Είναι καλό για την υγεία σου και για την κοινωνία. Απόφυγε να περνάς πολύ χρόνο μπροστά σε μια οθόνη.",
  playing_outside: "Παιχνίδι σε εξωτερικό χώρο",
  playing_outside_description: "Να είσαι φυσικά δραστήριος/α κάθε μέρα και προσπάθησε να μειώσεις τον χρόνο που κάθεσαι. Στον ελεύθερο χρόνο σου προσπάθησε να αθληθείς, να παίξεις με την παρέα σου ή να βοηθήσεις την κοινότητά σου. Είναι καλό για την υγεία σου και για την κοινωνία. Απόφυγε να περνάς πολύ χρόνο μπροστά σε μια οθόνη.",
  charity_work: "Κοινωφελής εργασία με ενήλικες",
  charity_work_description: "Να είσαι φυσικά δραστήριος/α κάθε μέρα και προσπάθησε να μειώσεις τον χρόνο που κάθεσαι. Στον ελεύθερο χρόνο σου προσπάθησε να αθληθείς, να παίξεις με την παρέα σου ή να βοηθήσεις την κοινότητά σου. Είναι καλό για την υγεία σου και για την κοινωνία. Απόφυγε να περνάς πολύ χρόνο μπροστά σε μια οθόνη.",
  carrots_tomatoes: "Καρότα/ντοματίνια",
  carrots_tomatoes_description: "Προτίμησε φρούτα, λαχανικά ή ξηρούς καρπούς. Περιόρισε τα σνακ και τα ποτά με υψηλή περιεκτικότητα σε σάκχαρα, λιπαρά και αλάτι.",
  fresh_fruit: "Νωπά φρούτα",
  fresh_fruit_description: "Προτίμησε φρούτα, λαχανικά ή ξηρούς καρπούς. Περιόρισε τα σνακ και τα ποτά με υψηλή περιεκτικότητα σε σάκχαρα, λιπαρά και αλάτι.",
  chocolate_bar: "Μπάρα σοκολάτας",
  chocolate_bar_description: "Προτίμησε φρούτα, λαχανικά ή ξηρούς καρπούς. Περιόρισε τα σνακ και τα ποτά με υψηλή περιεκτικότητα σε σάκχαρα, λιπαρά και αλάτι.",
  chips: "Τσιπς",
  chips_description: "Προτίμησε φρούτα, λαχανικά ή ξηρούς καρπούς. Περιόρισε τα σνακ και τα ποτά με υψηλή περιεκτικότητα σε σάκχαρα, λιπαρά και αλάτι.",
  juice_drink: "Φρουτοποτό",
  juice_drink_description: "Προτίμησε φρέσκα φρούτα ή φρεσκοστυμμένους χυμούς χωρίς πρόσθετα σάκχαρα, αντί για φρουτοποτά.",
  supermarket_prompt: "Επίλεξε έως 9 προϊόντα",
  raw_fish: "Ωμό ψάρι",
  raw_fish_description: "Τρώγοντας τακτικά ψάρια λαμβάνεις σημαντικές θρεπτικές ουσίες. Προσπάθησε να τρως διάφορα ψάρια",
  raw_beef: "Ωμό βοδινό",
  raw_beef_description: "Αν τρως κρέας, θα μπορούσες να ελαττώσεις την ποσότητα που προσλαμβάνεις, ιδίως όσον αφορά το κόκκινο ή το επεξεργασμένο κρέας. Είναι καλύτερο για την υγεία σου και για το περιβάλλον.",
  raw_chicken: "Ωμό κοτόπουλο",
  raw_chicken_description: "Αν τρως κρέας, θα μπορούσες να ελαττώσεις την ποσότητα που προσλαμβάνεις, ιδίως του κόκκινου ή του επεξεργασμένου κρέατος. Είναι καλύτερο για την υγεία σου και για το περιβάλλον.",
  whole_salad: "Ολόκληρο λαχανικό για σαλάτα - π.χ. μαρούλι",
  whole_salad_description: "Προτίμησε νωπά φρούτα και λαχανικά, ιδίως εποχιακά και τοπικά.",
  packaged_salad: "Συσκευασμένη σαλάτα",
  packaged_salad_description: "Προτίμησε νωπά φρούτα και λαχανικά, ιδίως εποχιακά και τοπικά.",
  seasonal_fruit: "Τοπικά φρούτα εποχής",
  seasonal_fruit_description: "Προτίμησε νωπά φρούτα και λαχανικά, ιδίως εποχιακά και τοπικά.",
  exotic_fruit: "Εξωτικά φρούτα",
  exotic_fruit_description: "Προτίμησε νωπά φρούτα και λαχανικά, ιδίως εποχιακά και τοπικά.",
  ham: "Ζαμπόν",
  ham_description: "Το επεξεργασμένο κρέας περιέχει μεγάλες ποσότητες λιπαρών και αλατιού. Αν τρως επεξεργασμένο κρέας, προσπάθησε να περιορίσεις την κατανάλωσή του.",
  tofu: "Τόφου",
  tofu_description: "Εξερεύνησε νέα φυτικά προϊόντα και νέες συνταγές.",
  hummus: "Χούμους",
  hummus_description: "Το χούμους είναι μια ωραία εναλλακτική επιλογή που θα σε βοηθήσει να αυξήσεις τα όσπρια που προσλαμβάνεις.",
  precooked_pizza: "Προμαγειρεμένη πίτσα",
  precooked_pizza_description: "Τα προμαγειρεμένα γεύματα μπορεί να περιέχουν μεγάλες ποσότητες αλατιού ή λιπαρών. Να προτιμάς προϊόντα που δεν είναι προμαγειρεμένα. Έτσι θα μπορείς να ελέγχεις την ποσότητα αλατιού, σακχάρων και λιπαρών στα γεύματα σου.",
  precooked_fries: "Προτηγανισμένες πατάτες",
  precooked_fries_description: "Τα προμαγειρεμένα γεύματα μπορεί να περιέχουν μεγάλες ποσότητες αλατιού ή λιπαρών. Να προτιμάς προϊόντα που δεν είναι προμαγειρεμένα. Έτσι θα μπορείς να ελέγχεις την ποσότητα αλατιού, σακχάρων και λιπαρών στα γεύματα σου.",
  food_delivery: "Φαγητό σε πακέτο",
  food_delivery_description: "",
  cooking_at_home: "Μαγείρεμα στο σπίτι",
  cooking_at_home_description: "",
  cheeseburger: "Τσίζμπεργκερ, τηγανητές πατάτες και αναψυκτικό",
  cheeseburger_description: "Απόφυγε την κατανάλωση τέτοιου είδους γευμάτων. Έχουν υψηλή περιεκτικότητα σε λιπαρά και αλάτι. Για ποτό, πιες νερό.",
  pizza_and_soft_drink: "Πίτσα και αναψυκτικό",
  pizza_and_soft_drink_description: "Τα γεύματα αυτά έχουν υψηλή περιεκτικότητα σε λιπαρά, αλάτι και σάκχαρα. Επίλεξε «απλές» πίτσες με λιγότερα συστατικά ή με περισσότερα λαχανικά, και επίλεξε ζύμη ολικής άλεσης για πίτσα, αν είναι δυνατόν. Για ποτό, πιες νερό.",
  noodles_with_vegetables: "Νούντλς με λαχανικά",
  noodles_with_vegetables_description: "Αυτή είναι μια καλή επιλογή για να αυξήσεις τα λαχανικά που καταναλώνεις. Πρόσθεσε ορισμένα όσπρια για το ένα πιο ολοκληρωμένο γεύμα.",
  mushroom_risoto: "Ριζότο μανιταριών",
  mushroom_risoto_description: "Τα μανιτάρια είναι μια καλή χορτοφαγική επιλογή. Πρόσεξε να μην προσθέσεις πάρα πολύ τυρί. Για ένα ολοκληρωμένο γεύμα, πρόσθεσε σαλάτα.",
  precooked_meal: "Προμαγειρεμένο έτοιμο γεύμα - κοτομπουκιές",
  precooked_meal_description: "Τα προμαγειρεμένα γεύματα μπορεί να περιέχουν μεγάλες ποσότητες αλατιού, λιπαρών ή ακόμη και σακχάρων. Προτίμησε προϊόντα που δεν είναι προμαγειρεμένα. Μπορείς να ελέγξεις καλύτερα την ποσότητα αυτών των θρεπτικών συστατικών. Για περισσότερη γεύση, χρησιμοποίησε αρωματικά φυτά και μπαχαρικά αντί για αλάτι.",
  frozen_pizza: "Κατεψυγμένη πίτσα",
  frozen_pizza_description: "Τα προμαγειρεμένα γεύματα μπορεί να περιέχουν μεγάλες ποσότητες αλατιού, λιπαρών ή ακόμη και σακχάρων. Προτίμησε προϊόντα που δεν είναι προμαγειρεμένα. Μπορείς να ελέγξεις καλύτερα την ποσότητα αυτών των θρεπτικών συστατικών. Για περισσότερη γεύση, χρησιμοποίησε αρωματικά φυτά και μπαχαρικά αντί για αλάτι.",
  rice_and_salad: "Μαγειρεμένο γεύμα με ρύζι και πράσινη σαλάτα",
  rice_and_salad_description: "Τα προμαγειρεμένα γεύματα μπορεί να περιέχουν μεγάλες ποσότητες αλατιού, λιπαρών ή ακόμη και σακχάρων. Προτίμησε προϊόντα που δεν είναι προμαγειρεμένα. Μπορείς να ελέγξεις καλύτερα την ποσότητα αυτών των θρεπτικών συστατικών. Για περισσότερη γεύση, χρησιμοποίησε αρωματικά φυτά και μπαχαρικά αντί για αλάτι.",
  ham_sandwich_and_soft_drink: "Σάντουιτς με ζαμπόν και αναψυκτικό",
  ham_sandwich_and_soft_drink_description: "Το επεξεργασμένο κρέας περιέχει μεγάλες ποσότητες λιπαρών και αλατιού. Αν τρως επεξεργασμένο κρέας, προσπάθησε να περιορίσεις την κατανάλωσή του. Επίσης, προσπάθησε να αποφύγεις τα ζαχαρούχα ποτά και τα αναψυκτικά. Αντί γι’ αυτά, πιες νερό βρύσης.",
  wholegrain_pasta_with_sauce: "Ζυμαρικά ολικής άλεσης με σάλτσα λαχανικών",
  wholegrain_pasta_with_sauce_description: "Δοκίμασε ζυμαρικά ολικής άλεσης.",
  hnw_title_adult: "Υγιής καινούριος κόσμος",
  hnw_description_1_adult: "Οι επιλογές μας διαμορφώνουν το μέλλον μας. Αν όλοι τρώμε και ζούμε όπως εσύ στο παιχνίδι, μπορούμε να έχουμε ένα υγιέστερο μέλλον όπου οι άνθρωποι θα νοιάζονται ο ένας για τον άλλο και για το περιβάλλον.",
  hnw_description_2_adult: "Οι επιστήμονες διαπιστώνουν σταθερά ότι για να διαφυλάξουμε την υγεία μας και την υγεία του πλανήτη μας, θα πρέπει να έχουμε ποικιλία στη διατροφή μας: πολλά δημητριακά, όσπρια, νωπά λαχανικά και φρούτα. Να αποφεύγουμε τα επεξεργασμένα τρόφιμα, το κρέας και τα σνακ με υψηλή περιεκτικότητα σε αλάτι, σάκχαρα και λιπαρά. Τα  αμοιβαία οφέλη θα είναι περισσότερα, αν είμαστε σωματικά δραστήριοι, περπατάμε, κάνουμε ποδήλατο ή χρησιμοποιούμε τα μέσα μαζικής μεταφοράς και μειώσουμε τη χρήση αυτοκινήτου.",
  hnw_description_3_adult: "Απλά πράγματα μπορούν να ενισχύσουν την υγεία σου και το περιβάλλον μας: καθαρός αέρας και νερό, ασφαλή τρόφιμα, πλούσια εδάφη και χώροι πρασίνου, σεβασμός για τη φύση και για όλους τους ανθρώπους. Σκέψου το, και έξω από αυτό το παιχνίδι. Ξεκίνα αυτό το ταξίδι μαζί με τους φίλους και την οικογένειά σου.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Θα ήθελες να ξαναπαίξεις;",
  hnw_title_child: "Οι επιλογές μας διαμορφώνουν το μέλλον μας",
  hnw_description_1_child: "Αν όλοι τρώμε και ζούμε όπως εσύ στο παιχνίδι, μπορούμε να έχουμε ένα υγιέστερο μέλλον.",
  hnw_description_2_child: "Να τι μπορείς να κάνεις:\n- Φρόντισε να έχεις ποικιλία στη διατροφή σου για να διαφυλάξεις την υγεία μας και την υγεία του πλανήτη μας.\n- Επίλεξε δημητριακά, όσπρια, νωπά λαχανικά και φρούτα.  \n- Περιόρισε τα επεξεργασμένα τρόφιμα, το κρέας και τα σνακ με υψηλή περιεκτικότητα σε αλάτι, σάκχαρα και λιπαρά.\n- Να είσαι σωματικά δραστήριος/α, περπάτησε, κάνε ποδήλατο, χρησιμοποίησε τα μέσα μαζικής μεταφοράς.\n- Μείωσε τη χρήση αυτοκινήτου.",
  hnw_description_3_child: "Αυτές οι απλές επιλογές μπορούν να ενισχύσουν την υγεία σου και να εξασφαλίσουν ότι φροντίζουμε το περιβάλλον και όλους τους ανθρώπους.",
  hnw_description_4_child: "Σκέψου το, και έξω από αυτό το παιχνίδι.",
  hnw_description_5_child: "Ξεκίνα αυτό το ταξίδι μαζί με τους φίλους και την οικογένειά σου.",
  hnw_description_6_child: "Θα ήθελες να ξαναπαίξεις;",
  etl_title_adult: "Τρώω για να ζω",
  etl_description_1_adult: "Οι επιλογές μας διαμορφώνουν το μέλλον μας. Μπορεί να μην το αντιλαμβάνεσαι, όμως ο τρόπος ζωής σου μπορεί να έχει αντίκτυπο στην υγεία του πλανήτη μας.",
  etl_description_2_adult: "Οι επιστήμονες διαπιστώνουν σταθερά ότι για να διαφυλάξουμε την υγεία μας και την υγεία του πλανήτη μας, θα πρέπει να έχουμε ποικιλία στη διατροφή μας: πολλά δημητριακά, όσπρια, νωπά λαχανικά και φρούτα. Να αποφεύγουμε τα επεξεργασμένα τρόφιμα, το κρέας και τα σνακ με υψηλή περιεκτικότητα σε αλάτι, σάκχαρα και λιπαρά. Τα  αμοιβαία οφέλη θα είναι περισσότερα, αν είμαστε σωματικά δραστήριοι, περπατάμε, κάνουμε ποδήλατο ή χρησιμοποιούμε τα μέσα μαζικής μεταφοράς και μειώσουμε τη χρήση αυτοκινήτου.",
  etl_description_3_adult: "Απλά πράγματα μπορούν να ενισχύσουν την υγεία σου και το περιβάλλον μας: καθαρός αέρας και νερό, ασφαλή τρόφιμα, πλούσια εδάφη και χώροι πρασίνου, σεβασμός στη φύση και σε όλους τους ανθρώπους.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Θα ήθελες να ξαναπαίξεις;",
  etl_title_child: "Οι επιλογές μας διαμορφώνουν το μέλλον μας",
  etl_description_1_child: "Αν όλοι βελτιώσουμε τον τρόπο που τρώμε και ζούμε, μπορούμε να έχουμε ένα υγιέστερο μέλλον.",
  etl_description_2_child: "Να τι μπορείς να κάνεις:\n- Φρόντισε να έχεις ποικιλία στη διατροφή σου για να διαφυλάξεις την υγεία μας και την υγεία του πλανήτη μας.\n- Επίλεξε δημητριακά, όσπρια, νωπά λαχανικά και φρούτα.  \n- Περιόρισε τα επεξεργασμένα τρόφιμα, το κρέας και τα σνακ με υψηλή περιεκτικότητα σε αλάτι, σάκχαρα και λιπαρά.\n- Να είσαι σωματικά δραστήριος/α, περπάτησε, κάνε ποδήλατο, χρησιμοποίησε τα μέσα μαζικής μεταφοράς.\n- Μείωσε τη χρήση αυτοκινήτου.",
  etl_description_3_child: "Αυτές οι απλές επιλογές μπορούν να ενισχύσουν την υγεία σου και να εξασφαλίσουν ότι φροντίζουμε το περιβάλλον και όλους τους ανθρώπους.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Θα ήθελες να ξαναπαίξεις;",
  share: "Αποστολή των αποτελεσμάτων μου μέσω email",
  replay: "Ξαναπαίξε",
  its_up_to_you: "Από σένα εξαρτάται",
  badge_action_1: "Ανακάλυψες ένα κρυμμένο μυστικό!",
  badge_action_2: "Κέρδισες το παράσημο %{badge}",
  fridgeDoor: "Πόρτα ψυγείου",
  light: "Φως",
  bottle: "Μπουκάλι",
  reusable_bag: "Επαναχρησιμοποιήσιμη σακούλα",
  plastic_bag: "Πλαστική σακούλα",
  recycle_bin: "Κάδος ανακύκλωσης",
  share_text_subject: "Από σένα εξαρτάται",
  share_text_body: "Έπαιξα 2050 – Από σένα εξαρτάται! Έχεις περιέργεια για το πώς μοιάζει ο δικός σου κόσμος; #ExperienceEUScience\nΠαίξε τώρα %{shareUrl}\nΤο παιχνίδι αυτό αναπτύχθηκε από το Κοινό Κέντρο Ερευνών, που είναι η υπηρεσία επιστήμης και γνώσης της Ευρωπαϊκής Επιτροπής.\nΜάθε περισσότερα https://joint-research-centre.ec.europa.eu/index_el?etrans=el"
};
export default i18n;
