import "common/Battery.scss";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { ReactComponent as BatterySvg } from "common/battery.svg";
import { useState, useEffect } from "react";
import { useDidUpdate } from "utils.js";
import Modal from "react-modal";
import { elevateUp, elevateDown } from "common/sounds.js";

const slots = 8;

const getBatterySvgSlotClassNames = (score, minScore, maxScore) => {
  const normalizedMaxScore = maxScore - minScore;
  const normalizedScore = score - minScore;
  const color =
    normalizedScore < normalizedMaxScore * 0.25
      ? "red"
      : normalizedScore < normalizedMaxScore * 0.5
      ? "orange"
      : "green";
  const classNames = [];
  for (let i = 0; i < slots; i++) {
    if ((slots * normalizedScore) / normalizedMaxScore >= i) classNames.push("slot-" + (i + 1) + "-" + color);
  }
  return classNames.join(" ");
};

const Battery = ({ t, score, minScore, maxScore }) => {
  const svgSlotClassNames = getBatterySvgSlotClassNames(score, minScore, maxScore);

  const [energyOverflow, setEnergyOverflow] = useState(false);
  const [slotsChanged, setSlotsChangedNotify] = useState(false);
  const [batteryHelpTextModalShown, setBatteryHelpTextModalShown] = useState(false);
  const openBatteryHelpTextModal = () => {
    setBatteryHelpTextModalShown(true);
    elevateUp();
  };
  const closeBatteryHelpTextModal = () => {
    setBatteryHelpTextModalShown(false);
    elevateDown();
  };

  useEffect(() => {
    if (score > maxScore) {
      setEnergyOverflow(true);
      setTimeout(() => setEnergyOverflow(false), 500);
    }
  }, [score, maxScore]);

  useDidUpdate(() => {
    setSlotsChangedNotify(true);
    setTimeout(() => setSlotsChangedNotify(false), 250);
  }, [svgSlotClassNames]);

  return (
    <div
      className={
        "Battery " +
        svgSlotClassNames +
        (energyOverflow ? " energy-overflow" : "") +
        (slotsChanged ? " slots-changed" : "")
      }
      title={t("battery")}
    >
      <div>
        <button onClick={openBatteryHelpTextModal}>
          <BatterySvg />
        </button>
      </div>
      <Modal
        isOpen={batteryHelpTextModalShown}
        onRequestClose={closeBatteryHelpTextModal}
        contentLabel={t("battery")}
        className="Battery"
      >
        <h2>{t("battery")}</h2>
        <p>{t("battery_description")}</p>
        <button onClick={closeBatteryHelpTextModal} className="close">
          {t("close")}
        </button>
      </Modal>
    </div>
  );
};

Battery.propTypes = exact({
  t: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  minScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired
});

export default Battery;
