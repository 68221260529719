import "App.scss";
import { useState, useRef, useEffect } from "react";
import { usePrevious, scrollTo } from "utils.js";
import { scenes, selectables, getNewGameState, getSceneStartAndStopSoundFunctions } from "game-state.js";
import { getScore } from "game-score.js";
import { getNewCharacterState } from "elements/character/Character.js";
import Intro from "scenes/Intro.js";
import CharacterSelection from "scenes/CharacterSelection.js";
import CharacterSetUp from "scenes/CharacterSetUp.js";
import Breakfast from "scenes/Breakfast.js";
import Transportation from "scenes/Transportation.js";
import Lunch from "scenes/Lunch.js";
import Breaktime from "scenes/Breaktime.js";
import Snacking from "scenes/Snacking.js";
import Supermarket from "scenes/Supermarket.js";
import DinnerSelection from "scenes/DinnerSelection.js";
import DinnerCooking from "scenes/DinnerCooking.js";
import DinnerDelivery from "scenes/DinnerDelivery.js";
import MapTransition from "scenes/MapTransition.js";
import World from "scenes/World.js";
import { jik } from "common/sounds.js";
import { languages, getValidLanguageCodeFromUrl, setTranslationLanguage, getT } from "common/languages.js";
import { allBadges } from "elements/badges/badges.js";
import Timeline from "common/Timeline.js";
import Battery from "common/Battery.js";

const DEBUG_MENU_CAPABILITY = process.env.REACT_APP_DEBUG_MENU_CAPABILITY === "true";

const initialGameState = getNewGameState();

function App() {
  // Generic App state which does not participate in the game state.
  const [sceneStateResetKey, setSceneStateResetKey] = useState(0);
  const [languageCode, setLanguageCode] = useState(getValidLanguageCodeFromUrl());

  // Game specific state which could be potentially saved and loaded and would
  // describe the full status of the game.
  const [scene, setScene] = useState(initialGameState.scene);
  const previousScene = usePrevious(scene);
  const [characterType, setCharacterType] = useState(undefined);
  const [characterSkinTone, setCharacterSkinTone] = useState(undefined);
  const [characterOutfit, setCharacterOutfit] = useState(undefined);
  const [characterHairStyle, setCharacterHairStyle] = useState(undefined);
  const [characterAccessory, setCharacterAccessory] = useState(undefined);
  const [badges, setBadges] = useState(initialGameState.badges);
  const [selections, setSelections] = useState(initialGameState.selections);
  const gameState = {
    scene,
    character: {
      type: characterType,
      skinTone: characterSkinTone,
      outfit: characterOutfit,
      hairStyle: characterHairStyle,
      accessory: characterAccessory
    },
    badges: badges,
    selections: selections
  };
  const currentSceneInitialGameState = useRef(initialGameState);

  // Helpers.
  const t = getT();

  // State mutators.
  const addBadge = badge => allBadges.includes(badge) && !badges.includes(badge) && setBadges(badges.concat(badge));

  const addSelection = selection => selectables.includes(selection) && setSelections(selections.concat(selection));

  const languageSelectorLanguageChanged = languageCode => {
    setTranslationLanguage(languageCode);
    setLanguageCode(languageCode);
    window.document.documentElement.setAttribute("lang", languageCode);
    window.document.title = getT()("title");
  };

  const setGameState = gameState => {
    setSceneStateResetKey(sceneStateResetKey + 1); // This is a hacky way to force a child component to start with a fresh state. The game state may be present within App.js, but there are small bits of state present in specific scenes which need to be reset when the player restarts a scene.
    setScene(gameState.scene);
    setCharacterType(gameState.character ? gameState.character.type : undefined);
    setCharacterSkinTone(gameState.character ? gameState.character.skinTone : undefined);
    setCharacterOutfit(gameState.character ? gameState.character.outfit : undefined);
    setCharacterHairStyle(gameState.character ? gameState.character.hairStyle : undefined);
    setCharacterAccessory(gameState.character ? gameState.character.accessory : undefined);
    setBadges(gameState.badges);
    setSelections(gameState.selections);
  };

  const restartScene = () => setGameState(currentSceneInitialGameState.current);

  const restartGame = () => setGameState(initialGameState);

  const selectCharacterType = type => {
    const newCharacter = getNewCharacterState(type);
    setCharacterType(newCharacter.type);
    setCharacterSkinTone(newCharacter.skinTone);
    setCharacterOutfit(newCharacter.outfit);
    setCharacterHairStyle(newCharacter.hairStyle);
    setCharacterAccessory(newCharacter.accessory);
  };

  if (currentSceneInitialGameState.current.scene !== gameState.scene) {
    console.log("Scene change detected. New game state is:", gameState);

    // Keep track of game state when player moves to a new scene because it will
    // be needed in case player wants to restart scene.
    currentSceneInitialGameState.current = JSON.parse(JSON.stringify(gameState)); // Very rough deep clone https://stackoverflow.com/a/47690512/72478

    // Scroll to top on scene change.
    scrollTo({ top: 0 });
  }

  const score = getScore(selections);

  // Possible score ranges based on game-score.js:
  // - adult: -14 to 12
  // - child: -11 to 10
  // Having a -9 to 9 is a good compromise.
  const battery = <Battery t={t} score={score} minScore={-9} maxScore={9} />;

  const debug = DEBUG_MENU_CAPABILITY && new URLSearchParams(window.location.search).has("debug");

  // Play ambient scene sounds (music). In case the new scene needs the same sound, do not stop the previously playing one.
  useEffect(() => {
    const [startSoundFunction] = getSceneStartAndStopSoundFunctions(scene);
    const [previousStartSoundFunction, previousStopSoundFunction] = getSceneStartAndStopSoundFunctions(previousScene);
    if (startSoundFunction !== previousStartSoundFunction) {
      if (previousStopSoundFunction) previousStopSoundFunction();
      if (startSoundFunction) startSoundFunction();
    }
  }, [scene, previousScene]);

  return (
    <div className="App">
      {debug && (
        <section className="debug">
          {scenes.map(sceneName => (
            <button
              key={sceneName}
              onClick={() => {
                setScene(sceneName);
              }}
              disabled={sceneName === gameState.scene}
            >
              {sceneName}
            </button>
          ))}
        </section>
      )}
      <Timeline t={t} scene={gameState.scene} characterType={gameState.character.type} />
      {gameState.scene === "intro" && (
        <Intro
          key={sceneStateResetKey}
          t={t}
          languages={languages}
          languageCode={languageCode}
          setLanguageCode={languageSelectorLanguageChanged}
          goToNextScene={() => {
            setScene("character-selection");
          }}
        />
      )}
      {gameState.scene === "character-selection" && (
        <CharacterSelection
          key={sceneStateResetKey}
          t={t}
          setCharacterType={selectCharacterType}
          setCharacterSkinTone={setCharacterSkinTone}
          setCharacterOutfit={setCharacterOutfit}
          setCharacterHairStyle={setCharacterHairStyle}
          setCharacterAccessory={setCharacterAccessory}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("character-set-up");
            jik();
          }}
        />
      )}
      {gameState.scene === "character-set-up" && (
        <CharacterSetUp
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          setCharacterSkinTone={setCharacterSkinTone}
          setCharacterOutfit={setCharacterOutfit}
          setCharacterHairStyle={setCharacterHairStyle}
          setCharacterAccessory={setCharacterAccessory}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("breakfast");
            jik();
          }}
        />
      )}
      {gameState.scene === "breakfast" && (
        <Breakfast
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("map-transition-from-breakfast-to-transportation");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "map-transition-from-breakfast-to-transportation" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="breakfast"
          toScene="transportation"
          goToNextScene={() => {
            setScene("transportation");
            jik();
          }}
        />
      )}
      {gameState.scene === "transportation" && (
        <Transportation
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("map-transition-from-transportation-to-lunch");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "map-transition-from-transportation-to-lunch" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="transportation"
          toScene="lunch"
          goToNextScene={() => {
            setScene("lunch");
            jik();
          }}
        />
      )}
      {gameState.scene === "lunch" && (
        <Lunch
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene(
              gameState.character.type === "adult"
                ? "map-transition-from-lunch-to-supermarket"
                : "map-transition-from-lunch-to-breaktime"
            );
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "map-transition-from-lunch-to-supermarket" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="lunch"
          toScene="supermarket"
          goToNextScene={() => {
            setScene("supermarket");
            jik();
          }}
        />
      )}
      {gameState.scene === "map-transition-from-lunch-to-breaktime" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="lunch"
          toScene="breaktime"
          goToNextScene={() => {
            setScene("breaktime");
            jik();
          }}
        />
      )}
      {gameState.scene === "breaktime" && gameState.character.type === "child" && (
        <Breaktime
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("map-transition-from-breaktime-to-snacking");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "map-transition-from-breaktime-to-snacking" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="breaktime"
          toScene="snacking"
          goToNextScene={() => {
            setScene("snacking");
            jik();
          }}
        />
      )}
      {gameState.scene === "snacking" && gameState.character.type === "child" && (
        <Snacking
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("dinner-selection");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "supermarket" && gameState.character.type === "adult" && (
        <Supermarket
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("dinner-selection");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "dinner-selection" && (
        <DinnerSelection
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene(
              selections.includes("food-delivery")
                ? "map-transition-from-dinner-selection-to-dinner-delivery"
                : selections.includes("cooking-at-home")
                ? "map-transition-from-dinner-selection-to-dinner-cooking"
                : undefined
            );
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "map-transition-from-dinner-selection-to-dinner-delivery" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="dinner-selection"
          toScene="dinner-delivery"
          goToNextScene={() => {
            setScene("dinner-delivery");
            jik();
          }}
        />
      )}
      {gameState.scene === "map-transition-from-dinner-selection-to-dinner-cooking" && (
        <MapTransition
          characterType={gameState.character.type}
          fromScene="dinner-selection"
          toScene="dinner-cooking"
          goToNextScene={() => {
            setScene("dinner-cooking");
            jik();
          }}
        />
      )}
      {gameState.scene === "dinner-delivery" && (
        <DinnerDelivery
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("world");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "dinner-cooking" && (
        <DinnerCooking
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          addSelection={addSelection}
          badges={gameState.badges}
          addBadge={addBadge}
          restartScene={restartScene}
          restartGame={restartGame}
          goToNextScene={() => {
            setScene("world");
            jik();
          }}
          battery={battery}
        />
      )}
      {gameState.scene === "world" && (
        <World
          key={sceneStateResetKey}
          t={t}
          character={gameState.character}
          badges={gameState.badges}
          restartGame={restartGame}
          score={score}
          languageCode={languageCode}
        />
      )}
    </div>
  );
}

export default App;
