const i18n = {
  title: "Žaidimas 2050-aisiais – sveika visuomenė",
  start: "Pradėti",
  select_language: "Pasirinkti kalbą",
  adult: "Suaugusysis",
  child: "Vaikas",
  fullscreen: "Rodyti per visą ekraną",
  restart_scene: "Iš naujo pradėti sceną",
  restart_game: "Iš naujo pradėti žaidimą",
  previous: "Ankstesnis",
  next: "Paskesnis",
  close: "Užverti",
  toggle_sound: "Įjungti/išjungti garsą",
  character_selection_title: "Pasirinkite personažą",
  character_selection_body: "Tai, ką renkamės, lemia mūsų ateitį. Šiame žaidime – kasdienės situacijos. Darykite įvairius pasirinkimus ir sužinokite apie jų pasekmes. Žaidime yra paslėptų užduotėlių – jas atlikę gausite specialių ženklelių! Pirmiausia susikurkite personažą.",
  character_set_up_title: "Pasirinkite personažo išvaizdą",
  select_me: "Rinktis",
  breakfast_title: "Ką renkatės pusryčiams?",
  your_badges: "Ženkleliai",
  badge_water_conservation_master_title: "Meistras",
  badge_water_conservation_master_description: "Sveikiname! Esate ribotų išteklių taupymo meistras! Vanduo yra būtinas visų rūšių gyvybei.",
  badge_clean_street_wizard_title: "Geroji fėja",
  badge_clean_street_wizard_description: "Sveikiname! Esate aplinkos švarinimo stebukladarys, nes renkate šiukšles ir išmetate jas į šiukšliadėžę.",
  badge_eco_ninja_title: "Nindzė",
  badge_eco_ninja_description: "Sveikiname! Esate energijos taupymo nindzė. Prietaisų sunaudojamos energijos kiekio mažinimas – puikus būdas padėti mažinti energijos suvartojimą.",
  badge_energy_saving_expert_title: "Ekspertas",
  badge_energy_saving_expert_description: "Sveikiname! Esate energijos taupymo ekspertas. Ir toliau ieškokite būdų, kaip suvartoti kuo mažiau energijos.",
  badge_low_waste_champion_title: "Čempionas",
  badge_low_waste_champion_description: "Sveikiname! Esate atliekų kiekio mažinimo ir aplinkos tausojimo čempionas, nes stengiatės naudoti kuo mažiau plastiko.",
  badge_responsible_shopper_title: "Profesionalas",
  badge_responsible_shopper_description: "Sveikiname! Jūs profesionaliai priimate sveikus, aplinkai naudingus sprendimus. Ir toliau naudokite kuo mažiau plastiko, nešiokitės daugkartinio naudojimo maišelį.",
  badge_recycling_captain_title: "Žvaigždė",
  badge_recycling_captain_description: "Sveikiname! Esate perdirbimo žvaigždė. Kad aplinka būtų sveika, svarbu perdirbti ir pakartotinai naudoti medžiagas.",
  faucet: "Čiaupas",
  transportation_title_adult: "Kaip keliaujate į darbą?",
  transportation_title_child: "Kaip keliaujate į mokyklą?",
  trash: "Šiukšlės",
  no_drink: "Jokio gėrimo",
  no_drink_description: "",
  tap_water: "Vandentiekio vanduo",
  tap_water_description: "Gerkite pakankamai vandens. Pasistenkite per dieną jo išgerti ne mažiau kaip 1,5-2 litrus.",
  milk: "Pienas",
  milk_description: "Rinkitės paprastą nesaldintą pieną, geriausia neriebų.",
  freshly_made_juice: "Šviežiai spaustos sultys",
  freshly_made_juice_description: "Jei mėgstate sultis, rinkitės šviežiai spaustas. Cukraus pridėti nereikia. Stenkitės valgyti šviežių vaisių.",
  coffee_or_tea: "Kava/ arbata",
  coffee_or_tea_description: "Arbatą ir kavą geriausia gerti be cukraus.",
  plant_based_drink: "Augalinis gėrimas",
  plant_based_drink_description: "Išbandykite augalinius gėrimus be pridėtinio cukraus ir, jei įmanoma, rinkitės tuos, kuriuose yra vitaminų ir mineralų.",
  whole_grain_cereals: "Visų grūdo dalių dribsniai",
  whole_grain_cereals_description: "Rinkitės visų grūdo dalių dribsnius be pridėtinio cukraus.",
  choco_balls: "Šokoladiniai rutuliukai",
  choco_balls_description: "Pusryčių javainiuose gali slypėti didelė mūsų kasdien suvartojamo cukraus dalis. Gal galite rinktis tuos, kuriuose yra mažiau pridėtinio cukraus? Jei valgote pusryčių javainius, rinkitės tuos, kuriuose yra daugiau skaidulinių medžiagų.",
  white_bread_or_toast: "Balta duona/ baltos duonos skrebutis",
  white_bread_or_toast_description: "Išbandykite visų grūdo dalių duoną.",
  wholemeal_bread_or_toast: "Visų grūdo dalių duona/ visų grūdo dalių duonos skrebutis",
  wholemeal_bread_or_toast_description: "Išbandykite visų grūdo dalių duoną.",
  biscuits: "Sausainiai",
  biscuits_description: "Sausainiai labai kaloringi. Juose gali slypėti didelė mūsų kasdien suvartojamo cukraus ir riebalų dalis. Geriau sausainių valgyti mažiau. Rinkitės tuos, kuriuose yra mažiau riebalų ir cukraus. Be to, jei juos valgote, rinkitės tuos, kuriuose yra daugiau skaidulinių medžiagų.",
  no_topping: "Jokių priedų",
  no_topping_description: "",
  yogurt: "Jogurtas",
  yogurt_description: "Išbandykite neriebų jogurtą be priedų ir be pridėtinio cukraus.",
  honey: "Medus",
  honey_description: "Rinkitės jogurtą be priedų ir nepridėkite cukraus ar uogienės. Verčiau valgykite jį su vaisiais ar nesaldintais visų grūdo dalių dribsniais.",
  jam: "Uogienė",
  jam_description: "Rinkitės jogurtą be priedų ir nepridėkite cukraus ar uogienės. Verčiau valgykite jį su vaisiais ar nesaldintais visų grūdo dalių dribsniais.",
  fruit: "Vaisiai",
  fruit_description: "Vaisiai pusryčiams - puiki dienos pradžia. Stenkitės rinktis sezoninius vaisius. Jei juos papildysite riešutais, rinkitės tuos, į kuriuos nepridėta druskos ar cukraus.",
  butter: "Sviestas",
  butter_description: "Svieste yra daug riebalų ir gali būti druskos. Pasistenkite jo nevalgyti per dažnai ir rinkitės nesūdytą.",
  chocolate_cream: "Šokoladinis kremas",
  chocolate_cream_description: "Šokoladiniame kreme yra daug cukraus ir sočiųjų riebalų. Pasistenkite jo nevalgyti per dažnai.",
  ham_or_bacon: "Kumpis/ šoninė",
  ham_or_bacon_description: "Perdirbtoje mėsoje yra daug riebalų ir druskos. Jei ją valgote, darykite tai kuo rečiau.",
  cheese: "Sūris",
  cheese_description: "Sūryje yra daug druskos ir sočiųjų riebalų. Pasistenkite jo nevalgyti per dažnai ir valgykite po nedaug.",
  avocado_and_tomato: "Avokadas ir pomidoras",
  avocado_and_tomato_description: "Šiek tiek daržovių pusryčiams - puiki dienos pradžia. Stenkitės per dieną suvalgyti daug vaisių ir daržovių – visų pirma rinkitės vietinius ir sezoninius.",
  battery: "Baterija",
  battery_description: "Atidžiai stebėkite bateriją - ji rodo jūsų pasirinkimų pasekmes!",
  lunch_title: "Ko norėtumėte pietums?",
  breaktime_title: "Laisva popietė. Laisvalaikis!",
  snacking_title: "Norite užkąsti?",
  supermarket_title: "Ką renkatės parduotuvėje?",
  dinner_selection_title: "Kokie jūsų vakarienės planai?",
  dinner_cooking_title: "Kas vakarienei?",
  dinner_delivery_title: "Kas vakarienei?",
  your_available_items: "Pasirinkimo variantai",
  your_selections: "Pasirinkti variantai",
  walk: "Pėsčiomis",
  walk_description: "Kasdien būkite fiziškai aktyvus. Jei įmanoma, į mokyklą / darbą keliaukite pėsčiomis arba dviračiu. Taip išsaugosite gerą fizinę formą ir nekenksite aplinkai.",
  bicycle: "Dviračiu",
  bicycle_description: "Kasdien būkite fiziškai aktyvus. Jei įmanoma, į mokyklą / darbą keliaukite pėsčiomis arba dviračiu. Taip išsaugosite gerą fizinę formą ir nekenksite aplinkai.",
  scooter_or_motorbike: "Paspirtuku/ motociklu",
  scooter_or_motorbike_description: "Pasistenkite automobiliu ar motociklu važinėti rečiau, kad aplinka taptų švaresnė. Jei tai neįmanoma, pabandykite su kuo nors susitarti į mokyklą / darbą keliauti viena transporto priemone.",
  car: "Automobiliu",
  car_description: "Pasistenkite automobiliu ar motociklu važinėti rečiau, kad aplinka taptų švaresnė. Jei tai neįmanoma, pabandykite su kuo nors susitarti į mokyklą / darbą keliauti viena transporto priemone.",
  public_transport: "Viešuoju transportu",
  public_transport_description: "Užuot važiavę automobiliu, keliaukite viešuoju transportu bei derinkite šį keliavimo būdą su ėjimu pėsčiomis ir važiavimu dviračiu. Pailsėkite nuo vairavimo ir padėkite tausoti aplinką.",
  salad_with_egg: "Salotos su kiaušiniu",
  salad_with_egg_description: "Kai įmanoma, papildykite patiekalus šviežiomis daržovėmis.",
  beef_steak: "Jautienos kepsnys",
  beef_steak_description: "Jei valgote mėsą, galbūt galėtumėte tai daryti rečiau, visų pirma valgyti mažiau raudonos ir perdirbtos mėsos? Tai bus naudingiau jūsų sveikatai ir aplinkai.",
  chicken: "Vištiena",
  chicken_description: "Jei valgote mėsą, galbūt galėtumėte tai daryti rečiau, visų pirma valgyti mažiau raudonos ir perdirbtos mėsos? Tai bus naudingiau jūsų sveikatai ir aplinkai.",
  lentil_soup: "Lęšių sriuba",
  lentil_soup_description: "Jei norite, kad jūsų mityba būtų įvairi ir daugiausia augalinė, verta reguliariai valgyti ankštinius.",
  vegetable_burger: "Vegetariškas mėsainis",
  vegetable_burger_description: "Vegetariškas mėsainis gali puikiai pakeisti mėsą. Žiūrėkite, kad jame nebūtų per daug druskos.",
  white_pasta: "Kvietiniai makaronai",
  white_pasta_description: "Rinkdamiesi makaronus, ryžius ir duoną, pirmenybę teikite visų grūdo dalių produktams.",
  wholegrain_pasta: "Visų grūdo dalių makaronai",
  wholegrain_pasta_description: "Rinkdamiesi makaronus, ryžius ir duoną, pirmenybę teikite visų grūdo dalių produktams.",
  white_rice: "Baltieji ryžiai",
  white_rice_description: "Rinkdamiesi makaronus, ryžius ir duoną, pirmenybę teikite visų grūdo dalių produktams.",
  wholegrain_rice: "Visų grūdo dalių ryžiai",
  wholegrain_rice_description: "Rinkdamiesi makaronus, ryžius ir duoną, pirmenybę teikite visų grūdo dalių produktams.",
  white_bread_bun: "Kvietinė bandelė",
  white_bread_bun_description: "Rinkdamiesi makaronus, ryžius ir duoną, pirmenybę teikite visų grūdo dalių produktams.",
  no_salad: "Nieko",
  no_salad_description: "",
  fresh_green_salad: "Šviežių daržovių salotos",
  fresh_green_salad_description: "Papildykite patiekalus ryškiaspalvėmis sezoninėmis daržovėmis - kuo įvairesnėmis, tuo geriau.",
  french_fries: "Gruzdintos bulvytės",
  french_fries_description: "Geriau rinktis ne gruzdintus bulvių produktus, o virtas bulves.",
  boiled_broccoli: "Virti brokoliai",
  boiled_broccoli_description: "Stenkitės per dieną suvalgyti daug daržovių. Rinkitės vietines ir sezonines daržoves.",
  bottled_water: "Vanduo buteliuose",
  bottled_water_description: "Gerkite vandentiekio vandenį, kad naudotumėte mažiau plastiko.",
  soft_drink: "Gaivusis gėrimas",
  soft_drink_description: "Saldintuose gėrimuose slypi didelė mūsų kasdien suvartojamo cukraus dalis. Gal galite vietoj jų gerti vandenį?",
  beer_or_wine: "Alus/ vynas",
  beer_or_wine_description: "Jei vartojate kokios nors rūšies alkoholį, darykite tai kuo rečiau. Negerdami alkoholio jausitės sveikesni.",
  watching_tv: "Televizoriaus žiūrėjimas arba vaizdo žaidimų žaidimas",
  watching_tv_description: "Stenkitės kiekvieną dieną būti fiziškai aktyvūs ir kuo mažiau laiko praleisti sėdėdami. Laisvalaikiu stenkitės pasportuoti, pažaisti su draugais arba padėti savo bendruomenei. Tai naudinga sveikatai ir visuomenei. Stenkitės pernelyg neužsisėdėti prie ekrano.",
  doing_sports: "Sportavimas",
  doing_sports_description: "Stenkitės kiekvieną dieną būti fiziškai aktyvūs ir kuo mažiau laiko praleisti sėdėdami. Laisvalaikiu stenkitės pasportuoti, pažaisti su draugais arba padėti savo bendruomenei. Tai naudinga sveikatai ir visuomenei. Stenkitės pernelyg neužsisėdėti prie ekrano.",
  playing_outside: "Žaidimas lauke",
  playing_outside_description: "Stenkitės kiekvieną dieną būti fiziškai aktyvūs ir kuo mažiau laiko praleisti sėdėdami. Laisvalaikiu stenkitės pasportuoti, pažaisti su draugais arba padėti savo bendruomenei. Tai naudinga sveikatai ir visuomenei. Stenkitės pernelyg neužsisėdėti prie ekrano.",
  charity_work: "Užsiėmimas labdaringa veikla su suaugusiaisiais",
  charity_work_description: "Stenkitės kiekvieną dieną būti fiziškai aktyvūs ir kuo mažiau laiko praleisti sėdėdami. Laisvalaikiu stenkitės pasportuoti, pažaisti su draugais arba padėti savo bendruomenei. Tai naudinga sveikatai ir visuomenei. Stenkitės pernelyg neužsisėdėti prie ekrano.",
  carrots_tomatoes: "Morkos/ vyšniniai pomidorai",
  carrots_tomatoes_description: "Rinkitės vaisius, daržoves ar riešutus. Venkite užkandžių ir gėrimų, kuriuose yra daug cukraus, riebalų ir druskos.",
  fresh_fruit: "Švieži vaisiai",
  fresh_fruit_description: "Rinkitės vaisius, daržoves ar riešutus. Venkite užkandžių ir gėrimų, kuriuose yra daug cukraus, riebalų ir druskos.",
  chocolate_bar: "Šokolado plytelė",
  chocolate_bar_description: "Rinkitės vaisius, daržoves ar riešutus. Venkite užkandžių ir gėrimų, kuriuose yra daug cukraus, riebalų ir druskos.",
  chips: "Traškučiai",
  chips_description: "Rinkitės vaisius, daržoves ar riešutus. Venkite užkandžių ir gėrimų, kuriuose yra daug cukraus, riebalų ir druskos.",
  juice_drink: "Sulčių gėrimas",
  juice_drink_description: "Užuot gėrę sulčių gėrimus, rinkitės šviežius vaisius arba šviežiai spaustas sultis be pridėtinio cukraus.",
  supermarket_prompt: "Pasirinkite iki 9 produktų.",
  raw_fish: "Žalia žuvis",
  raw_fish_description: "Reguliarus žuvies valgymas padeda gauti svarbių maisto medžiagų. Išbandykite įvairias žuvis.",
  raw_beef: "Žalia jautiena",
  raw_beef_description: "Jei valgote mėsą, galbūt galėtumėte tai daryti rečiau, visų pirma valgyti mažiau raudonos ir perdirbtos mėsos? Tai bus naudingiau jūsų sveikatai ir aplinkai.",
  raw_chicken: "Žalia vištiena",
  raw_chicken_description: "Jei valgote mėsą, galbūt galėtumėte tai daryti rečiau, visų pirma valgyti mažiau raudonos ir perdirbtos mėsos? Tai bus naudingiau jūsų sveikatai ir aplinkai.",
  whole_salad: "Visa salotinė daržovė - pvz., salota",
  whole_salad_description: "Rinkitės šviežius vaisius ir daržoves, ypač vietinius ir sezoninius.",
  packaged_salad: "Salotos pakuotėse",
  packaged_salad_description: "Rinkitės šviežius vaisius ir daržoves, ypač vietinius ir sezoninius.",
  seasonal_fruit: "Sezoniniai vietiniai vaisiai",
  seasonal_fruit_description: "Rinkitės šviežius vaisius ir daržoves, ypač vietinius ir sezoninius.",
  exotic_fruit: "Egzotiniai vaisiai",
  exotic_fruit_description: "Rinkitės šviežius vaisius ir daržoves, ypač vietinius ir sezoninius.",
  ham: "Kumpis",
  ham_description: "Perdirbtoje mėsoje yra daug riebalų ir druskos. Jei ją valgote, stenkitės tai daryti kuo rečiau.",
  tofu: "Tofu",
  tofu_description: "Išbandykite naujus augalinius produktus ir naujus receptus.",
  hummus: "Humusas",
  hummus_description: "Humusas - puikus pasirinkimas, padėsiantis valgyti daugiau ankštinių.",
  precooked_pizza: "Picos pusgaminis",
  precooked_pizza_description: "Pusgaminiuose gali būti daug druskos arba riebalų. Vietoj jų rinkitės neapvirtus ir neapkeptus produktus. Taip galėsite kontroliuoti druskos, cukraus ir riebalų kiekį maiste.",
  precooked_fries: "Gruzdintų bulvyčių pusgaminis",
  precooked_fries_description: "Pusgaminiuose gali būti daug druskos arba riebalų. Vietoj jų rinkitės neapvirtus ir neapkeptus produktus. Taip galėsite kontroliuoti druskos, cukraus ir riebalų kiekį maiste.",
  food_delivery: "Maisto pristatymas",
  food_delivery_description: "",
  cooking_at_home: "Maisto gaminimas namuose",
  cooking_at_home_description: "",
  cheeseburger: "Sūrainis, gruzdintos bulvytės ir gaivusis gėrimas",
  cheeseburger_description: "Stenkitės tokio maisto nevalgyti. Jame daug riebalų ir druskos. Gerkite vandenį.",
  pizza_and_soft_drink: "Pica ir gaivusis gėrimas",
  pizza_and_soft_drink_description: "Tokiame maiste yra daug riebalų, druskos ir cukraus. Rinkitės paprastas picas su kuo mažiau sudedamųjų dalių arba su daugiau daržovių. Jei įmanoma, rinkitės visų grūdo dalių pagrindą. Gerkite vandenį.",
  noodles_with_vegetables: "Makaronai su daržovėmis",
  noodles_with_vegetables_description: "Tai geras pasirinkimas siekiant valgyti daugiau daržovių. Kad patiekalas būtų visavertis, pridėkite šiek tiek ankštinių.",
  mushroom_risoto: "Rizotas su grybais",
  mushroom_risoto_description: "Grybai - geras vegetarinio maisto pasirinkimas. Stenkitės nepridėti per daug sūrio. Šį patiekalą galite valgyti su salotomis.",
  precooked_meal: "Pusgaminiai - vištienos kepsneliai",
  precooked_meal_description: "Pusgaminiuose gali būti daug druskos, riebalų ar net cukraus. Vietoj jų rinkitės neapvirtus ir neapkeptus produktus. Taip geriau kontroliuosite suvartojamą šių maisto medžiagų kiekį. Norėdami pagardinti maistą rinkitės žoleles ir prieskonius, o ne druską.",
  frozen_pizza: "Šaldyta pica",
  frozen_pizza_description: "Pusgaminiuose gali būti daug druskos, riebalų ar net cukraus. Vietoj jų rinkitės neapvirtus ir neapkeptus produktus. Taip geriau kontroliuosite suvartojamą šių maisto medžiagų kiekį. Norėdami pagardinti maistą rinkitės žoleles ir prieskonius, o ne druską.",
  rice_and_salad: "Namuose paruoštas patiekalas: ryžiai su šviežių daržovių salotomis",
  rice_and_salad_description: "Pusgaminiuose gali būti daug druskos, riebalų ar net cukraus. Vietoj jų rinkitės neapvirtus ir neapkeptus produktus. Taip geriau kontroliuosite suvartojamą šių maisto medžiagų kiekį. Norėdami pagardinti maistą rinkitės žoleles ir prieskonius, o ne druską.",
  ham_sandwich_and_soft_drink: "Sumuštinis su kumpiu ir gaivusis gėrimas",
  ham_sandwich_and_soft_drink_description: "Perdirbtoje mėsoje yra daug riebalų ir druskos. Jei ją valgote, stenkitės tai daryti kuo rečiau. Taip pat venkite saldintų ir gaiviųjų gėrimų. Vietoj jų gerkite vandentiekio vandenį.",
  wholegrain_pasta_with_sauce: "Visų grūdo dalių makaronai su daržovių padažu",
  wholegrain_pasta_with_sauce_description: "Išbandykite visų grūdo dalių makaronus.",
  hnw_title_adult: "Sveikas naujasis pasaulis",
  hnw_description_1_adult: "Tai, ką renkamės, lemia mūsų ateitį. Jei visi valgysime ir gyvensime taip, kaip jūs šiame žaidime, mūsų ateitis gali tapti sveikesnė, nes žmonės rūpinsis vieni kitais ir aplinka.",
  hnw_description_2_adult: "Mokslininkai nuolat nustato, kad siekdami apsaugoti savo ir mūsų planetos sveikatą turime maitintis įvairiai: valgyti daug grūdų, ankštinių, šviežių daržovių ir vaisių. Reikėtų vengti valgyti perdirbtą maistą, mėsą ir užkandžius, kuriuose yra daug druskos, cukraus ir riebalų. Dar geriau, jei esame fiziškai aktyvūs, vaikštome pėsčiomis, važinėjame dviračiu ar naudojamės viešuoju transportu ir kuo mažiau keliaujame automobiliu.",
  hnw_description_3_adult: "Paprasti dalykai gali sustiprinti jūsų sveikatą ir pagerinti aplinkos būklę. To rezultatas – švarus oras ir vanduo, saugus maistas, derlingas dirvožemis, žaliosios erdvės, pagarba gamtai ir visiems žmonėms. Prisiminkite tai ne tik žaisdami šį žaidimą. Ir pakvieskite šiuo keliu eiti ir savo draugus bei šeimą.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Ar norite žaisti iš naujo?",
  hnw_title_child: "Tai, ką renkamės, lemia mūsų ateitį",
  hnw_description_1_child: "Jei visi valgysime ir gyvensime taip, kaip tu šiame žaidime, mūsų ateitis gali tapti sveikesnė.",
  hnw_description_2_child: "Štai ką gali nuveikti:\n- maitinkis įvairiai, kad apsaugotum savo ir mūsų planetos sveikatą;\n- rinkis grūdus, ankštinius, šviežias daržoves ir vaisius; \n- valgyk kuo mažiau perdirbto maisto, mėsos ir užkandžių, kuriuose yra daug druskos, cukraus ir riebalų;\n- būk fiziškai aktyvus, vaikščiok pėsčiomis, važinėk dviračiu ar naudokis viešuoju transportu;\n- kuo mažiau keliauk automobiliu.",
  hnw_description_3_child: "Šie paprasti dalykai gali sustiprinti tavo sveikatą ir padėti rūpintis aplinka ir visais žmonėmis.",
  hnw_description_4_child: "Prisimink tai ne tik žaisdamas šį žaidimą.",
  hnw_description_5_child: "Ir paragink taip elgtis ir savo draugus bei šeimą.",
  hnw_description_6_child: "Ar norite žaisti iš naujo?",
  etl_title_adult: "Valgome tam, kad gyventume",
  etl_description_1_adult: "Tai, ką renkamės, lemia mūsų ateitį. Galbūt apie tai nesusimąstote, bet jūsų gyvenimo būdas gali turėti įtakos mūsų planetos sveikatai.",
  etl_description_2_adult: "Mokslininkai nuolat nustato, kad siekdami apsaugoti savo ir mūsų planetos sveikatą turime maitintis įvairiai: valgyti daug grūdų, ankštinių, šviežių daržovių ir vaisių. Reikėtų vengti valgyti perdirbtą maistą, mėsą ir užkandžius, kuriuose yra daug druskos, cukraus ir riebalų. Dar geriau, jei esame fiziškai aktyvūs, vaikštome pėsčiomis, važinėjame dviračiu ar naudojamės viešuoju transportu ir kuo mažiau keliaujame automobiliu.",
  etl_description_3_adult: "Paprasti dalykai gali sustiprinti jūsų sveikatą ir pagerinti aplinkos būklę. To rezultatas – švarus oras ir vanduo, saugus maistas, derlingas dirvožemis, žaliosios erdvės, pagarba gamtai ir visiems žmonėms.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Ar norite žaisti iš naujo?",
  etl_title_child: "Tai, ką renkamės, lemia mūsų ateitį",
  etl_description_1_child: "Jei visi stengsimės sveikiau maitintis ir gyventi, mūsų ateitis gali tapti sveikesnė.",
  etl_description_2_child: "Štai ką gali nuveikti:\n- maitinkis įvairiai, kad apsaugotum savo ir mūsų planetos sveikatą;\n- rinkis grūdus, ankštinius, šviežias daržoves ir vaisius; \n- valgyk kuo mažiau perdirbto maisto, mėsos ir užkandžių, kuriuose yra daug druskos, cukraus ir riebalų;\n- būk fiziškai aktyvus, vaikščiok pėsčiomis, važinėk dviračiu ar naudokis viešuoju transportu;\n- kuo mažiau keliauk automobiliu.",
  etl_description_3_child: "Šie paprasti dalykai gali sustiprinti tavo sveikatą ir padėti rūpintis aplinka ir visais žmonėmis.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Ar norite žaisti iš naujo?",
  share: "Dalytis",
  replay: "Žaisti dar kartą",
  its_up_to_you: "Viskas jūsų rankose",
  badge_action_1: "Atlikote paslėptą užduotėlę!",
  badge_action_2: "Gaunate ženklelį %{badge}.",
  fridgeDoor: "Šaldytuvo durys",
  light: "Šviesa",
  bottle: "Gertuvė",
  reusable_bag: "Daugkartinio naudojimo maišelis",
  plastic_bag: "Plastikinis maišelis",
  recycle_bin: "Šiukšliadėžė",
  share_text_subject: "Viskas jūsų rankose",
  share_text_body: "Sužaidžiau žaidimą 2050 m. Čia – mano pasaulis. Smalsu, kaip atrodo taviškis? #ExperienceEUScience\nSužaisk %{shareUrl}\nŠį žaidimą sukūrė Jungtinis tyrimų centras – Europos Komisijos mokslo ir žinių tarnyba.\nDaugiau informacijos  https://joint-research-centre.ec.europa.eu/index_lt?etrans=lt"
};
export default i18n;
