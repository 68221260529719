const i18n = {
  title: "Il-Logħba 2050 tal-Healthy Society",
  start: "Ibda",
  select_language: "Agħżel il-lingwa",
  adult: "Adult",
  child: "Minorenni",
  fullscreen: "Skrin sħiħ",
  restart_scene: "Erġ'ibda x-xena",
  restart_game: "Erġ'ibda l-logħba",
  previous: "Ta' qabel",
  next: "Li jmiss",
  close: "Agħlaq",
  toggle_sound: "Ħoss mixgħul/mitfi",
  character_selection_title: "Agħżel il-karattru tiegħek",
  character_selection_body: "L-għażliet tagħna jaffettwaw il-futur tagħna. F'din il-logħba, nissimulaw sitwazzjonijiet ta' kuljum. Agħmel l-għażliet tiegħek u tgħallem dwar l-impatt tagħhom. Fittex madwarek uħud mis-sigrieti moħbija biex tikseb l-unuri tiegħek. Ejja nibdew billi noħolqu l-karattru tiegħek.",
  character_set_up_title: "Ibni l-karattru tiegħek",
  select_me: "Agħżilni",
  breakfast_title: "“X’tieħu għall-kolazzjon?”",
  your_badges: "Badges",
  badge_water_conservation_master_title: "Master",
  badge_water_conservation_master_description: "Prosit! Inti master biex issalva riżorsa skarsa! L-ilma huwa fundamentali għal kull tip ta’ ħajja.",
  badge_clean_street_wizard_title: "Ġenju",
  badge_clean_street_wizard_description: "Prosit! Inti ġenju biex tgħin lill-ambjent tagħna jsir aktar nadif billi tiġbor l-iskart u tpoġġih fil-landa taż-żibel.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Prosit! Inti ninja fl-iffrankar tal-enerġija. L-iffrankar tal-enerġija mill-apparati domestiċi tagħna huwa mod tajjeb ħafna biex tgħin fit-tnaqqis tal-konsum tal-enerġija.",
  badge_energy_saving_expert_title: "Espert",
  badge_energy_saving_expert_description: "Prosit! Inti espert(a) fl-iffrankar tal-enerġija. Ibqa’ fittex modi kif tnaqqas il-konsum tiegħek tal-enerġija.",
  badge_low_waste_champion_title: "Champion",
  badge_low_waste_champion_description: "Prosit! Inti champion biex tnaqqas l-iskart tiegħek u tgħin lill-ambjent billi tnaqqas l-użu tal-plastik.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "Prosit, inti pro biex tagħmel għażliet tajbin għas-saħħa tal-ambjent. Kompli uża l-anqas ammont ta’ plastik possibbli billi ġġorr basket miegħek li jista’ jerġa’ jintuża.",
  badge_recycling_captain_title: "Stilla",
  badge_recycling_captain_description: "Prosit! Inti stilla fir-riċiklaġġ. Huwa importanti li l-materjali jiġu riċiklati u jintużaw mill-ġdid għal ambjent b’saħħtu.",
  faucet: "Vit",
  transportation_title_adult: "Kif tmur għax-xogħol?",
  transportation_title_child: "Kif tmur l-iskola?",
  trash: "Skart",
  no_drink: "L-ebda xarba",
  no_drink_description: "",
  tap_water: "Ilma tal-vit",
  tap_water_description: "Żomm ruħek idratat(a). Ipprova ixrob mill-anqas litru u nofs sa żewġ litri ilma kuljum.",
  milk: "Ħalib",
  milk_description: "Agħżel ħalib naturali mhux ħelu, preferibbilment b’kontenut baxx ta’ xaħam.",
  freshly_made_juice: "Meraq li jkun għadu kif ingħasar",
  freshly_made_juice_description: "Jekk tħobb il-meraq, agħżel wieħed li jkun għadu kif ingħasar – m'hemmx bżonn iżżid iz-zokkor. Ipprova kul frott frisk.",
  coffee_or_tea: "Kafè/te",
  coffee_or_tea_description: "Gawdi t-te u l-kafè tiegħek preferibbilment bla ma żżid iz-zokkor.",
  plant_based_drink: "Xorb ibbażat fuq il-pjanti",
  plant_based_drink_description: "Ipprova x-xorb ibbażat fuq il-pjanti, mingħajr zokkrijiet miżjuda u jekk possibbli agħżel dawk li fihom il-vitamini u l-minerali.",
  whole_grain_cereals: "Ċereali integrali",
  whole_grain_cereals_description: "Agħti preferenza liċ-ċereali integrali mingħajr zokkrijiet miżjuda.",
  choco_balls: "Blalen taċ-ċikkulata",
  choco_balls_description: "Iċ-ċereali tal-kolazzjon jistgħu jammontaw għal parti konsiderevoli mill-konsum tagħna ta’ kuljum taz-zokkrijiet – tista’ tagħżel dawk b’anqas zokkrijiet miżjuda? U kif tkun qiegħed/qiegħda fiż-żifna, agħżel dawk b’aktar fibra.",
  white_bread_or_toast: "Ħobż/toast abjad",
  white_bread_or_toast_description: "Ipprova l-ħobż integrali.",
  wholemeal_bread_or_toast: "Ħobż/toast integrali",
  wholemeal_bread_or_toast_description: "Ipprova l-ħobż integrali.",
  biscuits: "Gallettini",
  biscuits_description: "Il-gallettini għandhom kontenut għoli ta’ kaloriji u jistgħu jammontaw għal parti konsiderevoli mill-konsum tagħna ta’ kuljum taz-zokkrijiet u tax-xaħam. Ikun aħjar jekk tnaqqas il-konsum tagħhom. Agħti preferenza lil dawk b’anqas xaħam u b’anqas zokkrijiet. U kif tkun qiegħed/qiegħda fiż-żifna, agħżel ukoll dawk b’aktar fibra.",
  no_topping: "L-ebda żieda",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Ipprova l-jogurt naturali b’kontenut baxx ta’ xaħam mingħajr zokkrijiet miżjuda.",
  honey: "Għasel",
  honey_description: "Agħżel il-jogurt naturali mingħajr ma żżid zokkrijiet jew ġamm. Agħti preferenza li żżid il-frott jew iċ-ċereali integrali mhux ħelu.",
  jam: "Ġamm",
  jam_description: "Agħżel il-jogurt naturali mingħajr ma żżid zokkrijiet jew ġamm. Agħti preferenza li żżid il-frott jew iċ-ċereali integrali mhux ħelu.",
  fruit: "Frott",
  fruit_description: "Tkun għażla tajba ħafna li tibda l-ġurnata bil-frott, ipprova agħżel frott staġunali. Jekk iżżid il-ġewż, agħti preferenza lil dawk mingħajr melħ jew mingħajr zokkrijiet miżjuda.",
  butter: "Butir",
  butter_description: "Il-butir fih għadd ġmielu ta' xaħam u jista' jkun mielaħ. Prova tiklux sikwit u agħżel dak mingħajr melħ.",
  chocolate_cream: "Krema taċ-ċikkulata",
  chocolate_cream_description: "Il-krema taċ-ċikkulata fiha ħafna zokkor u xaħam saturat. Ipprova tikolhiex ta’ spiss.",
  ham_or_bacon: "Perżut/bacon",
  ham_or_bacon_description: "Il-laħam ipproċessat fih ammonti għoljin ta’ xaħam u melħ. Jekk tiekol laħam ipproċessat, illimita l-konsum tiegħu.",
  cheese: "Ġobon",
  cheese_description: "Il-ġobon fih għadd ġmielu ta' melħ u xaħam saturat. Prova tiklux sikwit u kulu f'ammonti żgħar.",
  avocado_and_tomato: "Avokado u tadam",
  avocado_and_tomato_description: "Hija għażla tajba ħafna li tibda l-ġurnata bil-ħaxix. Ipprova kul ħafna frott u ħaxix matul il-ġurnata u agħti preferenza lil dawk li huma lokali u staġunali.",
  battery: "Batterija",
  battery_description: "Ħu ħsieb il-batterija għax din turi l-impatt tal-għażliet tiegħek!",
  lunch_title: "X’tixtieq għall-ikla ta’ nofsinhar?",
  breaktime_title: "Waranofsinhar off - Ħin liberu!",
  snacking_title: "Għandek aptit snack?",
  supermarket_title: "X'se tixtri?",
  dinner_selection_title: "X'se tiekol?",
  dinner_cooking_title: "X’hemm għall-ikla ta’ filgħaxija?",
  dinner_delivery_title: "X’hemm għall-ikla ta’ filgħaxija?",
  your_available_items: "L-alternattivi tiegħek",
  your_selections: "L-għażliet tiegħek",
  walk: "Bil-mixi",
  walk_description: "Kun fiżikament attiv(a) fil-ħajja tiegħek ta’ kuljum. Jekk ikun possibbli, imxi jew mur bir-rota l-iskola/ix-xogħol. Dan iżommok f’saħħtek u huwa aħjar għall-ambjent.",
  bicycle: "Rota",
  bicycle_description: "Kun fiżikament attiv(a) fil-ħajja tiegħek ta’ kuljum. Jekk ikun possibbli, imxi jew mur bir-rota l-iskola/ix-xogħol. Dan iżommok f’saħħtek u huwa aħjar għall-ambjent.",
  scooter_or_motorbike: "Scooter/mutur",
  scooter_or_motorbike_description: "Ipprova uża l-karozza jew il-mutur anqas ta’ spiss għal ambjent aktar nadif. Jekk ma jkunx possibbli, ipprova agħmel car-pooling.",
  car: "Karozza",
  car_description: "Ipprova uża l-karozza jew il-mutur anqas ta’ spiss għal ambjent aktar nadif. Jekk ma jkunx possibbli, ipprova agħmel car-pooling.",
  public_transport: "Trasport pubbliku",
  public_transport_description: "Uża t-trasport pubbliku u kkombinah mal-mixi u ċ-ċikliżmu minflok ma tmur bil-karozza. Ħu pawża mis-sewqan u għin lill-ambjent.",
  salad_with_egg: "Insalata bil-bajd",
  salad_with_egg_description: "Żid il-ħaxix frisk mal-ikliet tiegħek, kull meta jkun possibbli.",
  beef_steak: "Steak taċ-ċanga",
  beef_steak_description: "Jekk tiekol il-laħam, ikkunsidra li tnaqqas il-konsum tiegħek, speċjalment il-laħam aħmar jew ipproċessat. Huwa aħjar għal saħħtek u għall-ambjent.",
  chicken: "Tiġieġ",
  chicken_description: "Jekk tiekol il-laħam, ikkunsidra li tnaqqas il-konsum tiegħek, speċjalment il-laħam aħmar jew ipproċessat. Huwa aħjar għal saħħtek u għall-ambjent.",
  lentil_soup: "Soppa tal-għads",
  lentil_soup_description: "Kul il-legumi regolarment bħala parti minn dieta varjata u fil-biċċa l-kbira bbażata fuq il-pjanti.",
  vegetable_burger: "Burger tal-ħaxix",
  vegetable_burger_description: "Burger tal-ħaxix jista’ jkun alternattiva tajba għal-laħam. Attent(a) li ma jkollux wisq melħ.",
  white_pasta: "Għaġin abjad",
  white_pasta_description: "Agħti preferenza lill-prodotti integrali meta tagħżel l-għaġin, ir-ross u l-ħobż.",
  wholegrain_pasta: "Għaġin integrali",
  wholegrain_pasta_description: "Agħti preferenza lill-prodotti integrali meta tagħżel l-għaġin, ir-ross u l-ħobż.",
  white_rice: "Ross abjad",
  white_rice_description: "Agħti preferenza lill-prodotti integrali meta tagħżel l-għaġin, ir-ross u l-ħobż.",
  wholegrain_rice: "Ross integrali",
  wholegrain_rice_description: "Agħti preferenza lill-prodotti integrali meta tagħżel l-għaġin, ir-ross u l-ħobż.",
  white_bread_bun: "Panina tal-ħobż abjad",
  white_bread_bun_description: "Agħti preferenza lill-prodotti integrali meta tagħżel l-għaġin, ir-ross u l-ħobż.",
  no_salad: "Xejn",
  no_salad_description: "",
  fresh_green_salad: "Insalata friska ħadra",
  fresh_green_salad_description: "Żid xi ħaxix tal-istaġun li jagħti l-kulur lill-ikliet tiegħek, aktar ma tvarja aktar ikun aħjar.",
  french_fries: "Patata moqlija",
  french_fries_description: "Il-patata mgħollija hija ppreferuta minn prodotti tal-patata moqlija.",
  boiled_broccoli: "Brokkli mgħolli",
  boiled_broccoli_description: "Ipprova kul ħafna ħaxix matul il-ġurnata. Agħżel ħaxix lokali u staġunali.",
  bottled_water: "Ilma tal-flixkun",
  bottled_water_description: "Ixrob l-ilma tal-vit biex tnaqqas l-użu tal-plastik.",
  soft_drink: "Soft drink",
  soft_drink_description: "Ix-xorb li fih iz-zokkor jammonta għal parti konsiderevoli mill-konsum tagħna ta’ kuljum taz-zokkor – tista’ tagħżel l-ilma minflok?",
  beer_or_wine: "Birra/inbid",
  beer_or_wine_description: "Jekk tixrob kwalunkwe tip ta' alkoħol, illimita l-konsum tiegħek. L-evitar tal-alkoħol huwa aħjar għal saħħtek.",
  watching_tv: "Nara t-televixin jew nilgħab logħob tal-kompjuter",
  watching_tv_description: "Żomm ruħek attiv(a) fiżikament kuljum u pprova naqqas il-ħin li tqatta’ bilqiegħda. Matul il-ħin liberu tiegħek ipprova agħmel xi sport, ilgħab ma’ sħabek jew għin fil-komunità tiegħek. Dan hu tajjeb għal saħħtek u għas-soċjetà. Evita li tqatta’ wisq ħin quddiem skrin.",
  doing_sports: "Nagħmel l-isport",
  doing_sports_description: "Żomm ruħek attiv(a) fiżikament kuljum u pprova naqqas il-ħin li tqatta’ bilqiegħda. Matul il-ħin liberu tiegħek ipprova agħmel xi sport, ilgħab ma’ sħabek jew għin fil-komunità tiegħek. Dan hu tajjeb għal saħħtek u għas-soċjetà. Evita li tqatta’ wisq ħin quddiem skrin.",
  playing_outside: "Nilgħab barra",
  playing_outside_description: "Żomm ruħek attiv(a) fiżikament kuljum u pprova naqqas il-ħin li tqatta’ bilqiegħda. Matul il-ħin liberu tiegħek ipprova agħmel xi sport, ilgħab ma’ sħabek jew għin fil-komunità tiegħek. Dan hu tajjeb għal saħħtek u għas-soċjetà. Evita li tqatta’ wisq ħin quddiem skrin.",
  charity_work: "Xogħol ta’ karità mal-adulti",
  charity_work_description: "Żomm ruħek attiv(a) fiżikament kuljum u pprova naqqas il-ħin li tqatta’ bilqiegħda. Matul il-ħin liberu tiegħek ipprova agħmel xi sport, ilgħab ma’ sħabek jew għin fil-komunità tiegħek. Dan hu tajjeb għal saħħtek u għas-soċjetà. Evita li tqatta’ wisq ħin quddiem skrin.",
  carrots_tomatoes: "Karrotti/tadam żgħir",
  carrots_tomatoes_description: "Agħti preferenza lill-frott, lill-ħaxix jew lill-ġewż. Illimita l-isnacks u x-xorb li għandu kontenut għoli ta' zokkrijiet, xaħam u melħ.",
  fresh_fruit: "Frott frisk",
  fresh_fruit_description: "Agħti preferenza lill-frott, lill-ħaxix jew lill-ġewż. Illimita l-isnacks u x-xorb li għandu kontenut għoli ta' zokkrijiet, xaħam u melħ.",
  chocolate_bar: "Biċċa ċikkulata",
  chocolate_bar_description: "Agħti preferenza lill-frott, lill-ħaxix jew lill-ġewż. Illimita l-isnacks u x-xorb li għandu kontenut għoli ta' zokkrijiet, xaħam u melħ.",
  chips: "Ċipps",
  chips_description: "Agħti preferenza lill-frott, lill-ħaxix jew lill-ġewż. Illimita l-isnacks u x-xorb li għandu kontenut għoli ta' zokkrijiet, xaħam u melħ.",
  juice_drink: "Xarba tal-meraq",
  juice_drink_description: "Agħti preferenza lill-frott frisk jew lil meraq li jkun għadu ingħasar mingħajr zokkor miżjud minflok xarbiet tal-meraq.",
  supermarket_prompt: "Agħżel sa 9 producti",
  raw_fish: "Ħut nej",
  raw_fish_description: "Kul il-ħut b'mod regolari biex tikseb nutrijenti importanti. Prova varjetà ta' ħut.",
  raw_beef: "Ċanga nejja",
  raw_beef_description: "Jekk tiekol il-laħam, naqqas l-ammonti, speċjalment fejn jidħol il-laħam aħmar jew proċessat. Hekk jixraq għal saħħtek u għall-ambjent.",
  raw_chicken: "Tiġieġ nej",
  raw_chicken_description: "Jekk tiekol il-laħam, ikkunsidra li tnaqqas il-konsum tiegħek, speċjalment il-laħam aħmar jew ipproċessat. Huwa aħjar għal saħħtek u għall-ambjent.",
  whole_salad: "Ħaxix sħiħ tal-insalata - eż. il-ħass",
  whole_salad_description: "Agħti preferenza lill-frott u lill-ħaxix frisk, speċjalment dak staġunali u lokali.",
  packaged_salad: "Insalata tal-pakkett",
  packaged_salad_description: "Agħti preferenza lill-frott u lill-ħaxix frisk, speċjalment dak staġunali u lokali.",
  seasonal_fruit: "Frott lokali staġunali",
  seasonal_fruit_description: "Agħti preferenza lill-frott u lill-ħaxix frisk, speċjalment dak staġunali u lokali.",
  exotic_fruit: "Frott eżotiku",
  exotic_fruit_description: "Agħti preferenza lill-frott u lill-ħaxix frisk, speċjalment dak staġunali u lokali.",
  ham: "Perżut",
  ham_description: "Il-laħam ipproċessat fih ammonti għoljin ta’ xaħam u melħ. Jekk tiekol laħam ipproċessat, ipprova illimita l-konsum tiegħu.",
  tofu: "Tofu",
  tofu_description: "Esplora prodotti ġodda bbażati fuq il-pjanti u riċetti ġodda.",
  hummus: "Ħumus",
  hummus_description: "Il-ħumus huwa alternattiva tajba li se jgħinek iżżid il-konsum tiegħek ta' legumi.",
  precooked_pizza: "Pizza msajra minn qabel",
  precooked_pizza_description: "L-ikliet imsajrin minn qabel jista’ jkun fihom ammonti għoljin ta’ melħ jew xaħam. Agħti preferenza lill-prodotti li ma jkunux imsajrin minn qabel. Dan jippermettilek tikkontrolla l-ammont ta’ melħ, zokkrijiet u xaħmijiet fl-ikliet tiegħek.",
  precooked_fries: "Patata moqlija imsajra minn qabel",
  precooked_fries_description: "L-ikliet imsajrin minn qabel jista’ jkun fihom ammonti għoljin ta’ melħ jew xaħam. Agħti preferenza lill-prodotti li ma jkunux imsajrin minn qabel. Dan jippermettilek tikkontrolla l-ammont ta’ melħ, zokkrijiet u xaħmijiet fl-ikliet tiegħek.",
  food_delivery: "Konsenja tal-ikel",
  food_delivery_description: "",
  cooking_at_home: "Tisjir fid-dar",
  cooking_at_home_description: "",
  cheeseburger: "Burger bil-ġobon, patata moqlija u soft drink",
  cheeseburger_description: "Evita li tikkonsma dan it-tip ta’ ikel. Dan fih ħafna xaħam u melħ. Bħala xorb, ħu l-ilma.",
  pizza_and_soft_drink: "Pizza u soft drink",
  pizza_and_soft_drink_description: "Dawn it-tipi ta’ ikliet fihom ħafna xaħam, melħ u zokkrijiet. Agħżel pizez “sempliċi” b’anqas ingredjenti jew aktar ħaxix u agħżel ħobż tal-pizza integrali, jekk possibbli. Bħala xorb, ħu l-ilma.",
  noodles_with_vegetables: "Noodles bil-ħaxix",
  noodles_with_vegetables_description: "Din hija għażla tajba biex iżżid il-konsum tiegħek tal-ħaxix. Żid xi legumi biex tikkomplementa l-ikla tiegħek.",
  mushroom_risoto: "Ross tal-faqqiegħ",
  mushroom_risoto_description: "Il-faqqiegħ huma għażla veġetarjana tajba. Oqħod attent(a) li ma żżidx wisq ġobon. Tista’ tikkomplementa din l-ikla b’insalata.",
  precooked_meal: "Ikla msajra minn qabel bil-lest - nuggets tat-tiġieġ",
  precooked_meal_description: "L-ikliet imsajrin minn qabel jista’ jkun fihom ammonti għoljin ta’ melħ, xaħam, jew saħansitra zokkrijiet. Agħti preferenza lill-prodotti li ma jkunux imsajrin minn qabel. Tista’ tikkontrolla aħjar l-ammont ta’ dawn in-nutrijenti. Uża ħxejjex aromatiċi u ħwawar minflok melħ għat-togħma.",
  frozen_pizza: "Pizza tal-friża",
  frozen_pizza_description: "L-ikliet imsajrin minn qabel jista’ jkun fihom ammonti għoljin ta’ melħ, xaħam, jew saħansitra zokkrijiet. Agħti preferenza lill-prodotti li ma jkunux imsajrin minn qabel. Tista’ tikkontrolla aħjar l-ammont ta’ dawn in-nutrijenti. Uża ħxejjex aromatiċi u ħwawar minflok melħ għat-togħma.",
  rice_and_salad: "Ikla msajra - ross u insalata ħadra",
  rice_and_salad_description: "L-ikliet imsajrin minn qabel jista’ jkun fihom ammonti għoljin ta’ melħ, xaħam, jew saħansitra zokkrijiet. Agħti preferenza lill-prodotti li ma jkunux imsajrin minn qabel. Tista’ tikkontrolla aħjar l-ammont ta’ dawn in-nutrijenti. Uża ħxejjex aromatiċi u ħwawar minflok melħ għat-togħma.",
  ham_sandwich_and_soft_drink: "Ħobż bil-perżut u soft drink",
  ham_sandwich_and_soft_drink_description: "Il-laħam ipproċessat fih ammonti għoljin ta’ xaħam u melħ. Jekk tiekol laħam ipproċessat, ipprova illimita l-konsum tiegħu. Barra minn hekk, ipprova evita x-xorb li fih iz-zokkor u s-soft drinks. Ħu ilma tal-vit minflok.",
  wholegrain_pasta_with_sauce: "Għaġin integrali biz-zalza tal-ħaxix",
  wholegrain_pasta_with_sauce_description: "Ipprova l-għaġin integrali.",
  hnw_title_adult: "Dinja Ġdida b’Saħħitha",
  hnw_description_1_adult: "L-għażliet tagħna jsawru l-futur tagħna. Jekk kollha kemm aħna nieklu u ngħixu bħalma għamilt inti fil-logħba, il-futur tagħna jista’ jkun aktar b’saħħtu b’persuni li jieħdu ħsieb lil xulxin u l-ambjent.",
  hnw_description_2_adult: "Ix-xjenzati jsibu b’mod konsistenti li, biex nippreservaw is-saħħa tagħna u dik tal-pjaneta tagħna, għandna nsegwu dieta varjata: rikka fiċ-ċereali, fil-legumi, fil-ħaxix u fil-frott frisk. Evita l-ikel ipproċessat, il-laħam u l-ikliet ħfief li jkun fihom ħafna melħ, zokkrijiet u xaħam. Huwa ta' benefiċċju għal kulħadd jekk inkunu fiżikament attivi, nimxu, nużaw ir-rota jew nużaw it-trasport pubbliku u nnaqqsu l-użu tal-karozza.",
  hnw_description_3_adult: "Affarijiet sempliċi jistgħu jsaħħu s-saħħa tiegħek u l-ambjent tagħna: arja u ilma nodfa, ikel sikur, ħamrija rikka u spazji ekoloġiċi, filwaqt li jiġu rispettati n-natura u l-poplu kollu. Aħseb ukoll dwar dan barra minn din il-logħba. Ħajjar lill-ħbieb u lill-familja tiegħek jingħaqdu miegħek matul il-vjaġġ.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Tixtieq terġa’ tilgħab?",
  hnw_title_child: "L-għażliet tagħna jsawru l-futur tagħna.",
  hnw_description_1_child: "Jekk kollha kemm aħna nieklu u ngħixu bħalma għamilt inti fil-logħba, jista’ jkollna futur aktar b’saħħtu.",
  hnw_description_2_child: "Dan huwa dak li tista’ tagħmel:\n- Ara li ssegwi dieta varjata biex tippreserva s-saħħa tagħna u tal-pjaneta tagħna.\n- Agħżel ċereali, legumi, ħaxix u frott frisk.  \n- Illimita l-ikel ipproċessat, il-laħam u l-isnacks li jkun fihom ħafna melħ, zokkrijiet u xaħam.\n- Żomm ruħek fiżikament aktar attiv(a), imxi, uża r-rota, uża t-trasport pubbliku.\n- Naqqas l-użu tal-karozza.",
  hnw_description_3_child: "Dawn l-għażliet sempliċi jistgħu jsaħħu s-saħħa tiegħek u jiżguraw li nieħdu ħsieb l-ambjent u l-poplu kollu.",
  hnw_description_4_child: "Aħseb ukoll dwar dan barra minn din il-logħba.",
  hnw_description_5_child: "Ħajjar lill-ħbieb u lill-familja tiegħek jingħaqdu miegħek matul il-vjaġġ.",
  hnw_description_6_child: "Tixtieq terġa’ tilgħab?",
  etl_title_adult: "Kul Sabiex Tgħix",
  etl_description_1_adult: "L-għażliet tagħna jsawru l-futur tagħna. Tista’ ma tkunx konxju/a li l-istil ta’ ħajja tiegħek jista’ jkollu impatt fuq is-saħħa tal-pjaneta tagħna.",
  etl_description_2_adult: "Ix-xjenzati jsibu b’mod konsistenti li, biex nippreservaw is-saħħa tagħna u dik tal-pjaneta tagħna, għandna nsegwu dieta varjata: rikka fiċ-ċereali, fil-legumi, fil-ħaxix u fil-frott frisk. Evita l-ikel ipproċessat, il-laħam u l-ikliet ħfief li jkun fihom ħafna melħ, zokkrijiet u xaħam. Huwa ta' benefiċċju għal kulħadd jekk inkunu fiżikament attivi, nimxu, nużaw ir-rota jew nużaw it-trasport pubbliku u nnaqqsu l-użu tal-karozza.",
  etl_description_3_adult: "Affarijiet sempliċi jistgħu jsaħħu s-saħħa tiegħek u l-ambjent tagħna: arja u ilma nodfa, ikel sikur, ħamrija rikka u spazji ekoloġiċi, filwaqt li jiġu rispettati n-natura u l-poplu kollu.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Tixtieq terġa’ tilgħab?",
  etl_title_child: "L-għażliet tagħna jsawru l-futur tagħna.",
  etl_description_1_child: "Jekk ilkoll intejbu l-mod kif nieklu u ngħixu, jista’ jkollna futur aktar b’saħħtu.",
  etl_description_2_child: "Dan huwa dak li tista’ tagħmel:\n- Ara li ssegwi dieta varjata biex tippreserva s-saħħa tagħna u tal-pjaneta tagħna.\n- Agħżel ċereali, legumi, ħaxix u frott frisk.  \n- Illimita l-ikel ipproċessat, il-laħam u l-isnacks li jkun fihom ħafna melħ, zokkrijiet u xaħam.\n- Żomm ruħek fiżikament aktar attiv(a), imxi, uża r-rota, uża t-trasport pubbliku.\n- Naqqas l-użu tal-karozza.",
  etl_description_3_child: "Dawn l-għażliet sempliċi jistgħu jsaħħu s-saħħa tiegħek u jiżguraw li nieħdu ħsieb l-ambjent u l-poplu kollu.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Tixtieq terġa’ tilgħab?",
  share: "Xerred",
  replay: "Erġ'ilgħab",
  its_up_to_you: "F'idejk",
  badge_action_1: "Is-sigriet mistur żvelat",
  badge_action_2: "Irbaħt %{badge} badge",
  fridgeDoor: "Bieb tal-friġġ",
  light: "Dawl",
  bottle: "Flixkun",
  reusable_bag: "Basket riużabbli",
  plastic_bag: "Basket tal-plastik",
  recycle_bin: "Basket riċiklabbli",
  share_text_subject: "F'idejk",
  share_text_body: "Lgħabt 2050 - Issa f'idejk! Għandi kurżità kif inhi d-dinja tiegħek! #ExperienceEUScience\nIbgħat issa %{shareUrl}\nIl-logħna nħolqot miċ-Ċentru Konġunt tar-Riċerka, is-servizz tax-xjenza u l-għarfien tal-Kummissjoni Ewropea.\nSkopri aktar https://joint-research-centre.ec.europa.eu/index_mt?etrans=mt"
};
export default i18n;
