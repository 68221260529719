import { useState, useRef, useEffect, Fragment } from "react";

// https://stackoverflow.com/a/53837442/72478
export const useForceUpdate = () => {
  const setValue = useState(0)[1];
  return () => setValue(value => value + 1);
};

// https://usehooks.com/usePrevious/
export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// https://stackoverflow.com/a/56267719/72478
export const useIsFirstRender = () => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  return isFirstRender.current;
};

// https://dev.to/savagepixie/how-to-mimic-componentdidupdate-with-react-hooks-3j8c
export const useDidUpdate = (callback, deps) => {
  const hasMounted = useRef(false);
  useEffect(() => {
    if (hasMounted.current) {
      callback();
    } else {
      hasMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

/**
 * Converts the specified text to a react Fragment with all newlines replaces with <br/>.
 */
export const renderFragmentWithNewlines = text =>
  text.split("\n").map((text, index) => (
    <Fragment key={index}>
      {index > 0 && <br />}
      {text}
    </Fragment>
  ));

/**
 * A wrapper around window.scrollTo to support old browsers.
 */
export const scrollTo = options => {
  try {
    window.scrollTo(options);
  } catch (error) {
    console.log(error);
    window.scrollTo(options.left || 0, options.top || 0);
  }
};
