const i18n = {
  title: "2050 Jocul Societate sănătoasă",
  start: "Start",
  select_language: "Selectează limba",
  adult: "Adult",
  child: "Copil",
  fullscreen: "Pe întregul ecran",
  restart_scene: "Reîncepe scena",
  restart_game: "Reîncepe jocul",
  previous: "Anterior",
  next: "Următorul",
  close: "Închide",
  toggle_sound: "Sunet activat/dezactivat",
  character_selection_title: "Alegeți-vă personajul",
  character_selection_body: "Alegerile pe care le facem ne afectează viitorul. În acest joc simulăm situații din viața de zi cu zi. Aflați mai multe despre impactul alegerilor pe care le faceți. Nu uitați să căutați secrete ascunse pentru a vă câștiga ecusoanele! Să începem prin crearea personajului.",
  character_set_up_title: "Construiți-vă personajul",
  select_me: "Selectează-mă",
  breakfast_title: "Ce servești la micul dejun?",
  your_badges: "Insigne",
  badge_water_conservation_master_title: "Maestru",
  badge_water_conservation_master_description: "Felicitări! Ești un maestru în economisirea unei resurse limitate! Apa este esențială pentru toate tipurile de viață.",
  badge_clean_street_wizard_title: "Magician",
  badge_clean_street_wizard_description: "Felicitări! Ești un magician al ajutorului la curățarea mediului nostru înconjurător, culegând gunoaie și punându-le la coșul gunoi.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Felicitări! Ești un ninja în economisirea energiei. Economisirea consumului de energie cu aparatele noastre electrocasnice este o modalitate excelentă de a contribui la reducerea consumului de energie.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Felicitări! Ești expert în economisirea energiei. Găsește în continuare modalități de a-ți reduce consumul de energie.",
  badge_low_waste_champion_title: "Campion",
  badge_low_waste_champion_description: "Felicitări! Ești un campion în reducerea propriilor deșeuri și în ceea ce privește sprijinirea mediului prin reducerea utilizării plasticului.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "Felicitări, ești un profesionist al alegerilor sănătoase pentru mediu. Continuă să folosești cea mai mică cantitate de plastic posibilă transportând propria pungă reutilizabilă.",
  badge_recycling_captain_title: "Stea",
  badge_recycling_captain_description: "Felicitări! Ești o stea în materie de reciclare. Pentru un mediu înconjurător sănătos, este important să reciclăm și să reutilizăm materialele.",
  faucet: "Robinet",
  transportation_title_adult: "Cum mergi la muncă?",
  transportation_title_child: "Cum mergi la școală?",
  trash: "Gunoi",
  no_drink: "Fără băutură",
  no_drink_description: "",
  tap_water: "Apă de la robinet",
  tap_water_description: "Hidratează-te! Încearcă să bei cel puțin 1,5-2 litri de apă pe zi.",
  milk: "Lapte",
  milk_description: "Alege lapte simplu neîndulcit, de preferință cu conținut scăzut de grăsime.",
  freshly_made_juice: "Suc proaspăt stors",
  freshly_made_juice_description: "Dacă îți place sucul, alege un suc proaspăt stors – nu este nevoie să adaugi zahăr. Încearcă să mănânci fructe proaspete.",
  coffee_or_tea: "Cafea/ceai",
  coffee_or_tea_description: "De preferință, servește ceaiul și cafeaua fără să adaugi zahăr.",
  plant_based_drink: "Băutură pe bază de plante",
  plant_based_drink_description: "Încearcă băuturile pe bază de plante, fără adaos de zaharuri și, dacă este posibil, alege băuturile care conțin vitamine și minerale.",
  whole_grain_cereals: "Cereale integrale",
  whole_grain_cereals_description: "Alege de preferință cereale integrale fără zaharuri adăugate.",
  choco_balls: "Cereale de tip „choco balls”",
  choco_balls_description: "Cerealele pentru micul dejun pot reprezenta o parte considerabilă din aportul nostru zilnic de zaharuri – poți alege cerealele cu mai puține zaharuri adăugate? În plus, alege cerealele cu un conținut mai mare de fibre.",
  white_bread_or_toast: "Pâine albă/prăjită",
  white_bread_or_toast_description: "Încearcă pâinea integrală.",
  wholemeal_bread_or_toast: "Pâine integrală/prăjită",
  wholemeal_bread_or_toast_description: "Încearcă pâinea integrală.",
  biscuits: "Biscuiți",
  biscuits_description: "Biscuiții sunt bogați în calorii și pot reprezenta o parte considerabilă din aportul nostru zilnic de zaharuri și grăsimi. Este mai bine să reducem consumul acestora. Alege de preferință biscuiți cu un conținut redus de grăsimi și mai puține zaharuri. În plus, alege biscuiții cu un conținut mai mare de fibre.",
  no_topping: "Fără topping-uri",
  no_topping_description: "",
  yogurt: "Iaurt",
  yogurt_description: "Încearcă iaurtul simplu cu conținut scăzut de grăsimi fără zaharuri adăugate.",
  honey: "Miere",
  honey_description: "Alege iaurt simplu fără adaos de zaharuri sau gemuri. Alege în schimb să adaugi fructe sau cereale integrale neîndulcite.",
  jam: "Gem",
  jam_description: "Alege iaurt simplu fără adaos de zaharuri sau gemuri. Alege în schimb să adaugi fructe sau cereale integrale neîndulcite.",
  fruit: "Fructe",
  fruit_description: "Excelentă alegere să îți începi ziua cu fructe; încearcă să alegi fructele de sezon. Dacă adaugi fructe cu coajă lemnoasă, alege-le de preferință pe cele fără adaos de sare sau de zaharuri.",
  butter: "Unt",
  butter_description: "Untul conține cantități mari de grăsime și poate fi sărat. Încercați să nu consumați unt prea des și să alegeți versiuni fără sare.",
  chocolate_cream: "Cremă de ciocolată",
  chocolate_cream_description: "Crema de ciocolată conține multe zaharuri și grăsimi saturate. Încearcă să nu mănânci cremă de ciocolată prea des.",
  ham_or_bacon: "Jambon/bacon",
  ham_or_bacon_description: "Carnea procesată conține cantități mari de grăsimi și sare. Dacă mănânci carne procesată, limitează consumul acesteia.",
  cheese: "Brânzeturi",
  cheese_description: "Brânzeturile conțin cantități mari de sare și grăsimi saturate. Încercați să nu consumați brânzeturi prea des și să le consumați în cantități mici.",
  avocado_and_tomato: "Avocado și  rosii",
  avocado_and_tomato_description: "Excelentă alegere să începi ziua consumând legume. Încearcă să consumi multe fructe și legume în timpul zilei și alege-le pe cele locale și de sezon.",
  battery: "Bateria",
  battery_description: "Urmăriți cu atenție bateria, ea indică impactul alegerilor dumneavoastră!",
  lunch_title: "Ce ai dori să servești la prânz?",
  breaktime_title: "După-amiaza liberă - timp liber",
  snacking_title: "Ai poftă de o gustare?",
  supermarket_title: "Ce cumpărați?",
  dinner_selection_title: "Ce planuri aveți pentru cină?",
  dinner_cooking_title: "Ce servim la cină?",
  dinner_delivery_title: "Ce servim la cină?",
  your_available_items: "Opțiunile tale",
  your_selections: "Selecțiile tale",
  walk: "Pe jos",
  walk_description: "Fii activ din punct de vedere fizic în viața de zi cu zi. Dacă este posibil, mergi pe jos sau cu bicicleta până la școală/muncă. Te menții în formă și este mai bine pentru mediul înconjurător.",
  bicycle: "Cu bicicleta",
  bicycle_description: "Fii activ din punct de vedere fizic în viața de zi cu zi. Dacă este posibil, mergi pe jos sau cu bicicleta până la școală/muncă. Te menții în formă și este mai bine pentru mediul înconjurător.",
  scooter_or_motorbike: "Pe scuter/motocicleta",
  scooter_or_motorbike_description: "Încearcă să folosești mai rar mașina sau motocicleta pentru un mediu înconjurător mai curat. Dacă acest lucru nu este posibil, încearcă să folosești automobilul în comun cu alte persoane (carpool).",
  car: "Cu automobilul",
  car_description: "Încearcă să folosești mai rar mașina sau motocicleta pentru un mediu înconjurător mai curat. Dacă acest lucru nu este posibil, încearcă să folosești automobilul în comun cu alte persoane (carpool).",
  public_transport: "Cu transportul public",
  public_transport_description: "Utilizează transportul public și combină-l cu mersul pe jos și cu bicicleta, în loc să mergi cu automobilul. Oferă-ți o pauză de la condus și ajută mediul înconjurător.",
  salad_with_egg: "Salată cu ou",
  salad_with_egg_description: "Adăugă legume proaspete la mesele tale, ori de câte ori este posibil.",
  beef_steak: "Friptură de vită",
  beef_steak_description: "Dacă mănânci carne, ai în vedere reducerea consumului, în special a cărnii roșii sau procesate. Este mai bine pentru sănătatea ta și pentru mediul înconjurător.",
  chicken: "Carne de pui",
  chicken_description: "Dacă mănânci carne, ai în vedere reducerea consumului, în special a cărnii roșii sau procesate. Este mai bine pentru sănătatea ta și pentru mediul înconjurător.",
  lentil_soup: "Supă de linte",
  lentil_soup_description: "Se încurajează consumul regulat de leguminoase în cadrul unei alimentații variate, bazate în cea mai mare parte pe plante.",
  vegetable_burger: "Burger vegetal",
  vegetable_burger_description: "Un burger vegetal poate fi o alternativă bună la carne. Atenție, să nu conțină prea multă sare!",
  white_pasta: "Paste făinoase albe",
  white_pasta_description: "Alege de preferință produse integrale atunci când alegi pastele făinoase, orezul și pâinea.",
  wholegrain_pasta: "Paste făinoase integrale",
  wholegrain_pasta_description: "Alege de preferință produse integrale atunci când alegi pastele făinoase, orezul și pâinea.",
  white_rice: "Orez alb",
  white_rice_description: "Alege de preferință produse integrale atunci când alegi pastele făinoase, orezul și pâinea.",
  wholegrain_rice: "Orez cu bob întreg",
  wholegrain_rice_description: "Alege de preferință produse integrale atunci când alegi pastele făinoase, orezul și pâinea.",
  white_bread_bun: "Chiflă albă",
  white_bread_bun_description: "Alege de preferință produse integrale atunci când alegi pastele făinoase, orezul și pâinea.",
  no_salad: "Niciuna",
  no_salad_description: "",
  fresh_green_salad: "Salată verde proaspătă",
  fresh_green_salad_description: "Adaugă câteva legume de sezon colorate la mesele tale, cu cât sunt mai variate, cu atât mai bine.",
  french_fries: "Cartofi prăjiți",
  french_fries_description: "Cartofii fierți sunt de preferat față de cartofii prăjiți în ulei.",
  boiled_broccoli: "Broccoli fiert",
  boiled_broccoli_description: "Încearcă să consumi multe legume în timpul zilei. Alege legume locale și de sezon.",
  bottled_water: "Apă îmbuteliată",
  bottled_water_description: "Bea apă de la robinet pentru a reduce utilizarea plasticului.",
  soft_drink: "Băuturi răcoritoare",
  soft_drink_description: "Băuturile cu adaos de zahăr reprezintă o parte considerabilă din aportul nostru zilnic de zaharuri – poți alege apa în schimb?",
  beer_or_wine: "Bere/vin",
  beer_or_wine_description: "În cazul în care consumi alcool de orice fel, limitează consumul. Este mai bine pentru sănătatea ta să eviți consumul de alcool.",
  watching_tv: "Vizionând un program TV sau jucând jocuri video",
  watching_tv_description: "Să fii activ din punct de vedere fizic în fiecare zi și să încerci să reduci timpul petrecut așezat. În timpul liber, încearcă să faci anumite sporturi, să te joci cu prietenii sau să îți ajuți comunitatea. Este bine pentru sănătatea ta și pentru societate. Evită să petreci prea mult timp în fața unui ecran.",
  doing_sports: "Făcând sport",
  doing_sports_description: "Să fii activ din punct de vedere fizic în fiecare zi și să încerci să reduci timpul petrecut așezat. În timpul liber, încearcă să faci anumite sporturi, să te joci cu prietenii sau să îți ajuți comunitatea. Este bine pentru sănătatea ta și pentru societate. Evită să petreci prea mult timp în fața unui ecran.",
  playing_outside: "La joacă afară",
  playing_outside_description: "Să fii activ din punct de vedere fizic în fiecare zi și să încerci să reduci timpul petrecut așezat. În timpul liber, încearcă să faci anumite sporturi, să te joci cu prietenii sau să îți ajuți comunitatea. Este bine pentru sănătatea ta și pentru societate. Evită să petreci prea mult timp în fața unui ecran.",
  charity_work: "Activități caritabile cu adulți",
  charity_work_description: "Să fii activ din punct de vedere fizic în fiecare zi și să încerci să reduci timpul petrecut așezat. În timpul liber, încearcă să faci anumite sporturi, să te joci cu prietenii sau să îți ajuți comunitatea. Este bine pentru sănătatea ta și pentru societate. Evită să petreci prea mult timp în fața unui ecran.",
  carrots_tomatoes: "Morcovi/roșii cherry",
  carrots_tomatoes_description: "Alege de preferință fructe, legume sau fructe cu coajă lemnoasă. Limitează gustările și băuturile cu un conținut ridicat de zaharuri, grăsimi și sare.",
  fresh_fruit: "Fructe proaspete",
  fresh_fruit_description: "Alege de preferință fructe, legume sau fructe cu coajă lemnoasă. Limitează gustările și băuturile cu un conținut ridicat de zaharuri, grăsimi și sare.",
  chocolate_bar: "Tabletă de ciocolată",
  chocolate_bar_description: "Alege de preferință fructe, legume sau fructe cu coajă lemnoasă. Limitează gustările și băuturile cu un conținut ridicat de zaharuri, grăsimi și sare.",
  chips: "Chips-uri",
  chips_description: "Alege de preferință fructe, legume sau fructe cu coajă lemnoasă. Limitează gustările și băuturile cu un conținut ridicat de zaharuri, grăsimi și sare.",
  juice_drink: "Sucuri",
  juice_drink_description: "Alege de preferință fructele proaspete sau sucurile proaspăt stoarse fără adaos de zahăr în locul băuturilor pe bază de sucuri.",
  supermarket_prompt: "Alegeți până la 9 produse",
  raw_fish: "Pește crud",
  raw_fish_description: "Consumul regulat de pește vă ajută să obțineți nutrienți importanți. Încercați să consumați diferite feluri de pește",
  raw_beef: "Vită crudă",
  raw_beef_description: "Dacă mâncați carne, aveți în vedere reducerea consumului, în special a cărnii roșii sau prelucrate. Este mai bine pentru sănătatea dumneavoastră și pentru mediu.",
  raw_chicken: "Pui crud",
  raw_chicken_description: "Dacă mănânci carne, ai în vedere reducerea consumului, în special a cărnii roșii sau procesate. Este mai bine pentru sănătatea ta și pentru mediul înconjurător.",
  whole_salad: "Salată numai pe bază de verdețuri de exemplu, salată verde",
  whole_salad_description: "Alege de preferință fructele și legumele proaspete, în special pe cele de sezon și locale.",
  packaged_salad: "Salată ambalată",
  packaged_salad_description: "Alege de preferință fructele și legumele proaspete, în special pe cele de sezon și locale.",
  seasonal_fruit: "Fructe locale de sezon",
  seasonal_fruit_description: "Alege de preferință fructele și legumele proaspete, în special pe cele de sezon și locale.",
  exotic_fruit: "Fructe exotice",
  exotic_fruit_description: "Alege de preferință fructele și legumele proaspete, în special pe cele de sezon și locale.",
  ham: "Jambon",
  ham_description: "Carnea procesată conține cantități mari de grăsime și sare. Dacă mănânci carne procesată, încearcă să limitezi consumul acesteia.",
  tofu: "Tofu",
  tofu_description: "Explorează noi produse pe bază de plante și noi rețete.",
  hummus: "Humus",
  hummus_description: "Humusul este o alternativă bună care te va ajuta să mărești consumul de leguminoase.",
  precooked_pizza: "Pizza semipreparată",
  precooked_pizza_description: "Mâncărurile semipreparate pot conține cantități mari de sare sau de grăsimi. Alege de preferință produsele care nu sunt semipreparate. Acest lucru îți va permite să controlezi cantitatea de sare, zaharuri și grăsimi din mesele tale.",
  precooked_fries: "Cartofi prăjiți semipreparați",
  precooked_fries_description: "Mâncărurile semipreparate pot conține cantități mari de sare sau de grăsimi. Alege de preferință produsele care nu sunt semipreparate. Acest lucru îți va permite să controlezi cantitatea de sare, zaharuri și grăsimi din mesele tale.",
  food_delivery: "Mâncare livrată la domiciliu",
  food_delivery_description: "",
  cooking_at_home: "Masă gătită acasă",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, cartofi prăjiți și băutură răcoritoare",
  cheeseburger_description: "Evită să consumi acest tip de mese. Ele sunt bogate în grăsimi și sare. Ca băutură, alege apa.",
  pizza_and_soft_drink: "Pizza și băutură răcoritoare",
  pizza_and_soft_drink_description: "Astfel de mese sunt bogate în grăsimi, sare și zaharuri. Alege pizza „simplă” cu mai puține ingrediente sau mai multe legume și alege pizza cu blat integral, dacă este posibil. Ca băutură, alege apa.",
  noodles_with_vegetables: "Tăiței cu legume",
  noodles_with_vegetables_description: "Aceasta este o opțiune bună pentru a-ți crește consumul de legume. Adaugă câteva leguminoase pentru a-ți completa masa.",
  mushroom_risoto: "Risotto de ciuperci",
  mushroom_risoto_description: "Ciupercile reprezintă o bună opțiune vegetariană. Ai grijă să nu adaugi prea mult brânză. Poți completa această masă cu o salată.",
  precooked_meal: "Mâncare gata preparată - chicken nuggets",
  precooked_meal_description: "Mâncărurile semipreparate pot conține cantități mari de sare, de grăsimi sau chiar de zaharuri. Alege de preferință produsele care nu sunt semipreparate. Poți controla mai bine cantitatea acestor nutrienți. Pentru gust, utilizează ierburi aromatice și mirodenii în locul sării.",
  frozen_pizza: "Pizza congelată",
  frozen_pizza_description: "Mâncărurile semipreparate pot conține cantități mari de sare, de grăsimi sau chiar de zaharuri. Alege de preferință produsele care nu sunt semipreparate. Poți controla mai bine cantitatea acestor nutrienți. Pentru gust, utilizează ierburi aromatice și mirodenii în locul sării.",
  rice_and_salad: "Mâncare gătită acasă - orez și salată verde",
  rice_and_salad_description: "Mâncărurile semipreparate pot conține cantități mari de sare, de grăsimi sau chiar de zaharuri. Alege de preferință produsele care nu sunt semipreparate. Poți controla mai bine cantitatea acestor nutrienți. Pentru gust, utilizează ierburi aromatice și mirodenii în locul sării.",
  ham_sandwich_and_soft_drink: "Sandviș cu jambon și băutură răcoritoare",
  ham_sandwich_and_soft_drink_description: "Carnea procesată conține cantități mari de grăsime și sare. Dacă mănânci carne procesată, încearcă să limitezi consumul acesteia. De asemenea, încearcă să eviți băuturile îndulcite și băuturile răcoritoare. Bea în schimb apă de la robinet.",
  wholegrain_pasta_with_sauce: "Paste făinoase integrale cu sos de legume",
  wholegrain_pasta_with_sauce_description: "Încearcă pastele făinoase integrale.",
  hnw_title_adult: "O lume nouă sănătoasă",
  hnw_description_1_adult: "Alegerile noastre ne modelează viitorul. Dacă mâncăm și trăim cu toții așa cum ai făcut în joc, viitorul nostru poate fi mai sănătos, cu oameni care au grijă unii de alții și de mediul înconjurător.",
  hnw_description_2_adult: "Oamenii de știință constată în mod regulat că, pentru a ne proteja sănătatea și pe cea a planetei noastre, ar trebui să avem o dietă variată: bogată în cereale, leguminoase, legume proaspete și fructe. Ar trebui evitate alimentele prelucrate, carnea și gustările cu un conținut ridicat de sare, zaharuri și grăsimi. Există mai multe câștiguri pe toate planurile dacă suntem activi din punct de vedere fizic, mergem pe jos, mergem cu bicicleta sau folosim mijloace de transport în comun și reducem utilizarea autoturismelor.",
  hnw_description_3_adult: "Lucrurile simple pot îmbunătăți sănătatea ta și pe cea a mediului nostru înconjurător: aer și apă curate, alimente din surse sigure, soluri bogate și spații verzi, respectul pentru natură și pentru oameni. Gândește-te la aceste aspecte și în afara acestui joc. Ia cu tine în această călătorie prietenii și familia.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Dorești să joci din nou?",
  hnw_title_child: "Alegerile noastre ne modelează viitorul",
  hnw_description_1_child: "Dacă mâncăm și trăim cu toții așa cum ai făcut în joc, putem avea un viitor mai sănătos.",
  hnw_description_2_child: "Iată ce poți face:\n- Asigură-te că ai o dietă variată pentru a proteja sănătatea noastră și pe cea a planetei.\n- Alege cereale, leguminoase, legume proaspete și fructe.\n- Limitează alimentele procesate, carnea și gustările cu un conținut ridicat de sare, zaharuri și grăsimi.\n- Fii activ din punct de vedere fizic, mergi pe jos, cu bicicleta, folosește mijloace de transport în comun.\n- Redu utilizarea autoturismelor.",
  hnw_description_3_child: "Aceste alegeri simple îți pot îmbunătăți sănătatea și garantează că avem grijă de mediul înconjurător și de oameni.",
  hnw_description_4_child: "Gândește-te la aceste aspecte și în afara acestui joc.",
  hnw_description_5_child: "Ia cu tine în această călătorie prietenii și familia.",
  hnw_description_6_child: "Dorești să joci din nou?",
  etl_title_adult: "Mănâncă pentru a trăi",
  etl_description_1_adult: "Alegerile noastre ne modelează viitorul. Poate nu știi, dar stilul tău de viață poate avea un impact asupra sănătății planetei noastre.",
  etl_description_2_adult: "Oamenii de știință constată în mod regulat că, pentru a ne proteja sănătatea și pe cea a planetei noastre, ar trebui să avem o dietă variată: bogată în cereale, leguminoase, legume proaspete și fructe. Ar trebui evitate alimentele prelucrate, carnea și gustările cu un conținut ridicat de sare, zaharuri și grăsimi. Există mai multe câștiguri pe toate planurile dacă suntem activi din punct de vedere fizic, mergem pe jos, mergem cu bicicleta sau folosim mijloace de transport în comun și reducem utilizarea autoturismelor.",
  etl_description_3_adult: "Lucrurile simple pot îmbunătăți sănătatea ta și mediul nostru înconjurător: aer și apă curate, alimente din surse sigure, soluri bogate și spații verzi, respectul pentru natură și pentru oameni.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Dorești să joci din nou?",
  etl_title_child: "Alegerile noastre ne modelează viitorul",
  etl_description_1_child: "Dacă ne îmbunătățim cu toții felul în care mâncăm și trăim, putem avea un viitor mai sănătos.",
  etl_description_2_child: "Iată ce poți face:\n- Asigură-te că ai o dietă variată pentru a proteja sănătatea noastră și pe cea a planetei.\n- Alege cereale, leguminoase, legume proaspete și fructe.\n- Limitează alimentele procesate, carnea și gustările cu un conținut ridicat de sare, zaharuri și grăsimi.\n- Fii activ din punct de vedere fizic, mergi pe jos, cu bicicleta, folosește mijloace de transport în comun.\n- Redu utilizarea autoturismelor.",
  etl_description_3_child: "Aceste alegeri simple îți pot îmbunătăți sănătatea și garantează că avem grijă de mediul înconjurător și de oameni.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Dorești să joci din nou?",
  share: "Partajează",
  replay: "Joacă din nou",
  its_up_to_you: "Decizia îți aparține",
  badge_action_1: "Ai aflat un secret!",
  badge_action_2: "Ai câștigat insigna %{badge}",
  fridgeDoor: "Ușa frigiderului",
  light: "Lumină",
  bottle: "Sticlă",
  reusable_bag: "Pungă reutilizabilă",
  plastic_bag: "Sacoșă de hârtie",
  recycle_bin: "Coș de reciclare",
  share_text_subject: "Decizia îți aparține",
  share_text_body: "Am jucat 2050 – Decizia îți aparține! Ești curios să vezi cum ar arăta lumea ta? #ExperienceEUScience\nJoacă acum %{shareUrl}\nAcest joc a fost dezvoltat de Centrul Comun de Cercetare, serviciul științific și de cunoaștere al Comisiei Europene.\nDescoperă mai mult https://joint-research-centre.ec.europa.eu/index_ro?etrans=ro"
};
export default i18n;
