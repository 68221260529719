const i18n = {
  title: "2050 Healthy Society Game",
  start: "Start",
  select_language: "Select language",
  adult: "Adult",
  child: "Child",
  fullscreen: "Full screen",
  restart_scene: "Restart scene",
  restart_game: "Restart game",
  previous: "Previous",
  next: "Next",
  close: "Close",
  toggle_sound: "Sound on/off",
  character_selection_title: "Select your character",
  character_selection_body: "Our choices affect our future. In this game, we simulate daily situations. Make your choices and learn about their impact. Be sure to look around for some hidden secrets to earn your badges! Let's start by creating your character.",
  character_set_up_title: "Build your character",
  select_me: "Select me",
  breakfast_title: "What do you have for breakfast?",
  your_badges: "Your badges",
  badge_water_conservation_master_title: "Master",
  badge_water_conservation_master_description: "Congratulations! You are a master in saving a scarce resource! Water is fundamental for all types of life.",
  badge_clean_street_wizard_title: "Fairy",
  badge_clean_street_wizard_description: "Congratulations! You are a fairy in helping our environment get cleaner by picking up trash and putting it in the trash can.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Congratulations! You are a ninja in saving energy. Saving up energy from our appliances is a great way to help reducing energy consumption.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Congratulations! You are an expert in saving energy. Keep finding ways to reduce your energy consumption.",
  badge_low_waste_champion_title: "Champion",
  badge_low_waste_champion_description: "Congratulations! You are a champion in reducing your waste and helping the environment by cutting down the use of plastic.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "Congratulations! You are a pro in making healthy choices for the environment. Keep using the least amount of plastic possible by carrying your own re-usable bag.",
  badge_recycling_captain_title: "Star",
  badge_recycling_captain_description: "Congratulations! You are a star in recycling. It is important to recycle and re-use materials for a healthy environment.",
  faucet: "Faucet",
  transportation_title_adult: "How do you get to work?",
  transportation_title_child: "How do you get to school?",
  trash: "Trash",
  no_drink: "No drink",
  no_drink_description: "",
  tap_water: "Tap water",
  tap_water_description: "Stay hydrated. Try to drink at least 1.5-2 liters of water per day.",
  milk: "Milk",
  milk_description: "Choose plain unsweetened milk, preferably low fat.",
  freshly_made_juice: "Freshly squeezed juice",
  freshly_made_juice_description: "If you like juice, choose a freshly squeezed one - no need to add sugar. Try eating fresh fruit.",
  coffee_or_tea: "Coffee/tea",
  coffee_or_tea_description: "Enjoy your tea and coffee preferably without adding sugar.",
  plant_based_drink: "Plant-based drink",
  plant_based_drink_description: "Try plant-based drinks, without added sugars and if possible choose those containing vitamins and minerals.",
  whole_grain_cereals: "Wholegrain cereals",
  whole_grain_cereals_description: "Prefer wholegrain cereals without added sugars.",
  choco_balls: "Choco balls",
  choco_balls_description: "Breakfast cereals can account for a considerable part of our daily sugars intake - can you choose those with fewer added sugars? And while you're at it, choose those with more fibre.",
  white_bread_or_toast: "White bread/toast",
  white_bread_or_toast_description: "Try wholegrain bread.",
  wholemeal_bread_or_toast: "Wholemeal bread/toast",
  wholemeal_bread_or_toast_description: "Try wholegrain bread.",
  biscuits: "Biscuits",
  biscuits_description: "Biscuits are high in calories and can account for a considerable part of our daily sugars and fat intake. It is better to reduce their consumption. Prefer those with lower fat and fewer sugars. And while you're at it, opt for those with more fibre too.",
  no_topping: "No topping",
  no_topping_description: "",
  yogurt: "Yogurt",
  yogurt_description: "Try plain low-fat yogurt without added sugars.",
  honey: "Honey",
  honey_description: "Choose plain yogurt without adding sugars or jam. Prefer to add fruit or unsweetened wholegrain cereals.",
  jam: "Jam",
  jam_description: "Choose plain yogurt without adding sugars or jam. Prefer to add fruit or unsweetened wholegrain cereals.",
  fruit: "Fruit",
  fruit_description: "Great choice to start the day with fruit, try to choose seasonal fruit. If you add nuts, prefer those without added salt or sugars.",
  butter: "Butter",
  butter_description: "Butter contains high amounts of fat and can be salty. Try not to eat it too often and go for the versions without salt.",
  chocolate_cream: "Chocolate cream",
  chocolate_cream_description: "Chocolate cream contains a lot of sugar and saturated fat. Try not to eat it too often.",
  ham_or_bacon: "Ham/bacon",
  ham_or_bacon_description: "Processed meat contains high amounts of fat and salt. If you eat processed meat, limit its consumption.",
  cheese: "Cheese",
  cheese_description: "Cheese contains high amounts of salt and saturated fat. Try not to eat it too often and consume it in small doses.",
  avocado_and_tomato: "Avocado and tomato",
  avocado_and_tomato_description: "Great choice to start the day with some vegetables. Try to eat plenty of fruit and vegetables during the day and prefer those that are local and seasonal.",
  battery: "Battery",
  battery_description: "Keep a close eye on your battery, it indicates the impact of your choices!",
  lunch_title: "What would you like for lunch?",
  breaktime_title: "Afternoon off - Free time!",
  snacking_title: "Fancy a snack?",
  supermarket_title: "What do you buy?",
  dinner_selection_title: "What are your dinner plans?",
  dinner_cooking_title: "What's for dinner?",
  dinner_delivery_title: "What's for dinner?",
  your_available_items: "Your options",
  your_selections: "Your selections",
  walk: "Walk",
  walk_description: "Be physically active in your daily life. If possible, walk or cycle to school/work. It keeps you fit and it is better for the environment.",
  bicycle: "Bicycle",
  bicycle_description: "Be physically active in your daily life. If possible, walk or cycle to school/work. It keeps you fit and it is better for the environment.",
  scooter_or_motorbike: "Scooter/motorbike",
  scooter_or_motorbike_description: "Try to use the car or motorbike less often for a cleaner environment. If not possible, try to carpool.",
  car: "Car",
  car_description: "Try to use the car or motorbike less often for a cleaner environment. If not possible, try to carpool.",
  public_transport: "Public transport",
  public_transport_description: "Use public transport and combine it with walking and cycling instead of going by car. Give yourself a break from driving and help the environment.",
  salad_with_egg: "Salad with egg",
  salad_with_egg_description: "Add fresh vegetables to your meals, whenever possible.",
  beef_steak: "Beef steak",
  beef_steak_description: "If you eat meat, consider reducing your intake, especially red or processed meats. It is better for your health and the environment.",
  chicken: "Chicken",
  chicken_description: "If you eat meat, consider reducing your intake, especially red or processed meats. It is better for your health and the environment.",
  lentil_soup: "Lentil soup",
  lentil_soup_description: "Eating legumes regularly is encouraged as part of a varied and mostly plant-based diet.",
  vegetable_burger: "Vegetable burger",
  vegetable_burger_description: "A vegetable burger can be a good alternative to meat. Watch out it doesn't contain too much salt.",
  white_pasta: "White pasta",
  white_pasta_description: "Prefer wholegrain products when choosing pasta, rice and bread.",
  wholegrain_pasta: "Wholegrain pasta",
  wholegrain_pasta_description: "Prefer wholegrain products when choosing pasta, rice and bread.",
  white_rice: "White rice",
  white_rice_description: "Prefer wholegrain products when choosing pasta, rice and bread.",
  wholegrain_rice: "Wholegrain rice",
  wholegrain_rice_description: "Prefer wholegrain products when choosing pasta, rice and bread.",
  white_bread_bun: "White bread bun",
  white_bread_bun_description: "Prefer wholegrain products when choosing pasta, rice and bread.",
  no_salad: "None",
  no_salad_description: "",
  fresh_green_salad: "Fresh green salad",
  fresh_green_salad_description: "Add some colourful seasonal vegetables to your meals, the more you vary the better.",
  french_fries: "French fries",
  french_fries_description: "Boiled potatoes are preferred over deep fried potato products.",
  boiled_broccoli: "Boiled broccoli",
  boiled_broccoli_description: "Try to eat plenty of vegetables during the day. Opt for local and seasonal vegetables.",
  bottled_water: "Bottled water",
  bottled_water_description: "Drink tap water to reduce plastic use.",
  soft_drink: "Soft drink",
  soft_drink_description: "Sugared beverages account for a considerable part of our daily sugar intake - can you choose water instead?",
  beer_or_wine: "Beer/wine",
  beer_or_wine_description: "If you drink alcohol of any type, limit your intake. Avoiding alcohol is better for your health.",
  watching_tv: "Watching TV or playing videogames",
  watching_tv_description: "Be physically active every day and try to reduce the time you spend sitting. During your free time try to do some sports, play with your friends or help your community. It is good for your health and for society. Avoid spending too much time in front of a screen.",
  doing_sports: "Doing sports",
  doing_sports_description: "Be physically active every day and try to reduce the time you spend sitting. During your free time try to do some sports, play with your friends or help your community. It is good for your health and for society. Avoid spending too much time in front of a screen.",
  playing_outside: "Playing outside",
  playing_outside_description: "Be physically active every day and try to reduce the time you spend sitting. During your free time try to do some sports, play with your friends or help your community. It is good for your health and for society. Avoid spending too much time in front of a screen.",
  charity_work: "Charity work with adults",
  charity_work_description: "Be physically active every day and try to reduce the time you spend sitting. During your free time try to do some sports, play with your friends or help your community. It is good for your health and for society. Avoid spending too much time in front of a screen.",
  carrots_tomatoes: "Carrots/cherry tomatoes",
  carrots_tomatoes_description: "Prefer fruits, vegetables or nuts. Limit snacks and drinks that are high in sugars, fat and salt.",
  fresh_fruit: "Fresh fruit",
  fresh_fruit_description: "Prefer fruits, vegetables or nuts. Limit snacks and drinks that are high in sugars, fat and salt.",
  chocolate_bar: "Chocolate bar",
  chocolate_bar_description: "Prefer fruits, vegetables or nuts. Limit snacks and drinks that are high in sugars, fat and salt.",
  chips: "Chips",
  chips_description: "Prefer fruits, vegetables or nuts. Limit snacks and drinks that are high in sugars, fat and salt.",
  juice_drink: "Juice drink",
  juice_drink_description: "Prefer fresh fruit or freshly squeezed juices without added sugar instead of juice drinks.",
  supermarket_prompt: "Choose up to 9 products",
  raw_fish: "Raw fish",
  raw_fish_description: "Eating fish regularly helps you get important nutrients. Try a variety of them",
  raw_beef: "Raw beef",
  raw_beef_description: "If you eat meat, consider reducing your intake, especially red or processed meats. It is better for your health and the environment.",
  raw_chicken: "Raw chicken",
  raw_chicken_description: "",
  whole_salad: "Whole salad vegetable - e.g. lettuce",
  whole_salad_description: "Prefer fresh fruit and vegetables, especially seasonal and local ones.",
  packaged_salad: "Packaged salad",
  packaged_salad_description: "Prefer fresh fruit and vegetables, especially seasonal and local ones.",
  seasonal_fruit: "Seasonal local fruit",
  seasonal_fruit_description: "Prefer fresh fruit and vegetables, especially seasonal and local ones.",
  exotic_fruit: "Exotic fruits",
  exotic_fruit_description: "Prefer fresh fruit and vegetables, especially seasonal and local ones.",
  ham: "Ham",
  ham_description: "Processed meat contains high amounts of fat and salt. If you eat processed meat, try to limit its consumption.",
  tofu: "Tofu",
  tofu_description: "Explore new plant-based products and new recipes.",
  hummus: "Hummus",
  hummus_description: "Hummus is a nice alternative that will help you increase your legume intake.",
  precooked_pizza: "Precooked pizza",
  precooked_pizza_description: "Precooked meals might contain high amounts of salt or fat. Prefer products that are not precooked. This will allow you to control the amount of salt, sugars and fats in your meals.",
  precooked_fries: "Precooked French fries",
  precooked_fries_description: "Precooked meals might contain high amounts of salt or fat. Prefer products that are not precooked. This will allow you to control the amount of salt, sugars and fats in your meals.",
  food_delivery: "Food delivery",
  food_delivery_description: "",
  cooking_at_home: "Cooking at home",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger",
  cheeseburger_description: "Avoid consuming this type of meals. They are rich in fat and salt. Drink enough water.",
  pizza_and_soft_drink: "Pizza and soft drink",
  pizza_and_soft_drink_description: "Such meals are high in fat, salt and sugars. Choose 'simple' pizzas with fewer ingredients or more vegetables and choose wholegrain pizza dough, if possible. Drink enough water.",
  noodles_with_vegetables: "Noodles with vegetables",
  noodles_with_vegetables_description: "This is a good option to increase your vegetable consumption. Add some legumes to complete your meal.",
  mushroom_risoto: "Mushroom risotto",
  mushroom_risoto_description: "Mushrooms are a good vegetarian option. Beware not to add too much cheese. You can complete this meal with a salad.",
  precooked_meal: "Precooked chicken nuggets",
  precooked_meal_description: "Precooked meals might contain high amounts of salt, fat, or even sugars. Prefer products that are not precooked. You can control the amount of these nutrients better. Use herbs and spices instead of salt for flavour.",
  frozen_pizza: "Frozen pizza",
  frozen_pizza_description: "Precooked meals might contain high amounts of salt, fat, or even sugars. Prefer products that are not precooked. You can control the amount of these nutrients better. Use herbs and spices instead of salt for flavour.",
  rice_and_salad: "Self-cooked meal rice and green salad",
  rice_and_salad_description: "Precooked meals might contain high amounts of salt, fat, or even sugars. Prefer products that are not precooked. You can control the amount of these nutrients better. Use herbs and spices instead of salt for flavour.",
  ham_sandwich_and_soft_drink: "Ham sandwich and soft drink",
  ham_sandwich_and_soft_drink_description: "Processed meat contains high amounts of fat and salt. If you eat processed meat, try to limit its consumption. Also, try to avoid sugared drinks and soft drinks. Have tap water instead.",
  wholegrain_pasta_with_sauce: "Wholegrain pasta with vegetable sauce",
  wholegrain_pasta_with_sauce_description: "Try wholegrain pasta.",
  hnw_title_adult: "Healthy New World",
  hnw_description_1_adult: "Our choices shape our future. If we all eat and live like you did in the game, our future can be healthier with people caring for each other and the environment.",
  hnw_description_2_adult: "Scientists consistently find that to preserve our health and that of our planet we ought to follow a varied diet: rich in cereals, legumes, fresh vegetables and fruit. Avoid little processed foods, meat and snacks high in salt, sugars and fat. There are more win-wins if we are physically active, walk, cycle or take public transport and reduce car use.",
  hnw_description_3_adult: "Simple things can power up your health and our environment: clean air and water, safe food, rich soils and green spaces, respecting nature and all people. Think about it outside this game, too. Take your friends and family along the journey.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Would you like to play again?",
  hnw_title_child: "Our choices shape our future",
  hnw_description_1_child: "If we all eat and live like you did in the game, we can have a healthier future.",
  hnw_description_2_child: "This is what you can do:\n- Ensure you follow a varied diet to preserve our health and our planet's health.\n- Choose cereals, legumes, fresh vegetables and fruit.  \n- Limit processed foods, meat and snacks high in salt, sugars and fat.\n- Get physically active, walk, cycle, take public transport.\n- Reduce car use.",
  hnw_description_3_child: "These simple choices can power up your health and ensure we care for the environment and all people.",
  hnw_description_4_child: "Think about it outside this game, too.",
  hnw_description_5_child: "Take your friends and family along the journey.",
  hnw_description_6_child: "Would you like to play again?",
  etl_title_adult: "Eat to Live",
  etl_description_1_adult: "Our choices shape our future. You may be unaware, your lifestyle can have an impact on the health of our planet.",
  etl_description_2_adult: "Scientists consistently find that to preserve our health and that of our planet we ought to follow a varied diet: rich in cereals, legumes, fresh vegetables and fruit. Avoid little processed foods, meat and snacks high in salt, sugars and fat. There are more win-wins if we are physically active, walk, cycle or take public transport and reduce car use.",
  etl_description_3_adult: "Simple things can power up your health and our environment: clean air and water, safe food, rich soils and green spaces, respecting nature and all people.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Would you like to play again?",
  etl_title_child: "Our choices shape our future",
  etl_description_1_child: "If we all improve the way we eat and live, we can have a healthier future.",
  etl_description_2_child: "This is what you can do:\n- Ensure you follow a varied diet to preserve our health and our planet's health.\n- Choose cereals, legumes, fresh vegetables and fruit.  \n- Limit processed foods, meat and snacks high in salt, sugars and fat.\n- Get physically active, walk, cycle, take public transport.\n- Reduce car use.",
  etl_description_3_child: "These simple choices can power up your health and ensure we care for the environment and all people.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Would you like to play again?",
  share: "Share",
  replay: "Replay",
  its_up_to_you: "It's up to you",
  badge_action_1: "Hidden secret revealed!",
  badge_action_2: "You won the %{badge} badge",
  fridgeDoor: "Fridge door",
  light: "Light",
  bottle: "Bottle",
  reusable_bag: "Reusable bag",
  plastic_bag: "Plastic bag",
  recycle_bin: "Recycle bin",
  share_text_subject: "It's up to you",
  share_text_body: "I played 2050 – It’s up to you! Curious what your world looks like? #ExperienceEUScience\nPlay now %{shareUrl}\nThis game was developed by the Joint Research Centre, the European Commission’s science and knowledge service.\nDiscover more https://joint-research-centre.ec.europa.eu/index_en?etrans=en"
};
export default i18n;
