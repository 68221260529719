import "scenes/CharacterSetUp.scss";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, {
  characterPropTypes,
  adultSkinTones,
  adultOutfits,
  adultHairStyles,
  adultAccessories,
  childSkinTones,
  childOutfits,
  childHairStyles,
  childAccessories
} from "elements/character/Character.js";
import Selector from "elements/character/selector/Selector.js";

const CharacterSetUp = ({
  t,
  character,
  setCharacterSkinTone,
  setCharacterOutfit,
  setCharacterHairStyle,
  setCharacterAccessory,
  restartScene,
  restartGame,
  goToNextScene
}) => {
  return (
    <div className="CharacterSetUp scene">
      <aside className="smallOptions">
        <div>
          <Selector
            t={t}
            type="hairStyle"
            options={character.type === "adult" ? adultHairStyles : childHairStyles}
            onOptionChange={setCharacterHairStyle}
            initialCurrentOption={character.hairStyle}
          />
          <Selector
            t={t}
            type="skinTone"
            options={character.type === "adult" ? adultSkinTones : childSkinTones}
            onOptionChange={setCharacterSkinTone}
            initialCurrentOption={character.skinTone}
          />
          <Selector
            t={t}
            type="accessory"
            options={character.type === "adult" ? adultAccessories : childAccessories}
            onOptionChange={setCharacterAccessory}
            initialCurrentOption={character.accessory}
          />
        </div>
      </aside>
      <main>
        <Character {...character} />
        <button onClick={goToNextScene}>{t("select_me")}</button>
      </main>
      <aside className="bigOptions">
        <Selector
          t={t}
          type="outfit"
          options={character.type === "adult" ? adultOutfits : childOutfits}
          onOptionChange={setCharacterOutfit}
          initialCurrentOption={character.outfit}
        />
      </aside>
      <Footer t={t} restartScene={restartScene} restartGame={restartGame} />
    </div>
  );
};

CharacterSetUp.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  setCharacterSkinTone: PropTypes.func.isRequired,
  setCharacterOutfit: PropTypes.func.isRequired,
  setCharacterHairStyle: PropTypes.func.isRequired,
  setCharacterAccessory: PropTypes.func.isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired
});

export default CharacterSetUp;
