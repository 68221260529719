import "scenes/DinnerDelivery.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import Footer from "common/Footer.js";
import Character, { characterPropTypes } from "elements/character/Character.js";
import { loFiClick } from "common/sounds.js";
import { allBadges, getAvailableBadgesFor } from "elements/badges/badges.js";
import Badges from "modals/Badges.js";
import AvailableItemsAndSelections from "modals/AvailableItemsAndSelections.js";
import CharacterResponsiveCustomModal from "elements/character/CharacterResponsiveCustomModal.js";
import ScrollableArrowsHintClasses from "common/ScrollableArrowsHintClasses.js";

const availableItems = ["cheeseburger", "pizza-and-soft-drink", "noodles-with-vegetables", "mushroom-risoto"];

const DinnerDelivery = ({ t, character, addSelection, badges, restartScene, restartGame, goToNextScene, battery }) => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModal, setOpenModal] = useState();
  const [sceneCompleted, setSceneCompleted] = useState(false);

  const itemClicked = item => {
    if (sceneCompleted) return;
    setSelectedItem(item);
    addSelection(item);
    loFiClick();
    setSceneCompleted(true);
  };

  return (
    <div className="DinnerDelivery scene">
      <div className="background-elements">
        <div className="item scooter"></div>
        <div className="item door"></div>
        <div className="item stool"></div>
        <div className="item plant"></div>
      </div>
      <div className="primary-elements">
        <CharacterResponsiveCustomModal t={t} characterElement={<Character {...character} />} />

        <ScrollableArrowsHintClasses>
          <div className="item window">
            {availableItems.map(item => (
              <button
                key={item}
                onClick={() => itemClicked(item)}
                className={`item ${item}${selectedItem === item ? " selected" : ""}`}
                title={t(item.replace(/-/g, "_"))}
                disabled={sceneCompleted}
              >
                <label>{t(item.replace(/-/g, "_"))}</label>
              </button>
            ))}
          </div>
        </ScrollableArrowsHintClasses>
      </div>

      <Footer
        t={t}
        openAvailableItemsAndSelectionsModal={() => setOpenModal("availableItemsAndSelections")}
        badgesCount={badges.length}
        openBadgesModal={() => setOpenModal("badges")}
        restartScene={sceneCompleted ? restartScene : undefined}
        restartGame={restartGame}
        goToNextScene={sceneCompleted ? goToNextScene : undefined}
      />
      {battery}
      <Badges
        t={t}
        badges={badges}
        availableBadges={getAvailableBadgesFor(character.type)}
        isOpen={openModal === "badges"}
        closeModal={() => setOpenModal(undefined)}
      />
      <AvailableItemsAndSelections
        t={t}
        availableItems={availableItems}
        selections={selectedItem === undefined ? undefined : [selectedItem]} // When the user has selected an item, show that one as the selections.
        isOpen={openModal === "availableItemsAndSelections"}
        closeModal={() => setOpenModal(undefined)}
      />
    </div>
  );
};

DinnerDelivery.propTypes = exact({
  t: PropTypes.func.isRequired,
  character: characterPropTypes.isRequired,
  addSelection: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.oneOf(allBadges)).isRequired,
  restartScene: PropTypes.func.isRequired,
  restartGame: PropTypes.func.isRequired,
  goToNextScene: PropTypes.func.isRequired,
  battery: PropTypes.element.isRequired
});

export default DinnerDelivery;
