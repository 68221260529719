import "common/Footer.scss";
import { useState, useEffect } from "react";
import { useForceUpdate, useDidUpdate } from "utils.js";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { jik, mute } from "common/sounds.js";
import { getMutePreference, setMutePreference } from "preferences.js";

const toggleSound = () => {
  const currentMutePreference = getMutePreference();
  const newMutePreference = !currentMutePreference;
  console.log(
    "Toggle sound from " + (currentMutePreference ? "off" : "on") + " to " + (newMutePreference ? "off" : "on")
  );
  mute(newMutePreference);
  jik();
  setMutePreference(newMutePreference);
};

// https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
const toggleFullScreen = () => {
  if (!window.document.fullscreenElement) {
    window.document.documentElement.requestFullscreen();
  } else {
    if (window.document.exitFullscreen) {
      window.document.exitFullscreen();
    }
  }
};

const Footer = ({
  t,
  availableItemsAndSelectionsNotificationHash,
  openAvailableItemsAndSelectionsModal,
  badgesCount,
  openBadgesModal,
  restartScene,
  restartGame,
  goToNextScene,
  shareResult
}) => {
  const forceUpdate = useForceUpdate();
  const [selectionsButtonNotify, setSelectionsButtonNotify] = useState(false);
  const [badgesButtonNotify, setBadgeButtonNotify] = useState(false);
  const [nextButtonNotify, setNextButtonNotify] = useState(false);

  useDidUpdate(() => {
    setSelectionsButtonNotify(true);
    setTimeout(() => setSelectionsButtonNotify(false), 500);
  }, [availableItemsAndSelectionsNotificationHash]);

  useDidUpdate(() => {
    setBadgeButtonNotify(true);
    setTimeout(() => setBadgeButtonNotify(false), 500);
  }, [badgesCount]);

  useDidUpdate(() => {
    // This very dirty hack is a result of poor requirements management.
    // Ideally we should refactor the components hierarchy or state handling in
    // a way where <App> knows when a child scene has completed,  thus renders
    // the "next" button through its <Footer>, in order to adjust the <Timeline>
    // accordingly. But we know this ain't happening.
    if (goToNextScene) {
      window.document.body.classList.add("-scene-completed");
    } else {
      window.document.body.classList.remove("-scene-completed");
    }
    setNextButtonNotify(true);
    setTimeout(() => setNextButtonNotify(false), 500);
  }, [goToNextScene]);

  useEffect(() => {
    // Also remove the class on component unmount.
    return () => {
      window.document.body.classList.remove("-scene-completed");
    };
  }, []);

  return (
    <footer className="Footer">
      <a href="#nav" className="menu-icon icon-burger-menu icon" id="mobile-burger-menu" type="button">
        &#9776;
      </a>

      <div id="nav">
        <a href="#close-menu" className="menu-icon-close" id="mobile-burger-menu-close" type="button">
          &#128473;
        </a>

        <ul>
          {openAvailableItemsAndSelectionsModal && (
            <li className={`selectionsModal ${selectionsButtonNotify ? "notify" : ""}`}>
              <button
                onClick={() => {
                  openAvailableItemsAndSelectionsModal();
                  jik();
                }}
                title={t("your_selections")}
              >
                {t("your_selections")}
              </button>
            </li>
          )}
          {openBadgesModal && (
            <li className={`badgesModal ${badgesButtonNotify ? "notify" : ""}`}>
              <button
                onClick={() => {
                  openBadgesModal();
                  jik();
                }}
                title={t("your_badges")}
              >
                {t("your_badges")} {badgesCount > 0 && <span>({badgesCount})</span>}
              </button>
            </li>
          )}
          <li className={`toggleSound ${getMutePreference() ? "off" : "on"}`}>
            <button
              onClick={() => {
                toggleSound();
                forceUpdate(); // We need this to rerender since mute preference is stored on localStorage.
              }}
              title={t("toggle_sound")}
            >
              {t("toggle_sound")}
            </button>
          </li>
          {window.document.fullscreenEnabled && (
            <li className="fullScreen">
              <button
                onClick={() => {
                  toggleFullScreen();
                  jik();
                }}
                title={t("fullscreen")}
              >
                {t("fullscreen")}
              </button>
            </li>
          )}
          {restartScene && (
            <li className="restartScene">
              <button
                onClick={() => {
                  restartScene();
                  jik();
                }}
                title={t("restart_scene")}
              >
                {t("restart_scene")}
              </button>
            </li>
          )}
          {restartGame && (
            <li className="restartGame">
              <button
                onClick={() => {
                  restartGame();
                  jik();
                }}
                title={t("restart_game")}
              >
                {t("restart_game")}
              </button>
            </li>
          )}
          {goToNextScene && (
            <li className={`goToNextScene ${nextButtonNotify ? "notify" : ""}`}>
              <button onClick={goToNextScene} title={t("next")}>
                {t("next")}
              </button>
            </li>
          )}
          {shareResult && (
            <li className="shareResult">
              <button onClick={shareResult} title={t("share")}>
                {t("share")}
              </button>
            </li>
          )}
        </ul>

        <a href="#close-menu-overlay" className="burger-menu-background-overlay" id="mobile-burger-menu" type="button">
          &#128473;
        </a>
      </div>
    </footer>
  );
};

Footer.propTypes = exact({
  t: PropTypes.func.isRequired,
  availableItemsAndSelectionsNotificationHash: PropTypes.string,
  openAvailableItemsAndSelectionsModal: PropTypes.func,
  badgesCount: PropTypes.number,
  openBadgesModal: PropTypes.func,
  restartScene: PropTypes.func,
  restartGame: PropTypes.func,
  goToNextScene: PropTypes.func,
  shareResult: PropTypes.func
});

export default Footer;
