const i18n = {
  title: "2050 gezonde samenleving spel",
  start: "Start",
  select_language: "Kies taal",
  adult: "Volwassene",
  child: "Kind",
  fullscreen: "Volledig scherm",
  restart_scene: "Scène opnieuw spelen",
  restart_game: "Spel opnieuw spelen",
  previous: "Terug",
  next: "Volgende",
  close: "Afsluiten",
  toggle_sound: "Geluid aan/uit",
  character_selection_title: "Kies je personage",
  character_selection_body: "Onze keuzes bepalen onze toekomst. Dit spel gaat over situaties uit het dagelijks leven. Maak je keuze en kijk welke impact deze heeft. Let goed op, want je kunt verborgen badges verdienen. Laten we eerst je personage creëren.",
  character_set_up_title: "Creëer je personage",
  select_me: "Kies mij",
  breakfast_title: "Hoe ziet jouw ontbijt eruit?",
  your_badges: "Je badges",
  badge_water_conservation_master_title: "Meester",
  badge_water_conservation_master_description: "Proficiat! Je bent een meester in zuinig zijn op een schaarse bron! Water is cruciaal voor alles wat leeft.",
  badge_clean_street_wizard_title: "Genie",
  badge_clean_street_wizard_description: "Proficiat! Je bent een genie in het netter maken van onze omgeving door zwerfvuil op te rapen en het in de vuilnisbak te gooien.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Proficiat! Je bent een ninja in energiebesparing. We kunnen ons energieverbruik beperken door onze toestellen slim te gebruiken.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Proficiat! Je bent een expert in energiebesparing. Blijf zoeken naar manieren om je energieverbruik te verminderen.",
  badge_low_waste_champion_title: "Kampioen",
  badge_low_waste_champion_description: "Proficiat! Je bent een kampioen in het verminderen van je afval en je helpt het milieu enorm door je gebruik van plastic te beperken.",
  badge_responsible_shopper_title: "Pro",
  badge_responsible_shopper_description: "Proficiat, je bent een pro in het maken van gezonde keuzes voor het milieu. Neem altijd je eigen herbruikbare tas mee om de hoeveelheid plastic te verminderen.",
  badge_recycling_captain_title: "Ster",
  badge_recycling_captain_description: "Proficiat! Je bent een ster in recycling. Het is belangrijk materialen te recyclen en te hergebruiken voor een gezond milieu.",
  faucet: "Kraan",
  transportation_title_adult: "Hoe ga je naar je werk?",
  transportation_title_child: "Hoe ga je naar school?",
  trash: "Afval",
  no_drink: "Niks te drinken",
  no_drink_description: "",
  tap_water: "Kraanwater",
  tap_water_description: "Blijf gehydrateerd. Probeer minstens 1,5 tot 2 liter water per dag te drinken.",
  milk: "Melk",
  milk_description: "Kies gewone, ongezoete, liefst magere melk.",
  freshly_made_juice: "Versgeperst sap",
  freshly_made_juice_description: "Als je graag sap drinkt, kies dan versgeperst sap – je hoeft geen suiker toe te voegen. Probeer vers fruit te eten.",
  coffee_or_tea: "Koffie/thee",
  coffee_or_tea_description: "Drink je thee en koffie bij voorkeur zonder suiker.",
  plant_based_drink: "Plantaardige dranken",
  plant_based_drink_description: "Probeer eens plantaardige dranken zonder toegevoegde suiker en kies zo mogelijk dranken met vitaminen en mineralen.",
  whole_grain_cereals: "Volkoren ontbijtgranen",
  whole_grain_cereals_description: "Geef de voorkeur aan volkoren ontbijtgranen zonder toegevoegde suiker.",
  choco_balls: "Chocoladebolletjes",
  choco_balls_description: "Ontbijtgranen kunnen voor een aanzienlijk deel van onze dagelijkse suikerinname zorgen – kan je kiezen voor ontbijtgranen met minder toegevoegde suiker? En als je dan toch bezig bent, kies dan bij voorkeur voor meer vezels.",
  white_bread_or_toast: "Wit brood/toast",
  white_bread_or_toast_description: "Probeer volkorenbrood.",
  wholemeal_bread_or_toast: "Volkorenbrood/toast",
  wholemeal_bread_or_toast_description: "Probeer volkorenbrood.",
  biscuits: "Koekjes",
  biscuits_description: "Koekjes bevatten veel calorieën en kunnen voor een aanzienlijk deel van onze dagelijkse suiker- en vetinname zorgen. Je kan er beter niet te veel van eten. Kies koekjes met minder vet en suiker. En als je dan toch bezig bent, kies dan bij voorkeur voor meer vezels.",
  no_topping: "Naturel",
  no_topping_description: "",
  yogurt: "Yoghurt",
  yogurt_description: "Probeer magere naturel yoghurt zonder toegevoegde suiker.",
  honey: "Honing",
  honey_description: "Kies voor naturel yoghurt zonder suiker of jam. Voeg liever fruit of volkoren ontbijtgranen zonder toegevoegde suiker toe.",
  jam: "Jam",
  jam_description: "Kies voor naturel yoghurt zonder suiker of jam. Voeg liever fruit of volkoren ontbijtgranen zonder toegevoegde suiker toe.",
  fruit: "Fruit",
  fruit_description: "Een goede keuze om je dag te beginnen met fruit, probeer te kiezen voor seizoensfruit. Als je noten toevoegt, kies dan noten zonder zout of suiker.",
  butter: "Boter",
  butter_description: "Boter bevat grote hoeveelheden vet en kan zout zijn. Probeer het niet al te vaak te eten en geef de voorkeur aan de versies zonder zout.",
  chocolate_cream: "Chocopasta",
  chocolate_cream_description: "Chocopasta bevat veel suiker en verzadigd vet. Eet het niet te vaak.",
  ham_or_bacon: "Ham/spek",
  ham_or_bacon_description: "Bewerkt vlees bevat veel vet en zout. Beperk de hoeveelheid bewerkt vlees die je eet.",
  cheese: "Kaas",
  cheese_description: "Kaas bevat veel zout en verzadigde vetten. Probeer het niet al te vaak te eten en eet kleine hoeveelheden.",
  avocado_and_tomato: "Avocado en tomaat",
  avocado_and_tomato_description: "De dag beginnen met een portie groenten is een uitstekend idee. Eet de hele dag door veel groenten en fruit en kies bij voorkeur lokale seizoensproducten.",
  battery: "Batterij",
  battery_description: "Let goed op je batterij, want die geeft aan wat de impact van je keuzes is!",
  lunch_title: "Wat wil je eten bij de lunch?",
  breaktime_title: "Een vrije middag – vrije tijd!",
  snacking_title: "Zin in een tussendoortje?",
  supermarket_title: "Wat koop je?",
  dinner_selection_title: "Wat zijn je plannen voor het avondeten?",
  dinner_cooking_title: "Wat eten we vanavond?",
  dinner_delivery_title: "Wat eten we vanavond?",
  your_available_items: "Je opties",
  your_selections: "Je keuzes",
  walk: "Te voet",
  walk_description: "Zorg ervoor dat je in het dagelijks leven voldoende beweegt. Ga zo mogelijk te voet of met de fiets naar school/je werk. Je blijft zo fit en het is beter voor het milieu.",
  bicycle: "Met de fiets",
  bicycle_description: "Zorg ervoor dat je in het dagelijks leven voldoende beweegt. Ga zo mogelijk te voet of met de fiets naar school/je werk. Je blijft zo fit en het is beter voor het milieu.",
  scooter_or_motorbike: "Met de scooter/motor",
  scooter_or_motorbike_description: "Gebruik de auto of motor wat minder, dat is beter voor het milieu. Als dat niet lukt, probeer dan te carpoolen.",
  car: "Met de auto",
  car_description: "Gebruik de auto of motor wat minder, dat is beter voor het milieu. Als dat niet lukt, probeer dan te carpoolen.",
  public_transport: "Met het openbaar vervoer",
  public_transport_description: "Gebruik het openbaar vervoer en combineer het met wandelen en fietsen in plaats van de auto te nemen. Laat de auto eens wat vaker aan de kant staan en help het milieu.",
  salad_with_egg: "Salade met ei",
  salad_with_egg_description: "Voeg waar je maar kan verse groenten aan je maaltijden toe.",
  beef_steak: "Biefstuk",
  beef_steak_description: "Beperk de hoeveelheid rood of bewerkt vlees die je eet. Dat is beter voor je gezondheid en voor het milieu.",
  chicken: "Kip",
  chicken_description: "Beperk de hoeveelheid rood of bewerkt vlees die je eet. Dat is beter voor je gezondheid en voor het milieu.",
  lentil_soup: "Linzensoep",
  lentil_soup_description: "Regelmatig peulvruchten eten wordt aangemoedigd als onderdeel van een gevarieerd en hoofdzakelijk plantaardig voedingspatroon.",
  vegetable_burger: "Groenteburger",
  vegetable_burger_description: "Een groenteburger kan een goed alternatief zijn voor vlees. Let erop dat er niet te veel zout in zit.",
  white_pasta: "Witte pasta",
  white_pasta_description: "Geef de voorkeur aan volkorenproducten wanneer je pasta, rijst en brood kiest.",
  wholegrain_pasta: "Volkorenpasta",
  wholegrain_pasta_description: "Geef de voorkeur aan volkorenproducten wanneer je pasta, rijst en brood kiest.",
  white_rice: "Witte rijst",
  white_rice_description: "Geef de voorkeur aan volkorenproducten wanneer je pasta, rijst en brood kiest.",
  wholegrain_rice: "Volle rijst",
  wholegrain_rice_description: "Geef de voorkeur aan volkorenproducten wanneer je pasta, rijst en brood kiest.",
  white_bread_bun: "Wit broodje",
  white_bread_bun_description: "Geef de voorkeur aan volkorenproducten wanneer je pasta, rijst en brood kiest.",
  no_salad: "Geen",
  no_salad_description: "",
  fresh_green_salad: "Verse groene salade",
  fresh_green_salad_description: "Voeg wat kleurige seizoensgroenten toe aan je maaltijden. Hoe meer variatie, hoe beter.",
  french_fries: "Frietjes",
  french_fries_description: "Gekookte aardappelen zijn gezonder dan gefrituurde aardappelproducten.",
  boiled_broccoli: "Gekookte broccoli",
  boiled_broccoli_description: "Probeer de hele dag door veel groenten te eten. Kies voor lokale seizoensgroenten.",
  bottled_water: "Fleswater",
  bottled_water_description: "Drink kraanwater om de hoeveelheid plastic te verminderen.",
  soft_drink: "Frisdranken",
  soft_drink_description: "Frisdranken zorgen voor een aanzienlijk deel van onze dagelijkse suikerinname. Kies in de plaats daarvan voor water.",
  beer_or_wine: "Bier/wijn",
  beer_or_wine_description: "Beperk de hoeveelheid alcohol die je drinkt. Alcohol vermijden is beter voor je gezondheid.",
  watching_tv: "TV kijken of videospelletjes spelen",
  watching_tv_description: "Zorg ervoor dat je elke dag beweegt en probeer de tijd dat je zit te beperken. Probeer tijdens je vrije tijd aan sport te doen, te spelen met je vrienden of je gemeenschap te helpen. Dat is beter voor je gezondheid en voor de samenleving. Breng niet te veel tijd voor een scherm door.",
  doing_sports: "Sporten",
  doing_sports_description: "Zorg ervoor dat je elke dag beweegt en probeer de tijd dat je zit te beperken. Probeer tijdens je vrije tijd aan sport te doen, te spelen met je vrienden of je gemeenschap te helpen. Dat is beter voor je gezondheid en voor de samenleving. Breng niet te veel tijd voor een scherm door.",
  playing_outside: "Buiten spelen",
  playing_outside_description: "Zorg ervoor dat je elke dag beweegt en probeer de tijd dat je zit te beperken. Probeer tijdens je vrije tijd aan sport te doen, te spelen met je vrienden of je gemeenschap te helpen. Dat is beter voor je gezondheid en voor de samenleving. Breng niet te veel tijd voor een scherm door.",
  charity_work: "Vrijwilligerswerk",
  charity_work_description: "Zorg ervoor dat je elke dag beweegt en probeer de tijd dat je zit te beperken. Probeer tijdens je vrije tijd aan sport te doen, te spelen met je vrienden of je gemeenschap te helpen. Dat is beter voor je gezondheid en voor de samenleving. Breng niet te veel tijd voor een scherm door.",
  carrots_tomatoes: "Wortelen/kerstomaatjes",
  carrots_tomatoes_description: "Kies voor fruit, groenten of noten. Beperk de hoeveelheid tussendoortjes en drank met veel suiker, vet en zout.",
  fresh_fruit: "Vers fruit",
  fresh_fruit_description: "Kies voor fruit, groenten of noten. Beperk de hoeveelheid tussendoortjes en drank met veel suiker, vet en zout.",
  chocolate_bar: "Chocoladereep",
  chocolate_bar_description: "Kies voor fruit, groenten of noten. Beperk de hoeveelheid tussendoortjes en drank met veel suiker, vet en zout.",
  chips: "Chips",
  chips_description: "Kies voor fruit, groenten of noten. Beperk de hoeveelheid tussendoortjes en drank met veel suiker, vet en zout.",
  juice_drink: "Vruchtensap",
  juice_drink_description: "Kies voor vers fruit of versgeperst sap zonder toegevoegde suiker in plaats van vruchtensap.",
  supermarket_prompt: "Kies maximaal 9 producten",
  raw_fish: "Rauwe vis",
  raw_fish_description: "Door regelmatig vis te eten, krijg je belangrijke voedingsstoffen binnen. Probeer eens verschillende soorten!",
  raw_beef: "Rauw rundvlees",
  raw_beef_description: "Beperk de hoeveelheid rood of bewerkt vlees die je eet. Dat is beter voor je gezondheid en voor het milieu.",
  raw_chicken: "Rauwe kip",
  raw_chicken_description: "Beperk de hoeveelheid rood of bewerkt vlees die je eet. Dat is beter voor je gezondheid en voor het milieu.",
  whole_salad: "Bladgroente - bv. sla",
  whole_salad_description: "Kies verse, bij voorkeur lokale groenten en fruit van het seizoen.",
  packaged_salad: "Verpakte salade",
  packaged_salad_description: "Kies verse, bij voorkeur lokale groenten en fruit van het seizoen.",
  seasonal_fruit: "Lokaal seizoensfruit",
  seasonal_fruit_description: "Kies verse, bij voorkeur lokale groenten en fruit van het seizoen.",
  exotic_fruit: "Exotische vruchten",
  exotic_fruit_description: "Kies verse, bij voorkeur lokale groenten en fruit van het seizoen.",
  ham: "Ham",
  ham_description: "Bewerkt vlees bevat veel vet en zout. Beperk de hoeveelheid bewerkt vlees die je eet.",
  tofu: "Tofu",
  tofu_description: "Ga op zoek naar nieuwe plantaardige producten en nieuwe recepten.",
  hummus: "Hummus",
  hummus_description: "Hummus is een lekker alternatief dat je zal helpen meer peulvruchten te eten.",
  precooked_pizza: "Kant-en-klaarpizza",
  precooked_pizza_description: "Kant-en-klaargerechten bevatten vaak veel zout of vet. Kies verse producten. Zo heb je meer controle over de hoeveelheid zout, suiker en vet in je maaltijden.",
  precooked_fries: "Voorgebakken frietjes",
  precooked_fries_description: "Kant-en-klaargerechten bevatten vaak veel zout of vet. Kies verse producten. Zo heb je meer controle over de hoeveelheid zout, suiker en vet in je maaltijden.",
  food_delivery: "Maaltijdbezorging",
  food_delivery_description: "",
  cooking_at_home: "Thuis koken",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, frietjes en frisdrank",
  cheeseburger_description: "Vermijd dergelijke maaltijden. Ze bevatten veel vet en zout. Drink water.",
  pizza_and_soft_drink: "Pizza en frisdrank",
  pizza_and_soft_drink_description: "Dergelijke maaltijden bevatten veel vet, zout en suiker. Kies “eenvoudige” pizza’s met minder ingrediënten of meer groenten en kies zo mogelijk een volkorenpizzabodem. Drink water.",
  noodles_with_vegetables: "Noedels met groenten",
  noodles_with_vegetables_description: "Dit is een goede keuze om meer groenten te eten. Voeg wat peulvruchten toe voor een volwaardige maaltijd.",
  mushroom_risoto: "Champignonrisotto",
  mushroom_risoto_description: "Champignons zijn een goede vegetarische keuze. Let erop dat je niet te veel kaas toevoegt. Je kan deze maaltijd aanvullen met een salade.",
  precooked_meal: "Kant-en-klare kipnuggets",
  precooked_meal_description: "Kant-en-klaargerechten bevatten vaak veel zout, vet of zelfs suiker. Kies verse producten. Dan heb je meer controle over de hoeveelheid zout, vet of suiker. Gebruik kruiden en specerijen in plaats van zout.",
  frozen_pizza: "Diepvriespizza",
  frozen_pizza_description: "Kant-en-klaargerechten bevatten vaak veel zout, vet of zelfs suiker. Kies verse producten. Dan heb je meer controle over de hoeveelheid zout, vet of suiker. Gebruik kruiden en specerijen in plaats van zout.",
  rice_and_salad: "Zelfbereide rijst en groene salade",
  rice_and_salad_description: "Kant-en-klaargerechten bevatten vaak veel zout, vet of zelfs suiker. Kies verse producten. Dan heb je meer controle over de hoeveelheid zout, vet of suiker. Gebruik kruiden en specerijen in plaats van zout.",
  ham_sandwich_and_soft_drink: "Broodje ham en frisdrank",
  ham_sandwich_and_soft_drink_description: "Bewerkt vlees bevat veel vet en zout. Beperk de hoeveelheid bewerkt vlees die je eet. Vermijd ook gesuikerde dranken en frisdranken. Drink liever kraanwater.",
  wholegrain_pasta_with_sauce: "Volkorenpasta met groentesaus",
  wholegrain_pasta_with_sauce_description: "Probeer eens volkorenpasta.",
  hnw_title_adult: "Gezonde nieuwe wereld",
  hnw_description_1_adult: "Onze keuzes bepalen onze toekomst. Als we allemaal eten en leven zoals jij dat deed in het spel, kan onze toekomst gezonder zijn, met mensen die zorg dragen voor elkaar en voor het milieu.",
  hnw_description_2_adult: "Wetenschappers stellen steeds opnieuw vast dat we, voor onze eigen gezondheid en die van de planeet, gevarieerd moeten eten: veel granen, peulvruchten, verse groenten en vers fruit. Vermijd bewerkte levensmiddelen, vlees en tussendoortjes met veel zout, suiker en vet. Bewegen, wandelen, fietsen of het openbaar vervoer nemen en de auto wat vaker laten staan heeft alleen maar voordelen.",
  hnw_description_3_adult: "Eenvoudige dingen kunnen je gezondheid en ons milieu ten goede komen: schone lucht en schoon water, veilig voedsel, rijke bodems en groene ruimten, met respect voor de natuur en de mens. Denk er ook na dit spel aan. Overtuig ook je vrienden en familie.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Wil je graag opnieuw spelen?",
  hnw_title_child: "Onze keuzes bepalen onze toekomst",
  hnw_description_1_child: "Als we allemaal eten en leven zoals jij dat deed in het spel, wordt onze toekomst gezonder.",
  hnw_description_2_child: "Dit kun jij doen:\n- Eet gevarieerd om je eigen gezondheid en die van onze planeet te beschermen.\n- Kies voor granen, peulvruchten, verse groenten en vers fruit.  \n- Beperk de hoeveelheid bewerkte levensmiddelen, vlees en tussendoortjes met veel zout, suiker en vet.\n- Beweeg, wandel, fiets, neem het openbaar vervoer.\n- Laat de auto wat vaker aan de kant staan.",
  hnw_description_3_child: "Met deze eenvoudige keuzes kan je je gezondheid verbeteren en zorg dragen voor het milieu en voor alle mensen.",
  hnw_description_4_child: "Denk er ook na dit spel aan.",
  hnw_description_5_child: "Overtuig ook je vrienden en familie.",
  hnw_description_6_child: "Wil je graag opnieuw spelen?",
  etl_title_adult: "Eten om te leven",
  etl_description_1_adult: "Onze keuzes bepalen onze toekomst. Je weet het misschien niet, maar je levensstijl kan een impact hebben op de gezondheid van onze planeet.",
  etl_description_2_adult: "Wetenschappers stellen steeds opnieuw vast dat we, voor onze eigen gezondheid en die van de planeet, gevarieerd moeten eten: veel granen, peulvruchten, verse groenten en vers fruit. Vermijd bewerkte levensmiddelen, vlees en tussendoortjes met veel zout, suiker en vet. Bewegen, wandelen, fietsen of het openbaar vervoer nemen en de auto wat vaker laten staan heeft alleen maar voordelen.",
  etl_description_3_adult: "Eenvoudige dingen kunnen je gezondheid en ons milieu ten goede komen: schone lucht en schoon water, veilig voedsel, rijke bodems en groene ruimten, met respect voor de natuur en de mens.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Wil je graag opnieuw spelen?",
  etl_title_child: "Onze keuzes bepalen onze toekomst",
  etl_description_1_child: "Als we allemaal beter gaan eten en leven, wordt onze toekomst gezonder.",
  etl_description_2_child: "Dit kun jij doen:\n- Eet gevarieerd om je eigen gezondheid en die van onze planeet te beschermen.\n- Kies voor granen, peulvruchten, verse groenten en vers fruit.  \n- Beperk de hoeveelheid bewerkte levensmiddelen, vlees en tussendoortjes met veel zout, suiker en vet.\n- Beweeg, wandel, fiets, neem het openbaar vervoer.\n- Laat de auto wat vaker aan de kant staan.",
  etl_description_3_child: "Met deze eenvoudige keuzes kan je je gezondheid verbeteren en zorg dragen voor het milieu en voor alle mensen.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Wil je graag opnieuw spelen?",
  share: "Delen",
  replay: "Opnieuw spelen",
  its_up_to_you: "Het is aan jou!",
  badge_action_1: "Geheim ontdekt!",
  badge_action_2: "Je hebt de %{badge}-badge gewonnen",
  fridgeDoor: "Koelkastdeur",
  light: "Lampje",
  bottle: "Fles",
  reusable_bag: "Herbruikbare tas",
  plastic_bag: "Plastic zak",
  recycle_bin: "Vuilnisbak",
  share_text_subject: "Het is aan jou!",
  share_text_body: "Ik speelde 2050 – Het is aan jou! Wil je weten hoe jouw wereld eruit ziet? #ExperienceEUScience\nSpeel nu %{shareUrl}\nDit spel is ontwikkeld door het Gemeenschappelijk Centrum voor Onderzoek, de wetenschaps- en kennisdienst van de Europese Commissie.\nMeer informatie https://joint-research-centre.ec.europa.eu/index_nl?etrans=nl"
};
export default i18n;
