const i18n = {
  title: "2050 – Egészséges társadalom játék",
  start: "Indítás",
  select_language: "Nyelv kiválasztása",
  adult: "Felnőtt",
  child: "Gyermek",
  fullscreen: "Teljes képernyő",
  restart_scene: "A jelenet újraindítása",
  restart_game: "A játék újraindítása",
  previous: "Előző",
  next: "Következő",
  close: "Bezárás",
  toggle_sound: "Hang bekapcsolva/kikapcsolva",
  character_selection_title: "Válaszd ki karakteredet",
  character_selection_body: "Választásaink befolyásolják jövőnket. Ebben a játékban mindennapi helyzeteket szimulálunk. Hozd meg döntéseidet, és ismerd meg azok hatását. Nézz körül, találsz-e néhány rejtett titkot, hogy megszerezd jelvényeidet. Kezdjük azzal, hogy létrehozzuk karakteredet.",
  character_set_up_title: "Alkosd meg karakteredet",
  select_me: "Válassz engem",
  breakfast_title: "Mit iszol reggelire?",
  your_badges: "Jelvények",
  badge_water_conservation_master_title: "Mester",
  badge_water_conservation_master_description: "Gratulálunk! Mesterien védelmezed e ritka erőforrást! A víz minden létforma számára elengedhetetlen.",
  badge_clean_street_wizard_title: "Tündér",
  badge_clean_street_wizard_description: "Gratulálunk! Felszeded a szemetet és a kukába dobod: ezzel varázslatosan tisztán tartod környezetünk.",
  badge_eco_ninja_title: "Nindzsa",
  badge_eco_ninja_description: "Gratulálunk! Az energiatakarékosság nindzsája vagy. Az elektromos eszközökkel való takarékosság remek módja annak, hogy csökkentsük energiafogyasztásunk.",
  badge_energy_saving_expert_title: "Szakértő",
  badge_energy_saving_expert_description: "Gratulálunk! Az energiatakarékosság szakértője vagy. Keress továbbra is új megoldásokat az energiafogyasztásod csökkentésére!",
  badge_low_waste_champion_title: "Bajnok",
  badge_low_waste_champion_description: "Gratulálunk! A hulladékcsökkentés bajnokaként segíted a környezetet azáltal, hogy kevesebb műanyagot használsz.",
  badge_responsible_shopper_title: "Profi",
  badge_responsible_shopper_description: "Gratulálunk, profi módon hozol meg egészséges döntéseket, melyekkel a környezetet véded. Továbbra is a lehető legkevesebb műanyagot használd, és saját, újrahasználható zacskóval vásárolj be.",
  badge_recycling_captain_title: "Csillag",
  badge_recycling_captain_description: "Gratulálunk! Az újrahasznosítás csillaga vagy. Az egészséges környezet érdekében fontos újrahasznosítani és újra felhasználni az anyagokat.",
  faucet: "Vízcsap",
  transportation_title_adult: "Hogy jutsz el a munkahelyedre?",
  transportation_title_child: "Hogy jutsz el az iskolába?",
  trash: "Szemetes",
  no_drink: "Semmilyen ital",
  no_drink_description: "",
  tap_water: "Csapvíz",
  tap_water_description: "Maradj hidratált! Igyekezz napi 1,5-2 liter vizet inni!",
  milk: "Tej",
  milk_description: "Válassz ízesítetlen, cukrozatlan, lehetőleg alacsony zsírtartalmú tejet.",
  freshly_made_juice: "Frissen facsart narancslé",
  freshly_made_juice_description: "Ha szereted a gyümölcslevet, válaszd a frissen facsart változatot – semmi szükség hozzáadott cukorra. Próbálj friss gyümölcsöt enni!",
  coffee_or_tea: "Kávé/tea",
  coffee_or_tea_description: "Lehetőleg cukor nélkül fogyaszd a teát és a kávét!",
  plant_based_drink: "Növényi ital",
  plant_based_drink_description: "Próbálj ki hozzáadott cukor nélküli növényi italokat, lehetőleg olyanokat, melyek vitaminokat és ásványi anyagokat is tartalmaznak.",
  whole_grain_cereals: "Teljes kiőrlésű gabonapehely",
  whole_grain_cereals_description: "Ha teheted, válassz teljes kiőrlésű gabonapelyhet hozzáadott cukor nélkül!",
  choco_balls: "Csokis gabonagolyók",
  choco_balls_description: "A reggeli gabonapelyhek napi cukorbevitelünk jelentős részét tehetik ki – keress olyan fajtát, amelyik kevesebb hozzáadott cukrot tartalmaz! Emellett pedig érdemes a magasabb rosttartalmúakat választani.",
  white_bread_or_toast: "Fehér kenyér / pirítós kenyér",
  white_bread_or_toast_description: "Próbáld ki a teljes kiőrlésű kenyeret!",
  wholemeal_bread_or_toast: "Teljes kiőrlésű kenyér / pirítós kenyér",
  wholemeal_bread_or_toast_description: "Próbáld ki a teljes kiőrlésű kenyeret!",
  biscuits: "Kekszek",
  biscuits_description: "A keksz kalóriatartalma magas, és napi cukor- illetve zsírbevitelünk jelentős részét teheti ki. Érdemes kevesebbet fogyasztani belőle. Inkább az alacsonyabb zsír- és cukortartalmú kekszek mellett dönts! Emellett pedig érdemes a magasabb rosttartalmúakat választani.",
  no_topping: "Semmilyen feltét",
  no_topping_description: "",
  yogurt: "Joghurt",
  yogurt_description: "Próbálj ki alacsony zsírtartalmú, hozzáadott cukrot nem tartalmazó joghurtokat!",
  honey: "Méz",
  honey_description: "Válassz ízesítetlen joghurtokat, cukor vagy lekvár hozzáadása nélkül! Inkább gyümölccsel vagy cukrozatlan, teljes kiőrlésű gabonapehellyel dúsítsd.",
  jam: "Lekvár",
  jam_description: "Válassz ízesítetlen joghurtokat, cukor vagy lekvár hozzáadása nélkül! Inkább gyümölccsel vagy cukrozatlan, teljes kiőrlésű gabonapehellyel dúsítsd.",
  fruit: "Gyümölcs",
  fruit_description: "Remek döntés gyümölccsel indítani a napot: ha teheted, válassz idénygyümölcsöt! Ha magvakat is eszel hozzá, inkább a sótlan és cukrozatlan termékeket válaszd!",
  butter: "Vaj",
  butter_description: "A vaj nagy mennyiségű zsírt tartalmaz, és sós is lehet. Igyekezz nem túl gyakran fogyasztani belőle, és a só nélkül változatokat keresd.",
  chocolate_cream: "Csokoládékrém",
  chocolate_cream_description: "A csokoládékrém sok cukrot és telített zsírt tartalmaz. Igyekezz minél ritkábban fogyasztani.",
  ham_or_bacon: "Sonka/szalonna",
  ham_or_bacon_description: "A feldolgozott hús zsír- és sótartalma magas. Ha fogyasztasz feldolgozott húst, igyekezz minél kevesebbet fogyasztani.",
  cheese: "Sajt",
  cheese_description: "A sajt nagy mennyiségű sót és telített zsírt tartalmaz. Igyekezz nem túl gyakran és csak kis adagokat fogyasztani.",
  avocado_and_tomato: "Avokádó és paradicsom",
  avocado_and_tomato_description: "Remek döntés zöldségekkel indítani a napot. Igyekezz sok – lehetőleg helyben termesztett és idény szerinti – gyümölcsöt és zöldséget fogyasztani napközben.",
  battery: "Akku",
  battery_description: "Ügyelj az akkudra, az jelzi döntéseid hatását!",
  lunch_title: "Mit szeretnél ebédre?",
  breaktime_title: "Tiéd a délután - Szabadidő!",
  snacking_title: "Kérsz egy nasit?",
  supermarket_title: "Mit vásárolsz?",
  dinner_selection_title: "Mit tervezel vacsorára?",
  dinner_cooking_title: "Mi a vacsora?",
  dinner_delivery_title: "Mi a vacsora?",
  your_available_items: "Lehetőségeid",
  your_selections: "Választásaid",
  walk: "Gyalog",
  walk_description: "Tedd fizikailag aktívvá mindennapjaid. Ha teheted, járj gyalog vagy kerékpárral iskolába/munkába. Így formában maradsz, és a környezetet is véded.",
  bicycle: "Kerékpár",
  bicycle_description: "Tedd fizikailag aktívvá mindennapjaid. Ha teheted, járj gyalog vagy kerékpárral iskolába/munkába. Így formában maradsz, és a környezetet is véded.",
  scooter_or_motorbike: "Robogó/motor",
  scooter_or_motorbike_description: "Igyekezz ritkábban használni az autót vagy a motort a tisztább környezet érdekében. Ha ez nem lehetséges, igyekezz más autójába betársulni!",
  car: "Autó",
  car_description: "Igyekezz ritkábban használni az autót vagy a motort a tisztább környezet érdekében. Ha ez nem lehetséges, igyekezz más autójába betársulni!",
  public_transport: "Tömegközlekedés",
  public_transport_description: "Járj tömegközlekedéssel – sétával vagy kerékpározással kombinálva – ahelyett, hogy autóba ülnél. Tarts szünetet a vezetésben, és óvd a környezetet!",
  salad_with_egg: "Saláta tojással",
  salad_with_egg_description: "Amikor csak lehetséges, legyen a friss zöldség is az étkezéseid része.",
  beef_steak: "Bifsztek",
  beef_steak_description: "Ha fogyasztasz húst, fontold meg a bevitel csökkentését, különösen vörös vagy feldolgozott húsok esetén. Ez mind az egészséged, mind a környezet szempontjából jobbat tesz.",
  chicken: "Csirke",
  chicken_description: "Ha fogyasztasz húst, fontold meg a bevitel csökkentését, különösen vörös vagy feldolgozott húsok esetén. Ez mind az egészséged, mind a környezet szempontjából jobbat tesz.",
  lentil_soup: "Lencseleves",
  lentil_soup_description: "A változatos és főképp növényi alapú étrend részeként ajánlott a hüvelyesek rendszeres fogyasztása.",
  vegetable_burger: "Zöldségburger",
  vegetable_burger_description: "A zöldségburger a hús megfelelő alternatívája lehet. Vigyázz, hogy ne legyen túlsózva.",
  white_pasta: "Fehér tészta",
  white_pasta_description: "Válasszd a teljes kiőrlésű tésztát, rizs és kenyeret.",
  wholegrain_pasta: "Teljes kiőrlésű tészta",
  wholegrain_pasta_description: "Válasszd a teljes kiőrlésű tésztát, rizs és kenyeret.",
  white_rice: "Fehér rizs",
  white_rice_description: "Válasszd a teljes kiőrlésű tésztát, rizs és kenyeret.",
  wholegrain_rice: "Teljes kiőrlésű rizs",
  wholegrain_rice_description: "Válasszd a teljes kiőrlésű tésztát, rizs és kenyeret.",
  white_bread_bun: "Fehér zsemle",
  white_bread_bun_description: "Válasszd a teljes kiőrlésű tésztát, rizs és kenyeret.",
  no_salad: "Semmi",
  no_salad_description: "",
  fresh_green_salad: "Friss zöldsaláta",
  fresh_green_salad_description: "Színesítsd étkezéseid idényzöldségekkel: minél változatosabb, annál jobb.",
  french_fries: "Hasábburgonya",
  french_fries_description: "A bő olajban sütött burgonya helyett válaszd a főtt burgonyát.",
  boiled_broccoli: "Főtt brokkoli",
  boiled_broccoli_description: "Igyekezz napközben minél több zöldséget fogyasztani. Válaszd a helyben termesztett vagy idény szerinti zöldségeket.",
  bottled_water: "Palackozott víz",
  bottled_water_description: "Igyál csapvizet a műanyaghulladék csökkentése érdekében!",
  soft_drink: "Üdítőital",
  soft_drink_description: "A cukros üdítőitalok napi cukorbevitelünk jelentős részét teszik ki - válaszd inkább a vizet!",
  beer_or_wine: "Sör/bor",
  beer_or_wine_description: "Korlátozd az alkoholbevitelt, bármilyen típusú alkoholt is fogyasztasz. Az alkohol kerülésével óvod egészséged.",
  watching_tv: "Tévénézés vagy videójátékok",
  watching_tv_description: "Minden nap mozogj valamit, és igyekezz csökkenteni az ülve töltött időt. Szabadidődben igyekezz sportolni, a barátaiddal játszani vagy végezz közösségi munkát. Ez az egészségednek és a társadalomnak is jót tesz. Próbálj meg minél kevesebb időt képernyő előtt tölteni.",
  doing_sports: "Sportolás",
  doing_sports_description: "Minden nap mozogj valamit, és igyekezz csökkenteni az ülve töltött időt. Szabadidődben igyekezz sportolni, a barátaiddal játszani vagy végezz közösségi munkát. Ez az egészségednek és a társadalomnak is jót tesz. Próbálj meg minél kevesebb időt képernyő előtt tölteni.",
  playing_outside: "Játék a szabadban",
  playing_outside_description: "Minden nap mozogj valamit, és igyekezz csökkenteni az ülve töltött időt. Szabadidődben igyekezz sportolni, a barátaiddal játszani vagy végezz közösségi munkát. Ez az egészségednek és a társadalomnak is jót tesz. Próbálj meg minél kevesebb időt képernyő előtt tölteni.",
  charity_work: "Jótékonykodás felnőttekkel",
  charity_work_description: "Minden nap mozogj valamit, és igyekezz csökkenteni az ülve töltött időt. Szabadidődben igyekezz sportolni, a barátaiddal játszani vagy végezz közösségi munkát. Ez az egészségednek és a társadalomnak is jót tesz. Próbálj meg minél kevesebb időt képernyő előtt tölteni.",
  carrots_tomatoes: "Répa/koktélparadicsom",
  carrots_tomatoes_description: "Válaszd a gyümölcsöket, zöldségeket vagy magvakat! Korlátozd a magas cukor-, zsír- és sótartalmú ropogtatnivalók és italok fogyasztását!",
  fresh_fruit: "Friss gyümölcs",
  fresh_fruit_description: "Válaszd a gyümölcsöket, zöldségeket vagy magvakat! Korlátozd a magas cukor-, zsír- és sótartalmú ropogtatnivalók és italok fogyasztását!",
  chocolate_bar: "Csokoládészelet",
  chocolate_bar_description: "Válaszd a gyümölcsöket, zöldségeket vagy magvakat! Korlátozd a magas cukor-, zsír- és sótartalmú ropogtatnivalók és italok fogyasztását!",
  chips: "Csipsz",
  chips_description: "Válaszd a gyümölcsöket, zöldségeket vagy magvakat! Korlátozd a magas cukor-, zsír- és sótartalmú ropogtatnivalók és italok fogyasztását!",
  juice_drink: "Gyümölcslé",
  juice_drink_description: "Gyümölcsnektárok helyett inkább a friss gyümölcsöt vagy a hozzáadott cukor nélküli, frissen facsart gyümölcslét válaszd!",
  supermarket_prompt: "Válassz ki maximum 9 terméket",
  raw_fish: "Nyers hal",
  raw_fish_description: "A rendszeres halfogyasztás révén fontos tápanyagokhoz juthatsz. Kóstolj meg többféle halat is.",
  raw_beef: "Nyers marhahús",
  raw_beef_description: "Ha fogyasztasz húst, fontold meg a bevitel csökkentését, különösen vörös vagy feldolgozott húsok esetén. Ez mind az egészséged, mind a környezet szempontjából kedvezőbb.",
  raw_chicken: "Nyers csirkehús",
  raw_chicken_description: "Ha fogyasztasz húst, fontold meg a bevitel csökkentését, különösen vörös vagy feldolgozott húsok esetén. Ez mind az egészséged, mind a környezet szempontjából jobbat tesz.",
  whole_salad: "Teljes salátazöldségek - pl. saláta",
  whole_salad_description: "Válassz friss – különösen idény szerinti és helyben termesztett – gyümölcsöket és zöldségeket!",
  packaged_salad: "Csomagolt saláta",
  packaged_salad_description: "Válassz friss - különösen idény szerinti és helyben termesztett – gyümölcsöket és zöldségeket!",
  seasonal_fruit: "Idény szerinti, helyben termesztett gyümölcs",
  seasonal_fruit_description: "Válassz friss - különösen idény szerinti és helyben termesztett – gyümölcsöket és zöldségeket!",
  exotic_fruit: "Egzotikus gyümölcsök",
  exotic_fruit_description: "Válassz friss - különösen idény szerinti és helyben termesztett – gyümölcsöket és zöldségeket!",
  ham: "Sonka",
  ham_description: "A feldolgozott hús zsír- és sótartalma magas. Ha fogyasztasz feldolgozott húst, minél ritkábban tedd azt.",
  tofu: "Tofu",
  tofu_description: "Ismerkedj meg új, növényi termékekkel és új receptekkel.",
  hummus: "Hummusz",
  hummus_description: "A hummusz finom alternatíva arra, hogy több hüvelyest vigyél be.",
  precooked_pizza: "Mirelitpizza",
  precooked_pizza_description: "A félkész ételeknek magas lehet a só- és zsírtartalmuk. Kerüld a félkész ételeket! Ezáltal tőled függ majd az ételeidbe kerülő só, cukor és zsír mennyisége.",
  precooked_fries: "Fagyasztott hasábburgonya",
  precooked_fries_description: "A félkész ételeknek magas lehet a só- és zsírtartalmuk. Kerüld a félkész ételeket! Ezáltal tőled függ majd az ételeidbe kerülő só, cukor és zsír mennyisége.",
  food_delivery: "Ételrendelés",
  food_delivery_description: "",
  cooking_at_home: "Főzés otthon",
  cooking_at_home_description: "",
  cheeseburger: "Sajtburger, sült krumpli és üdítőital",
  cheeseburger_description: "Kerüld az ilyen típusú ételek fogyasztását; magas a zsír- és sótartalmuk. Ha szomjas vagy, igyál vizet!",
  pizza_and_soft_drink: "Pizza és üdítő",
  pizza_and_soft_drink_description: "Az ilyen ételeknek magas a zsír-, só- és cukortartalmuk. Válassz „egyszerű” pizzákat kevesebb feltéttel vagy több zöldséggel, és – ha teheted – válassz teljes kiőrlésű pizzatésztát! Ha szomjas vagy, igyál vizet!",
  noodles_with_vegetables: "Zöldséges tészta",
  noodles_with_vegetables_description: "Ez egy jó lehetőség a zöldségfogyasztás növelésére. Egyél hozzá hüvelyeseket is, hogy teljes legyen az étkezés.",
  mushroom_risoto: "Gombás rizottó",
  mushroom_risoto_description: "A gomba jó vegetáriánus opció. Ügyelj arra, hogy ne legyen túl sok sajt a rizottóban. Ezt az ételt kiegészítheted egy salátával.",
  precooked_meal: "Félkész ételek - csirkefalatok",
  precooked_meal_description: "A félkész ételeknek magas lehet a só-, zsír-, vagy akár a cukortartalmuk is. Kerüld a félkész ételeket! Te jobban tudod ellenőrizni ezeknek a tápanyagoknak a mennyiségét. Ha ízesítenéd, só helyett használj inkább fűszereket!",
  frozen_pizza: "Mirelitpizza",
  frozen_pizza_description: "A félkész ételeknek magas lehet a só-, zsír-, vagy akár a cukortartalmuk is. Kerüld a félkész ételeket! Te jobban tudod ellenőrizni ezeknek a tápanyagoknak a mennyiségét. Ha ízesítenéd, só helyett használj inkább fűszereket!",
  rice_and_salad: "Otthon elkészített étel - rizs és zöldsaláta",
  rice_and_salad_description: "A félkész ételeknek magas lehet a só-, zsír-, vagy akár a cukortartalmuk is. Kerüld a félkész ételeket! Te jobban tudod ellenőrizni ezeknek a tápanyagoknak a mennyiségét. Ha ízesítenéd, só helyett használj inkább fűszereket!",
  ham_sandwich_and_soft_drink: "Sonkás szendvics és üdítőital",
  ham_sandwich_and_soft_drink_description: "A feldolgozott hús zsír- és sótartalma magas. Ha fogyasztasz feldolgozott húst, minél ritkábban tedd azt.",
  wholegrain_pasta_with_sauce: "Teljes kiőrlésű tészta zöldségszósszal",
  wholegrain_pasta_with_sauce_description: "Próbáld ki a teljes kiőrlésű tésztát!",
  hnw_title_adult: "Egészséges új világ",
  hnw_description_1_adult: "Választásaink befolyásolják jövőnket. Ha mind úgy étkezünk és élünk, ahogy azt te is tetted a játék folyamán, egészségesebb jövő vár ránk, ahol az emberek figyelnek egymásra és a környezetre.",
  hnw_description_2_adult: "A tudósok rendre megállapítják, hogy a saját egészségünk és bolygónk egészségének a megőrzése érdekében változatos étrendet kell követnünk, mely gazdag gabonafélékben, hüvelyesekben, friss zöldségben és gyümölcsben. Kerüld a feldolgozott ételeket, a húst, és a magas só-, cukor- és zsírtartalmú ropogtatnivalókat. Mindannyian jól járunk, ha fizikailag aktívak vagyunk, sétálunk, kerékpározunk, vagy tömegközlekedést használunk, és csökkentjük az autóhasználatot.",
  hnw_description_3_adult: "Egyszerű dolgok is képesek lendületet adni az egészségünknek és környezetünknek: tiszta levegő és víz, egészséges ételek, gazdag földek és zöld terek, a természet és minden ember tisztelete. Gondolkodj el ezen a játékon kívül is! Hozd el barátaid és családod is erre az utazásra!",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Szeretnél újra játszani?",
  hnw_title_child: "Választásaink befolyásolják jövőnket",
  hnw_description_1_child: "Ha mind úgy étkezünk és élünk, ahogy azt te is tetted a játék folyamán, egészségesebb jövő várhat ránk.",
  hnw_description_2_child: "A következőket teheted:\n- Bizonyosodj meg róla, hogy változatos étrendet követsz a saját egészséged és a bolygónk egészsége érdekében!\n- Válassz gabonaféléket, hüvelyeseket, friss zöldségeket és gyümölcsöket!  \n- Korlátozd a feldolgozott ételek, a hús, és a magas só-, cukor- és zsírtartalmú ropogtatnivalók fogyasztását!\n- Légy fizikailag aktív, sétálj, kerékpározz, használj tömegközlekedést!\n- Csökkentsd az autóhasználatot!",
  hnw_description_3_child: "Ezek az egyszerű döntések lendületet adhatnak az egészségednek és biztosíthatják, hogy gondját viseljük a környezetnek és minden embernek.",
  hnw_description_4_child: "Gondolkodj el ezen a játékon kívül is!",
  hnw_description_5_child: "Hozd el barátaid és családod is erre az utazásra!",
  hnw_description_6_child: "Szeretnél újra játszani?",
  etl_title_adult: "Együnk, hogy élhessünk",
  etl_description_1_adult: "Választásaink befolyásolják jövőnket. Talán nem is tudsz róla, de az életmódod hatással lehet bolygónk egészségére.",
  etl_description_2_adult: "A tudósok rendre megállapítják, hogy a saját egészségünk és bolygónk egészségének a megőrzése érdekében változatos étrendet kell követnünk, mely gazdag gabonafélékben, hüvelyesekben, friss zöldségben és gyümölcsben. Kerüld a feldolgozott ételeket, a húst, és a magas só-, cukor- és zsírtartalmú ropogtatnivalókat. Mindannyian jól járunk, ha fizikailag aktívak vagyunk, sétálunk, kerékpározunk, vagy tömegközlekedést használunk, és csökkentjük az autóhasználatot.",
  etl_description_3_adult: "Egyszerű dolgok is képesek lendületet adni az egészségünknek és környezetünknek: tiszta levegő és víz, egészséges ételek, gazdag földek és zöld terek, a természet és minden ember tisztelete.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Szeretnél újra játszani?",
  etl_title_child: "Választásaink befolyásolják jövőnket",
  etl_description_1_child: "Ha mind javítunk étrendünkön és életmódunkon, egészségesebb jövő vár ránk.",
  etl_description_2_child: "A következőket teheted:\n- Bizonyosodj meg róla, hogy változatos étrendet követsz a saját egészséged és a bolygónk egészsége érdekében!\n- Válassz gabonaféléket, hüvelyeseket, friss zöldségeket és gyümölcsöket!  \n- Korlátozd a feldolgozott ételek, a hús, és a magas só-, cukor- és zsírtartalmú ropogtatnivalók fogyasztását!\n- Légy fizikailag aktív, sétálj, kerékpározz, használj tömegközlekedést!\n- Csökkentsd az autóhasználatot!",
  etl_description_3_child: "Ezek az egyszerű döntések lendületet adhatnak az egészségednek és biztosíthatják, hogy gondját viseljük a környezetnek és minden embernek.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Szeretnél újra játszani?",
  share: "Megosztás",
  replay: "Újrajátszás",
  its_up_to_you: "Rajtad múlik",
  badge_action_1: "Rejtett titkot fedeztél fel!",
  badge_action_2: "Megnyerted a(z) %{badge} jelvényt",
  fridgeDoor: "Hűtőajtó",
  light: "Fény",
  bottle: "Palack",
  reusable_bag: "Többször használatos bevásárlótáska",
  plastic_bag: "Műanyag szatyor",
  recycle_bin: "Szelektív szemetes",
  share_text_subject: "Rajtad múlik",
  share_text_body: "Részt vettem a 2050 – Rajtad múlik játékban. Kíváncsi vagy a te világodra? #ExperienceEUScience\nJátssz most %{shareUrl}\nEzt a játékot az Európai Bizottság tudományos szolgálataként és tudásközpontjaként működő Közös Kutatóközpont fejlesztette ki.\nFedezz fel többet https://joint-research-centre.ec.europa.eu/index_hu?etrans=hu"
};
export default i18n;
