import Polyglot from "node-polyglot";
import i18n_bg from "i18n.bg.js";
import i18n_cs from "i18n.cs.js";
import i18n_da from "i18n.da.js";
import i18n_de from "i18n.de.js";
import i18n_el from "i18n.el.js";
import i18n_en from "i18n.en.js";
import i18n_es from "i18n.es.js";
import i18n_et from "i18n.et.js";
import i18n_fi from "i18n.fi.js";
import i18n_fr from "i18n.fr.js";
import i18n_ga from "i18n.ga.js";
import i18n_hr from "i18n.hr.js";
import i18n_hu from "i18n.hu.js";
import i18n_it from "i18n.it.js";
import i18n_lt from "i18n.lt.js";
import i18n_lv from "i18n.lv.js";
import i18n_mt from "i18n.mt.js";
import i18n_nl from "i18n.nl.js";
import i18n_pl from "i18n.pl.js";
import i18n_pt from "i18n.pt.js";
import i18n_ro from "i18n.ro.js";
import i18n_sk from "i18n.sk.js";
import i18n_sl from "i18n.sl.js";
import i18n_sv from "i18n.sv.js";
export const languages = [
  { code: "bg", title: "български", phrases: i18n_bg },
  { code: "cs", title: "čeština", phrases: i18n_cs },
  { code: "da", title: "dansk", phrases: i18n_da },
  { code: "de", title: "Deutsch", phrases: i18n_de },
  { code: "el", title: "ελληνικά", phrases: i18n_el },
  { code: "en", title: "English", phrases: i18n_en },
  { code: "es", title: "español", phrases: i18n_es },
  { code: "et", title: "eesti", phrases: i18n_et },
  { code: "fi", title: "suomi", phrases: i18n_fi },
  { code: "fr", title: "français", phrases: i18n_fr },
  { code: "ga", title: "Gaeilge", phrases: i18n_ga },
  { code: "hr", title: "hrvatski", phrases: i18n_hr },
  { code: "hu", title: "magyar", phrases: i18n_hu },
  { code: "it", title: "italiano", phrases: i18n_it },
  { code: "lt", title: "lietuvių", phrases: i18n_lt },
  { code: "lv", title: "latviešu", phrases: i18n_lv },
  { code: "mt", title: "Malti", phrases: i18n_mt },
  { code: "nl", title: "Nederlands", phrases: i18n_nl },
  { code: "pl", title: "polski", phrases: i18n_pl },
  { code: "pt", title: "português", phrases: i18n_pt },
  { code: "ro", title: "română", phrases: i18n_ro },
  { code: "sk", title: "slovenčina", phrases: i18n_sk },
  { code: "sl", title: "slovenščina", phrases: i18n_sl },
  { code: "sv", title: "svenska", phrases: i18n_sv }
];

export const DEFAULT_LANGUAGE_CODE = "en";

export const getValidLanguageCodeFromUrl = () => {
  const urlLanguage = new URLSearchParams(window.location.search).get("lang");
  const selectedLanguage = languages.find(language => language.code === urlLanguage);
  return selectedLanguage ? selectedLanguage.code : DEFAULT_LANGUAGE_CODE;
};

let t = undefined;
export const setTranslationLanguage = languageCode => {
  const newLanguage = languages.find(language => language.code === languageCode);
  const polyglot_ = new Polyglot({
    locale: newLanguage.code,
    phrases: newLanguage.phrases,
    allowMissing: true,
    onMissingKey: () => null
  });
  t = polyglot_.t.bind(polyglot_);
};
setTranslationLanguage(getValidLanguageCodeFromUrl());

export const getT = () => t;
