const i18n = {
  title: "Terve yhteiskunta 2050-peli",
  start: "Aloita",
  select_language: "Valitse kieli",
  adult: "Aikuinen",
  child: "Lapsi",
  fullscreen: "Koko näyttö",
  restart_scene: "Aloita osio alusta",
  restart_game: "Aloita peli alusta",
  previous: "Edellinen",
  next: "Seuraava",
  close: "Sulje",
  toggle_sound: "Äänet päälle/pois",
  character_selection_title: "Valitse hahmosi",
  character_selection_body: "Vaikutamme tulevaisuuteen valinnoillamme. Tässä pelissä teet arjen valintoja. Tee valintasi ja opi miten ne vaikuttavat. Etsi piilotettuja salaisuuksia ansaitaksesi pelimerkkisi.",
  character_set_up_title: "Rakenna pelihahmosi",
  select_me: "Valitse",
  breakfast_title: "Mitä otat aamiaiseksi?",
  your_badges: "Kunniamerkit",
  badge_water_conservation_master_title: "Mestari",
  badge_water_conservation_master_description: "Onnittelut! Olet mestaritasolla vähäisten luonnonvarojemme suojelussa! Vesi on kaiken elämän edellytys.",
  badge_clean_street_wizard_title: "Velho",
  badge_clean_street_wizard_description: "Onnittelut! Olet velho ympäristön puhtauden suojelussa – keräät roskat maasta ja laitat ne roskiin.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Onnittelut! Olet ninja energian säästämisessä. Erilaiset energiansäästötavat laitteiden käytössä ovat hyvä tapa vähentää energiankulutusta.",
  badge_energy_saving_expert_title: "Asiantuntija",
  badge_energy_saving_expert_description: "Onnittelut! Olet asiantuntijatasolla energian säästämisessä. Etsi lisää tapoja, joilla voit pienentää energiankulutustasi.",
  badge_low_waste_champion_title: "Osaaja",
  badge_low_waste_champion_description: "Onnittelut! Osaat vähentää jätteen määrää ja parantaa ympäristön hyvinvointia vähentämällä muovinkäyttöä.",
  badge_responsible_shopper_title: "Ammattilainen",
  badge_responsible_shopper_description: "Onnittelut! Olet ammattilaistasolla ympäristön hyvinvointia lisäävissä valinnoissa. Jatka muovinkulutuksen vähentämistä käyttämällä omaa kestokassia.",
  badge_recycling_captain_title: "Tähti",
  badge_recycling_captain_description: "Onnittelut! Olet tähtiluokkaa kierrättämisessä. Kierrättäminen ja materiaalien uudelleenkäyttö on tärkeää, sillä ne auttavat ympäristöä voimaan hyvin.",
  faucet: "Hana",
  transportation_title_adult: "Miten menet töihin?",
  transportation_title_child: "Miten menet kouluun?",
  trash: "Roska",
  no_drink: "Ei juomaa",
  no_drink_description: "",
  tap_water: "Hanavettä",
  tap_water_description: "Muista juoda tarpeeksi vettä. Vettä olisi hyvä juoda 1,5–2 litraa päivässä.",
  milk: "Maitoa",
  milk_description: "Kannattaa valita rasvaton maito, jota ei ole makeutettu.",
  freshly_made_juice: "Vastapuristettua mehua",
  freshly_made_juice_description: "Jos pidät mehusta, valitse sokeroimaton, vastapuristettu mehu. Muista myös syödä hedelmiä.",
  coffee_or_tea: "Kahvia/teetä",
  coffee_or_tea_description: "Tee ja kahvi kannattaa nauttia ilman lisättyä sokeria.",
  plant_based_drink: "Kasvipohjaisen juoman",
  plant_based_drink_description: "Tutustu erilaisiin kasvipohjaisiin juomiin. Jos mahdollista, valitse juoma, jossa on vitamiineja ja mineraaleja mutta ei lisättyä sokeria.",
  whole_grain_cereals: "Täysjyvämuroja tai -mysliä",
  whole_grain_cereals_description: "Valitse täysjyvämuroja ja -myslejä, joissa ei ole lisättyä sokeria.",
  choco_balls: "Suklaamuroja",
  choco_balls_description: "Suuri osa päivän aikana saadusta sokerimäärästä saattaa tulla aamiaismuroista – kannattaakin valita vähemmän sokeria sisältävä vaihtoehto. Samalla on hyvä valita tuote, jossa on runsaasti kuitua.",
  white_bread_or_toast: "Valkoista leipää",
  white_bread_or_toast_description: "Kannattaa valita täysjyväleipä.",
  wholemeal_bread_or_toast: "Täysjyväleipää",
  wholemeal_bread_or_toast_description: "Kannattaa valita täysjyväleipä.",
  biscuits: "Keksejä",
  biscuits_description: "Kekseissä on runsaasti kaloreita. Myös huomattava osa päivän aikana saadusta sokeri- ja rasvamäärästä saattaa tulla tämänkaltaisista tuotteista. Niiden kulutusta onkin hyvä vähentää. Jos syöt keksejä, kannattaa valita vähärasvainen ja -sokerinen vaihtoehto. Samalla on hyvä valita tuote, jossa on runsaasti kuitua.",
  no_topping: "Ei kastiketta",
  no_topping_description: "",
  yogurt: "Jogurttia",
  yogurt_description: "Kannattaa valita maustamattomia, rasvattomia jogurtteja, joihin ei ole lisätty sokeria.",
  honey: "Hunajaa",
  honey_description: "Jogurtti on hyvä nauttia maustamattomana ilman lisättyä sokeria tai hilloa. Niiden sijaan jogurttiin kannattaa lisätä hedelmiä tai makeuttamatonta täysjyvämysliä.",
  jam: "Hilloa",
  jam_description: "Jogurtti on hyvä nauttia maustamattomana ilman lisättyä sokeria tai hilloa. Niiden sijaan jogurttiin kannattaa lisätä hedelmiä tai makeuttamatonta täysjyvämysliä.",
  fruit: "Hedelmiä",
  fruit_description: "Hedelmät ovat hyvä valinta aamuun. Valitse hedelmävaihtoehtoja satokauden mukaan. Jos lisäät pähkinöitä, valitse tuote, jossa ei ole lisättyä suolaa tai sokeria.",
  butter: "Voita",
  butter_description: "Voissa on paljon rasvaa ja usein myös reippaasti suolaa. Yritä syödä sitä vain harvoin ja suosi suolattomia vaihtoehtoja.",
  chocolate_cream: "Kaakaolevitettä",
  chocolate_cream_description: "Kaakaolevitteissä on paljon sokeria ja tyydyttynyttä rasvaa. Niiden syömistä kannattaakin välttää.",
  ham_or_bacon: "Kinkkua/pekonia",
  ham_or_bacon_description: "Lihajalosteet sisältävät runsaasti rasvaa ja suolaa. Niitä kannattaakin syödä mahdollisimman vähän.",
  cheese: "Juustoa",
  cheese_description: "Juustossa on paljon suolaa ja tyydyttyneitä rasvahappoja. Yritä syödä sitä vain harvoin ja vähän kerrallaan.",
  avocado_and_tomato: "Avokadoa ja tomaattia",
  avocado_and_tomato_description: "Kasvikset ovat hyvä valinta aamuun. Syö runsaasti hedelmiä ja vihanneksia päivän aikana. Valitse paikallisia ja kauden mukaisia tuotteita.",
  battery: "Akku",
  battery_description: "Pidä silmällä akkua, se kertoo valintojesi vaikutuksista!",
  lunch_title: "Mitä haluaisit lounaaksi?",
  breaktime_title: "Vapaa iltapäivä – omaa aikaa!",
  snacking_title: "Haluaisitko välipalaa?",
  supermarket_title: "Mitä ostat?",
  dinner_selection_title: "Mitä suunnittelet illalliseksi?",
  dinner_cooking_title: "Mitä on päivälliseksi?",
  dinner_delivery_title: "Mitä on päivälliseksi?",
  your_available_items: "Vaihtoehtosi",
  your_selections: "Valintasi",
  walk: "Kävellen",
  walk_description: "Liiku monipuolisesti joka päivä. Kävele tai pyöräile kouluun/töihin, jos mahdollista. Näin pysyt hyvässä kunnossa etkä rasita ympäristöä.",
  bicycle: "Polkupyörällä",
  bicycle_description: "Liiku monipuolisesti joka päivä. Kävele tai pyöräile kouluun/töihin, jos mahdollista. Näin pysyt hyvässä kunnossa etkä rasita ympäristöä.",
  scooter_or_motorbike: "Skootterilla/moottoripyörällä",
  scooter_or_motorbike_description: "Suojele ympäristöä vähentämällä autoilua tai moottoripyöräilyä. Jos vähentäminen ei ole mahdollista, kannattaa järjestää kimppakyytejä.",
  car: "Autolla",
  car_description: "Suojele ympäristöä vähentämällä autoilua tai moottoripyöräilyä. Jos vähentäminen ei ole mahdollista, kannattaa järjestää kimppakyytejä.",
  public_transport: "Julkisilla liikennevälineillä",
  public_transport_description: "Yksityisautoilun sijaan hyödynnä julkista liikennettä ja yhdistä se kävelyyn ja pyöräilyyn. Anna itsellesi tauko autolla ajamisesta – ja autat samalla ympäristöä.",
  salad_with_egg: "Salaattia, jossa on kananmunaa",
  salad_with_egg_description: "Lisää tuoreita vihanneksia ateriaasi aina kun mahdollista.",
  beef_steak: "Naudanlihapihviä",
  beef_steak_description: "Etenkin punaista lihaa tai lihajalosteita kannattaa syödä mahdollisimman vähän. Lihan vähentäminen tukee terveyttäsi ja ympäristön hyvinvointia.",
  chicken: "Kanaa",
  chicken_description: "Etenkin punaista lihaa tai lihajalosteita kannattaa syödä mahdollisimman vähän. Lihan vähentäminen tukee terveyttäsi ja ympäristön hyvinvointia.",
  lentil_soup: "Linssikeittoa",
  lentil_soup_description: "Syö palkokasveja säännöllisesti osana monipuolista kasvipohjaista ruokavaliota.",
  vegetable_burger: "Kasvishampurilaisen",
  vegetable_burger_description: "Kasvishampurilainen voi olla hyvä vaihtoehto lihalle. Varmista kuitenkin, ettei tuotteessa ole liikaa suolaa.",
  white_pasta: "Valkoista pastaa",
  white_pasta_description: "Kun valitset pastaa, riisiä ja leipää, suosi täysjyvätuotteita.",
  wholegrain_pasta: "Täysjyväpastaa",
  wholegrain_pasta_description: "Kun valitset pastaa, riisiä ja leipää, suosi täysjyvätuotteita.",
  white_rice: "Valkoista riisiä",
  white_rice_description: "Kun valitset pastaa, riisiä ja leipää, suosi täysjyvätuotteita.",
  wholegrain_rice: "Täysjyväriisiä",
  wholegrain_rice_description: "Kun valitset pastaa, riisiä ja leipää, suosi täysjyvätuotteita.",
  white_bread_bun: "Vaaleita sämpylöitä",
  white_bread_bun_description: "Kun valitset pastaa, riisiä ja leipää, suosi täysjyvätuotteita.",
  no_salad: "Ei lainkaan",
  no_salad_description: "",
  fresh_green_salad: "Tuoretta vihreää salaattia",
  fresh_green_salad_description: "Lisää aterioihisi kunakin satokautena tarjolla olevia värikkäitä kasviksia – mitä monipuolisemmin, sen parempi.",
  french_fries: "Ranskalaisia perunoita",
  french_fries_description: "Kannattaa valita keitettyjä perunoita uppopaistettujen sijaan.",
  boiled_broccoli: "Keitettyä parsakaalia",
  boiled_broccoli_description: "Syö runsaasti kasviksia päivän aikana. Valitse paikallisia kasviksia satokauden mukaan.",
  bottled_water: "Pullovettä",
  bottled_water_description: "Vähennä muovien käyttöä ja juo hanavettä pulloveden sijaan.",
  soft_drink: "Virvoitusjuomia",
  soft_drink_description: "Suuri osa päivän aikana saadusta sokerimäärästä tulee makeutetuista juomista – juomaksi kannattaakin valita vesi.",
  beer_or_wine: "Olutta/viiniä",
  beer_or_wine_description: "Alkoholin kulutusta kannattaa vähentää. Alkoholin välttäminen parantaa terveyttäsi.",
  watching_tv: "Katselen TV:tä tai pelaan videopelejä",
  watching_tv_description: "Liiku monipuolisesti joka päivä ja pyri vähentämään istumista. Harrasta vapaa-ajalla urheilua, liiku ystävien kanssa tai tee vapaaehtoistyötä. Näin voit parantaa omaa terveyttäsi ja lisätä yhteiskunnallista hyvinvointia. Älä vietä liikaa aikaa ruudun edessä.",
  doing_sports: "Harrastan urheilua",
  doing_sports_description: "Liiku monipuolisesti joka päivä ja pyri vähentämään istumista. Harrasta vapaa-ajalla urheilua, liiku ystävien kanssa tai tee vapaaehtoistyötä. Näin voit parantaa omaa terveyttäsi ja lisätä yhteiskunnallista hyvinvointia. Älä vietä liikaa aikaa ruudun edessä.",
  playing_outside: "Liikun ulkona",
  playing_outside_description: "Liiku monipuolisesti joka päivä ja pyri vähentämään istumista. Harrasta vapaa-ajalla urheilua, liiku ystävien kanssa tai tee vapaaehtoistyötä. Näin voit parantaa omaa terveyttäsi ja lisätä yhteiskunnallista hyvinvointia. Älä vietä liikaa aikaa ruudun edessä.",
  charity_work: "Teen vapaaehtoistyötä",
  charity_work_description: "Liiku monipuolisesti joka päivä ja pyri vähentämään istumista. Harrasta vapaa-ajalla urheilua, liiku ystävien kanssa tai tee vapaaehtoistyötä. Näin voit parantaa omaa terveyttäsi ja lisätä yhteiskunnallista hyvinvointia. Älä vietä liikaa aikaa ruudun edessä.",
  carrots_tomatoes: "Porkkanaa/kirsikkatomaatteja",
  carrots_tomatoes_description: "Valitse välipalaksi hedelmiä, vihanneksia tai pähkinöitä. Vältä välipaloja ja juomia, joissa on runsaasti sokeria, rasvaa ja suolaa.",
  fresh_fruit: "Tuoreita hedelmiä",
  fresh_fruit_description: "Valitse välipalaksi hedelmiä, vihanneksia tai pähkinöitä. Vältä välipaloja ja juomia, joissa on runsaasti sokeria, rasvaa ja suolaa.",
  chocolate_bar: "Suklaapatukan",
  chocolate_bar_description: "Valitse välipalaksi hedelmiä, vihanneksia tai pähkinöitä. Vältä välipaloja ja juomia, joissa on runsaasti sokeria, rasvaa ja suolaa.",
  chips: "Sipsejä",
  chips_description: "Valitse välipalaksi hedelmiä, vihanneksia tai pähkinöitä. Vältä välipaloja ja juomia, joissa on runsaasti sokeria, rasvaa ja suolaa.",
  juice_drink: "Mehujuoman",
  juice_drink_description: "Valitse mehujuomien sijaan hedelmä tai vastapuristettua mehua, johon ei ole lisätty sokeria.",
  supermarket_prompt: "Valitse enintään 9 tuotetta",
  raw_fish: "Raakaa kalaa",
  raw_fish_description: "Kalan säännöllinen syöminen auttaa sinua saamaan tärkeitä ravintoaineita. Kokeile eri kalalajeja.",
  raw_beef: "Raakaa naudanlihaa",
  raw_beef_description: "Jos syöt lihaa, harkitse sen vähentämistä, erityisesti punaisen lihan ja lihajalosteiden osalta. Siitä hyötyy sekä oma terveytesi että ympäristö.",
  raw_chicken: "Raakaa kanaa",
  raw_chicken_description: "Etenkin punaista lihaa tai lihajalosteita kannattaa syödä mahdollisimman vähän. Lihan vähentäminen tukee terveyttäsi ja ympäristön hyvinvointia.",
  whole_salad: "Salaattikasviksia - esim. Lehtisalaattia",
  whole_salad_description: "Suosi tuoreita hedelmiä ja vihanneksia. Valitse kauden mukaisia paikallisia tuotteita.",
  packaged_salad: "Pakattua salaattia",
  packaged_salad_description: "Suosi tuoreita hedelmiä ja vihanneksia. Valitse kauden mukaisia paikallisia tuotteita.",
  seasonal_fruit: "Kauden mukaisia paikallisia hedelmiä",
  seasonal_fruit_description: "Suosi tuoreita hedelmiä ja vihanneksia. Valitse kauden mukaisia paikallisia tuotteita.",
  exotic_fruit: "Eksoottisia hedelmiä",
  exotic_fruit_description: "Suosi tuoreita hedelmiä ja vihanneksia. Valitse kauden mukaisia paikallisia tuotteita.",
  ham: "Kinkkua",
  ham_description: "Lihajalosteet sisältävät runsaasti rasvaa ja suolaa. Niitä kannattaakin syödä mahdollisimman vähän.",
  tofu: "Tofua",
  tofu_description: "Kokeile uusia kasvipohjaisia tuotteita ja uusia reseptejä.",
  hummus: "Hummusta",
  hummus_description: "Hummus on hyvä vaihtoehto, jolla saat lisättyä palkokasvien kulutustasi.",
  precooked_pizza: "Valmispitsa",
  precooked_pizza_description: "Valmisateriat voivat sisältää runsaasti suolaa tai rasvaa. Suosimalla muita kuin valmisaterioita hallitset itse suolan, sokereiden ja rasvojen määrää aterioissasi.",
  precooked_fries: "Valmiiksi paistettuja ranskalaisia perunoita",
  precooked_fries_description: "Valmisateriat voivat sisältää runsaasti suolaa tai rasvaa. Suosimalla muita kuin valmisaterioita hallitset itse suolan, sokereiden ja rasvojen määrää aterioissasi.",
  food_delivery: "Ruokatoimitus",
  food_delivery_description: "",
  cooking_at_home: "Ruoanlaitto kotona",
  cooking_at_home_description: "",
  cheeseburger: "Juustohampurilainen, ranskalaisia ja virvoitusjuoma",
  cheeseburger_description: "Vältä tämäntyyppisiä aterioita. Niissä on runsaasti rasvaa ja suolaa. Juomaksi on hyvä valita vesi.",
  pizza_and_soft_drink: "Pitsaa ja virvoitusjuoma",
  pizza_and_soft_drink_description: "Tämänkaltaisissa aterioissa on runsaasti rasvaa, suolaa ja sokereita. Suosi ”yksinkertaisia” pitsoja, joissa on vähän ainesosia tai paljon kasviksia. Valitse täysjyväpohja jos mahdollista. Juomaksi on hyvä valita vesi.",
  noodles_with_vegetables: "Nuudeleita ja kasviksia",
  noodles_with_vegetables_description: "Tämä on hyvä vaihtoehto, jolla saat lisättyä kasvisten kulutustasi. Ateriaa voi vielä täydentää palkokasveilla.",
  mushroom_risoto: "Sienirisottoa",
  mushroom_risoto_description: "Sienet ovat hyvä kasvisvaihtoehto. Jos lisäät risottoon juustoa, katso ettei sitä tule liikaa. Täydennä ateria salaatilla.",
  precooked_meal: "Valmisateria - kananugetit",
  precooked_meal_description: "Valmisateriat voivat sisältää runsaasti suolaa ja rasvaa, ja jopa sokereita. Suosimalla muita kuin valmisaterioita hallitset itse näiden ravintoaineiden saantia. Mausta ateriat suolan sijaan yrteillä ja mausteilla.",
  frozen_pizza: "Pakastepitsa",
  frozen_pizza_description: "Valmisateriat voivat sisältää runsaasti suolaa ja rasvaa, ja jopa sokereita. Suosimalla muita kuin valmisaterioita hallitset itse näiden ravintoaineiden saantia. Mausta ateriat suolan sijaan yrteillä ja mausteilla.",
  rice_and_salad: "Itse valmistettu ateria - riisiä ja vihreää salaattia",
  rice_and_salad_description: "Valmisateriat voivat sisältää runsaasti suolaa ja rasvaa, ja jopa sokereita. Suosimalla muita kuin valmisaterioita hallitset itse näiden ravintoaineiden saantia. Mausta ateriat suolan sijaan yrteillä ja mausteilla.",
  ham_sandwich_and_soft_drink: "Kinkkuvoileipä ja virvoitusjuoma",
  ham_sandwich_and_soft_drink_description: "Lihajalosteet sisältävät runsaasti rasvaa ja suolaa. Syö prosessoitua lihaa mahdollisimman vähän. Vältä myös sokerilla makeutettuja juomia, kuten virvoitusjuomia. Valitse juomaksi hanavesi.",
  wholegrain_pasta_with_sauce: "Täysjyväpastaa ja kasviskastiketta",
  wholegrain_pasta_with_sauce_description: "Valitse täysjyväpasta.",
  hnw_title_adult: "Terveellinen uusi maailma",
  hnw_description_1_adult: "Valintamme muovaavat tulevaisuutta. Jos kaikki söisivät ja eläisivät kuten sinä pelissä, tuloksena olisi terveempi tulevaisuus, jossa ihmiset välittävät toisistaan ja ympäristöstä.",
  hnw_description_2_adult: "Tutkijoiden havainnot puhuvat selvää kieltä siitä, että oman terveytemme ja terveen ympäristön säilyttämiseksi meidän pitäisi noudattaa monipuolista ruokavaliota, joka sisältää runsaasti viljoja, palkokasveja, tuoreita vihanneksia ja hedelmiä. Vältä valmisruokia, lihaa ja välipaloja, joissa on runsaasti suolaa, sokereita ja rasvaa. Sekä ihmisten että ympäristön hyvinvointi paranee vieläkin enemmän, kun harrastamme aktiivisesti liikuntaa ja valitsemme kävelyn, pyöräilyn tai julkiset liikennevälineet yksityisautoilun sijaan.",
  hnw_description_3_adult: "Terveyden ja ympäristön hyvinvoinnin eväät ovat hyvin yksinkertaisia: puhdas ilma ja vesi, turvalliset elintarvikkeet, ravinnepitoinen maaperä ja viheralueet sekä luonnon ja kaikkien ihmisten kunnioittaminen. Mieti näitä asioita myös pelin ulkopuolella. Ota myös ystäväsi ja perheesi mukaan matkalle muutokseen.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Haluatko pelata uudelleen?",
  hnw_title_child: "Valintamme muovaavat tulevaisuutta",
  hnw_description_1_child: "Jos kaikki söisivät ja eläisivät kuten sinä pelissä, tulevaisuus olisi terveempi.",
  hnw_description_2_child: "Hyviä vinkkejä:\n- Syö monipuolisesti. Valitse ruokia, jotka auttavat sinua ja ympäristöä pysymään terveenä.\n- Syö paljon viljoja, palkokasveja, tuoreita vihanneksia ja hedelmiä.  \n- Vältä valmisruokia ja lihaa. Älä ahmi välipaloja, joissa on runsaasti suolaa, sokereita ja rasvaa.\n- Harrasta monipuolisesti liikuntaa, kävele, pyöräile ja käytä julkisia liikennevälineitä.\n- Vältä autokyytejä, jos se on mahdollista.",
  hnw_description_3_child: "Näillä yksinkertaisilla valinnoilla voit sekä parantaa omaa hyvinvointiasi että suojella ympäristön ja ihmisten tulevaisuutta.",
  hnw_description_4_child: "Mieti näitä asioita myös pelin ulkopuolella.",
  hnw_description_5_child: "Ota myös ystäväsi ja perheesi mukaan matkalle muutokseen.",
  hnw_description_6_child: "Haluatko pelata uudelleen?",
  etl_title_adult: "Syö elääksesi",
  etl_description_1_adult: "Valintamme muovaavat tulevaisuutta. Elämäntapamme vaikuttavat ympäristön hyvinvointiin.",
  etl_description_2_adult: "Tutkijat vahvistavat kerta toisensa jälkeen, että ihmisten ja ympäristön hyvinvoinnin ylläpitämiseksi meidän pitäisi noudattaa monipuolista ruokavaliota, joka sisältää runsaasti viljoja, palkokasveja, tuoreita vihanneksia ja hedelmiä. Sen sijaan valmisruokia, lihaa ja runsaasti suolaa, sokereita ja rasvaa sisältäviä välipaloja pitäisi välttää. Sekä ihmisten että ympäristön hyvinvointi paranee vieläkin enemmän, kun harrastamme aktiivisesti liikuntaa ja valitsemme kävelyn, pyöräilyn tai julkiset liikennevälineet yksityisautoilun sijaan.",
  etl_description_3_adult: "Terveyden ja ympäristön hyvinvoinnin eväät ovat hyvin yksinkertaisia: puhdas ilma ja vesi, turvalliset elintarvikkeet, ravinnepitoinen maaperä ja viheralueet sekä luonnon ja kaikkien ihmisten kunnioittaminen.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Haluatko pelata uudelleen?",
  etl_title_child: "Valintamme muovaavat tulevaisuutta",
  etl_description_1_child: "Jos me kaikki parannamme syömis- ja elintapojamme, saamme terveemmän tulevaisuuden.",
  etl_description_2_child: "Hyviä vinkkejä:\n- Syö monipuolisesti. Valitse ruokia, jotka auttavat sinua ja ympäristöä pysymään terveenä.\n- Syö paljon viljoja, palkokasveja, tuoreita vihanneksia ja hedelmiä.  \n- Vältä valmisruokia ja lihaa. Älä ahmi välipaloja, joissa on runsaasti suolaa, sokereita ja rasvaa.\n- Harrasta monipuolisesti liikuntaa, kävele, pyöräile ja käytä julkisia liikennevälineitä.\n- Vältä autokyytejä, jos se on mahdollista.",
  etl_description_3_child: "Näillä yksinkertaisilla valinnoilla voit sekä parantaa omaa hyvinvointiasi että suojella ympäristön ja ihmisten tulevaisuutta.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Haluatko pelata uudelleen?",
  share: "Jaa",
  replay: "Toista",
  its_up_to_you: "Sinä päätät",
  badge_action_1: "Salaisuus paljastettu!",
  badge_action_2: "Voitit merkin %{badge}",
  fridgeDoor: "Jääkaapin ovi",
  light: "Valo",
  bottle: "Pullo",
  reusable_bag: "Kestokassi",
  plastic_bag: "Muovikassi",
  recycle_bin: "Roskapönttö",
  share_text_subject: "Sinä päätät",
  share_text_body: "Pelasin peliä 2050 – Sinä päätät! Millainen sinun olisi? #ExperienceEUScience\nPelaa %{shareUrl}\nTämän pelin on kehittänyt Euroopan komission tutkimuskeskus JRC.\nLue lisää https://joint-research-centre.ec.europa.eu/index_fi?etrans=fi"
};
export default i18n;
