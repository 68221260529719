const i18n = {
  title: "Igra – Zdrava družba do leta 2050",
  start: "Začetek",
  select_language: "Izberite jezik",
  adult: "Odrasli",
  child: "Otrok",
  fullscreen: "Celotni zaslon",
  restart_scene: "Ponovni začetek prizora",
  restart_game: "Ponovni začetek igre",
  previous: "Prejšnji",
  next: "Naslednji",
  close: "Zapri",
  toggle_sound: "Vklop/Izklop zvoka",
  character_selection_title: "Izberite svoj lik",
  character_selection_body: "Naše odločitve vplivajo na našo prihodnost. V tej igri simuliramo vsakodnevne situacije. Sprejmite svoje odločitve in se seznanite z njihovim vplivom. Poglejte naokrog, poiščite skrite zaklade in si prislužite svoje značke! Začnite z ustvarjanjem svojega lika.",
  character_set_up_title: "Ustvarite svoj lik",
  select_me: "Izberite me",
  breakfast_title: "Kaj zajtrkuješ?",
  your_badges: "Značke",
  badge_water_conservation_master_title: "mojster",
  badge_water_conservation_master_description: "Čestitamo! Si mojster pri varčevanju z redkimi viri! Voda je temeljnega pomena za vse vrste življenja.",
  badge_clean_street_wizard_title: "čarovnik",
  badge_clean_street_wizard_description: "Čestitamo! Si čarovnik, ki prispeva k čistejšemu okolju, saj pobiraš smeti in jih odlagaš v koš za smeti.",
  badge_eco_ninja_title: "ninja",
  badge_eco_ninja_description: "Čestitamo! Si ninja za varčevanje z energijo. Varčevanje z energijo, ki jo porabljajo električni aparati, je odličen način za zmanjšanje porabe energije.",
  badge_energy_saving_expert_title: "strokovnjak",
  badge_energy_saving_expert_description: "Čestitamo! Si strokovnjak za varčevanje z energijo. Še naprej se trudi za manjšo porabo energije.",
  badge_low_waste_champion_title: "prvak",
  badge_low_waste_champion_description: "Čestitamo! Si prvak v zmanjševanju količine odpadkov in skrbi za okolje, saj se trudiš za manjšo uporabo plastike.",
  badge_responsible_shopper_title: "profesionalec",
  badge_responsible_shopper_description: "Čestitamo! Si profesionalec pri sprejemanju odločitev, ki koristijo okolju. Še naprej se trudi za manjšo uporabo plastike in uporabljaj svojo vrečko za večkratno uporabo.",
  badge_recycling_captain_title: "zvezda",
  badge_recycling_captain_description: "Čestitamo! Si zvezda recikliranja. Pomembno je, da recikliramo in ponovno uporabljamo materiale ter tako poskrbimo za zdravo okolje.",
  faucet: "Pipa",
  transportation_title_adult: "Kako greš v službo?",
  transportation_title_child: "Kako greš v šolo?",
  trash: "Odpadki",
  no_drink: "Brez pijače",
  no_drink_description: "",
  tap_water: "voda iz pipe",
  tap_water_description: "Pazi, da popiješ dovolj tekočine. Spij vsaj 1,5–2 litra vode na dan.",
  milk: "Mleko",
  milk_description: "Izberi navadno nesladkano mleko, po možnosti posneto.",
  freshly_made_juice: "Sveže stisnjen sok",
  freshly_made_juice_description: "Če imaš rad sok, izberi sveže stisnjenega; nobene potrebe ni, da bi dodajal sladkor. Jej sveže sadje.",
  coffee_or_tea: "Kava/čaj",
  coffee_or_tea_description: "Po možnosti kavi in čaju ne dodajaj sladkorja.",
  plant_based_drink: "Pijača rastlinskega izvora",
  plant_based_drink_description: "Poskusi pijače rastlinskega izvora brez dodanih sladkorjev in po možnosti izberi tiste, ki vsebujejo vitamine in minerale.",
  whole_grain_cereals: "Polnozrnati žitni kosmiči",
  whole_grain_cereals_description: "Izberi polnozrnate žitne kosmiče brez dodanih sladkorjev.",
  choco_balls: "Kosmiči čokoladne kroglice",
  choco_balls_description: "Kosmiči za zajtrk lahko predstavljajo precejšen del našega dnevnega vnosa sladkorjev. Izberi tiste z manj dodanih sladkorjev. Izberi tiste z več vlakninami.",
  white_bread_or_toast: "Beli kruh/toast",
  white_bread_or_toast_description: "Poskusi polnozrnati kruh.",
  wholemeal_bread_or_toast: "Polnozrnati kruh/toast",
  wholemeal_bread_or_toast_description: "Poskusi polnozrnati kruh.",
  biscuits: "Keksi",
  biscuits_description: "Keksi imajo veliko kalorij in lahko predstavljajo precejšen del našega dnevnega vnosa sladkorjev in maščob. Bolje jih je zaužiti manj. Raje izberi tiste z nižjo vsebnostjo maščob in sladkorjev. Hkrati se odloči za tiste z več vlakninami.",
  no_topping: "Brez poliva",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Poskusi navadni jogurt z nizko vsebnostjo maščob brez dodanih sladkorjev.",
  honey: "Med",
  honey_description: "Izberi navadni jogurt in ne dodajaj sladkorja ali marmelade. Raje dodaj sadje ali nesladkane polnozrnate žitne kosmiče.",
  jam: "Marmelada",
  jam_description: "Izberi navadni jogurt in ne dodajaj sladkorja ali marmelade. Raje dodaj sadje ali nesladkane polnozrnate žitne kosmiče.",
  fruit: "Sadje",
  fruit_description: "Sadje je odlična izbira za začetek dneva; izberi sezonsko sadje. Če želiš dodati oreške, raje izberi tiste brez dodane soli ali sladkorjev.",
  butter: "Maslo",
  butter_description: "Maslo vsebuje velike količine maščobe in je lahko slano. Ne jejte ga prepogosto in izberite maslo brez soli.",
  chocolate_cream: "Čokoladna krema",
  chocolate_cream_description: "Čokoladna krema vsebuje veliko sladkorja in nasičenih maščob. Ne jej je prepogosto.",
  ham_or_bacon: "Šunka/slanina",
  ham_or_bacon_description: "Predelane mesnine vsebujejo velike količine maščob in soli. Uživaj jih v omejenih količinah.",
  cheese: "Sir",
  cheese_description: "Sir vsebuje velike količine soli in nasičenih maščob. Ne jejte ga prepogosto in uživajte ga v majhnih količinah.",
  avocado_and_tomato: "Avokado in paradižnik",
  avocado_and_tomato_description: "Odlična izbira za začetek dneva z nekaj zelenjave. Čez dan pojej veliko sadja in zelenjave, ki naj bosta po možnosti lokalnega izvora in sezonska.",
  battery: "Baterija",
  battery_description: "Bodite pozorni na svojo baterijo, saj kaže vpliv vaših odločitev!",
  lunch_title: "Kaj bi za kosilo?",
  breaktime_title: "Prosto popoldne – prosti čas!",
  snacking_title: "Bi prigrizek?",
  supermarket_title: "Kaj kupujete?",
  dinner_selection_title: "Kakšni so vaši načrti za večerjo?",
  dinner_cooking_title: "Kaj je za večerjo?",
  dinner_delivery_title: "Kaj je za večerjo?",
  your_available_items: "Vaše možnosti",
  your_selections: "Vaša izbira",
  walk: "Peš",
  walk_description: "Bodi telesno dejaven v vsakdanjem življenju. Če je mogoče, v šolo ali službo hodi peš ali s kolesom. Tako boš ohranjal dobro telesno pripravljenost in hkrati varoval okolje.",
  bicycle: "S kolesom",
  bicycle_description: "Bodi telesno dejaven v vsakdanjem življenju. Če je mogoče, v šolo ali službo hodi peš ali s kolesom. Tako boš ohranjal dobro telesno pripravljenost in hkrati varoval okolje.",
  scooter_or_motorbike: "S skirojem/motornim kolesom",
  scooter_or_motorbike_description: "Za čistejše okolje poskušaj redkeje uporabljati avtomobil ali motorno kolo. Če to ni mogoče, se poskusi voziti skupaj z drugimi.",
  car: "Z avtom",
  car_description: "Za čistejše okolje poskušaj redkeje uporabljati avtomobil ali motorno kolo. Če to ni mogoče, se poskusi voziti skupaj z drugimi.",
  public_transport: "Z javim prevozom",
  public_transport_description: "Namesto avtomobila uporabi javni prevoz in ga kombiniraj s hojo in kolesarjenjem. Pusti avtomobil doma in varuj okolje.",
  salad_with_egg: "Solata z jajcem",
  salad_with_egg_description: "Če je le mogoče, obrokom dodaj svežo zelenjavo.",
  beef_steak: "Goveji zrezek",
  beef_steak_description: "Razmisli o zmanjšanju uživanja mesa, zlasti rdečega ali predelanega. To koristi zdravju in okolju.",
  chicken: "Piščanec",
  chicken_description: "Razmisli o zmanjšanju uživanja mesa, zlasti rdečega ali predelanega. To koristi zdravju in okolju.",
  lentil_soup: "Lečna juha",
  lentil_soup_description: "Redno uživanje stročnic je priporočljivo kot del raznolike in večinoma rastlinske prehrane.",
  vegetable_burger: "Zelenjavni burger",
  vegetable_burger_description: "Zelenjavni burger je lahko dobra alternativa mesu. Pazi, da ne vsebuje preveč soli.",
  white_pasta: "Bele testenine",
  white_pasta_description: "Pri izbiri testenin, riža in kruha daj prednost polnozrnatim izdelkom.",
  wholegrain_pasta: "Polnozrnate testenine",
  wholegrain_pasta_description: "Pri izbiri testenin, riža in kruha daj prednost polnozrnatim izdelkom.",
  white_rice: "Beli riž",
  white_rice_description: "Pri izbiri testenin, riža in kruha daj prednost polnozrnatim izdelkom.",
  wholegrain_rice: "Polnozrnati riž",
  wholegrain_rice_description: "Pri izbiri testenin, riža in kruha daj prednost polnozrnatim izdelkom.",
  white_bread_bun: "Bela žemljica",
  white_bread_bun_description: "Pri izbiri testenin, riža in kruha daj prednost polnozrnatim izdelkom.",
  no_salad: "Nič",
  no_salad_description: "",
  fresh_green_salad: "Sveža zelena solata",
  fresh_green_salad_description: "Jedem dodaj nekaj barvite sezonske zelenjave; ki naj bo čim bolj raznolika.",
  french_fries: "Ocvrti krompirček",
  french_fries_description: "Kuhan krompir naj ima prednost pred ocvrtimi krompirjevimi izdelki.",
  boiled_broccoli: "Kuhan brokoli",
  boiled_broccoli_description: "Čez dan pojej veliko zelenjave. Odloči se za lokalno in sezonsko zelenjavo.",
  bottled_water: "Voda v plastenkah",
  bottled_water_description: "S pitjem vodovodne vode zmanjšuješ porabo plastike.",
  soft_drink: "Brezalkoholna pijača",
  soft_drink_description: "Sladkane pijače predstavljajo precejšen del našega dnevnega vnosa sladkorjev. Izberi raje vodo.",
  beer_or_wine: "Pivo/vino",
  beer_or_wine_description: "Omeji uživanje vseh vrst alkoholnih pijač. Izogibanje alkoholu koristi zdravju.",
  watching_tv: "Gledanje televizije ali igranje videoiger",
  watching_tv_description: "Bodi vsak dan telesno dejaven in poskušaj zmanjšati čas, ki ga preživiš v sedečem položaju. V prostem času se ukvarjaj s športom, igraj se s prijatelji ali bodi dejaven v skupnosti. Tako storiš nekaj dobrega za zdravje in družbo. Ne preživi preveč časa pred zaslonom.",
  doing_sports: "Športno udejstvovanje",
  doing_sports_description: "Bodi vsak dan telesno dejaven in poskušaj zmanjšati čas, ki ga preživiš v sedečem položaju. V prostem času se ukvarjaj s športom, igraj se s prijatelji ali bodi dejaven v skupnosti. Tako storiš nekaj dobrega za zdravje in družbo. Ne preživi preveč časa pred zaslonom.",
  playing_outside: "Igra na prostem",
  playing_outside_description: "Bodi vsak dan telesno dejaven in poskušaj zmanjšati čas, ki ga preživiš v sedečem položaju. V prostem času se ukvarjaj s športom, igraj se s prijatelji ali bodi dejaven v skupnosti. Tako storiš nekaj dobrega za zdravje in družbo. Ne preživi preveč časa pred zaslonom.",
  charity_work: "Dobrodelno delo z odraslimi",
  charity_work_description: "Bodi vsak dan telesno dejaven in poskušaj zmanjšati čas, ki ga preživiš v sedečem položaju. V prostem času se ukvarjaj s športom, igraj se s prijatelji ali bodi dejaven v skupnosti. Tako storiš nekaj dobrega za zdravje in družbo. Ne preživi preveč časa pred zaslonom.",
  carrots_tomatoes: "Korenje/češnjevi paradižniki",
  carrots_tomatoes_description: "Raje izberi sadje, zelenjavo ali oreške. Omeji prigrizke in pijače z visoko vsebnostjo sladkorjev, maščob in soli.",
  fresh_fruit: "Sveže sadje",
  fresh_fruit_description: "Raje izberi sadje, zelenjavo ali oreške. Omeji prigrizke in pijače z visoko vsebnostjo sladkorjev, maščob in soli.",
  chocolate_bar: "Čokoladna ploščica",
  chocolate_bar_description: "Raje izberi sadje, zelenjavo ali oreške. Omeji prigrizke in pijače z visoko vsebnostjo sladkorjev, maščob in soli.",
  chips: "Čips",
  chips_description: "Raje izberi sadje, zelenjavo ali oreške. Omeji prigrizke in pijače z visoko vsebnostjo sladkorjev, maščob in soli.",
  juice_drink: "Sok",
  juice_drink_description: "Namesto sokov raje izberi sveže sadje ali sveže stisnjene sokove brez dodanega sladkorja.",
  supermarket_prompt: "Izberite do 9 izdelkov.",
  raw_fish: "Surove ribe",
  raw_fish_description: "Redno uživanje rib vam pomaga pridobiti pomembna hranila. Poskusite uživati različne vrste rib.",
  raw_beef: "Surova govedina",
  raw_beef_description: "Razmislite o zmanjšanju uživanja mesa, zlasti rdečega ali predelanega. To koristi vašemu zdravju in okolju.",
  raw_chicken: "Surov piščanec",
  raw_chicken_description: "Razmisli o zmanjšanju uživanja mesa, zlasti rdečega ali predelanega. To koristi zdravju in okolju.",
  whole_salad: "Solatna zelenjava - npr. zelena solata",
  whole_salad_description: "Raje izberi sveže sadje in zelenjavo, zlasti sezonsko in lokalno.",
  packaged_salad: "Pakirana solata",
  packaged_salad_description: "Raje izberi sveže sadje in zelenjavo, zlasti sezonsko in lokalno.",
  seasonal_fruit: "Sezonsko lokalno sadje",
  seasonal_fruit_description: "Raje izberi sveže sadje in zelenjavo, zlasti sezonsko in lokalno.",
  exotic_fruit: "Eksotično sadje",
  exotic_fruit_description: "Raje izberi sveže sadje in zelenjavo, zlasti sezonsko in lokalno.",
  ham: "Šunka",
  ham_description: "Predelane mesnine vsebujejo velike količine maščob in soli. Uživaj jih v omejenih količinah.",
  tofu: "Tofu",
  tofu_description: "Poskusi nove rastlinske proizvode in nove recepte.",
  hummus: "Humus",
  hummus_description: "Humus je dobra alternativa, ki lahko pripomore k povečanju uživanja stročnic.",
  precooked_pizza: "vnaprej pripravljena pica",
  precooked_pizza_description: "Vnaprej pripravljeni obroki lahko vsebujejo velike količine soli ali maščob. Raje izberi izdelke, ki niso vnaprej pripravljeni. Tako boš imel nadzor nad količino soli, sladkorjev in maščob v obrokih.",
  precooked_fries: "vnaprej pripravljen ocvrti krompirček",
  precooked_fries_description: "Vnaprej pripravljeni obroki lahko vsebujejo velike količine soli ali maščob. Raje izberi izdelke, ki niso vnaprej pripravljeni. Tako boš imel nadzor nad količino soli, sladkorjev in maščob v obrokih.",
  food_delivery: "Dostava hrane",
  food_delivery_description: "",
  cooking_at_home: "Doma skuhan obrok",
  cooking_at_home_description: "",
  cheeseburger: "Hamburger s sirom, krompirček in brezalkoholna pijača",
  cheeseburger_description: "Izogibaj se uživanju tovrstnih obrokov. Vsebujejo veliko maščob in soli. Pij vodo.",
  pizza_and_soft_drink: "Pica in brezalkoholna pijača",
  pizza_and_soft_drink_description: "Takšni obroki vsebujejo veliko maščob, soli in sladkorjev. Izberi „enostavne“ pice z manj sestavinami ali več zelenjave in po možnosti s polnozrnatim testom. Pij vodo.",
  noodles_with_vegetables: "Rezanci z zelenjavo",
  noodles_with_vegetables_description: "To je dobra možnost za povečanje uživanja zelenjave. Dodaj nekaj stročnic, pa bo obrok popoln.",
  mushroom_risoto: "Gobova rižota",
  mushroom_risoto_description: "Gobe so dobra vegetarijanska izbira. Pazi, da ne dodaš preveč sira. Tak obrok lahko dopolniš s solato.",
  precooked_meal: "Vnaprej pripravljen gotovi obrok - piščančji kroketi",
  precooked_meal_description: "Vnaprej pripravljeni obroki lahko vsebujejo velike količine soli, maščob ali celo sladkorjev. Raje izberi izdelke, ki niso vnaprej pripravljeni. Tako boš imeli boljši nadzor nad količino teh hranil. Namesto soli jed začini z zelišči in začimbami.",
  frozen_pizza: "Zamrznjena pica",
  frozen_pizza_description: "Vnaprej pripravljeni obroki lahko vsebujejo velike količine soli, maščob ali celo sladkorjev. Raje izberi izdelke, ki niso vnaprej pripravljeni. Tako boš imeli boljši nadzor nad količino teh hranil. Namesto soli jed začini z zelišči in začimbami.",
  rice_and_salad: "Lastnoročno pripravljen obrok - riž in zelena solata",
  rice_and_salad_description: "Vnaprej pripravljeni obroki lahko vsebujejo velike količine soli, maščob ali celo sladkorjev. Raje izberi izdelke, ki niso vnaprej pripravljeni. Tako boš imeli boljši nadzor nad količino teh hranil. Namesto soli jed začini z zelišči in začimbami.",
  ham_sandwich_and_soft_drink: "Sendvič s šunko in brezalkoholna pijača",
  ham_sandwich_and_soft_drink_description: "Predelane mesnine vsebujejo velike količine maščob in soli. Uživaj jih v omejenih količinah. Prav tako se izogibaj sladkanim in brezalkoholnim pijačam. Raje pij vodovodno vodo.",
  wholegrain_pasta_with_sauce: "Polnozrnate testenine z zelenjavno omako",
  wholegrain_pasta_with_sauce_description: "Poskusi polnozrnate testenine.",
  hnw_title_adult: "Zdravi novi svet",
  hnw_description_1_adult: "Naše odločitve oblikujejo našo prihodnost. Če se bomo vsi prehranjevali in živeli tako kot v igri, je lahko naša prihodnost bolj zdrava v svetu, v katerem bodo ljudje skrbeli drug za drugega in za okolje.",
  hnw_description_2_adult: "Znanstveniki vedno znova ugotavljajo, da bi se morali prehranjevati raznoliko, da bi ohranjali svoje zdravje in zdravje našega planeta: uživati bi morali hrano, bogato z žiti, stročnicami, svežo zelenjavo in sadjem. Izogibajte se predelanim živilom, mesu in prigrizkom z visoko vsebnostjo soli, sladkorjev in maščob. Še več koristi prinašajo telesna dejavnost, hoja, kolesarjenje ali uporaba javnega prevoza in manjša uporaba avtomobila.",
  hnw_description_3_adult: "K boljšemu zdravju in okolju pripomorejo preproste stvari: čist zrak in voda, varna hrana, bogata tla, zelene površine ter spoštovanje narave in vseh ljudi. Na to pomislite tudi, kadar ne igrate te igre. Naj se vam pri tem pridružijo prijatelji in družina.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Bi igrali še enkrat?",
  hnw_title_child: "Naše odločitve oblikujejo našo prihodnost",
  hnw_description_1_child: "Če se bomo vsi prehranjevali in živeli tako kot v igri, je lahko naša prihodnost bolj zdrava.",
  hnw_description_2_child: "Kaj lahko storiš sam:\n- poskrbi za raznoliko prehrano, s čimer boš ohranjal svoje zdravje in zdravje našega planeta;\n- izberi žita, stročnice, svežo zelenjavo in sadje;\n- omeji uživanje predelanih živil, mesa in prigrizkov z visoko vsebnostjo soli, sladkorjev in maščob;\n- bodi telesno dejaven, hodi, kolesari in uporabljaj javni prevoz\n- ter zmanjšaj uporabo avtomobila.",
  hnw_description_3_child: "S takšnimi preprostimi odločitvami lahko pripomoreš k svojemu zdravju pa tudi k skrbi za okolje in ostale ljudi.",
  hnw_description_4_child: "Na to pomisli tudi, kadar ne igraš te igre.",
  hnw_description_5_child: "Naj se ti pri tem pridružijo prijatelji in družina.",
  hnw_description_6_child: "Bi igrali še enkrat?",
  etl_title_adult: "Hrana za življenje",
  etl_description_1_adult: "Naše odločitve oblikujejo našo prihodnost. Morda se tega ne zavedate, vendar vaš življenjski slog lahko vpliva na zdravje našega planeta.",
  etl_description_2_adult: "Znanstveniki vedno znova ugotavljajo, da bi se morali prehranjevati raznoliko, da bi ohranjali svoje zdravje in zdravje našega planeta: uživati bi morali hrano, bogato z žiti, stročnicami, svežo zelenjavo in sadjem. Izogibajte se predelanim živilom, mesu in prigrizkom z visoko vsebnostjo soli, sladkorjev in maščob. Še več koristi prinašajo telesna dejavnost, hoja, kolesarjenje ali uporaba javnega prevoza in manjša uporaba avtomobila.",
  etl_description_3_adult: "K boljšemu zdravju in okolju pripomorejo preproste stvari: čist zrak in voda, varna hrana, bogata tla, zelene površine ter spoštovanje narave in vseh ljudi.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Bi igrali še enkrat?",
  etl_title_child: "Naše odločitve oblikujejo našo prihodnost",
  etl_description_1_child: "Če vsi izboljšamo svoj način prehranjevanja in življenja, bo lahko naša prihodnost bolj zdrava.",
  etl_description_2_child: "Kaj lahko storiš sam:\n- poskrbi za raznoliko prehrano, s čimer boš ohranjal svoje zdravje in zdravje našega planeta;\n- izberi žita, stročnice, svežo zelenjavo in sadje;\n- omeji uživanje predelanih živil, mesa in prigrizkov z visoko vsebnostjo soli, sladkorjev in maščob;\n- bodi telesno dejaven, hodi, kolesari in uporabljaj javni prevoz\n- ter zmanjšaj uporabo avtomobila.",
  etl_description_3_child: "S takšnimi preprostimi odločitvami lahko pripomoreš k svojemu zdravju pa tudi k skrbi za okolje in ostale ljudi.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Bi igrali še enkrat?",
  share: "Delite",
  replay: "Ponovite",
  its_up_to_you: "Izbira je vaša!",
  badge_action_1: "Skriti zaklad razkrit!",
  badge_action_2: "Pridobili ste značko %{badge}",
  fridgeDoor: "Vrata hladilnika",
  light: "Luč",
  bottle: "Steklenica",
  reusable_bag: "Vrečka za večkratno uporabo",
  plastic_bag: "Plastična vrečka",
  recycle_bin: "Koš za recikliranje",
  share_text_subject: "Izbira je vaša!",
  share_text_body: "Igral/Igrala sem 2050 – Izbira je vaša! Bi radi vedeli, kakšen je vaš svet? #ExperienceEUScience\nIgrajte zdaj %{shareUrl}\nTo igro je razvilo Skupno raziskovalno središče, služba Evropske komisije za znanost in znanje.\nOdkrijte več https://joint-research-centre.ec.europa.eu/index_sl?etrans=sl"
};
export default i18n;
