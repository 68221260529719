import { Howl, Howler } from "howler";
import { getMutePreference } from "preferences.js";

export const mute = muted => Howler.mute(muted);

// Make sure sounds are muted globally on application start if this has been the preference of the user.
mute(getMutePreference());

const carPassSound = new Howl({ src: ["sounds/car-pass.webm", "sounds/car-pass.mp3"], volume: 0.45 });
export const carPass = () => {
  carPassSound.play();
};
export const carPassStop = () => {
  carPassSound.stop();
};

const cityTrafficSound = new Howl({
  src: ["sounds/city-traffic.webm", "sounds/city-traffic.mp3"],
  volume: 0.4,
  loop: true
});
export const cityTraffic = () => {
  cityTrafficSound.play();
};
export const cityTrafficStop = () => {
  cityTrafficSound.stop();
};

const dropletSound = new Howl({ src: ["sounds/droplet.webm", "sounds/droplet.mp3"] });
export const droplet = () => {
  dropletSound.play();
};

const elevateDownSound = new Howl({ src: ["sounds/elevate-down.webm", "sounds/elevate-down.mp3"] });
export const elevateDown = () => {
  elevateDownSound.play();
};

const elevateUpSound = new Howl({ src: ["sounds/elevate-up.webm", "sounds/elevate-up.mp3"] });
export const elevateUp = () => {
  elevateUpSound.play();
};

const industrialSound = new Howl({ src: ["sounds/industrial.webm", "sounds/industrial.mp3"], volume: 0.9, loop: true });
export const industrial = () => {
  industrialSound.play();
};
export const industrialStop = () => {
  industrialSound.stop();
};

const jikSound = new Howl({ src: ["sounds/jik.webm", "sounds/jik.mp3"] });
export const jik = () => {
  jikSound.play();
};

const loFiClickSound = new Howl({ src: ["sounds/lo-fi-click.webm", "sounds/lo-fi-click.mp3"] });
export const loFiClick = () => {
  loFiClickSound.play();
};

const morningSpringSound = new Howl({
  src: ["sounds/morning-spring.webm", "sounds/morning-spring.mp3"],
  volume: 0.7,
  loop: true
});
export const morningSpring = () => {
  morningSpringSound.play();
};
export const morningSpringStop = () => {
  morningSpringSound.stop();
};

const playgroundNoisesSound = new Howl({
  src: ["sounds/playground-noises.webm", "sounds/playground-noises.mp3"],
  volume: 0.4,
  loop: true
});
export const playgroundNoises = () => {
  playgroundNoisesSound.play();
};
export const playgroundNoisesStop = () => {
  playgroundNoisesSound.stop();
};

const psychedelicSound = new Howl({
  src: ["sounds/psychedelic.webm", "sounds/psychedelic.mp3"],
  volume: 0.16,
  loop: true
});
export const psychedelic = () => {
  psychedelicSound.play();
};
export const psychedelicStop = () => {
  psychedelicSound.stop();
};

const roomAmbienceWithBirdsSound = new Howl({
  src: ["sounds/room-ambience-with-birds.webm", "sounds/room-ambience-with-birds.mp3"],
  volume: 0.7,
  loop: true
});
export const roomAmbienceWithBirds = () => {
  roomAmbienceWithBirdsSound.play();
};
export const roomAmbienceWithBirdsStop = () => {
  roomAmbienceWithBirdsSound.stop();
};

const selectNextSound = new Howl({ src: ["sounds/select-next.webm", "sounds/select-next.mp3"], volume: 0.5 });
export const selectNext = () => {
  selectNextSound.play();
};

const selectPreviousSound = new Howl({
  src: ["sounds/select-previous.webm", "sounds/select-previous.mp3"],
  volume: 0.5
});
export const selectPrevious = () => {
  selectPreviousSound.play();
};

const suburbanTrafficWithBirdsSound = new Howl({
  src: ["sounds/suburban-traffic-with-birds.webm", "sounds/suburban-traffic-with-birds.mp3"],
  volume: 0.55,
  loop: true
});
export const suburbanTrafficWithBirds = () => {
  suburbanTrafficWithBirdsSound.play();
};
export const suburbanTrafficWithBirdsStop = () => {
  suburbanTrafficWithBirdsSound.stop();
};

const successSound = new Howl({ src: ["sounds/success.webm", "sounds/success.mp3"] });
export const success = () => {
  successSound.play();
};

const supermarketSound = new Howl({
  src: ["sounds/supermarket.webm", "sounds/supermarket.mp3"],
  volume: 0.55,
  loop: true
});
export const supermarket = () => {
  supermarketSound.play();
};
export const supermarketStop = () => {
  supermarketSound.stop();
};
