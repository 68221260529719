const i18n = {
  title: "2050 sällskapsspel om hälsa",
  start: "Starta",
  select_language: "Välj språk",
  adult: "Vuxen",
  child: "Barn",
  fullscreen: "Helskärm",
  restart_scene: "Starta scenen igen",
  restart_game: "Starta spelet igen",
  previous: "Tillbaka",
  next: "Nästa",
  close: "Stäng",
  toggle_sound: "Ljud på/av",
  character_selection_title: "Välj en roll",
  character_selection_body: "Våra val påverkar vår framtid. I det här spelet simulerar vi vardagssituationer. Gör dina val och lär dig mer om deras inverkan. Se till att leta efter dolda hemligheter för att tjäna dina märken! Först ska du skapa en roll.",
  character_set_up_title: "Skapa din roll",
  select_me: "Välj mig",
  breakfast_title: "Vad dricker du till frukost?",
  your_badges: "Märken",
  badge_water_conservation_master_title: "Mästare",
  badge_water_conservation_master_description: "Grattis! Du är en mästare på att hushålla med en knapp resurs! Vatten är grundläggande för allt liv.",
  badge_clean_street_wizard_title: "Fe",
  badge_clean_street_wizard_description: "Grattis! Du är en fena på att göra vår miljö renare genom att plocka upp skräp och slänga det i soptunnan.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Grattis! Du är en ninja på att spara energi! Att hushålla med energin som används av våra apparater är ett utmärkt sätt att minska energiförbrukningen.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Grattis! Du är expert på att spara energi! Fortsätt att hitta sätt att minska din energiförbrukning.",
  badge_low_waste_champion_title: "Vinnare",
  badge_low_waste_champion_description: "Grattis! Du är världsbäst på att minska mängden avfall och hjälpa miljön genom att dra ner på plastanvändningen.",
  badge_responsible_shopper_title: "Proffs",
  badge_responsible_shopper_description: "Grattis! Du är proffs på att göra hälsosamma val för miljön. Fortsätt att använda så lite plast som möjligt genom att ta med dig en tygpåse när du går och handlar.",
  badge_recycling_captain_title: "Stjärna",
  badge_recycling_captain_description: "Grattis! Du är en mästare på återvinning! Det är viktigt att återvinna och återanvända material för en hälsosam miljö.",
  faucet: "Kranvatten",
  transportation_title_adult: "Hur tar du dig till arbetet?",
  transportation_title_child: "Hur tar du dig till skolan?",
  trash: "Skräpdryck",
  no_drink: "Dricker inget",
  no_drink_description: "",
  tap_water: "Kranvatten",
  tap_water_description: "Se till att få i dig tillräckligt med vätska. Försök att dricka åtminstone 1,5–2 liter vatten per dag.",
  milk: "Mjölk",
  milk_description: "Drick vanlig osötad mjölk, helst med låg fetthalt.",
  freshly_made_juice: "Färskpressad juice",
  freshly_made_juice_description: "Om du dricker juice är det bäst att välja färskpressad, utan tillsats av socker. Ät gärna färsk frukt.",
  coffee_or_tea: "Kaffe/te",
  coffee_or_tea_description: "Undvik helst socker när du dricker te eller kaffe.",
  plant_based_drink: "Växtbaserad dryck",
  plant_based_drink_description: "Pröva på växtbaserade drycker; utan tillsats av socker och gärna drycker som innehåller vitaminer och mineraler.",
  whole_grain_cereals: "Fullkornsflingor",
  whole_grain_cereals_description: "Välj gärna fullkornsflingor utan tillsats av socker.",
  choco_balls: "Chokladpuffar",
  choco_balls_description: "Frukostflingor kan stå för en betydande del av ditt dagliga sockerintag. Överväg att välja flingor med mindre tillsats av socker, och gärna med mer fibrer.",
  white_bread_or_toast: "Vitt bröd",
  white_bread_or_toast_description: "Pröva på fullkornsbröd.",
  wholemeal_bread_or_toast: "Fullkornsbröd",
  wholemeal_bread_or_toast_description: "Pröva på fullkornsbröd.",
  biscuits: "Kakor/kex",
  biscuits_description: "Kakor och kex är kaloririka och kan stå för en betydande del av ditt dagliga intag av socker och fett. Det bästa är om du kan dra ner på intaget och välja alternativ med lägre fetthalt och mindre socker, och gärna med mer fibrer.",
  no_topping: "Ingen topping",
  no_topping_description: "",
  yogurt: "Yoghurt",
  yogurt_description: "Pröva på naturlig yoghurt med låg fetthalt och utan tillsats av socker.",
  honey: "Honung",
  honey_description: "Välj naturlig yoghurt utan tillsatt socker och sylt. I stället kan du lägga i frukt eller osötade fullkornsflingor.",
  jam: "Sylt",
  jam_description: "Välj naturlig yoghurt utan tillsatt socker och sylt. I stället kan du lägga i frukt eller osötade fullkornsflingor.",
  fruit: "Frukt",
  fruit_description: "Det är toppen att börja dagen med frukt. Välj gärna frukt efter säsong. Om du äter nötter är det bäst att välja sorter utan tillsatt salt eller socker.",
  butter: "Smör",
  butter_description: "Smör innehåller stora mängder fett och kan vara saltat. Försök att inte äta smör för ofta och använd osaltat smör.",
  chocolate_cream: "Bredbar choklad",
  chocolate_cream_description: "Bredbar choklad innehåller mycket socker och mättat fett. Försök att inte äta det så ofta.",
  ham_or_bacon: "Skinka/bacon",
  ham_or_bacon_description: "Bearbetat kött innehåller stora mängder fett och salt. Försök att äta det mer sällan.",
  cheese: "Ost",
  cheese_description: "Ost innehåller stora mängder salt och mättat fett. Försök att inte äta ost för ofta och bara lite varje gång.",
  avocado_and_tomato: "Avokado och tomat",
  avocado_and_tomato_description: "Att börja dagen med lite grönsaker är toppen! Försök att få i dig ordentligt med frukt och grönsaker under dagen. Välj gärna lokalproducerat och efter säsong.",
  battery: "Batteri",
  battery_description: "Kolla ditt batteri. Det visar hur dina val påverkar dig!",
  lunch_title: "Vad vill du äta till lunch?",
  breaktime_title: "En ledig eftermiddag! Vad gör du?",
  snacking_title: "Vad väljer du till mellanmål?",
  supermarket_title: "Vad köper du?",
  dinner_selection_title: "Vad ska du äta till middag?",
  dinner_cooking_title: "Vad blir det till middag?",
  dinner_delivery_title: "Vad blir det till middag?",
  your_available_items: "Dina alternativ",
  your_selections: "Dina val",
  walk: "Till fots",
  walk_description: "Se till att hålla dig fysiskt aktiv i din vardag. Det bästa är att gå eller cykla till skolan/arbetet om du kan. Det hjälper dig hålla dig i form och det är bättre för miljön.",
  bicycle: "Cykel",
  bicycle_description: "Se till att hålla dig fysiskt aktiv i din vardag. Det bästa är att gå eller cykla till skolan/arbetet om du kan. Det hjälper dig hålla dig i form och det är bättre för miljön.",
  scooter_or_motorbike: "Motorcykel/moped",
  scooter_or_motorbike_description: "Försök att använda bilen eller motorcykeln mer sällan för att värna om miljön. Om det inte går kanske du kan samåka med någon?",
  car: "Bil",
  car_description: "Försök att använda bilen eller motorcykeln mer sällan för att värna om miljön. Om det inte går kanske du kan samåka med någon?",
  public_transport: "Kollektivtrafik",
  public_transport_description: "Åk kollektivt och kombinera med gång eller cykling i stället för att ta bilen. Ta en paus från bilkörningen och värna om miljön.",
  salad_with_egg: "Sallad med ägg",
  salad_with_egg_description: "Tillsätt gärna färska grönsaker när du kan.",
  beef_steak: "Nötkött",
  beef_steak_description: "Överväg att minska din konsumtion av kött, särskilt rött eller bearbetat kött. Det är bättre för både din hälsa och miljön.",
  chicken: "Kyckling",
  chicken_description: "Överväg att minska din konsumtion av kött, särskilt rött eller bearbetat kött. Det är bättre för både din hälsa och miljön.",
  lentil_soup: "Linssoppa",
  lentil_soup_description: "Regelbunden konsumtion av baljväxter rekommenderas som en del av varierad och främst växtbaserad kost.",
  vegetable_burger: "Vegoburgare",
  vegetable_burger_description: "En vegetarisk burgare kan vara ett bra alternativ till kött. Se bara till att den inte innehåller för mycket salt.",
  white_pasta: "Vit pasta",
  white_pasta_description: "Välj hellre fullkornsprodukter när du äter pasta, ris och bröd.",
  wholegrain_pasta: "Fullkornspasta",
  wholegrain_pasta_description: "Välj hellre fullkornsprodukter när du äter pasta, ris och bröd.",
  white_rice: "Vitt ris",
  white_rice_description: "Välj hellre fullkornsprodukter när du äter pasta, ris och bröd.",
  wholegrain_rice: "Fullkornsris",
  wholegrain_rice_description: "Välj hellre fullkornsprodukter när du äter pasta, ris och bröd.",
  white_bread_bun: "Vitt bröd",
  white_bread_bun_description: "Välj hellre fullkornsprodukter när du äter pasta, ris och bröd.",
  no_salad: "Inget",
  no_salad_description: "",
  fresh_green_salad: "Färsk grönsallad",
  fresh_green_salad_description: "Ät gärna färgglada grönsaker som är i säsong. Ju större variation, desto bättre!",
  french_fries: "Pommes frites",
  french_fries_description: "Välj hellre kokt potatis än friterade potatisprodukter.",
  boiled_broccoli: "Kokt broccoli",
  boiled_broccoli_description: "Försök att få i dig ordentligt med grönsaker under dagen. Välj lokalproducerat och efter säsong.",
  bottled_water: "Vatten på flaska",
  bottled_water_description: "Drick kranvatten för att minska användningen av plast.",
  soft_drink: "Läsk",
  soft_drink_description: "Sockrade drycker kan stå för en betydande del av ditt dagliga sockerintag. Drick hellre vatten.",
  beer_or_wine: "Öl/vin",
  beer_or_wine_description: "Begränsa ditt intag av alkohol. Att undvika alkohol helt är bättre för din hälsa.",
  watching_tv: "Tittar på tv eller spelar tv-spel",
  watching_tv_description: "Se till att röra på dig och minska tiden du sitter stilla. Använd gärna fritiden till att idrotta, leka med kompisar eller hjälp någon i din omgivning. Det är bra för både din hälsa och samhället. Försök att dra ner på skärmtiden.",
  doing_sports: "Idrotta",
  doing_sports_description: "Se till att röra på dig och minska tiden du sitter stilla. Använd gärna fritiden till att idrotta, leka med kompisar eller hjälp någon i din omgivning. Det är bra för både din hälsa och samhället. Försök att dra ner på skärmtiden.",
  playing_outside: "Leka utomhus",
  playing_outside_description: "Se till att röra på dig och minska tiden du sitter stilla. Använd gärna fritiden till att idrotta, leka med kompisar eller hjälp någon i din omgivning. Det är bra för både din hälsa och samhället. Försök att dra ner på skärmtiden.",
  charity_work: "Välgörenhetsarbete med vuxna",
  charity_work_description: "Se till att röra på dig och minska tiden du sitter stilla. Använd gärna fritiden till att idrotta, leka med kompisar eller hjälp någon i din omgivning. Det är bra för både din hälsa och samhället. Försök att dra ner på skärmtiden.",
  carrots_tomatoes: "Morötter/körsbärstomater",
  carrots_tomatoes_description: "Ät gärna frukt, grönsaker eller nötter. Undvik mellanmål som innehåller mycket socker, fett och salt.",
  fresh_fruit: "Färsk frukt",
  fresh_fruit_description: "Ät gärna frukt, grönsaker eller nötter. Undvik mellanmål som innehåller mycket socker, fett och salt.",
  chocolate_bar: "Choklad",
  chocolate_bar_description: "Ät gärna frukt, grönsaker eller nötter. Undvik mellanmål som innehåller mycket socker, fett och salt.",
  chips: "Chips",
  chips_description: "Ät gärna frukt, grönsaker eller nötter. Undvik mellanmål som innehåller mycket socker, fett och salt.",
  juice_drink: "Juicedryck",
  juice_drink_description: "Välj hellre färsk frukt eller färskpressad juice utan tillsatt socker i stället för juicedrycker.",
  supermarket_prompt: "Välj upp till 9 produkter",
  raw_fish: "Rå fisk",
  raw_fish_description: "Om du äter fisk regelbundet får du i dig viktiga näringsämnen. Ät olika sorts fisk.",
  raw_beef: "Rått nötkött",
  raw_beef_description: "Försök att minska din konsumtion av kött, särskilt rött eller bearbetat kött. Det är bättre för både din hälsa och miljön.",
  raw_chicken: "Olagad kyckling",
  raw_chicken_description: "Överväg att minska din konsumtion av kött, särskilt rött eller bearbetat kött. Det är bättre för både din hälsa och miljön.",
  whole_salad: "Salladshuvud - t.ex. Isbergssallad",
  whole_salad_description: "Välj gärna färska frukter och grönsaker, i första hand lokalproducerat och efter säsong.",
  packaged_salad: "Paketerad sallad",
  packaged_salad_description: "Välj gärna färska frukter och grönsaker, i första hand lokalproducerat och efter säsong.",
  seasonal_fruit: "Lokalproducerad frukt som är i säsong",
  seasonal_fruit_description: "Välj gärna färska frukter och grönsaker, i första hand lokalproducerat och efter säsong.",
  exotic_fruit: "Exotiska frukter",
  exotic_fruit_description: "Välj gärna färska frukter och grönsaker, i första hand lokalproducerat och efter säsong.",
  ham: "Skinka",
  ham_description: "Bearbetat kött innehåller stora mängder fett och salt. Försök att äta det mer sällan.",
  tofu: "Tofu",
  tofu_description: "Pröva på nya växtbaserade produkter och recept.",
  hummus: "Hummus",
  hummus_description: "Hummus är ett bra alternativ för att öka intaget av baljväxter.",
  precooked_pizza: "Fryspizza",
  precooked_pizza_description: "Färdigrätter kan innehålla höga halter av salt och fett. Välj hellre andra alternativ så att du kan begränsa mängden salt, socker och fett i din kost.",
  precooked_fries: "Frysta pommes frites",
  precooked_fries_description: "Färdigrätter kan innehålla höga halter av salt och fett. Välj hellre andra alternativ så att du kan begränsa mängden salt, socker och fett i din kost.",
  food_delivery: "Hemleverans eller takeaway",
  food_delivery_description: "",
  cooking_at_home: "Matllagning hemma eller något från kylen eller frysen",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburgare, pommes frites och läsk",
  cheeseburger_description: "Undvik den här typen av måltider, som innehåller mycket fett och salt. Drick helst vatten.",
  pizza_and_soft_drink: "Pizza och läsk",
  pizza_and_soft_drink_description: "Den här typen av måltider innehåller mycket fett, salt och socker. Välj ”enklare” pizzor med färre ingredienser eller med mer grönsaker och om möjligt med fullkornsbotten. Drick helst vatten.",
  noodles_with_vegetables: "Nudlar med grönsaker",
  noodles_with_vegetables_description: "Detta är ett bra alternativ för att öka ditt grönsaksintag. Lägg till lite baljväxter så blir det ännu bättre!",
  mushroom_risoto: "Svamprisotto",
  mushroom_risoto_description: "Svamp är ett bra vegetariskt alternativ. Se bara till att det inte blir för mycket ost. Kombinera gärna med en sallad för en fullständig måltid.",
  precooked_meal: "Färdigmåltider - kycklingnuggets",
  precooked_meal_description: "Färdigrätter kan innehålla höga halter av salt, fett och till och med socker. Välj hellre andra alternativ så att du kan begränsa intaget av dessa näringsämnen. I stället för salt kan du smaksätta med örter och kryddor.",
  frozen_pizza: "Fryspizza",
  frozen_pizza_description: "Färdigrätter kan innehålla höga halter av salt, fett och till och med socker. Välj hellre andra alternativ så att du kan begränsa intaget av dessa näringsämnen. I stället för salt kan du smaksätta med örter och kryddor.",
  rice_and_salad: "Hemlagad måltid - ris och grönsallad",
  rice_and_salad_description: "Färdigrätter kan innehålla höga halter av salt, fett och till och med socker. Välj hellre andra alternativ så att du kan begränsa intaget av dessa näringsämnen. I stället för salt kan du smaksätta med örter och kryddor.",
  ham_sandwich_and_soft_drink: "Skinksmörgås och läsk",
  ham_sandwich_and_soft_drink_description: "Bearbetat kött innehåller stora mängder fett och salt. Försök att äta det mer sällan. Dessutom bör du försöka undvika sockrade drycker och läsk. Drick hellre kranvatten.",
  wholegrain_pasta_with_sauce: "Fullkornspasta med grönsakssås",
  wholegrain_pasta_with_sauce_description: "Pröva på fullkornspasta.",
  hnw_title_adult: "En hälsosam ny värld",
  hnw_description_1_adult: "Våra val formar framtiden. Om vi alla åt och levde som du gjorde i spelet skulle vi få en hälsosammare framtid, med människor som bryr sig om varandra och miljön.",
  hnw_description_2_adult: "Forskare konstaterar ständigt att vi behöver äta varierad kost för att bevara både vår egen och planetens hälsa, en kost rik på spannmål, baljväxter samt färska frukter och grönsaker. Undvik bearbetade livsmedel, kött samt mellanmål som är rika på salt, socker och fett. Det finns mycket att vinna på att vara fysiskt aktiv – gå, cykla eller åk kollektivt och undvik att ta bilen.",
  hnw_description_3_adult: "De små tingen kan förbättra både din hälsa och vår miljö: ren luft och rent vatten, säkra livsmedel, bördiga jordar och gröna ytor samt respekt för naturen och människor. Håll det här i minnet även utanför det här spelet och få med dig dina vänner och din familj.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Vill du spela igen?",
  hnw_title_child: "Våra val formar framtiden",
  hnw_description_1_child: "Om vi alla åt och levde som du gjorde i spelet skulle vi få en hälsosammare framtid.",
  hnw_description_2_child: "Vad kan du göra?\n- Se till att du får i dig varierad kost för att bevara både din egen och planetens hälsa.\n- Ät spannmål, baljväxter samt färska frukter och grönsaker.\n- Begränsa ditt intag av bearbetade livsmedel, kött samt mellanmål med högt innehåll av salt, socker och fett.\n- Motionera mer – gå, cykla och åk kollektivt.\n- Undvik att åka bil.",
  hnw_description_3_child: "Genom de här enkla valen kan du förbättra din hälsa och se till att vi tar hand om miljön och varandra.",
  hnw_description_4_child: "Ta med dig detta även utanför det här spelet och",
  hnw_description_5_child: "ta med dig vänner och familj på vägen.",
  hnw_description_6_child: "Vill du spela igen?",
  etl_title_adult: "Ät för att leva",
  etl_description_1_adult: "Våra val formar framtiden. Du kanske inte är medveten om att din livsstil kan påverka vår planets hälsa.",
  etl_description_2_adult: "Forskare konstaterar ständigt att vi behöver äta varierad kost för att bevara både vår egen och planetens hälsa, en kost rik på spannmål, baljväxter samt färska frukter och grönsaker. Undvik bearbetade livsmedel, kött samt mellanmål som är rika på salt, socker och fett. Det finns mycket att vinna på att vara fysiskt aktiv – gå, cykla eller åk kollektivt och undvik att ta bilen.",
  etl_description_3_adult: "De små tingen kan förbättra både din hälsa och vår miljö: ren luft och rent vatten, säkra livsmedel, bördiga jordar och gröna ytor samt respekt för naturen och människor.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Vill du spela igen?",
  etl_title_child: "Våra val formar framtiden",
  etl_description_1_child: "Om vi alla förbättrade hur vi åt och levde skulle vi få en hälsosammare framtid.",
  etl_description_2_child: "Vad kan du göra?\n- Se till att du får i dig varierad kost för att bevara både din egen och planetens hälsa.\n- Ät spannmål, baljväxter samt färska frukter och grönsaker.\n- Begränsa ditt intag av bearbetade livsmedel, kött samt mellanmål med högt innehåll av salt, socker och fett.\n- Motionera mer – gå, cykla och åk kollektivt.\n- Undvik att åka bil.",
  etl_description_3_child: "Genom de här enkla valen kan du förbättra din hälsa och se till att vi tar hand om miljön och varandra.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Vill du spela igen?",
  share: "Dela",
  replay: "Spela igen",
  its_up_to_you: "Valet är ditt",
  badge_action_1: "Hemligheten avslöjas",
  badge_action_2: "Du har vunnit  %{badge} märke",
  fridgeDoor: "Kylskåpsdörr",
  light: "Belysning",
  bottle: "Flaska",
  reusable_bag: "Återanvändbar kasse",
  plastic_bag: "Plastpåse",
  recycle_bin: "Soptunna",
  share_text_subject: "Valet är ditt",
  share_text_body: "Jag har spelat 2050 – Valet är ditt! Vill du se din värld? #ExperienceEUScience\nSpela nu %{shareUrl}\nSpelet har tagits fram av det gemensamma forskningscentrumet, som är Europeiska kommissionens forsknings- och kunskapscentrum.\nLäs mer https://joint-research-centre.ec.europa.eu/index_sv?etrans=sv"
};
export default i18n;
