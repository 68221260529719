const i18n = {
  title: "Zdravo društvo 2050",
  start: "Početak",
  select_language: "Odabir jezika",
  adult: "Odrasla osoba",
  child: "Dijete",
  fullscreen: "Puni ekran",
  restart_scene: "Pokreni scenu ispočetka",
  restart_game: "Pokreni igru ispočetka",
  previous: "Prijašnje",
  next: "Sljedeće",
  close: "Zatvori",
  toggle_sound: "Zvuk uključen/isključen",
  character_selection_title: "Odaberite svoj lik",
  character_selection_body: "Svaki odabir može utjecati na našu budućnost. U ovoj igri simuliramo svakodnevne situacije. Napravite odabire i saznajte više o njihovu učinku. Svakako potražite skrivene tajne kako biste osvojili bedževe! Započnimo izradom Vašeg lika.",
  character_set_up_title: "Izradite svoj lik",
  select_me: "Odaberi me",
  breakfast_title: "Od čega se sastoji Vaš doručak?",
  your_badges: "Bedževi",
  badge_water_conservation_master_title: "Majstor",
  badge_water_conservation_master_description: "Čestitamo! Zaista ste majstor u sprečavanju rasipanja rijetkog resursa! Voda je nužna svim oblicima života.",
  badge_clean_street_wizard_title: "Vila",
  badge_clean_street_wizard_description: "Čestitamo! Vi ste ekološki mađioničar čija je predstava poznata po triku „nestajanje smeća”: smeće nestane s ulice i pojavi se u kanti za smeće.",
  badge_eco_ninja_title: "Nindža",
  badge_eco_ninja_description: "Čestitamo! Kad je riječ o štednji energije, pravi ste nindža! Ako svojim kuhinjskim uređajima štedimo energiju, znatno pomažemo smanjivanju potrošnje energije.",
  badge_energy_saving_expert_title: "Stručnjak",
  badge_energy_saving_expert_description: "Čestitamo! Stručnjak ste za uštedu energije. Nastavite pronalaziti načine da smanjiš svoju potrošnju energije.",
  badge_low_waste_champion_title: "Šampion",
  badge_low_waste_champion_description: "Čestitamo! Stručno smanjujete količinu smeća i pomažete okolišu manjim korištenjem plastike.",
  badge_responsible_shopper_title: "Profi",
  badge_responsible_shopper_description: "Čestitamo, iz aviona se vidi da znate koje su odluke zdrave za okoliš. Samo nastavite koristiti svoju višekratno upotrebljivu vrećicu i svedite upotrebu plastike na minimum.",
  badge_recycling_captain_title: "Zvijezda",
  badge_recycling_captain_description: "Čestitamo! Pravi ste uzor kad je riječ o recikliranju. Recikliranje i korištenje postojećih materijala važno je za zdrav okoliš.",
  faucet: "Slavina",
  transportation_title_adult: "Kako idete na posao?",
  transportation_title_child: "Kako idete u školu?",
  trash: "Smeće",
  no_drink: "Bez pića",
  no_drink_description: "",
  tap_water: "Voda - iz slavine",
  tap_water_description: "Treba piti dovoljno vode. Dnevno bismo trebali popiti između 1,5 i 2 litre vode.",
  milk: "Mlijeko",
  milk_description: "Najbolje je piti obično, nezaslađeno mlijeko, po mogućnosti s manjim udjelom mliječne masti.",
  freshly_made_juice: "Svježe cijeđeni sok",
  freshly_made_juice_description: "Ljubiteljima sokova najzdravije je piti svježe cijeđene sokove i to bez dodatnog zaslađivanja. Dobro je jesti što više voća.",
  coffee_or_tea: "Kava/čaj",
  coffee_or_tea_description: "Kavu i čaj najbolje bi bilo piti bez šećera.",
  plant_based_drink: "Biljni napitak",
  plant_based_drink_description: "Najpoželjniji su biljni napitci bez dodanih šećera. Posebno oni s istaknutim udjelom vitamina i minerala.",
  whole_grain_cereals: "Cjelovite žitarice",
  whole_grain_cereals_description: "Najbolje je jesti cjelovite žitarice bez dodanog šećera.",
  choco_balls: "Čokoladne pahuljice",
  choco_balls_description: "Žitarice koje se jedu za doručak čine znatan dio našeg dnevnog unosa šećera, te je zbog toga bolje odabrati one s manjim udjelom dodanih šećera. Uz to, poželjno je odabrati žitarice s više vlakana.",
  white_bread_or_toast: "Bijeli kruh/tost",
  white_bread_or_toast_description: "Bijeli kruh/tost dobro je zamijeniti integralnim.",
  wholemeal_bread_or_toast: "Integralni kruh/tost",
  wholemeal_bread_or_toast_description: "Bijeli kruh/tost dobro je zamijeniti integralnim.",
  biscuits: "Keksi",
  biscuits_description: "Keksi su visokokalorična hrana koja može činiti velik dio našeg dnevnog unosa šećera i masnoća. Poželjno ih je manje jesti, a kad ih se jede, birati one s manje masnoće i šećera te s više vlakana.",
  no_topping: "Bez dodataka",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Poželjno je konzumirati jogurt s niskim udjelom masnoće i bez dodanih šećera.",
  honey: "Med",
  honey_description: "Najbolje je konzumirati običan jogurt, bez dodavanja šećera ili marmelade. U redu je dodati voće ili nezaslađene cjelovite žitarice.",
  jam: "Marmelada",
  jam_description: "Najbolje je konzumirati običan jogurt, bez dodavanja šećera ili marmelade. U redu je dodati voće ili nezaslađene cjelovite žitarice.",
  fruit: "Voće",
  fruit_description: "Sjajno je započeti dan voćem, a još bolje ako je sezonsko. Ako se dodaju orašasti plodovi, bolje je da nisu posoljeni ni zašećereni.",
  butter: "Maslac",
  butter_description: "Maslac sadrži velike količine masti i može biti slan. Pokušajte ga ne jesti prečesto i odaberite vrstu bez soli.",
  chocolate_cream: "Čokoladni namaz",
  chocolate_cream_description: "U čokoladnim namazima ima puno šećera i zasićenih masnoća. Ne valja ih jesti prečesto.",
  ham_or_bacon: "Šunka/slanina",
  ham_or_bacon_description: "U industrijski obrađenom mesu ima puno masnoća i soli. Treba ga jesti u ograničenim količinama.",
  cheese: "Sir",
  cheese_description: "Sir sadrži velike količine soli i zasićenih masti. Pokušajte ga jesti u malim količinama i ne prečesto.",
  avocado_and_tomato: "Avokado i rajčica",
  avocado_and_tomato_description: "Povrće je izvrstan odabir za početak dana. Ako je moguće, treba svakodnevno jesti puno voća i povrća. Ako je sezonsko i lokalno, još bolje.",
  battery: "Baterija",
  battery_description: "Pratite stanje baterije jer ona pokazuje učinak vaših odabira!",
  lunch_title: "Što bismo mogli ručati?",
  breaktime_title: "Popodne bez obaveza: slobodno vrijeme!",
  snacking_title: "Grickalice?",
  supermarket_title: "Što ćete kupiti?",
  dinner_selection_title: "Što planirate za večeru?",
  dinner_cooking_title: "Što je za večeru?",
  dinner_delivery_title: "Što je za večeru?",
  your_available_items: "Vaše opcije",
  your_selections: "Vaši odabiri",
  walk: "Pješke",
  walk_description: "Fizička aktivnost u svakodnevnom životu uvijek je korisna. Ako je ikako moguće, odaberite pješačenje ili vožnju bicikla do škole / radnog mjesta. Ne samo što pomaže kondiciji, nego je dobro i za okoliš.",
  bicycle: "Bicikl",
  bicycle_description: "Fizička aktivnost u svakodnevnom životu uvijek je korisna. Ako je ikako moguće, odaberite pješačenje ili vožnju bicikla do škole / radnog mjesta. Ne samo što pomaže kondiciji, nego je dobro i za okoliš.",
  scooter_or_motorbike: "Skuter/motor",
  scooter_or_motorbike_description: "Manje vožnji autom ili motorom znači čišći okoliš. Dogovor da se u istom automobilu vozi više osoba, odnosno dijeljenje prijevoza također smanjuje štetni utjecaj na okoliš.",
  car: "Automobil",
  car_description: "Manje vožnji autom ili motorom znači čišći okoliš. Dogovor da se u istom automobilu vozi više osoba, odnosno dijeljenje prijevoza također smanjuje štetni utjecaj na okoliš.",
  public_transport: "Javni prijevoz",
  public_transport_description: "Korištenje javnog prijevoza u kombinaciji s hodanjem ili vožnjom bicikla bolji je izbor od vožnje automobilom. Manje vožnji, čišći okoliš.",
  salad_with_egg: "Salata s jajima",
  salad_with_egg_description: "Kad god je moguće, u obroke treba dodati svježe povrće.",
  beef_steak: "Goveđi odrezak",
  beef_steak_description: "Ljubitelji mesa trebali bi razmisliti o smanjivanju konzumacije mesa, posebno crvenog ili obrađenog. Manja potrošnja mesa dobra je i za zdravlje i za okoliš.",
  chicken: "Piletina",
  chicken_description: "Ljubitelji mesa trebali bi razmisliti o smanjivanju konzumacije mesa, posebno crvenog ili obrađenog. Manja potrošnja mesa dobra je i za zdravlje i za okoliš.",
  lentil_soup: "Juha od leće",
  lentil_soup_description: "Potiče se redovita konzumacija mahunarki u širem okviru raznovrsne prehrane, koja se prvenstveno temelji na biljkama.",
  vegetable_burger: "Vegetarijanski burger",
  vegetable_burger_description: "Vegetarijanski burger može biti dobra alternativa mesu. Međutim, treba pripaziti da u njemu nema previše soli.",
  white_pasta: "Tjestenina od bijelog brašna",
  white_pasta_description: "Ako je moguće, bolje je odabrati integralnu tjesteninu, rižu i kruh.",
  wholegrain_pasta: "Integralna tjestenina",
  wholegrain_pasta_description: "Ako je moguće, bolje je odabrati integralnu tjesteninu, rižu i kruh.",
  white_rice: "Bijela riža",
  white_rice_description: "Ako je moguće, bolje je odabrati integralnu tjesteninu, rižu i kruh.",
  wholegrain_rice: "Integralna riža",
  wholegrain_rice_description: "Ako je moguće, bolje je odabrati integralnu tjesteninu, rižu i kruh.",
  white_bread_bun: "Pecivo od bijelog brašna",
  white_bread_bun_description: "Ako je moguće, bolje je odabrati integralnu tjesteninu, rižu i kruh.",
  no_salad: "Bez salate",
  no_salad_description: "",
  fresh_green_salad: "Svježa zelena salata",
  fresh_green_salad_description: "Šarenilo sezonskog povrća u obroku dobro dođe. Što raznolikije, to bolje.",
  french_fries: "Pomfrit",
  french_fries_description: "Kuhani krumpir bolji je izbor od krumpira pripremljenog u fritezi.",
  boiled_broccoli: "Kuhana brokula",
  boiled_broccoli_description: "Konzumacija veće količine povrća tijekom dana je zdrava, a lokalno i sezonsko povrće je posebno dobar odabir.",
  bottled_water: "Voda u boci",
  bottled_water_description: "Konzumiranjem vode iz vodovoda smanjuje se korištenje plastike.",
  soft_drink: "Gazirani napitci",
  soft_drink_description: "Gazirani zašećereni napitci čine znatan dio našeg dnevnog unosa šećera. Najbolje je odabrati običnu vodu.",
  beer_or_wine: "Pivo/vino",
  beer_or_wine_description: "Unos bilo koje vrste alkohola treba biti ograničen. Za zdravlje je najbolje izbjegavati alkohol.",
  watching_tv: "Gledanje televizije ili igranje videoigara",
  watching_tv_description: "Poželjno je svakodnevno se baviti fizičkim aktivnostima i smanjiti vrijeme koje se provodi sjedeći. Sport, igranje s prijateljima i pomaganje u zajednici aktivnosti su kojima bismo se mogli baviti u slobodno vrijeme. Te su aktivnosti dobre i za zdravlje i za društvo. Ne bi trebalo provoditi previše vremena ispred ekrana.",
  doing_sports: "Bavljenje sportom",
  doing_sports_description: "Poželjno je svakodnevno se baviti fizičkim aktivnostima i smanjiti vrijeme koje se provodi sjedeći. Sport, igranje s prijateljima i pomaganje u zajednici aktivnosti su kojima bismo se mogli baviti u slobodno vrijeme. Te su aktivnosti dobre i za zdravlje i za društvo. Ne bi trebalo provoditi previše vremena ispred ekrana.",
  playing_outside: "Igranje vani",
  playing_outside_description: "Poželjno je svakodnevno se baviti fizičkim aktivnostima i smanjiti vrijeme koje se provodi sjedeći. Sport, igranje s prijateljima i pomaganje u zajednici aktivnosti su kojima bismo se mogli baviti u slobodno vrijeme. Te su aktivnosti dobre i za zdravlje i za društvo. Ne bi trebalo provoditi previše vremena ispred ekrana.",
  charity_work: "Volontiranje s odraslima",
  charity_work_description: "Poželjno je svakodnevno se baviti fizičkim aktivnostima i smanjiti vrijeme koje se provodi sjedeći. Sport, igranje s prijateljima i pomaganje u zajednici aktivnosti su kojima bismo se mogli baviti u slobodno vrijeme. Te su aktivnosti dobre i za zdravlje i za društvo. Ne bi trebalo provoditi previše vremena ispred ekrana.",
  carrots_tomatoes: "Mrkve / male rajčice",
  carrots_tomatoes_description: "Dobro je jesti voće, povrće ili orašaste plodove. Ne valja pretjerivati s grickalicama i pićima s puno šećera, masnoća i soli.",
  fresh_fruit: "Svježe voće",
  fresh_fruit_description: "Dobro je jesti voće, povrće ili orašaste plodove. Ne valja pretjerivati s grickalicama i pićima s puno šećera, masnoća i soli.",
  chocolate_bar: "Čokolada",
  chocolate_bar_description: "Dobro je jesti voće, povrće ili orašaste plodove. Ne valja pretjerivati s grickalicama i pićima s puno šećera, masnoća i soli.",
  chips: "Čips",
  chips_description: "Dobro je jesti voće, povrće ili orašaste plodove. Ne valja pretjerivati s grickalicama i pićima s puno šećera, masnoća i soli.",
  juice_drink: "Voćni napitci",
  juice_drink_description: "Svježe voće i svježe cijeđeni sokovi bez dodanih šećera bolji su izbor od procesuiranih napitaka.",
  supermarket_prompt: "Odaberite do devet proizvoda",
  raw_fish: "Sirova riba",
  raw_fish_description: "Ako redovito jedete ribu, pomažete organizmu da dobije važne hranjive tvari. Preporučuje se raznovrsna riba.",
  raw_beef: "Sirova govedina",
  raw_beef_description: "Ljubitelji mesa trebali bi razmisliti o smanjivanju konzumacije mesa, posebno crvenog mesa ili prerađenih mesnih proizvoda. Manja potrošnja mesa dobra je i za zdravlje i za okoliš.",
  raw_chicken: "Sirova piletina",
  raw_chicken_description: "Ljubitelji mesa trebali bi razmisliti o smanjivanju konzumacije mesa, posebno crvenog ili obrađenog. Manja potrošnja mesa dobra je i za zdravlje i za okoliš.",
  whole_salad: "Salata - npr. glavica zelene salate",
  whole_salad_description: "Svježe voće i povrće, osobito sezonsko i lokalno, trebalo bi biti na prvome mjestu.",
  packaged_salad: "Zapakirana salata",
  packaged_salad_description: "Svježe voće i povrće, osobito sezonsko i lokalno, trebalo bi biti na prvome mjestu.",
  seasonal_fruit: "Sezonsko lokalno voće",
  seasonal_fruit_description: "Svježe voće i povrće, osobito sezonsko i lokalno, trebalo bi biti na prvome mjestu.",
  exotic_fruit: "Egzotično voće",
  exotic_fruit_description: "Svježe voće i povrće, osobito sezonsko i lokalno, trebalo bi biti na prvome mjestu.",
  ham: "Šunka",
  ham_description: "U industrijski obrađenom mesu ima puno masnoća i soli. Treba ga jesti u ograničenim količinama.",
  tofu: "Tofu",
  tofu_description: "Postoji mnoštvo novih proizvoda biljnog porijekla, kao i recepata s tim namirnicama.",
  hummus: "Humus",
  hummus_description: "Humus je zgodna alternativa za povećanje konzumacije mahunarki.",
  precooked_pizza: "Gotove pizze",
  precooked_pizza_description: "Gotova jela mogu sadržavati puno soli ili masnoća. Proizvodi koji nisu unaprijed skuhani su poželjniji jer se tako lakše kontrolira količina soli, šećera i masti u obrocima.",
  precooked_fries: "Prethodno ispržen pomfrit",
  precooked_fries_description: "Gotova jela mogu sadržavati puno soli ili masnoća. Proizvodi koji nisu unaprijed skuhani su poželjniji jer se tako lakše kontrolira količina soli, šećera i masti u obrocima.",
  food_delivery: "Hrana iz dostave",
  food_delivery_description: "",
  cooking_at_home: "Kuhanje",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, pomfrit i gazirano piće",
  cheeseburger_description: "Ovu vrstu obroka treba izbjegavati jer je puna masnoća i soli. Također, bolje je piti običnu vodu nego gazirana pića.",
  pizza_and_soft_drink: "Pizza i gazirano piće",
  pizza_and_soft_drink_description: "Takav je obrok prepun masnoća, soli i šećera. Najbolja je jednostavna pizza s manje sastojaka ili više povrća, po mogućnosti s tijestom od integralnog brašna. Bolje je piti običnu vodu.",
  noodles_with_vegetables: "Rezanci s povrćem",
  noodles_with_vegetables_description: "Ovo je nedvojbeno dobra opcija za povećanje konzumacije povrća. Ako se tome dodaju neke mahunarke, imamo kompletan obrok.",
  mushroom_risoto: "Rižoto s gljivama",
  mushroom_risoto_description: "Gljive su dobra vegetarijanska opcija, ali ne valja pretjerivati sa sirom. Salata će sjajno zaokružiti ovaj obrok.",
  precooked_meal: "Gotova jela - pileći medaljoni",
  precooked_meal_description: "Gotova jela mogu sadržavati puno soli ili masnoća pa čak i šećera. Proizvodi koji nisu unaprijed skuhani su poželjniji jer se tako lakše kontrolira količina hranjivih tvari. Začini su bolji od soli za poboljšavanje okusa.",
  frozen_pizza: "Smrznuta pizza",
  frozen_pizza_description: "Gotova jela mogu sadržavati puno soli ili masnoća pa čak i šećera. Proizvodi koji nisu unaprijed skuhani su poželjniji jer se tako lakše kontrolira količina hranjivih tvari. Začini su bolji od soli za poboljšavanje okusa.",
  rice_and_salad: "Riža skuhana kod kuće i zelena salata",
  rice_and_salad_description: "Gotova jela mogu sadržavati puno soli ili masnoća pa čak i šećera. Proizvodi koji nisu unaprijed skuhani su poželjniji jer se tako lakše kontrolira količina hranjivih tvari. Začini su bolji od soli za poboljšavanje okusa.",
  ham_sandwich_and_soft_drink: "Sendvič sa šunkom i gazirano piće",
  ham_sandwich_and_soft_drink_description: "U industrijski obrađenom mesu ima puno masnoća i soli. Treba ga jesti u ograničenim količinama. Ujedno je poželjno izbjegavati napitke sa šećerom i gazirana pića, najbolje je umjesto njih piti običnu vodu.",
  wholegrain_pasta_with_sauce: "Integralna tjestenina s umakom od povrća",
  wholegrain_pasta_with_sauce_description: "Probajte integralnu tjesteninu.",
  hnw_title_adult: "Zdravi novi svijet",
  hnw_description_1_adult: "Naša budućnost oblikovana je našim odabirima. Ako jedemo i živimo u skladu s Vašim izborima iz igre, budućnost nam može biti zdravija, s više međuljudske solidarnosti i brige za okoliš.",
  hnw_description_2_adult: "Znanstvenici dosljedno pronalaze dokaze koji potvrđuju da bismo za očuvanje svog zdravlja i zaštitu planeta trebali jesti raznoliko, i to puno žitarica, mahunarki, svježeg voća i povrća. Izbjegavajte industrijski obrađenu hranu, meso i grickalice s velikim sadržajem soli, šećera i masnoća. Još ćemo veću korist ostvariti ako budemo fizički aktivni, ako hodamo, vozimo bicikl i koristimo javni prijevoz, i ako općenito manje koristimo automobil.",
  hnw_description_3_adult: "Zdravlje i okoliš poboljšavaju se jednostavnim stvarima: čistim zrakom i vodom, sigurnom hranom, bogatim tlom i zelenim prostorima, poštovanjem prirode i svih ljudi. Sjetite se toga i nakon igre! Potaknite prijatelje i obitelj da vam se pridruže!",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Želite li igrati ponovno?",
  hnw_title_child: "Naša budućnost oblikovana je našim odabirima",
  hnw_description_1_child: "Ako jedete i živite u skladu sa svojim odlukama u igri, budućnost nam može biti zdravija.",
  hnw_description_2_child: "Možete napraviti sljedeće:\n- Pripazite da jedete raznoliko jer je to dobro i za vaše zdravlje i za planet.\n- Jedite puno žitarica, mahunarki, svježeg voća i povrća.  \n- Izbjegavajte industrijski obrađenu hranu, meso i grickalice s velikim sadržajem soli, šećera i masnoća.\n- Budite fizički aktivni, hodajte, vozite bicikl. \n- Koristite javni prijevoz. \n- Vozite se što rjeđe automobilom.",
  hnw_description_3_child: "To su jednostavne odluke koje su dobre za vaše zdravlje, a pomažu i okolišu i ljudima općenito.",
  hnw_description_4_child: "Sjetite se toga i nakon igre!",
  hnw_description_5_child: "Potaknite prijatelje i obitelj da vam se pridruže!",
  hnw_description_6_child: "Želite li igrati ponovno?",
  etl_title_adult: "Hrana za preživljavanje",
  etl_description_1_adult: "Naša budućnost oblikovana je našim odabirima. Možda niste svjesni kako Vaš način života utječe na zdravlje planeta.",
  etl_description_2_adult: "Znanstvenici dosljedno pronalaze dokaze koji potvrđuju da bismo za očuvanje svog zdravlja i zaštitu planeta trebali jesti raznoliko, i to puno žitarica, mahunarki, svježeg voća i povrća. Izbjegavajte industrijski obrađenu hranu, meso i grickalice s velikim sadržajem soli, šećera i masnoća. Još ćemo veću korist ostvariti ako budemo fizički aktivni, ako hodamo, vozimo bicikl i koristimo javni prijevoz, i ako općenito manje koristimo automobil.",
  etl_description_3_adult: "Zdravlje i okoliš poboljšavaju se jednostavnim stvarima: čistim zrakom i vodom, sigurnom hranom, bogatim tlom i zelenim prostorima, poštovanjem prirode i svih ljudi.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Želite li igrati ponovno?",
  etl_title_child: "Naša budućnost oblikovana je našim odabirima",
  etl_description_1_child: "Ako poboljšamo svoju prehranu i općenito način života, pred nama je zdravija budućnost.",
  etl_description_2_child: "Možete napraviti sljedeće:\n- Pripazite da jedete raznoliko jer je to dobro i za vaše zdravlje i za planet.\n- Jedite puno žitarica, mahunarki, svježeg voća i povrća.  \n- Izbjegavajte industrijski obrađenu hranu, meso i grickalice s velikim sadržajem soli, šećera i masnoća.\n- Budite fizički aktivni, hodajte, vozite bicikl. Koristite javni prijevoz. \n- Vozite se što rjeđe automobilom.",
  etl_description_3_child: "To su jednostavne odluke koje su dobre za vaše zdravlje, a pomažu i okolišu i ljudima općenito.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Želite li igrati ponovno?",
  share: "Podijeli",
  replay: "Odigraj ponovno",
  its_up_to_you: "Na Vama je",
  badge_action_1: "Tajna je otkrivena!",
  badge_action_2: "Osvojili ste bedž %{badge}",
  fridgeDoor: "Vrata hladnjaka",
  light: "Svjetlo",
  bottle: "Boca",
  reusable_bag: "Vrećica za višekratnu upotrebu",
  plastic_bag: "Plastična vrećica",
  recycle_bin: "Kanta za smeće",
  share_text_subject: "Na Vama je",
  share_text_body: "Igrao sam 2050 – na vama je! Zanima li vas kako izgleda vaš? #ExperienceEUScience\nProbajte sad na %{shareUrl}\nOvu je igru napravio Zajednički istraživački centar, služba Europske komisije za znanost i znanje.\nSaznajte više: https://joint-research-centre.ec.europa.eu/index_hr?etrans=hr"
};
export default i18n;
