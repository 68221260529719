const i18n = {
  title: "Hra Zdravá společnost 2050",
  start: "Start",
  select_language: "Vyber si jazyk",
  adult: "Dospělý",
  child: "Dítě",
  fullscreen: "Celá obrazovka",
  restart_scene: "Restartovat zobrazení",
  restart_game: "Restartovat hru",
  previous: "Předchozí",
  next: "Další",
  close: "Zavřít",
  toggle_sound: "Zapnout/vypnout zvuk",
  character_selection_title: "Vyber si postavu",
  character_selection_body: "Naše rozhodnutí utvářejí naši budoucnost. V této hře simulujeme každodenní situace. Rozhoduj se a zjisti, jaký budou mít tvé volby dopad. Dávej pozor, zda kolem tebe nejsou schovaná překvapení, která ti pomohou získat odznaky! Začni tím, že si vytvoříš postavu.",
  character_set_up_title: "Vytvoř si postavu",
  select_me: "Vyber tuto postavu",
  breakfast_title: "Co si dáš k snídani?",
  your_badges: "Odznaky",
  badge_water_conservation_master_title: "Mistr",
  badge_water_conservation_master_description: "Blahopřejeme! Jsi mistr v šetření vzácného zdroje! Voda je základ veškerého života.",
  badge_clean_street_wizard_title: "Víla",
  badge_clean_street_wizard_description: "Blahopřejeme! Jsi čaroděj/ka v oblasti čistšího životního prostředí díky tomu, že sbíráš odpadky a vyhazuješ je do koše.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Blahopřejeme! Jsi ninja v úspoře energie. Skvělý způsob, jak snížit její spotřebu, je omezit použití spotřebičů.",
  badge_energy_saving_expert_title: "Expert",
  badge_energy_saving_expert_description: "Blahopřejeme! Jsi expert na úsporu energie. Pokračuj v hledání způsobů, jak snížit spotřebu energie.",
  badge_low_waste_champion_title: "Šampion/ka",
  badge_low_waste_champion_description: "Blahopřejeme! Jsi šampion/ka v oblasti omezování odpadu a napomáhání životnímu prostředí tím, že nepoužíváš tolik plastu.",
  badge_responsible_shopper_title: "Profík",
  badge_responsible_shopper_description: "Blahopřejeme, jsi profesionál/ka v oblasti zdravého rozhodování o životním prostředí. Nos s sebou tašku na více použití a používej co nejméně těch plastových.",
  badge_recycling_captain_title: "Hvězda",
  badge_recycling_captain_description: "Blahopřejeme! Jsi hvězda recyklace. V zájmu zdravého životního prostředí je důležité materiály recyklovat a používat je opakovaně.",
  faucet: "Vodovodní kohoutek",
  transportation_title_adult: "Jak se dostaneš do práce?",
  transportation_title_child: "Jak se dostaneš do školy?",
  trash: "Odpadky",
  no_drink: "Žádný nápoj",
  no_drink_description: "",
  tap_water: "Voda z kohoutku",
  tap_water_description: "Dodržuj pitný režim. Snaž se vypít alespoň 1,5–2 litry vody za den.",
  milk: "Mléko",
  milk_description: "Pij neslazené a pokud možno nízkotučné mléko bez příchuti.",
  freshly_made_juice: "Čerstvě vymačkaný džus",
  freshly_made_juice_description: "Pokud máš rád/a džus, raději si připrav čerstvý – přidávat cukr není potřeba. Snaž se jíst čerstvé ovoce.",
  coffee_or_tea: "Káva/čaj",
  coffee_or_tea_description: "Vychutnej si čaj a kávu raději bez cukru.",
  plant_based_drink: "Rostlinný nápoj",
  plant_based_drink_description: "Vyzkoušej rostlinné nápoje bez přidaného cukru a pokud možno takové, které obsahují vitamíny a minerály.",
  whole_grain_cereals: "Celozrnné cereálie",
  whole_grain_cereals_description: "Vybírej celozrnné cereálie bez přidaného cukru.",
  choco_balls: "Čokoládové cereální kuličky",
  choco_balls_description: "Snídaňové cereálie mohou tvořit značnou část naší denní dávky cukru – nechceš si raději vybrat ty s nižším obsahem přidaného cukru? A rovnou sáhni po těch, které mají víc vlákniny.",
  white_bread_or_toast: "Bílý chléb / toustový chléb",
  white_bread_or_toast_description: "Vyzkoušej celozrnný chléb.",
  wholemeal_bread_or_toast: "Celozrnný chléb / toustový chléb",
  wholemeal_bread_or_toast_description: "Vyzkoušej celozrnný chléb.",
  biscuits: "Sušenky",
  biscuits_description: "Sušenky obsahují hodně kalorií a mohou tvořit značnou část naší denní dávky cukru a tuku. Je lepší nejíst jich tolik. Vybírej si proto sušenky s nižším obsahem cukru a tuku. A rovnou sáhni po těch, které mají víc vlákniny.",
  no_topping: "Bez polevy",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Vyzkoušej bílý nízkotučný jogurt bez přidaného cukru.",
  honey: "Med",
  honey_description: "Dej si bílý jogurt bez cukru nebo džemu. Raději si do něj přidej ovoce nebo neslazené celozrnné cereálie.",
  jam: "Džem",
  jam_description: "Dej si bílý jogurt bez cukru nebo džemu. Raději si do něj přidej ovoce nebo neslazené celozrnné cereálie.",
  fruit: "Ovoce",
  fruit_description: "Začít den ovocem je skvělá volba. Snaž se vybírat sezónní ovoce. Pokud si dáváš i oříšky, vyber si raději takové, které neobsahují přidanou sůl nebo cukry.",
  butter: "Máslo",
  butter_description: "Máslo obsahuje velké množství tuku a může být slané. Snaž se ho nejíst příliš často a vybírej si raději máslo bez soli.",
  chocolate_cream: "Čokoládová pomazánka",
  chocolate_cream_description: "Čokoládová pomazánka obsahuje hodně cukru a nasyceného tuku. Snaž se ji nejíst moc často.",
  ham_or_bacon: "Šunka/slanina",
  ham_or_bacon_description: "Zpracované maso obsahuje vysoké množství tuku a soli. Pokud ho jíš, snaž se to omezit.",
  cheese: "Sýr",
  cheese_description: "Sýr obsahuje velké množství soli a nasycených tuků. Snaž se ho nejíst příliš často a jez ho jen málo.",
  avocado_and_tomato: "Avokádo a rajče",
  avocado_and_tomato_description: "Začít den zeleninou je skvělá volba. Snaž se přes den jíst hodně ovoce a zeleniny a vybírej si plody, které jsou lokální a sezónní.",
  battery: "Baterie",
  battery_description: "Sleduj stav své baterie – ukazuje, jaký mají tvé volby dopad!",
  lunch_title: "Co si dáš k obědu?",
  breaktime_title: "Volné odpoledne – volná zábava!",
  snacking_title: "Dáš si sváču?",
  supermarket_title: "Co si koupíš?",
  dinner_selection_title: "Co budeš mít k večeři?",
  dinner_cooking_title: "Co bude k večeři?",
  dinner_delivery_title: "Co bude k večeři?",
  your_available_items: "Tvoje možnosti",
  your_selections: "Tvůj výběr",
  walk: "Pěšky",
  walk_description: "Každý den se hýbej. Pokud můžeš, choď do školy či práce pěšky nebo jezdi na kole. Prospěje to tvému zdraví i životnímu prostředí.",
  bicycle: "Kolo",
  bicycle_description: "Každý den se hýbej. Pokud můžeš, choď do školy či práce pěšky nebo jezdi na kole. Prospěje to tvému zdraví i životnímu prostředí.",
  scooter_or_motorbike: "Skútr/motorka",
  scooter_or_motorbike_description: "Snaž se nejezdit na motorce nebo autem příliš často. Pomůžeš zachovat čisté životní prostředí. Pokud to nejde, zkus spolujízdu.",
  car: "Auto",
  car_description: "Snaž se nejezdit na motorce nebo autem příliš často. Pomůžeš zachovat čisté životní prostředí. Pokud to nejde, zkus spolujízdu.",
  public_transport: "Veřejná doprava",
  public_transport_description: "Místo auta využij veřejnou dopravu a zkombinuj ji s chůzí nebo jízdou na kole. Dej si pauzu od řízení a pomoz životnímu prostředí.",
  salad_with_egg: "Salát s vejcem",
  salad_with_egg_description: "Kdykoli to jde, přidej do svého jídla čerstvou zeleninu.",
  beef_steak: "Hovězí steak",
  beef_steak_description: "Pokud jíš maso, zvaž omezení jeho konzumace, a to hlavně v případě červeného nebo zpracovaného masa. Prospěje to tvému zdraví i životnímu prostředí.",
  chicken: "Kuře",
  chicken_description: "Pokud jíš maso, zvaž omezení jeho konzumace, a to hlavně v případě červeného nebo zpracovaného masa. Prospěje to tvému zdraví i životnímu prostředí.",
  lentil_soup: "Čočková polévka",
  lentil_soup_description: "Luštěniny se doporučují jíst jako součást různorodé a převážně rostlinné stravy.",
  vegetable_burger: "Zeleninový burger",
  vegetable_burger_description: "Zeleninový burger může být dobrou alternativou masa. Dej si pozor, aby neobsahoval příliš mnoho soli.",
  white_pasta: "Bílé těstoviny",
  white_pasta_description: "Vybírej si raději celozrnné těstoviny, rýži a pečivo.",
  wholegrain_pasta: "Celozrnné těstoviny",
  wholegrain_pasta_description: "Vybírej si raději celozrnné těstoviny, rýži a pečivo.",
  white_rice: "Bílá rýže",
  white_rice_description: "Vybírej si raději celozrnné těstoviny, rýži a pečivo.",
  wholegrain_rice: "Celozrnná rýže",
  wholegrain_rice_description: "Vybírej si raději celozrnné těstoviny, rýži a pečivo.",
  white_bread_bun: "Bílá houska",
  white_bread_bun_description: "Vybírej si raději celozrnné těstoviny, rýži a pečivo.",
  no_salad: "Žádný",
  no_salad_description: "",
  fresh_green_salad: "Čerstvý zelený salát",
  fresh_green_salad_description: "Obohať své jídlo o nějakou barevnou sezónní zeleninu. Čím rozmanitější, tím lepší.",
  french_fries: "Hranolky",
  french_fries_description: "Vařené brambory jsou lepší než smažené výrobky z brambor.",
  boiled_broccoli: "Vařená brokolice",
  boiled_broccoli_description: "Snaž se přes den sníst hodně zeleniny. Vybírej si lokální a sezónní zeleninu.",
  bottled_water: "Balená voda",
  bottled_water_description: "Pij raději vodu z kohoutku, snížíš tak využívání plastů.",
  soft_drink: "Limonáda",
  soft_drink_description: "Slazené nápoje mohou tvořit značnou část naší denní dávky cukru – nechceš si raději dát vodu?",
  beer_or_wine: "Pivo/vino",
  beer_or_wine_description: "Pokud piješ jakýkoli druh alkoholu, nepij ho příliš. Prospěje to tvému zdraví.",
  watching_tv: "Sledování televize nebo hraní videoher",
  watching_tv_description: "Snaž se každý den hýbat a omezit sezení. Během svého volného času se věnuj sportu, hraj si s kamarády nebo pomáhej svému okolí. Prospěje to tvému zdraví a pomůže to společnosti. Neseď příliš dlouho před obrazovkou.",
  doing_sports: "Sport",
  doing_sports_description: "Snaž se každý den hýbat a omezit sezení. Během svého volného času se věnuj sportu, hraj si s kamarády nebo pomáhej svému okolí. Prospěje to tvému zdraví a pomůže to společnosti. Neseď příliš dlouho před obrazovkou.",
  playing_outside: "Hraní venku",
  playing_outside_description: "Snaž se každý den hýbat a omezit sezení. Během svého volného času se věnuj sportu, hraj si s kamarády nebo pomáhej svému okolí. Prospěje to tvému zdraví a pomůže to společnosti. Neseď příliš dlouho před obrazovkou.",
  charity_work: "Dobrovolnická činnost s dospělými",
  charity_work_description: "Snaž se každý den hýbat a omezit sezení. Během svého volného času se věnuj sportu, hraj si s kamarády nebo pomáhej svému okolí. Prospěje to tvému zdraví a pomůže to společnosti. Neseď příliš dlouho před obrazovkou.",
  carrots_tomatoes: "Mrkev / cherry rajčata",
  carrots_tomatoes_description: "Jez raději ovoce, zeleninu nebo ořechy. Vyhýbej se svačinám a nápojům, které obsahují hodně cukrů, tuku a soli.",
  fresh_fruit: "Čerstvé ovoce",
  fresh_fruit_description: "Jez raději ovoce, zeleninu nebo ořechy. Vyhýbej se svačinám a nápojům, které obsahují hodně cukrů, tuku a soli.",
  chocolate_bar: "Čokoládová tyčinka",
  chocolate_bar_description: "Jez raději ovoce, zeleninu nebo ořechy. Vyhýbej se svačinám a nápojům, které obsahují hodně cukrů, tuku a soli.",
  chips: "Brambůrky",
  chips_description: "Jez raději ovoce, zeleninu nebo ořechy. Vyhýbej se svačinám a nápojům, které obsahují hodně cukrů, tuku a soli.",
  juice_drink: "Ovocné šťávy",
  juice_drink_description: "Místo ovocných šťáv raději jez čerstvé ovoce nebo pij čerstvě vymačkané džusy bez přidaného cukru.",
  supermarket_prompt: "Vyber až 9 produktů",
  raw_fish: "Syrová ryba",
  raw_fish_description: "Pravidelná konzumace ryb ti dodá důležité živiny. Vyzkoušej různé druhy",
  raw_beef: "Syrové hovězí maso",
  raw_beef_description: "Pokud jíš maso, zvaž omezení jeho konzumace, a to hlavně v případě červeného nebo zpracovaného masa. Prospěje to tvému zdraví i životnímu prostředí.",
  raw_chicken: "Syrové kuřecí maso",
  raw_chicken_description: "Pokud jíš maso, zvaž omezení jeho konzumace, a to hlavně v případě červeného nebo zpracovaného masa. Prospěje to tvému zdraví i životnímu prostředí.",
  whole_salad: "Celý salát - např. Hlávkový",
  whole_salad_description: "Kupuj raději čerstvé ovoce a zeleninu a především sezónní a lokální plody.",
  packaged_salad: "Balený salát",
  packaged_salad_description: "Kupuj raději čerstvé ovoce a zeleninu a především sezónní a lokální plody.",
  seasonal_fruit: "Lokální sezónní ovoce",
  seasonal_fruit_description: "Kupuj raději čerstvé ovoce a zeleninu a především sezónní a lokální plody.",
  exotic_fruit: "Exotické ovoce",
  exotic_fruit_description: "Kupuj raději čerstvé ovoce a zeleninu a především sezónní a lokální plody.",
  ham: "Šunka",
  ham_description: "Zpracované maso obsahuje vysoké množství tuku a soli. Pokud ho jíš, snaž se to omezit.",
  tofu: "Tofu",
  tofu_description: "Zkoušej nové rostlinné výrobky a nové recepty.",
  hummus: "Hummus",
  hummus_description: "Hummus je dobrá alternativa, která ti pomůže zvýšit příjem luštěnin.",
  precooked_pizza: "Mražená pizza",
  precooked_pizza_description: "Polotovary mohou obsahovat velké množství soli nebo tuku. Kupuj raději čerstvé výrobky. Budeš tak mít pod kontrolou obsah soli, cukrů a tuků ve svých jídlech.",
  precooked_fries: "Mražené hranolky",
  precooked_fries_description: "Polotovary mohou obsahovat velké množství soli nebo tuku. Kupuj raději čerstvé výrobky. Budeš tak mít pod kontrolou obsah soli, cukrů a tuků ve svých jídlech.",
  food_delivery: "Rozvoz jídla",
  food_delivery_description: "",
  cooking_at_home: "Doma uvařené jídlo",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, hranolky a limonáda",
  cheeseburger_description: "Těmto jídlům se raději vyhýbej. Jsou bohatá hlavně na tuky a soli. K jídlu pij vodu.",
  pizza_and_soft_drink: "Pizza a limonáda",
  pizza_and_soft_drink_description: "Takováto jídla obsahují hodně tuku, soli a cukrů. Pokud je to možné, vyber si raději „jednoduchou“ pizzu s menším množstvím přísad nebo větším množstvím zeleniny a z celozrnného těsta. K jídlu pij vodu.",
  noodles_with_vegetables: "Nudle se zeleninou",
  noodles_with_vegetables_description: "Tohle je dobrá volba, pokud chceš zvýšit příjem zeleniny. Jídlo doplň nějakými luštěninami.",
  mushroom_risoto: "Houbové rizoto",
  mushroom_risoto_description: "Houby jsou dobrou vegetariánskou volbou. Dej si pozor, ať to nepřeženeš se sýrem. Jídlo můžeš doplnit salátem.",
  precooked_meal: "Polotovary - kuřecí nugetky",
  precooked_meal_description: "Polotovary mohou obsahovat velké množství soli, tuku, nebo dokonce cukrů. Kupuj raději čerstvé výrobky. Budeš tak mít tyto živiny lépe pod kontrolou. Místo soli používej k dochucování bylinky a koření.",
  frozen_pizza: "Mražená pizza",
  frozen_pizza_description: "Polotovary mohou obsahovat velké množství soli, tuku, nebo dokonce cukrů. Kupuj raději čerstvé výrobky. Budeš tak mít tyto živiny lépe pod kontrolou. Místo soli používej k dochucování bylinky a koření.",
  rice_and_salad: "Doma uvařené jídlo - rýže a zelený salát",
  rice_and_salad_description: "Polotovary mohou obsahovat velké množství soli, tuku, nebo dokonce cukrů. Kupuj raději čerstvé výrobky. Budeš tak mít tyto živiny lépe pod kontrolou. Místo soli používej k dochucování bylinky a koření.",
  ham_sandwich_and_soft_drink: "Sendvič se šunkou a limonáda",
  ham_sandwich_and_soft_drink_description: "Zpracované maso obsahuje vysoké množství tuku a soli. Pokud ho jíš, snaž se to omezit. Také se snaž vyhýbat slazeným nápojům a limonádám. Dej si raději vodu z kohoutku.",
  wholegrain_pasta_with_sauce: "Celozrnné těstoviny se zeleninovou omáčkou",
  wholegrain_pasta_with_sauce_description: "Vyzkoušej celozrnné těstoviny.",
  hnw_title_adult: "Nový zdravý svět",
  hnw_description_1_adult: "Naše rozhodnutí utvářejí naši budoucnost. Pokud budeme všichni jíst a žít jako ty ve hře, může být naše budoucnost zdravější – lidé budou dbát o sebe navzájem i o životní prostředí.",
  hnw_description_2_adult: "Vědci jsou dlouhodobě toho názoru, že pokud chceme zachovat zdraví své i naší planety, měli bychom jíst rozmanitou stravu: bohatou na obilniny, luštěniny, čerstvou zeleninu a ovoce. Vyhýbej se zpracovaným jídlům, masu a svačinám, které obsahují vysoké množství soli, cukrů a tuku. Pokud se budeme hýbat, chodit pěšky, jezdit na kole nebo veřejnou dopravou a omezíme jízdu autem, bude nám to jedině ku prospěchu.",
  hnw_description_3_adult: "Našemu zdraví a životnímu prostředí mohou prospět jednoduché věci: čistý vzduch a voda, bezpečné potraviny, úrodná půda a zelené plochy, ohleduplnost k přírodě a ke všem lidem. Mysli na to, i když zrovna nehraješ hru. Na cestu vezmi i své přátele a rodinu.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Chceš si zahrát znovu?",
  hnw_title_child: "Naše rozhodnutí utvářejí naši budoucnost",
  hnw_description_1_child: "Pokud budeme všichni jíst a žít jako ty ve hře, může být naše budoucnost zdravější.",
  hnw_description_2_child: "Dělat pro to můžeš tohle:\n- Dávej si pozor, abys jedl/a rozmanitě. Tím si udržíš zdraví své i naší planety.\n- Jez obilniny, luštěniny, čerstvou zeleninu a ovoce.  \n- Vyhýbej se zpracovaným jídlům, masu a svačinám, které obsahují vysoké množství soli, cukrů a tuku.\n- Hýbej se, choď pěšky, jezdi na kole a veřejnou dopravou.\n- Nejezdi tolik autem.",
  hnw_description_3_child: "Tato jednoduchá rozhodnutí mohou prospět tvému zdraví a vést k tomu, že budeme dbát o životní prostředí a všechny lidi.",
  hnw_description_4_child: "Mysli na to, i když zrovna nehraješ hru.",
  hnw_description_5_child: "Na cestu vezmi i své přátele a rodinu.",
  hnw_description_6_child: "Chceš si zahrát znovu?",
  etl_title_adult: "Jez, abys žil",
  etl_description_1_adult: "Naše rozhodnutí utvářejí naši budoucnost. Možná si to neuvědomuješ, ale tvůj životní styl může mít vliv na zdraví naší planety.",
  etl_description_2_adult: "Vědci jsou dlouhodobě toho názoru, že pokud chceme zachovat zdraví své i naší planety, měli bychom jíst rozmanitou stravu: bohatou na obilniny, luštěniny, čerstvou zeleninu a ovoce. Vyhýbej se zpracovaným jídlům, masu a svačinám, které obsahují vysoké množství soli, cukrů a tuku. Pokud se budeme hýbat, chodit pěšky, jezdit na kole nebo veřejnou dopravou a omezíme jízdu autem, bude nám to jedině ku prospěchu.",
  etl_description_3_adult: "Našemu zdraví a životnímu prostředí mohou prospět jednoduché věci: čistý vzduch a voda, bezpečné potraviny, úrodná půda a zelené plochy, ohleduplnost k přírodě a ke všem lidem.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Chceš si zahrát znovu?",
  etl_title_child: "Naše rozhodnutí utvářejí naši budoucnost",
  etl_description_1_child: "Pokud všichni zlepšíme to, jak se stravujeme a žijeme, může být naše budoucnost zdravější.",
  etl_description_2_child: "Dělat pro to můžeš tohle:\n- Dávej si pozor, abys jedl/a rozmanitě. Tím si udržíš zdraví své i naší planety.\n- Jez obilniny, luštěniny, čerstvou zeleninu a ovoce.  \n- Vyhýbej se zpracovaným jídlům, masu a svačinám, které obsahují vysoké množství soli, cukrů a tuku.\n- Hýbej se, choď pěšky, jezdi na kole a veřejnou dopravou.\n- Nejezdi tolik autem.",
  etl_description_3_child: "Tato jednoduchá rozhodnutí mohou prospět tvému zdraví a vést k tomu, že budeme dbát o životní prostředí a všechny lidi.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Chceš si zahrát znovu?",
  share: "Sdílet",
  replay: "Hrát znovu",
  its_up_to_you: "Je to na tobě",
  badge_action_1: "Našel jsi schované překvapení!",
  badge_action_2: "Získáváš odznak %{badge}",
  fridgeDoor: "Dveře lednice",
  light: "Světlo",
  bottle: "Lahev",
  reusable_bag: "Taška na opakované použití",
  plastic_bag: "Plastová taška",
  recycle_bin: "Recyklační koš",
  share_text_subject: "Je to na tobě",
  share_text_body: "Zahrál(a) jsem si hru 2050 – je to na tobě! Chceš vědět, jak vypadá ten tvůj? #ExperienceEUScience\nHraj hned %{shareUrl}\nTuto hru vytvořilo Společné výzkumné středisko, což je útvar pro vědu a znalosti Evropské komise.\nObjev víc https://joint-research-centre.ec.europa.eu/index_cs?etrans=cs"
};
export default i18n;
