const i18n = {
  title: "Gra Zdrowe Społeczeństwo 2050",
  start: "Start",
  select_language: "Wybierz język",
  adult: "Dorosły",
  child: "Dziecko",
  fullscreen: "Pełny ekran",
  restart_scene: "Rozpocznij ponownie scenę",
  restart_game: "Rozpocznij ponownie grę",
  previous: "Poprzednia",
  next: "Następna",
  close: "Zamknij",
  toggle_sound: "Dźwięk włączony/wyłączony",
  character_selection_title: "Wybierz swoją postać",
  character_selection_body: "Nasze wybory kształtują naszą przyszłość. W tej grze symulujemy codzienne sytuacje. Wybierz i poznaj skutki swoich wyborów. Szukaj ukrytych tajemnic, aby zdobyć odznaki! Zacznijmy od stworzenia Twojej postaci.",
  character_set_up_title: "Stwórz swoją postać",
  select_me: "Wybierz mnie",
  breakfast_title: "Jak wygląda Twoje śniadanie?",
  your_badges: "Odznaki",
  badge_water_conservation_master_title: "Mistrz",
  badge_water_conservation_master_description: "Gratulacje! Jesteś mistrzem w oszczędzaniu ograniczonych zasobów! Woda jest podstawą dla wszystkich form życia.",
  badge_clean_street_wizard_title: "Czarodziejski duszek",
  badge_clean_street_wizard_description: "Gratulacje! Jesteś czarodziejem pomagającym oczyszczać nasze środowisko poprzez zbieranie śmieci i wrzucanie ich do kosza na śmieci.",
  badge_eco_ninja_title: "Ninja",
  badge_eco_ninja_description: "Gratulacje! Jesteś ninją oszczędzania energii. Oszczędzanie energii wykorzystywanej przez nasze urządzenia to doskonały sposób na zmniejszenie zużycia energii.",
  badge_energy_saving_expert_title: "Ekspert",
  badge_energy_saving_expert_description: "Gratulacje! Jesteś ekspertem w oszczędzaniu energii. Nieustannie szukaj sposobów na zmniejszenie zużycia energii.",
  badge_low_waste_champion_title: "Lider",
  badge_low_waste_champion_description: "Gratulacje! Jesteś liderem w ograniczaniu ilości odpadów i ochrony środowiska poprzez zmniejszenie zużycia plastiku.",
  badge_responsible_shopper_title: "Profesjonalista",
  badge_responsible_shopper_description: "Gratulacje, jesteś profesjonalistą w dokonywaniu wyborów zdrowych dla środowiska. Zużywaj jak najmniej plastiku, nosząc przy sobie własną torbę wielokrotnego użytku.",
  badge_recycling_captain_title: "Gwiazda",
  badge_recycling_captain_description: "Gratulacje! Jesteś gwiazdą recyklingu. Dla zachowania zdrowego środowiska ważny jest recykling i ponowne wykorzystanie materiałów.",
  faucet: "Kran",
  transportation_title_adult: "Jak docierasz do pracy?",
  transportation_title_child: "Jak docierasz do szkoły?",
  trash: "Śmieci",
  no_drink: "Brak napoju",
  no_drink_description: "",
  tap_water: "Woda z kranu",
  tap_water_description: "Dbaj o nawodnienie. Staraj się pić co najmniej 1,5–2 litrów wody dziennie.",
  milk: "Mleko",
  milk_description: "Wybieraj nieprzetworzone, niesłodzone mleko, najlepiej o niskiej zawartości tłuszczu.",
  freshly_made_juice: "Świeżo wyciskany sok",
  freshly_made_juice_description: "Jeśli lubisz sok, wybieraj świeżo wyciskany – nie musisz dodawać cukru. Staraj się jeść świeże owoce.",
  coffee_or_tea: "Herbata/kawa",
  coffee_or_tea_description: "Pij herbatę i kawę najlepiej bez dodatku cukru.",
  plant_based_drink: "Napój roślinny",
  plant_based_drink_description: "Spróbuj napojów roślinnych, bez dodatku cukru i w miarę możliwości wybieraj te zawierające witaminy i minerały.",
  whole_grain_cereals: "Płatki pełnoziarniste",
  whole_grain_cereals_description: "Wybieraj płatki pełnoziarniste bez dodatku cukru.",
  choco_balls: "Kulki czekoladowe",
  choco_balls_description: "Płatki śniadaniowe mogą stanowić znaczną część naszego dziennego spożycia cukru – czy możesz wybierać te z mniejszą ilością dodatku cukru? Skoro już o tym mowa, wybieraj te z większą zawartością błonnika.",
  white_bread_or_toast: "Białe pieczywo/tosty",
  white_bread_or_toast_description: "Spróbuj pełnoziarnistego pieczywa.",
  wholemeal_bread_or_toast: "Razowe pieczywo/tosty",
  wholemeal_bread_or_toast_description: "Spróbuj pełnoziarnistego pieczywa.",
  biscuits: "Herbatniki",
  biscuits_description: "Herbatniki są wysokokaloryczne i mogą stanowić znaczną część naszego dziennego spożycia cukru i tłuszczów. Lepiej jest ograniczyć ich spożycie. Wybieraj te o niższej zawartości tłuszczu i mniejszej ilości cukru. Przy okazji, wybieraj też te z większą zawartością błonnika.",
  no_topping: "Brak dodatków",
  no_topping_description: "",
  yogurt: "Jogurt",
  yogurt_description: "Spróbuj jogurtu naturalnego o niskiej zawartości tłuszczu bez dodatku cukru.",
  honey: "Miód",
  honey_description: "Wybieraj jogurt naturalny i nie dodawaj cukru ani dżemu. Lepiej dodawaj owoce lub niesłodzone płatki pełnoziarniste.",
  jam: "Dżem",
  jam_description: "Wybieraj jogurt naturalny i nie dodawaj cukru ani dżemu. Lepiej dodawaj owoce lub niesłodzone płatki pełnoziarniste.",
  fruit: "Owoc",
  fruit_description: "Owoce to świetny wybór na rozpoczęcie dnia, staraj się wybierać owoce sezonowe. Jeśli dodajesz orzechy, to najlepiej te bez dodatku soli i cukru.",
  butter: "Masło",
  butter_description: "Masło zawiera duże ilości tłuszczu i może być słone. Spróbuj nie spożywać go zbyt często i wybieraj wersje bez soli.",
  chocolate_cream: "Krem czekoladowy",
  chocolate_cream_description: "Krem czekoladowy zawiera dużo cukru i tłuszczów nasyconych. Staraj się nie jadać go zbyt często.",
  ham_or_bacon: "Szynka/bekon",
  ham_or_bacon_description: "Przetworzone mięso zawiera duże ilości tłuszczu i soli. Jeśli jesz przetworzone mięso, ogranicz jego spożycie.",
  cheese: "Ser",
  cheese_description: "Ser zawiera duże ilości soli i tłuszczów nasyconych. Spróbuj nie spożywać go zbyt często i unikaj dużych ilości.",
  avocado_and_tomato: "Awokado i pomidory",
  avocado_and_tomato_description: "Warzywa to doskonały wybór na początek dnia. Staraj się jeść dużo owoców i warzyw w ciągu dnia, najlepiej lokalnych i sezonowych.",
  battery: "Bateria",
  battery_description: "Uważnie obserwuj baterię, pokazuje ona skutki Twoich wyborów!",
  lunch_title: "Co zjesz na obiad?",
  breaktime_title: "Wolne popołudnie – czas wolny!",
  snacking_title: "Masz ochotę na przekąskę?",
  supermarket_title: "Co kupujesz?",
  dinner_selection_title: "Co planujesz na obiad?",
  dinner_cooking_title: "Co na kolację?",
  dinner_delivery_title: "Co na kolację?",
  your_available_items: "Twoje opcje",
  your_selections: "Wybrane opcje",
  walk: "Pieszo",
  walk_description: "Dbaj o aktywność fizyczną na co dzień. W miarę możliwości chodź pieszo lub dojeżdżaj rowerem do szkoły/pracy. Dzięki temu zachowasz dobrą kondycję fizyczną i będzie to lepsze dla środowiska.",
  bicycle: "Rower",
  bicycle_description: "Dbaj o aktywność fizyczną na co dzień. W miarę możliwości chodź pieszo lub dojeżdżaj rowerem do szkoły/pracy. Dzięki temu zachowasz dobrą kondycję fizyczną i będzie to lepsze dla środowiska.",
  scooter_or_motorbike: "Skuter/motocykl",
  scooter_or_motorbike_description: "Staraj się rzadziej korzystać z samochodu lub motocykla z myślą o czystszym środowisku. Jeśli jest to niemożliwe, staraj się dzielić samochód z innymi.",
  car: "Samochód",
  car_description: "Staraj się rzadziej korzystać z samochodu lub motocykla z myślą o czystszym środowisku. Jeśli jest to niemożliwe, staraj się dzielić samochód z innymi.",
  public_transport: "Transport publiczny",
  public_transport_description: "Korzystaj z transportu publicznego i łącz go z poruszaniem się pieszo i rowerem, zamiast jeździć samochodem. Zrób sobie przerwę od samochodu i pomóż środowisku.",
  salad_with_egg: "Sałatka z jajkiem",
  salad_with_egg_description: "Dodawaj świeże warzywa do swoich posiłków, gdy tylko jest to możliwe.",
  beef_steak: "Stek z wołowiny",
  beef_steak_description: "Jeśli jesz mięso, pomyśl o ograniczeniu jego spożycia, zwłaszcza mięsa czerwonego lub przetworzonego. Jest to korzystne dla zdrowia i środowiska.",
  chicken: "Kurczak",
  chicken_description: "Jeśli jesz mięso, pomyśl o ograniczeniu jego spożycia, zwłaszcza mięsa czerwonego lub przetworzonego. Jest to korzystne dla zdrowia i środowiska.",
  lentil_soup: "Zupa z soczewicy",
  lentil_soup_description: "Regularne spożywanie roślin strączkowych jest zalecane jako część zróżnicowanej diety opartej głównie na roślinach.",
  vegetable_burger: "Burger roślinny",
  vegetable_burger_description: "Burger roślinny może być dobrą alternatywą dla mięsa. Zwróć uwagę, aby nie zawierał zbyt dużo soli.",
  white_pasta: "Makaron biały",
  white_pasta_description: "Wybierając makaron, ryż i pieczywo, postaw na produkty pełnoziarniste.",
  wholegrain_pasta: "Makaron pełnoziarnisty",
  wholegrain_pasta_description: "Wybierając makaron, ryż i pieczywo, postaw na produkty pełnoziarniste.",
  white_rice: "Ryż biały",
  white_rice_description: "Wybierając makaron, ryż i pieczywo, postaw na produkty pełnoziarniste.",
  wholegrain_rice: "Ryż pełnoziarnisty",
  wholegrain_rice_description: "Wybierając makaron, ryż i pieczywo, postaw na produkty pełnoziarniste.",
  white_bread_bun: "Biała bułka",
  white_bread_bun_description: "Wybierając makaron, ryż i pieczywo, postaw na produkty pełnoziarniste.",
  no_salad: "Brak",
  no_salad_description: "",
  fresh_green_salad: "Świeża zielona sałata",
  fresh_green_salad_description: "Dodaj trochę kolorowych, sezonowych warzyw do swoich posiłków, im większe urozmaicenie, tym lepiej.",
  french_fries: "Frytki",
  french_fries_description: "Gotowane ziemniaki są lepsze od produktów ziemniaczanych smażonych na głębokim oleju.",
  boiled_broccoli: "Gotowane brokuły",
  boiled_broccoli_description: "Staraj się jeść dużo warzyw w ciągu dnia. Wybieraj warzywa lokalne i sezonowe.",
  bottled_water: "Woda butelkowana",
  bottled_water_description: "Pij wodę z kranu, aby zmniejszyć zużycie plastiku.",
  soft_drink: "Napoje",
  soft_drink_description: "Napoje słodzone stanowią znaczną część naszego dziennego spożycia cukru – czy możesz zamiast nich wybierać wodę?",
  beer_or_wine: "Piwo/wino",
  beer_or_wine_description: "Jeśli pijesz alkohol dowolnego rodzaju, ogranicz jego spożycie. Unikanie alkoholu jest korzystne dla zdrowia.",
  watching_tv: "Oglądanie telewizji lub granie w gry wideo",
  watching_tv_description: "Zadbaj o codzienną aktywność fizyczną i staraj się ograniczyć czas spędzany w pozycji siedzącej. W wolnym czasie staraj się uprawiać jakiś sport, bawić się z przyjaciółmi lub pomagać swojej społeczności. Jest to korzystne dla zdrowia i społeczeństwa. Unikaj spędzania zbyt wiele czasu przed ekranem.",
  doing_sports: "Uprawianie sportu",
  doing_sports_description: "Zadbaj o codzienną aktywność fizyczną i staraj się ograniczyć czas spędzany w pozycji siedzącej. W wolnym czasie staraj się uprawiać jakiś sport, bawić się z przyjaciółmi lub pomagać swojej społeczności. Jest to korzystne dla zdrowia i społeczeństwa. Unikaj spędzania zbyt wiele czasu przed ekranem.",
  playing_outside: "Zabawa na zewnątrz",
  playing_outside_description: "Zadbaj o codzienną aktywność fizyczną i staraj się ograniczyć czas spędzany w pozycji siedzącej. W wolnym czasie staraj się uprawiać jakiś sport, bawić się z przyjaciółmi lub pomagać swojej społeczności. Jest to korzystne dla zdrowia i społeczeństwa. Unikaj spędzania zbyt wiele czasu przed ekranem.",
  charity_work: "Praca charytatywna z dorosłymi",
  charity_work_description: "Zadbaj o codzienną aktywność fizyczną i staraj się ograniczyć czas spędzany w pozycji siedzącej. W wolnym czasie staraj się uprawiać jakiś sport, bawić się z przyjaciółmi lub pomagać swojej społeczności. Jest to korzystne dla zdrowia i społeczeństwa. Unikaj spędzania zbyt wiele czasu przed ekranem.",
  carrots_tomatoes: "Marchewki/pomidory wiśniowe",
  carrots_tomatoes_description: "Wybieraj owoce, warzywa lub orzechy. Ogranicz przekąski i napoje o dużej zawartości cukru, tłuszczu i soli.",
  fresh_fruit: "Świeże owoce",
  fresh_fruit_description: "Wybieraj owoce, warzywa lub orzechy. Ogranicz przekąski i napoje o dużej zawartości cukru, tłuszczu i soli.",
  chocolate_bar: "Baton czekoladowy",
  chocolate_bar_description: "Wybieraj owoce, warzywa lub orzechy. Ogranicz przekąski i napoje o dużej zawartości cukru, tłuszczu i soli.",
  chips: "Chipsy",
  chips_description: "Wybieraj owoce, warzywa lub orzechy. Ogranicz przekąski i napoje o dużej zawartości cukru, tłuszczu i soli.",
  juice_drink: "Napoje owocowe",
  juice_drink_description: "Zamiast napojów owocowych wybieraj świeże owoce lub świeżo wyciskane soki bez dodatku cukru.",
  supermarket_prompt: "Wybierz maksymalnie 9 produktów",
  raw_fish: "Surowa ryba",
  raw_fish_description: "Regularne spożywanie ryb zapewnia ważne składniki pokarmowe. Spróbuj różnych ryb",
  raw_beef: "Surowa wołowina",
  raw_beef_description: "Jeśli jesz mięso, pomyśl o ograniczeniu jego spożycia, zwłaszcza mięsa czerwonego lub przetworzonego. Jest to korzystne dla zdrowia i środowiska.",
  raw_chicken: "Surowy kurczak",
  raw_chicken_description: "Jeśli jesz mięso, pomyśl o ograniczeniu jego spożycia, zwłaszcza mięsa czerwonego lub przetworzonego. Jest to korzystne dla zdrowia i środowiska.",
  whole_salad: "Całe warzywa sałatkowe -np. Sałata",
  whole_salad_description: "Wybieraj świeże owoce i warzywa, szczególnie te sezonowe i lokalne.",
  packaged_salad: "Sałatka pakowana",
  packaged_salad_description: "Wybieraj świeże owoce i warzywa, szczególnie te sezonowe i lokalne.",
  seasonal_fruit: "Sezonowe owoce lokalne",
  seasonal_fruit_description: "Wybieraj świeże owoce i warzywa, szczególnie te sezonowe i lokalne.",
  exotic_fruit: "Owoce egzotyczne",
  exotic_fruit_description: "Wybieraj świeże owoce i warzywa, szczególnie te sezonowe i lokalne.",
  ham: "Szynka",
  ham_description: "Przetworzone mięso zawiera duże ilości tłuszczu i soli. Jeśli jesz przetworzone mięso, postaraj się ograniczyć jego spożycie.",
  tofu: "Tofu",
  tofu_description: "Poznawaj nowe produkty na bazie roślin i nowe przepisy.",
  hummus: "Hummus",
  hummus_description: "Hummus jest dobrą alternatywą, która pomoże Ci zwiększyć spożycie roślin strączkowych.",
  precooked_pizza: "Gotowa pizza",
  precooked_pizza_description: "Gotowe posiłki mogą zawierać duże ilości soli lub tłuszczu. Wybieraj produkty, które nie są gotowe. Dzięki temu będziesz mógł kontrolować ilość soli, cukru i tłuszczów w swoich posiłkach.",
  precooked_fries: "Gotowe frytki",
  precooked_fries_description: "Gotowe posiłki mogą zawierać duże ilości soli lub tłuszczu. Wybieraj produkty, które nie są gotowe. Dzięki temu będziesz mógł kontrolować ilość soli, cukru i tłuszczów w swoich posiłkach.",
  food_delivery: "Dostawa żywności",
  food_delivery_description: "",
  cooking_at_home: "Gotowanie w domu",
  cooking_at_home_description: "",
  cheeseburger: "Cheeseburger, frytki i napój",
  cheeseburger_description: "Unikaj spożywania tego typu posiłków. Zawierają one dużo tłuszczu i soli. Jako napój wybieraj wodę.",
  pizza_and_soft_drink: "Pizza i napój",
  pizza_and_soft_drink_description: "Takie posiłki mają wysoką zawartość tłuszczu, soli i cukru. Wybieraj „proste” pizze z mniejszą ilością składników lub większą ilością warzyw, a jeśli to możliwe, decyduj się na pełnoziarniste ciasto do pizzy. Jako napój wybieraj wodę.",
  noodles_with_vegetables: "Makaron z warzywami",
  noodles_with_vegetables_description: "Jest to dobry sposób, aby zwiększyć spożycie warzyw. Dodaj trochę roślin strączkowych, aby uzupełnić swój posiłek.",
  mushroom_risoto: "Risotto z grzybami",
  mushroom_risoto_description: "Grzyby są dobrym rozwiązaniem wegetariańskim. Uważaj, aby nie dodać zbyt dużo sera. Możesz uzupełnić ten posiłek sałatką.",
  precooked_meal: "Gotowy posiłek - nuggetsy z kurczaka",
  precooked_meal_description: "Gotowe posiłki mogą zawierać duże ilości soli, tłuszczu, a nawet cukru. Wybieraj produkty, które nie są gotowe. Możesz lepiej kontrolować ilość tych składników odżywczych. Aby nadać smak potrawom, zamiast soli używaj ziół i przypraw.",
  frozen_pizza: "Mrożona pizza",
  frozen_pizza_description: "Gotowe posiłki mogą zawierać duże ilości soli, tłuszczu, a nawet cukru. Wybieraj produkty, które nie są gotowe. Możesz lepiej kontrolować ilość tych składników odżywczych. Aby nadać smak potrawom, zamiast soli używaj ziół i przypraw.",
  rice_and_salad: "Samodzielnie ugotowany posiłek z ryżem i zielona sałatka",
  rice_and_salad_description: "Gotowe posiłki mogą zawierać duże ilości soli, tłuszczu, a nawet cukru. Wybieraj produkty, które nie są gotowe. Możesz lepiej kontrolować ilość tych składników odżywczych. Aby nadać smak potrawom, zamiast soli używaj ziół i przypraw.",
  ham_sandwich_and_soft_drink: "Kanapka z szynką i napój",
  ham_sandwich_and_soft_drink_description: "Przetworzone mięso zawiera duże ilości tłuszczu i soli. Jeśli jesz przetworzone mięso, postaraj się ograniczyć jego spożycie. Staraj się również unikać napojów, w tym słodzonych. Zamiast tego pij wodę z kranu.",
  wholegrain_pasta_with_sauce: "Makaron pełnoziarnisty z sosem warzywnym",
  wholegrain_pasta_with_sauce_description: "Spróbuj pełnoziarnistego makaronu.",
  hnw_title_adult: "Nowy, zdrowy świat",
  hnw_description_1_adult: "Nasze wybory kształtują naszą przyszłość. Jeśli wszyscy będziemy jeść i żyć tak jak Ty w grze, nasza przyszłość może być zdrowsza, a ludzie będą dbać o siebie nawzajem i o środowisko.",
  hnw_description_2_adult: "Naukowcy konsekwentnie twierdzą, że aby zachować zdrowie nasze i naszej planety, powinniśmy stosować zróżnicowaną dietę: bogatą w zboża, rośliny strączkowe, świeże warzywa i owoce. Unikaj przetworzonej żywności, mięsa i przekąsek o wysokiej zawartości soli, cukru i tłuszczu. Korzyści są większe, jeśli jesteśmy aktywni fizycznie, chodzimy pieszo, jeździmy na rowerze lub korzystamy z transportu publicznego i ograniczamy korzystanie z samochodów.",
  hnw_description_3_adult: "Proste rzeczy mogą poprawić stan Twojego zdrowia i naszego środowiska: czyste powietrze i woda, bezpieczna żywność, żyzne gleby i zielone przestrzenie, szacunek dla natury i wszystkich ludzi. Pomyśl o tym również niezależnie od tej gry. Zaproś przyjaciół i rodzinę do wzięcia udziału.",
  hnw_description_4_adult: "",
  hnw_description_5_adult: "",
  hnw_description_6_adult: "Chcesz zagrać jeszcze raz?",
  hnw_title_child: "Nasze wybory kształtują naszą przyszłość",
  hnw_description_1_child: "Jeśli wszyscy będziemy jeść i żyć tak jak Ty w grze, nasza przyszłość może być zdrowsza.",
  hnw_description_2_child: "Możesz to zrobić w taki sposób:\n- przestrzegaj zróżnicowanej diety, aby zachować zdrowie nasze i naszej planety;\n- wybieraj zboża, rośliny strączkowe, świeże warzywa i owoce;\n- ogranicz przetworzoną żywność, mięso i przekąski o wysokiej zawartości soli, cukru i tłuszczu;\n- dbaj o aktywność fizyczną, spaceruj, jeźdź na rowerze, korzystaj z transportu publicznego;\n- ogranicz korzystanie z samochodu.",
  hnw_description_3_child: "Te proste wybory mogą poprawić stan Twojego zdrowia i spowodować, że będziemy dbać o środowisko i wszystkich ludzi.",
  hnw_description_4_child: "Pomyśl o tym również niezależnie od tej gry.",
  hnw_description_5_child: "Zaproś przyjaciół i rodzinę do wzięcia udziału.",
  hnw_description_6_child: "Chcesz zagrać jeszcze raz?",
  etl_title_adult: "Jedz, aby żyć",
  etl_description_1_adult: "Nasze wybory kształtują naszą przyszłość. Być może nie zdajesz sobie sprawy z tego, że Twój styl życia może mieć wpływ na zdrowie naszej planety.",
  etl_description_2_adult: "Naukowcy konsekwentnie twierdzą, że aby zachować zdrowie nasze i naszej planety, powinniśmy stosować zróżnicowaną dietę: bogatą w zboża, rośliny strączkowe, świeże warzywa i owoce. Unikaj przetworzonej żywności, mięsa i przekąsek o wysokiej zawartości soli, cukru i tłuszczu. Korzyści są większe, jeśli jesteśmy aktywni fizycznie, chodzimy pieszo, jeździmy na rowerze lub korzystamy z transportu publicznego i ograniczamy korzystanie z samochodów.",
  etl_description_3_adult: "Proste rzeczy mogą poprawić stan Twojego zdrowia i naszego środowiska: czyste powietrze i woda, bezpieczna żywność, żyzne gleby i zielone przestrzenie, szacunek dla natury i wszystkich ludzi.",
  etl_description_4_adult: "",
  etl_description_5_adult: "",
  etl_description_6_adult: "Chcesz zagrać jeszcze raz?",
  etl_title_child: "Nasze wybory kształtują naszą przyszłość",
  etl_description_1_child: "Jeśli wszyscy będziemy się lepiej odżywiać i żyć, możemy mieć zdrowszą przyszłość.",
  etl_description_2_child: "Możesz to zrobić w taki sposób:\n- przestrzegaj zróżnicowanej diety, aby zachować zdrowie nasze i naszej planety;\n- wybieraj zboża, rośliny strączkowe, świeże warzywa i owoce;\n- ogranicz przetworzoną żywność, mięso i przekąski o wysokiej zawartości soli, cukru i tłuszczu;\n- dbaj o aktywność fizyczną, spaceruj, jeźdź na rowerze, korzystaj z transportu publicznego;\n- ogranicz korzystanie z samochodu.",
  etl_description_3_child: "Te proste wybory mogą poprawić stan Twojego zdrowia i spowodować, że będziemy dbać o środowisko i wszystkich ludzi.",
  etl_description_4_child: "",
  etl_description_5_child: "",
  etl_description_6_child: "Chcesz zagrać jeszcze raz?",
  share: "Udostępnij",
  replay: "Zagraj ponownie",
  its_up_to_you: "To Twój wybór",
  badge_action_1: "Ujawniono ukrytą tajemnicę!",
  badge_action_2: "Przepustka %{badge} zdobyta",
  fridgeDoor: "Drzwi lodówki",
  light: "Światło",
  bottle: "Butelka",
  reusable_bag: "Torba wielokrotnego użytku",
  plastic_bag: "Torba plastikowa",
  recycle_bin: "Pojemnik do recyklingu",
  share_text_subject: "To Twój wybór",
  share_text_body: "Gram w 2050 r. – to Twój wybór! Chcesz wiedzieć, jak wygląda Twój świat? #ExperienceEUScience\nZagraj teraz %{shareUrl}\nGra została opracowana przez Wspólne Centrum Badawcze, służby Komisji Europejskiej ds. nauki i wiedzy.\nWięcej informacji https://joint-research-centre.ec.europa.eu/index_pl?etrans=pl"
};
export default i18n;
