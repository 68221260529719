import "modals/AvailableItemsAndSelections.scss";
import Modal from "react-modal";
import PropTypes from "prop-types";
import exact from "prop-types-exact";
import { selectableToPoints } from "game-score.js";
import { elevateDown } from "common/sounds.js";

const AvailableItemsAndSelections = ({ t, availableItems, selections, isOpen, closeModal }) => {
  const showSelections = selections !== undefined;
  const items = showSelections ? selections : availableItems;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={t(showSelections ? "your_selections" : "your_available_items")}
      className="AvailableItemsAndSelections"
    >
      <h2>{t(showSelections ? "your_selections" : "your_available_items")}</h2>
      <div className="items">
        {items.map((item, index) => (
          <div key={index} className={`item ${item}`}>
            <div className="item-text">
              <h3>{t(item.replace(/-/g, "_"))}</h3>
              <p>{t(item.replace(/-/g, "_") + "_description")}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          closeModal();
          elevateDown();
        }}
        className="close"
      >
        {t("close")}
      </button>
    </Modal>
  );
};

AvailableItemsAndSelections.propTypes = exact({
  t: PropTypes.func.isRequired,
  availableItems: PropTypes.arrayOf(PropTypes.oneOf(selectableToPoints.map(selectable => selectable.name))).isRequired,
  selections: PropTypes.arrayOf(PropTypes.oneOf(selectableToPoints.map(selectable => selectable.name))),
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
});

export default AvailableItemsAndSelections;
