import React from "react";

import { useRef, useEffect } from "react";

const PADDING = 10;

const toggleScrollClasses = ({ target: element }) => {
  if (element.clientWidth >= element.scrollWidth) {
    element.classList.remove("has-more-before");
    element.classList.remove("has-more-after");
    return;
  }
  if (element.scrollLeft > PADDING) {
    element.classList.add("has-more-before");
  } else {
    element.classList.remove("has-more-before");
  }
  if (element.scrollWidth - element.clientWidth - element.scrollLeft > PADDING) {
    element.classList.add("has-more-after");
  } else {
    element.classList.remove("has-more-after");
  }
};

const ScrollableArrowsHintClasses = ({ children }) => {
  React.Children.only(children);
  const fragmentRef = useRef();

  // Executes once only to assign listeners.
  useEffect(() => {
    const toggle = () => toggleScrollClasses({ target: fragmentRef.current });
    fragmentRef.current.addEventListener("scroll", toggle);
    window.addEventListener("resize", toggle);
    return () => {
      window.removeEventListener("resize", toggle);
    };
  }, []);

  // Executes always, on children change.
  useEffect(() => {
    toggleScrollClasses({ target: fragmentRef.current });
  }, [children]);

  // https://stackoverflow.com/a/29581089/72478
  // https://codesandbox.io/s/reverent-benz-c4f3c2?file=/src/App.js
  const cloneWithRef = React.cloneElement(children, {
    ref: element => {
      fragmentRef.current = element;
      if (children.ref) {
        if (typeof children.ref === "function") {
          children.ref(element);
        } else {
          children.ref.current = element;
        }
      }
    }
  });

  return cloneWithRef;
};

export default ScrollableArrowsHintClasses;
